import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogWorkspacesMembersAction } from '../../../../actions/updateDialogWorkspacesMembersAction';
import { updateStickyWorkspaceMemberStatusWithThunk } from '../../../../api/workspacesMembersThunk';
import { removeStickyWorkspaceMemberWithThunk } from '../../../../api/workspacesMembersThunk';

class WorkspacesMembersModal extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        dialog: PropTypes.object.isRequired,
        workspace: PropTypes.object.isRequired,
        sorting: PropTypes.object.isRequired,
        dispatchDialogWorkspacesMembers: PropTypes.func.isRequired,
        updateWorkspaceMemberStatus: PropTypes.func.isRequired,
        removeWorkspaceMember: PropTypes.func.isRequired,
        pagination: PropTypes.func.isRequired
    };

    setDefaultDialogState() {
        let workspaces_members = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            show_dialog: false
        };

        this.props.dispatchDialogWorkspacesMembers(workspaces_members);
    }

    updateWorkspaceMember() {
        const { dialog, type } = this.props;

        this.setDefaultDialogState();

        if (type === "list") {
            if (dialog.dialog_type === 'update_status') {
                const { workspace } = this.props;
                const status = (dialog.status === 'Active') ? "Suspended" : "Active";

                this.props.updateWorkspaceMemberStatus(workspace.id, dialog.id, status);
            }
            else if (dialog.dialog_type === "remove_member") {
                const { workspace } = this.props;

                this.props.removeWorkspaceMember(workspace.id, dialog.id);
            }
        }
    }

    closeModal() {
        this.setDefaultDialogState();
    }

    render() {
        const { dialog } = this.props;
        const dialogCommand = (dialog.dialog_type === "update_status") ? "SUSPEND" : "REMOVE";

        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{dialog.dialog_title}</h2>
                            <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody">
                        <div className="p-4">
                            <div className="summernote">
                                <p>
                                    Are you sure you want to <b className={(dialog.dialog_type === "remove_member") ? "actionRemove" : ''}>{dialogCommand}</b> user
                                    &nbsp;<b className="appName">{dialog.name}</b>&nbsp;?
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-2">
                            <div className="row">
                                <div className="col">
                                    <button className="btn greyBtn icoTextBtn closePopup" onClick={() => { this.closeModal(); }}>
                                        <span className="icon-wrd-close"></span>
                                        Cancel
                                    </button>
                                </div>
                                <div className="col text-right">
                                    <button className="redBtn btn icoTextBtn mr-3 closePopup"
                                        onClick={() => { this.updateWorkspaceMember(); }}
                                    >
                                        <span className={(dialog.dialog_type === "update_status") ? "icon-wrd-lock" : "icon-wrd-trash"}></span>
                                        {(dialog.dialog_type === "update_status") ? "Suspend" : "Remove"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ dialog, detail, search, sorting }) => ({
    dialog: dialog.workspaces_members,
    workspace: detail.workspace,
    search: search.member,
    sorting: sorting.member
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogWorkspacesMembers: (dialog) => dispatch(updateDialogWorkspacesMembersAction(dialog)),
    updateWorkspaceMemberStatus: (workspaceId, userId, status) => dispatch(updateStickyWorkspaceMemberStatusWithThunk(workspaceId, userId, status)),
    removeWorkspaceMember: (workspaceId, userId) => dispatch(removeStickyWorkspaceMemberWithThunk(workspaceId, userId))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesMembersModal));
