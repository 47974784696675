import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setupNewUserAction } from '../../../actions/setupNewUserAction'
import { createUserWithThunk } from '../../../api/usersThunk';
import { createWorkspacesMembersWithThunk } from '../../../api/workspacesMembersThunk';
import { getUrlWorkspaceId } from '../../../utils/url';
import { overrideWorkspaceId } from '../../../utils/workspaces';
import { routeCodes } from '../../../configurations/routes';

class UserCreateAction extends Component {
    constructor(props) {
        super(props);
        this.createNewUser = this.createNewUser.bind(this);
        this.cancelCreate = this.cancelCreate.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        routeBack: PropTypes.object.isRequired,
        setupNewUser: PropTypes.func.isRequired,
        createUser: PropTypes.func.isRequired,
        createWorkspaceMember: PropTypes.func.isRequired,
        scrollTop: PropTypes.func.isRequired
    };

    cancelCreate() {
        this.props.history.goBack()
    }

    async createNewUser() {
        const { user, routeBack } = this.props;

        this.props.scrollTop();
        if (routeBack.source_page === "") {
            let id = getUrlWorkspaceId(this.props.location.pathname);
            if (id !== "") {
                let wId = await overrideWorkspaceId(id);
                this.props.createWorkspaceMember(wId, user, routeCodes.WORKSPACES_LIST + "/" + id + "/members");
            }
            else {
                this.props.createUser(user, routeCodes.USERS_LIST);
            }
        }
        else if (routeBack.source_page === "workspace_member") {
            const { workspace } = this.props;
            this.props.createWorkspaceMember(workspace.id, user, routeBack.url)
        }
        else {
            this.props.createUser(user, routeBack.url);
        }
    }

    render() {
        return (
            <div className="col-md-12">
                <div className="inputForm buttonGroup mt-2 text-center">
                    <button className="btn greyBtn icoTextBtn mx-2" onClick={this.cancelCreate}><span className="icon-wrd-close"></span>Cancel</button>
                    <button className="btn greenBtn icoTextBtn mx-2" onClick={this.createNewUser}><span className="icon-wrd-add"></span> Create User</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, setup, routeBack }) => ({
    workspace: detail.workspace,
    user: setup.user,
    routeBack: routeBack.create_user
});

const mapDispatchToProps = (dispatch) => ({
    setupNewUser: (user) => dispatch(setupNewUserAction(user)),
    createUser: (user, url) => dispatch(createUserWithThunk(user, url)),
    createWorkspaceMember: (id, member, url) => dispatch(createWorkspacesMembersWithThunk(id, member, url))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserCreateAction));