
import { GET_WORKSPACES_SHARE_LIST, UPDATE_WORKSPACES_SHARE_LIST, CLEAR_WORKSPACES_SHARE_LIST } from '../actions/index';

const getWorkspacesShareList = (workspacesShareList = [], action) => {
    let itemsKeys = [];
    let workspacesKeys = action.workspaces.reduce((keys, workspace) => {
        itemsKeys.push(workspace.id);
        keys = itemsKeys;
        return keys;
    }, []);

    let result = workspacesShareList.filter((workspace) => {
        return workspacesKeys.indexOf(workspace.id) === -1;
    });

    return result.concat(action.workspaces);
}

const updateWorkspacesShareList = (workspacesShareList = [], action) => {
    let itemsKeys = [];
    let workspacesKeys = action.workspaces.reduce((keys, workspace) => {
        itemsKeys.push(workspace.id);
        keys = itemsKeys;
        return keys;
    }, []);

    let result = workspacesShareList.filter((workspace) => {
        return workspacesKeys.indexOf(workspace.id) === -1;
    });

    return result.concat(action.workspaces);
}

const clearWorkspacesShareList = (action) => {
    return action.workspaces;
}

export const workspacesShareList = (workspacesShareList = [], action) => {
    switch (action.type) {
        case GET_WORKSPACES_SHARE_LIST: return getWorkspacesShareList(workspacesShareList, action);
        case UPDATE_WORKSPACES_SHARE_LIST: return updateWorkspacesShareList(workspacesShareList, action);
        case CLEAR_WORKSPACES_SHARE_LIST: return clearWorkspacesShareList(action);
        default: return workspacesShareList;
    }
};
