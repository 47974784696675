import { updateImage } from '../../utils/snapshot';
import { axios, queryBaseUrlV1, queryBaseUrlV2 } from './getCookies';

async function getWorkspaceSettings(workspaceId) {
  const results = await axios.get(
    queryBaseUrlV1 + 'workspace/setting/' + workspaceId
  );

  return results;
}

async function getWorkspaceSettingLogoById(workspaceId) {
  const results = await axios.get(
    queryBaseUrlV1 + 'workspace/setting/logo/' + workspaceId
  );

  return results;
}

async function getWorkspaceThemeSettings(workspaceId) {
  const results = await axios.get(
    queryBaseUrlV1 + 'workspace/' + workspaceId + '/setting/theme'
  );

  return results;
}

async function getWorkspaceSettingById(workspaceId) {
  const results = await axios
    .get(queryBaseUrlV2 + 'workspace/' + workspaceId + '/settings')
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getWorkspaceThemeSettingImplementationById(workspaceId) {
  const results = await axios
    .get(queryBaseUrlV1 + 'workspace/' + workspaceId + '/theme')
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getWorkspaceCustomThemeFile(workspaceId) {
  const results = await axios
    .get(
      queryBaseUrlV1 +
        'workspace/' +
        workspaceId +
        '/setting/theme/custom/style'
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getWorkspaceSettingBannerById(workspaceId) {
  const results = await axios
    .get(queryBaseUrlV2 + 'workspace/' + workspaceId + '/banner')
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getWorkspaceSettingIncludeById(workspaceId, includeOnly) {
  const results = await axios
    .get(
      queryBaseUrlV2 +
        'workspace/' +
        workspaceId +
        '/settings?includeOnly=' +
        includeOnly
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getWorkspaceLoginSettingById(workspaceId) {
  const results = await axios
    .get(queryBaseUrlV1 + 'workspace/' + workspaceId + '/setting/login')
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getWorkspaceLoginBackgroundSettingById(workspaceId) {
  const results = await axios
    .get(
      queryBaseUrlV1 +
        'workspace/' +
        workspaceId +
        '/setting/login/background?thumbnail=false'
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getWorkspaceLoginWelcomeBackgroundSettingById(workspaceId) {
  const results = await axios
    .get(
      queryBaseUrlV1 +
        'workspace/' +
        workspaceId +
        '/setting/login/welcome-background?thumbnail=false'
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getWorkspaceEmailSettingById(workspaceId) {
  const results = await axios
    .get(queryBaseUrlV1 + 'workspace/' + workspaceId + '/setting/email')
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getWorkspaceThemeSettingById(id) {
  const results = await axios
    .get(queryBaseUrlV1 + 'workspace/' + id + '/setting/theme')
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function updateWorkspaceSetting(workspaceSetting) {
  const results = await axios
    .put(
      queryBaseUrlV1 + 'workspace/setting/' + workspaceSetting.id,
      workspaceSetting
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function updateWorkspaceSettingLogo(workspaceId, content) {
  const url = queryBaseUrlV2 + 'workspace/setting/image/' + workspaceId;

  return await updateImage(content, url);
}

async function updateWorkspaceSettingBanner(workspaceId, content) {
  const url = queryBaseUrlV2 + 'workspace/setting/banner/' + workspaceId;

  return await updateImage(content, url);
}

async function updateWorkspaceLoginSetting(workspaceId, workspaceSetting) {
  const results = await axios
    .put(
      queryBaseUrlV1 + 'workspace/' + workspaceId + '/setting/login/',
      workspaceSetting
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function updateWorkspaceLoginSettingBackground(workspaceId, content) {
  const url =
    queryBaseUrlV2 + 'workspace/' + workspaceId + '/setting/login/background';

  return await updateImage(content, url);
}

async function updateWorkspaceWelcomeSettingBackground(workspaceId, content) {
  const url =
    queryBaseUrlV2 +
    'workspace/' +
    workspaceId +
    '/setting/login/welcome-background';

  return await updateImage(content, url);
}

async function updateWorkspaceEmailSetting(workspaceId, workspaceSetting) {
  const results = await axios
    .put(
      queryBaseUrlV1 + 'workspace/' + workspaceId + '/setting/email/',
      workspaceSetting
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function updateWorkspaceThemeSetting(workspaceId, workspaceSetting) {
  const results = await axios
    .put(
      queryBaseUrlV2 + 'workspace/' + workspaceId + '/setting/theme/',
      workspaceSetting
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

export {
  getWorkspaceSettings,
  getWorkspaceSettingLogoById,
  getWorkspaceThemeSettings,
  getWorkspaceThemeSettingImplementationById,
  getWorkspaceCustomThemeFile,
  getWorkspaceSettingById,
  getWorkspaceSettingBannerById,
  getWorkspaceSettingIncludeById,
  getWorkspaceLoginSettingById,
  getWorkspaceLoginBackgroundSettingById,
  getWorkspaceLoginWelcomeBackgroundSettingById,
  getWorkspaceEmailSettingById,
  getWorkspaceThemeSettingById,
  updateWorkspaceSetting,
  updateWorkspaceSettingLogo,
  updateWorkspaceSettingBanner,
  updateWorkspaceLoginSetting,
  updateWorkspaceLoginSettingBackground,
  updateWorkspaceWelcomeSettingBackground,
  updateWorkspaceEmailSetting,
  updateWorkspaceThemeSetting,
};
