import { GET_USERS, UPDATE_USERS, CLEAR_USERS, CREATE_USER } from '../actions/index';

const getUsers = (users = [], action) => {
    let itemsKeys = [];
    let usersKeys = action.users.reduce((keys, user) => {
        itemsKeys.push(user.id);
        keys = itemsKeys;
        return keys;
    }, []);

    let result = users.filter((user) => {
        return usersKeys.indexOf(user.id) === -1;
    });

    return result.concat(action.users);
}

const updateUsers = (users = [], action) => {
    const us = users.map((item) => {
        const changesUsers = action.users.filter((change) => change.id === item.id)
        if (changesUsers.count > 0) {
            return changesUsers[0];
        }

        return item
    })

    return us;
}

const clearUsers = (action) => {
    return action.users;
}

const createUser = (users = [], action) => {
    return users.concat(action.user);
}

export const users = (users = [], action) => {
    switch (action.type) {
        case GET_USERS: return getUsers(users, action);
        case UPDATE_USERS: return updateUsers(users, action);
        case CLEAR_USERS: return clearUsers(action);
        case CREATE_USER: return createUser(users, action);
        default: return users;
    }
};