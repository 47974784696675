import { axios, queryBaseUrlV1, queryBaseUrlV2, getCookies } from './getCookies';

const CircularJSON = require('circular-json');

async function getApplicationAvailability(domain, port) {
    const results = await axios.get(queryBaseUrlV2 + "portal/utils/ping/" + domain + "/" + port)
        .catch(function (error) { return error.response });

    return results;
}

async function getPortalBanner(exDisable, thumb) {
  const results = await axios.get(queryBaseUrlV1 + "portal/banner/list?excludeDisable=" + exDisable + "&thumbnail=" + thumb)
        .catch(function (error) { return error.response; });
    return results;
}

async function getPortalSingleFeature(id) {
   const results = await axios.get(queryBaseUrlV1 + "portal/feature/" + id)
        .catch(function (error) { return error.response; });
    return results;
}

async function getPortalFeature(exDisable, thumb) {
    const results = await axios.get(queryBaseUrlV1 + "portal/feature/list?excludeDisable=" + exDisable + "&thumbnail=" + thumb + "&pageIndex=1&pageSize=10")
        .catch(function (error) { return error.response; });
    return results;
}

async function getPortalSingleNews(id) {
   const results = await axios.get(queryBaseUrlV1 + "portal/news/" + id)
        .catch(function (error) { return error.response; });
    return results;
}

async function getPortalNews(exDisable, thumb) {
    const results = await axios.get(queryBaseUrlV1 + "portal/news/list?excludeDisable=" + exDisable + "&thumbnail=" + thumb + "&pageIndex=1&pageSize=10")
        .catch(function (error) { return error.response; });
    return results;
}

async function getPortalLogin(thumb) {
    const results = await axios.get(queryBaseUrlV1 + "portal/setting/login?thumbnail=" + thumb)
        .catch(function (error) { return error.response; });
    return results;
}

async function getPortalTheme(exDisable) {
    const results = await axios.get(queryBaseUrlV1 + "portal/setting/themes/list?excludeDisable=" + exDisable)
        .catch(function (error) { return error.response; });
    return results;
}

async function getPortalThemeSetting(width, height) {
   const results = await axios.get(queryBaseUrlV2 + "portal/setting/themes/list?image=true&width=" + width + "&height=" + height);
    return results;
}

async function getPortalThemeById(themeId) {
   const results = await axios.get(queryBaseUrlV1 + "portal/setting/themes/" + themeId + "/style");
    return results;
}

async function getPortalEmail() {
   const results = await axios.get(queryBaseUrlV1 + "portal/setting/email/list");
    return results;
}

async function addPortalBanner(bannerSetting) {
    const results = await axios.post(queryBaseUrlV1 + "portal/banner", bannerSetting)
        .catch(function (error) { return error.response; });

    return results;
}

async function addPortalFeature(featureSetting) {
   const results = await axios.post(queryBaseUrlV1 + "portal/feature", featureSetting)
        .catch(function (error) { return error.response; });

    return results;
}

async function addPortalNews(newsSetting) {
    const results = await axios.post(queryBaseUrlV1 + "portal/news", newsSetting)
        .catch(function (error) { return error.response; });

    return results;
}

async function addPortalTheme(themeSetting) {
    const results = await axios.post(queryBaseUrlV1 + "portal/setting/themes", themeSetting)
        .catch(function (error) { return error.response; });

    return results;
}

async function addPortalThemeFile(themeId, cssFile) {
   const results = await axios.post(queryBaseUrlV1 + "portal/setting/themes/" + themeId + "/upload", cssFile)
        .catch(function (error) { return error.response; });

    return results;
}

async function updatePortalBanner(bannerid, bannerSetting) {
   const results = await axios.put(queryBaseUrlV1 + "portal/banner/" + bannerid, bannerSetting)
        .catch(function (error) { return error.response; });

    return results;
}

async function updatePortalFeature(featureid, featureSetting) {
    const results = await axios.put(queryBaseUrlV1 + "portal/feature/" + featureid, featureSetting)
        .catch(function (error) { return error.response; });

    return results;
}

async function updatePortalNews(newsid, newsSetting) {
    const results = await axios.put(queryBaseUrlV1 + "portal/news/" + newsid, newsSetting)
        .catch(function (error) { return error.response; });

    return results;
}

async function updatePortalLogin(loginSetting) {
    const results = await axios.put(queryBaseUrlV1 + "portal/setting/login", loginSetting).then((response) => {
        let json = CircularJSON.stringify(response);
        return JSON.parse(json);
    }).catch((error) => {
        return error.response;
    });

    return results;
}

async function updatePortalEmail(emailSetting) {
    const results = await axios.put(queryBaseUrlV1 + "portal/setting/email", emailSetting).then((response) => {
        let json = CircularJSON.stringify(response);
        return JSON.parse(json);
    }).catch((error) => {
        return error.response;
    });

    return results;
}

async function updatePortalTheme(themeid, themeSetting) {
   const results = await axios.put(queryBaseUrlV1 + "portal/setting/themes/" + themeid, themeSetting)
        .catch(function (error) { return error.response; });

    return results;
}

async function removePortalBanner(bannerid) {
   const results = await axios.delete(queryBaseUrlV1 + "portal/banner/" + bannerid)
        .catch(function (error) { return error.response; });

    return results;
}

async function removePortalFeature(featureid) {
    const results = await axios.delete(queryBaseUrlV1 + "portal/feature/" + featureid)
        .catch(function (error) { return error.response; });

    return results;
}

async function removePortalNews(newsid) {
    const results = await axios.delete(queryBaseUrlV1 + "portal/news/" + newsid)
        .catch(function (error) { return error.response; });

    return results;
}

async function removePortalTheme(themeid) {
    const results = await axios.delete(queryBaseUrlV1 + "portal/setting/themes/" + themeid)
        .catch(function (error) { return error.response; });

    return results;
}

async function getPortalIcons() {
    const results = await axios.get(getCookies.baseURL + '/icons',)
        .catch(function (error) { return error.response; });
    return results;
}

async function postPortalIcon(icon) {
    let data = new FormData();
    data.append('fileName', icon.name)
    data.append('image', icon, icon.name);
  
    const iconId = await axios
      .post(
        getCookies.baseURL + '/icon',
        data,
        {
          headers: {
            'Content-Type': `multipart/form-data`,
          },
        }
      )
      .catch((error) => {
        return error.response;
      });
    return iconId;
}

export {
    getApplicationAvailability,
    getPortalBanner,
    getPortalSingleFeature,
    getPortalFeature,
    getPortalSingleNews,
    getPortalNews,
    getPortalLogin,
    getPortalTheme,
    getPortalThemeSetting,
    getPortalThemeById,
    getPortalEmail,
    addPortalBanner,
    addPortalFeature,
    addPortalNews,
    addPortalTheme,
    addPortalThemeFile,
    updatePortalFeature,
    updatePortalNews,
    updatePortalLogin,
    updatePortalEmail,
    updatePortalTheme,
    updatePortalBanner,
    removePortalBanner,
    removePortalFeature,
    removePortalNews,
    removePortalTheme,
    getPortalIcons,
    postPortalIcon,
}
