import { v1 as uuidv1 } from 'uuid';
import { axios } from '../backend/api/getCookies';

const FormData = require('form-data');

export async function createImage(content, url) {
    let filePathName = generateFilePathName();

    if (content) {
        const blob64 = b64toBlob(content, 'image/png');
        const form = new FormData();
        form.append('image', blob64, {
            filepath: filePathName,
            contentType: 'image/png'
        });

        const results = await postImage(url, form);
        if (results.data !== undefined) {
            return results.data;
        }
    }
}

export async function updateImage(content, url) {
    let filePathName = generateFilePathName();

    if (content) {
        const blob64 = b64toBlob(content, 'image/png');
        const form = new FormData();

        form.append('image', blob64, {
            filepath: filePathName,
            contentType: 'image/png',
        });

        const results = await putImage(url, form);
        if (results.data !== undefined) {
            return results.data;
        }
    }
}

export function generateFilePathName() {
    let filePathName = uuidv1() + ".png";
    return filePathName;
};

async function postImage(url, form) {
    return await axios.post(url, form, {
        headers: { "Content-Type": "multipart/form-data" }
    });
}

async function putImage(url, form) {
    let result = await axios.put(url, form, {
        headers: { "Content-Type": "multipart/form-data" },
        maxContentLength: Infinity,
        maxBodyLength: Infinity
    });

    return result;
}

export function getImageSize(base64Image, text) {
    let buffer = Buffer.from(base64Image, 'base64');

    if (!buffer.length) {
        return {
            'width': 1200,
            'height': 900
        }
    } 
    if (buffer.toString('ascii', 12, 16) === 'CgBI') {
        return {
            'width': buffer.readUInt32BE(32),
            'height': buffer.readUInt32BE(36)
        };
    }
    return {
        'width': buffer.readUInt32BE(16),
        'height': buffer.readUInt32BE(20)
    };
}

export function b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
}
