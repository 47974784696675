import { store } from '../index';
import { getWorkspacesMembersAction } from '../actions/getWorkspacesMembersAction';
import { updateWorkspacesMembersAction } from '../actions/updateWorkspacesMembersAction';
import { clearWorkspacesMembersAction } from '../actions/clearWorkspacesMembersAction';

export const getWorkspaceMemberHeaders = () => {
    const headers = [
        { id: 1, header_name: "Picture", sort_column: "picture" },
        { id: 2, header_name: "User Detail", sort_column: "UserName" },
        { id: 3, header_name: "Organization", sort_column: "organization" },
        { id: 4, header_name: "Role", sort_column: "role" },
        { id: 5, header_name: "Registration", sort_column: "registration_status" },
        { id: 6, header_name: "Status", sort_column: "user_status" },
        { id: 7, header_name: "Access", sort_column: "member_status" }
    ];

    return headers;
};

export const updateWorkspacesMembers = (workspacesMembers) => {
    const currentWorkspacesMembers = store.getState().workspacesMembers;
    let u = [];

    u = u.concat(currentWorkspacesMembers);
    u = u.concat(workspacesMembers);

    store.dispatch(getWorkspacesMembersAction(u))
} 

export const updateWorkspacesMembersRole = (workspaceId, userId, role) => {
    const workspacesMembers = store.getState().workspacesMembers;

    for (let i in workspacesMembers) {
        let workspaceMember = workspacesMembers[i];
        if (workspaceMember.workspace_id === workspaceId && workspaceMember.uid === userId) {
            workspaceMember.user_role = role;
            break;
        }
    }

    store.dispatch(updateWorkspacesMembersAction(workspacesMembers))
} 

export const updateWorkspacesMembersStatus = (workspaceId, userId, status) => {
    const workspacesMembers = store.getState().workspacesMembers;

    for (let i in workspacesMembers) {
        let workspaceMember = workspacesMembers[i];
        if (workspaceMember.workspace_id === workspaceId && workspaceMember.uid === userId) {
            workspaceMember.member_status = status;
            break;
        }
    }

    store.dispatch(updateWorkspacesMembersAction(workspacesMembers))
}

export const updateWorkspacesMembersRemove = (workspaceId, userId) => {
    const currentRorkspacesMembers = store.getState().workspacesMembers;
    const workspacesMembers = currentRorkspacesMembers.filter((item) => item.workspace_id === workspaceId && item.uid !== userId);

    store.dispatch(clearWorkspacesMembersAction(workspacesMembers))
}