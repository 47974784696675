import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Iframe from 'react-iframe';
import { changeNavigationAction } from '../../../../actions/changeNavigationAction';
import { clearWorkspaceAction } from '../../../../actions/clearWorkspaceAction';
import { updateDialogWorkspacesLandingApplicationAction } from '../../../../actions/updateDialogWorkspacesLandingApplicationAction';
import { clearWorkspaceApplicationLandingAction } from '../../../../actions/clearWorkspaceApplicationLandingAction';
import { getWorkspaceApplicationLandingSettingWithThunk } from '../../../../api/workspacesSettingsThunk';
import { getSessionStorage } from '../../../../utils/sessionStorage';
import { anonymousUserId } from '../../../../configurations/app';
import { generateAnonymousToken } from '../../../../utils/token';
import { updateGoogleAnalytics } from '../../../../utils/googleAnalytics';
import {
  MatomoComponent,
  updateMatomoAnalytics,
} from 'apps/portal/src/utils/motomoAnalytics';
import {
  getUrlWorkspaceId,
  getUrlWorkspaceApplicationId,
  getUrlWorkspaceApplicationFirstParameter,
  getUrlWorkspaceApplicationSecondParameter,
} from '../../../../utils/url';
import { routeCodes } from '../../../../configurations/routes';
import WorkspacesLandingApplicationModal from '../../Modal/WorkspacesLandingApplicationModal';
import { ReactComponent as AppsOutline } from './../../../../images/app-rectangle-outline.svg';
import { ReactComponent as AppsSolid } from './../../../../images/app-rectangle-solid.svg';
import { SharedHeaderApp } from '@wrd/shared/header-app';
import { axios, queryBaseUrlV2 } from 'apps/portal/src/backend/api/getCookies';
import { getWorkspaceApplicationList } from 'apps/portal/src/backend/api/workspacesApplications';
import { isEqual } from 'lodash';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
const jwt = require('jsonwebtoken');

class WorkspacesApplicationsLandingContainer extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    navigation: PropTypes.object.isRequired,
    workspace: PropTypes.object.isRequired,
    workspaceSetting: PropTypes.object.isRequired,
    workspaceApplication: PropTypes.object.isRequired,
    workspaceApplicationLanding: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    role: PropTypes.object.isRequired,
    dispatchNavigation: PropTypes.func.isRequired,
    applications: PropTypes.array.isRequired,
    dispatchDialogWorkspacesLandingApplication: PropTypes.func.isRequired,
  };

  state = {
    userId: '',
    portalToken: '',
    firstParam: '',
    secondParam: '',
    isHttps: false,
    checkGoogleAnalytics: false,
    checkMatomoAnalytics: false,
    url: '',
    displayApps: false,
    requestBody: {},
    result: {},
    anchorEl: null,
    getappWorkspace: [],
    matomoInstance: null,
    tenantName: '',
    visitorType: '',
  };

  wrapperRef = React.createRef();

  handleClickOutside = this.handleClickOutside.bind(this);
  handleClickAncorEl = this.handleClickAncorEl.bind(this);

  getFirstParam = () => {
    let param = getUrlWorkspaceApplicationFirstParameter(
      this.props.location.pathname
    );
    return param;
  };

  getSecondParam = () => {
    let param = getUrlWorkspaceApplicationSecondParameter(
      this.props.location.pathname
    );
    return param;
  };

  getCurrentWorkspaceId = () => {
    let id = getUrlWorkspaceId(this.props.location.pathname);
    return id;
  };

  getCurrentApplicationId = () => {
    let id = getUrlWorkspaceApplicationId(this.props.location.pathname);
    return id;
  };

  getApplicationLogoStyle() {
    const { workspace, workspaceSetting } = this.props;
    let width = 50;
    let height = Math.round(
      width / workspaceSetting.workspace_image_aspect_ratio
    );
    let top = 25 - height / 2;
    let style = { width: width + 'px', paddingTop: top + 'px' };

    if (workspace.workspace_url !== '') {
      style.cursor = 'pointer';
    } else {
      style.cursor = 'default';
    }

    return style;
  }

  changeNavigation(name) {
    let navigation = {
      name,
    };
    this.props.dispatchNavigation(navigation);
  }

  getApplication = async (workspaceId) => {
    try {
      const getappWorkspace = await axios.get(
        queryBaseUrlV2 +
          'workspace/' +
          workspaceId +
          '/application/list?logo=true&snapshot=true&width=32&height=32'
      );

      this.setState({ getappWorkspace: getappWorkspace.data }, function () {
        this.performAppLogin();
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  async componentDidMount() {
    const { role, getWorkspaceSettings, applications } = this.props;
    const url = window.location.href;

    let userId = '';
    let portalToken = '';
    let firstParam = this.getFirstParam();
    let secondParam = this.getSecondParam();
    let isHttps = url.includes('https') ? true : false;

    if (role.user_id === '') {
      const sessionRole = getSessionStorage('role');

      if (sessionRole !== '') {
        const session = JSON.parse(sessionRole);
        userId = session.user_id;
        portalToken = session.token.accessToken;
      }
    } else {
      userId = role.user_id;
      portalToken = role.token.accessToken;
    }

    if (userId === '') {
      userId = anonymousUserId;
      portalToken = generateAnonymousToken();
    }

    this.setState({
      userId,
      portalToken,
      firstParam,
      secondParam,
      isHttps,
    });

    this.changeNavigation('Applications');

    window.addEventListener('message', (message) => {
      let data = message.data;
      switch (data.type) {
        case 'copy-to-clipboard':
          navigator.clipboard.write(
            // eslint-disable-next-line no-undef
            [new ClipboardItem({ 'image/png': data.toCopy })]
          );
          break;
        case 'copy-text-to-clipboard':
          navigator.clipboard.writeText(data.toCopy);
          break;
        default:
          break;
      }
    });

    const workspaceId = this.getCurrentWorkspaceId();
    await getWorkspaceSettings(
      workspaceId,
      'WorkspaceName;ShowPortalLogo;GoogleAnalytic;ShowWorkspaceName;ShowWorkspaceLogo;WorkspaceLogo',
      userId,
      role.user_role
    );

    if (!!this.props.workspaceSetting.id.length) {
      this.getApplication(this.props.workspaceSetting.id);
    }
  }

  componentDidUpdate(prevProps, nextState) {
    const {
      workspaceApplicationLanding,
      workspaceSetting,
      role,
      clientIp,
      requestBody,
      result,
      match,
    } = this.props;

    if (!this.state.tenantName && role.token.accessToken) {
      const decoded = jwt.decode(role.token.accessToken);
      this.setState({ tenantName: decoded.tenant_name });
    }

    if (!this.state.visitorType && role.user_name) {
      const visitorType = role.user_name.includes('dhigroup')
        ? 'DHI Employee'
        : 'Customer';
      this.setState({ visitorType: visitorType });
    }

    if (
      !!this.props.workspaceSetting.id &&
      prevProps.workspaceSetting.id !== this.props.workspaceSetting.id
    ) {
      this.getApplication(this.props.workspaceSetting.id);
    }

    const { checkGoogleAnalytics } = this.state;
    const { checkMatomoAnalytics } = this.state;
    const application = match.params;

    if (
      (application.id === undefined || application.id === '') &&
      (application.applicationid === undefined ||
        application.applicationid === '')
    )
      return;

    if (
      workspaceSetting.id !== '' &&
      role.user_id !== '' &&
      clientIp.ipAddress !== '' &&
      !checkGoogleAnalytics &&
      !checkMatomoAnalytics
    ) {
      if (workspaceSetting.useGoogleAnalytics) {
        const trackingId = workspaceSetting.googleAnalyticsTrackingId;
        const workspaceName = workspaceSetting.workspace_name;
        const applicationName = application.applicationid;
        const userName = role.user_name;
        const ipAddress = clientIp.ipAddress;

        updateGoogleAnalytics(
          trackingId,
          workspaceName,
          applicationName,
          userName,
          ipAddress,
          this.props.location
        );
      }
      if (workspaceSetting.useMatomoAnalytics) {
        const trackingId = workspaceSetting.matomoAnalyticsTrackingId;
        const url = workspaceSetting.matomoAnalyticsURL;
        const matIns = updateMatomoAnalytics(trackingId, url);

        this.setState({ matomoInstance: matIns });
      }

      this.setState({ checkMatomoAnalytics: true });
      this.setState({ checkGoogleAnalytics: true });
    }
    const prevRequestBody = prevProps.requestBody;
    const prevResult = prevProps.result;
    if (requestBody !== prevRequestBody || result !== prevResult) {
      this.performAppLogin();
    }
  }

  performAppLogin = async () => {
    try {
      const { applications, role } = this.props;
      const { getappWorkspace } = this.state;
      const { location } = this.props.history;
      const lastSlashIndex = location.pathname.lastIndexOf('/');
      const activeRouteApps = location.pathname.substring(
        lastSlashIndex + 1,
        location.pathname.length
      );
      const str = activeRouteApps.replace(/\+/g, ' ');
      const workspaceID = this.props.workspaceSetting;

      let result;
      if (getappWorkspace) {
        result = getappWorkspace.find(
          (item) => item.name.toLowerCase() === str.toLowerCase()
        );
      } else {
        result = applications.find(
          (item) => item.name.toLowerCase() === str.toLowerCase()
        );
      }

      this.setState({ result: result });
      const requestBody = {
        workspaceId: result?.workspaceId,
        applicationId: result?.applicationId,
        userId: role.user_id,
      };
      this.setState({ requestBody: requestBody });

      const response = await axios.post(
        queryBaseUrlV2 + 'application/authentication/applogin',
        requestBody
      );

      let url =
        result !== undefined && result.url.includes('?')
          ? result?.url +
            '&portalToken=' +
            role?.token?.accessToken +
            '&appToken=' +
            response.data
          : result?.url +
            '?portalToken=' +
            role?.token?.accessToken +
            '&appToken=' +
            response.data;

      url =
        this.state.firstParam !== ''
          ? url + '&appParam1=' + this.state.firstParam
          : url;
      url =
        this.state.secondParam !== ''
          ? url + '&appParam2=' + this.state.secondParam
          : url;
      url = url + '&isSecure=' + this.state.isHttps;
      this.setState({ url: url });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  componentWillUnmount() {
    // this.setState({ url: "" });
    this.props.clearWorkspace();
    this.props.clearWorkspaceApplicationLanding();
    // this.setState({ getappWorkspace: "" });
  }

  getInitials(string) {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  wsLandingPage = () => {
    let workspaceName = this.getCurrentWorkspaceId();
    this.props.history.push(routeCodes.WORKSPACES_LIST + '/' + workspaceName);
  };

  logout() {
    this.props.history.push(routeCodes.USERS_LOGOUT);
  }

  loginPage() {
    let workspaceName = this.getCurrentWorkspaceId();
    this.props.history.push(routeCodes.USERS_LOGIN + '/' + workspaceName);
  }

  profilePage() {
    this.props.history.push(routeCodes.PROFILE);
  }

  privacyPolicy = () => {
    window.open('https://www.dhigroup.com/legal-and-compliance/privacy-policy', '_blank');
  };

  userGuidePage = () => {
    window.open("https://doc.mikepoweredbydhi.help/webhelp/mike-cloud/MIKEMine/index.htm", "_blank");
  }

  contactSupport = () => {
    let workspaceName = this.getCurrentWorkspaceId();
    this.props.history.push(routeCodes.WORKSPACES_LIST  + "/" + workspaceName+ "/contactOwners");
    // changeRoute(routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+"));
    // history.push(routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/contactOwners");
  }

  openUrl(url) {
    if (url.trim() === '') {
      return;
    }

    if (!url.includes('http')) {
      url = 'https://' + url;
    }

    const win = window.open(url, '_blank');
    if (win != null) {
      win.focus();
    }
  }

  handleDisplayApps() {
    const { displayApps } = this.state;

    this.setState({ displayApps: !displayApps }, () => {
      if (!displayApps) {
        document.addEventListener('mousedown', this.handleClickOutside);
      } else {
        document.removeEventListener('mousedown', this.handleClickOutside);
      }
    });
  }

  handleSwithApps(e, item) {
    const { location } = this.props.history;
    const lastSlashIndex = location.pathname.lastIndexOf('/');
    const everythingAfterTheFinalSlash = location.pathname.substring(
      0,
      lastSlashIndex + 1
    );
    const link =
      everythingAfterTheFinalSlash +
      item.name.replace(/\s+/g, '+').toLowerCase();
    const url = window.location.href;
    const isPortalHttps = url.includes('https') ? true : false;
    const isApplicationHttps = item.url.includes('https') ? true : false;
    const isWarning = isPortalHttps && !isApplicationHttps;

    if (!e.ctrlKey) {
      if (!isWarning) {
        this.setState({ displayApps: false });
        this.props.history.push(link);
        this.performAppLogin();
        this.setState({ anchorEl: null });
      } else {
        this.showDialog(item.name, link, false);
      }
    } else {
      if (!isWarning) {
        const win = window.open(link, '_blank');
        if (win != null) {
          win.focus();
        }
      } else {
        this.showDialog(item.name, link, true);
      }
    }
  }

  showDialog(appName, link, controlKey) {
    let workspaces_landing_application = {
      id: '',
      name: '',
      status: '',
      dialog_title: appName,
      dialog_type: '',
      link,
      control_key: controlKey,
      show_dialog: true,
    };

    this.props.dispatchDialogWorkspacesLandingApplication(
      workspaces_landing_application
    );
  }

  handleClickAncorEl(item) {
    this.setState({ anchorEl: item });
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ displayApps: false }, () => {
        document.removeEventListener('mousedown', this.handleClickOutside);
      });
    }
  }

  handleWorkspacesClick = () => {
    const { workspaceSetting } = this.props;
    this.props.history.push(
      routeCodes.WORKSPACES_LIST +
        '/' +
        workspaceSetting.workspace_name.toLowerCase().replaceAll(' ', '+') +
        '/properties'
    );
  };

  render() {
    const {
      role,
      workspace,
      workspaceApplicationLanding,
      workspaceSetting,
      workspace_theme,
      loadingTheme,
      applications,
      themeStyle,
      clientIp,
    } = this.props;
    const { url, displayApps, getappWorkspace, anchorEl, matomoInstance } =
      this.state;
    const { location } = this.props.history;
    const lastSlashIndex = location.pathname.lastIndexOf('/');
    const activeRouteApps = location.pathname.substring(
      lastSlashIndex + 1,
      location.pathname.length
    );
    const str = activeRouteApps.replace(/\+/g, ' ');
    let result;
    if (getappWorkspace) {
      result = getappWorkspace.find(
        (item) => item.name.toLowerCase() === str.toLowerCase()
      );
    } else {
      result = applications.find(
        (item) => item.name.toLowerCase() === str.toLowerCase()
      );
    }
    let getapp;
    if (getappWorkspace) {
      getapp = getappWorkspace;
    } else {
      getapp = applications;
    }
    // const requestBody = {
    //   workspaceId : result?.workspaceId,
    //   applicationId: result?.applicationId,
    //   userId: role.user_id,
    // }
    // this.performAppLogin(requestBody, role, result)

    // if ((application !== undefined && application.application_id === "") ||
    //     application.available === undefined ||
    //     (application.application_token === undefined || application.application_token === ""))

    if (result === undefined && result?.applicationId === '') return null;
    if (loadingTheme) {
      return (
        <div className="loader-container">
          <Loader
            type="BallTriangle"
            color="#00BFFF"
            height="100"
            width="100"
          />
        </div>
      );
    }

    return (
      <MatomoProvider value={matomoInstance}>
        <div>
          <WorkspacesLandingApplicationModal />
          {!loadingTheme && result?.applicationId && (
            <main className="workspaceApp cancel-main-margin">
              <MatomoComponent
                workspaceName={workspaceSetting.workspace_name}
                applicationName={result?.name}
                userName={role.user_name}
                roleName={role.user_role}
                tenantName={this.state.tenantName}
                visitorType={this.state.visitorType}
                ipAddress={clientIp.ipAddress}
                instance={matomoInstance}
              />
              <SharedHeaderApp
                url={process.env.PUBLIC_URL}
                showPortalLogo={workspaceSetting.showPortalLogo}
                userImage={workspace.user_picture}
                userInitial={workspace.user_initial}
                login={role.is_logged_in}
                workspaceEdit={
                  role.user_role === 'Administrator' ||
                  (workspace.owner && !workspace.suspendedUser)
                }
                workspaceName={workspaceSetting.workspace_name}
                showWorkspaceLogo={workspaceSetting.showWorkspaceLogo}
                workspaceImage={result?.logo}
                applicationName={result?.name}
                closeDropdown={anchorEl}
                setCloseDropdown={this.handleClickAncorEl}
                handleWorkspacesClick={this.wsLandingPage} // {this.handleWorkspacesClick}
                listApps={getapp.map((appItem) => {
                  return {
                    ...appItem,
                    handleClickAppItem: (e, d) => this.handleSwithApps(e, d),
                  };
                })}
                // handleOpenWorkspaces={this.handleOpenWorkspaces}
                handleOpenLoginPage={this.loginPage}
                showAvilableWorkspaces={false}
                listNav={[
                  {
                    label: 'Profile',
                    handleClickNavItem: () => this.profilePage(),
                  },
                  {
                    label: 'Help & User Guide',
                    handleClickNavItem: this.userGuidePage,
                  },
                  {
                    label: 'Privacy policy',
                    handleClickNavItem: this.privacyPolicy,
                  },
                  {
                    label: 'Contact & Support',
                    handleClickNavItem: () => this.contactSupport(),
                  },
                  {
                    label: 'Logout',
                    handleClickNavItem: () => this.logout(),
                  },
                ]}
              />
              <div
                ref={this.wrapperRef}
                className={
                  displayApps
                    ? 'panel-apps-' + themeStyle + ' animated fadeIn'
                    : 'fadeOut d-none'
                }
              >
                <div
                  style={{ display: 'flex', margin: '10px', fontSize: '18px' }}
                >
                  <div
                    className=""
                    style={{
                      color: themeStyle === 'default' ? '#ffffff' : '#0b4566',
                    }}
                  >
                    Available Application(s)
                  </div>
                  <button
                    className="btn iconBtn minimizeBtn"
                    style={{ position: 'absolute', right: '0px', top: '0px' }}
                    onClick={(e) => this.handleDisplayApps()}
                  >
                    {' '}
                    <span className="icon-wrd-close"></span>
                  </button>
                </div>
                <Scrollbars style={{ width: 350, height: 450 }}>
                  <ul className="">
                    {applications.map((item) => {
                      const selectedApps =
                        activeRouteApps.toLowerCase() ===
                        item.name.toLowerCase().replace(/ /g, '+');
                      return (
                        <li
                          className={'list-apps-' + themeStyle}
                          key={'app-' + item.id}
                        >
                          <button
                            onClick={(e) => this.handleSwithApps(e, item)}
                            className={`btn link-apps-${themeStyle}${
                              selectedApps ? ` apps-selected-${themeStyle}` : ''
                            }`}
                            to={''}
                          >
                            <span className="appIcon">
                              <img
                                className="logo-apps"
                                src={
                                  (item.logo !== undefined
                                    ? 'data:image/jpeg;base64,'
                                    : '') + item.logo
                                }
                                alt=""
                              />
                            </span>
                            <div className="appName">{item.name}</div>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </Scrollbars>
              </div>
              <div className="mapContainer">
                {/* {result?.applicationId && */}
                <Iframe
                  url={url}
                  id={result?.applicationId}
                  width="100%"
                  className="workspaces-application-container"
                  display="initial"
                  position="fixed"
                  allow="clipboard-read; clipboard-write;"
                />
                {/* } */}
                {!result?.applicationId && (
                  <main className="workspaces-application-not-available unavailablePage">
                    <div className="sectionError">
                      <span className="icon-wrd-page-error"></span>
                      <p>The application</p>
                      <b className="d-block">{result?.application_name}</b>
                      <p>is not currently available</p>
                    </div>
                  </main>
                )}
              </div>
            </main>
          )}
        </div>
      </MatomoProvider>
    );
  }
}

const mapStateToProps = ({
  detail,
  loading,
  role,
  navigation,
  clientIp,
  workspaceApplicationLanding,
}) => ({
  workspaceSetting: detail.workspace_setting,
  workspace: detail.workspace,
  workspace_theme: detail.workspace_theme_setting,
  workspaceApplication: detail.workspace_application,
  loading: loading.workspace_application.loading,
  loadingTheme: loading.theme.loading,
  role,
  navigation,
  clientIp,
  workspaceApplicationLanding:
    workspaceApplicationLanding.workspace_application_landing_data,
  applications: workspaceApplicationLanding.applications,
  themeStyle: workspaceApplicationLanding.theme,
});

const mapDispatchToProps = (dispatch) => ({
  getWorkspaceSettings: (workspaceId, includeOnly, userId, role) =>
    dispatch(
      getWorkspaceApplicationLandingSettingWithThunk(
        workspaceId,
        includeOnly,
        userId,
        role
      )
    ),
  dispatchNavigation: (navigation) =>
    dispatch(changeNavigationAction(navigation)),
  clearWorkspace: () => dispatch(clearWorkspaceAction([])),
  clearWorkspaceApplicationLanding: () =>
    dispatch(clearWorkspaceApplicationLandingAction([])),
  dispatchDialogWorkspacesLandingApplication: (dialog) =>
    dispatch(updateDialogWorkspacesLandingApplicationAction(dialog)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(WorkspacesApplicationsLandingContainer)
);
