import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import { resetPasswordWithThunk } from '../../../api/resetPasswordThunk';
import { updateResetPasswordAction } from '../../../actions/updateResetPasswordAction';
import { routeCodes } from '../../../configurations/routes';

class UsersResetPasswordBody extends Component {
    constructor(props) {
        super(props);
        this.SubmitReset = this.SubmitReset.bind(this);
        this.enterSubmitReset = this.enterSubmitReset.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.backButton = this.backButton.bind(this);
    }

    static propTypes = {
        resetPassword: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        userReset: PropTypes.func.isRequired,
        dispatchReset: PropTypes.func.isRequired,
        portalLogin: PropTypes.object.isRequired
    };

    SubmitReset() {
        const { resetPassword, portalLogin } = this.props;
        this.props.userReset(resetPassword, portalLogin.id);
    }

    async enterSubmitReset(e) {
        if (e.key === 'Enter') {
            this.SubmitReset();
        }
    }

    handleChangeEmail(e) {
        const reset = {
            ...this.props.resetPassword,
            email: e.target.value
        }

        this.props.dispatchReset(reset);
    }

    handleChangeUsername(e) {
        const reset = {
            ...this.props.resetPassword,
            username: e.target.value
        }

        this.props.dispatchReset(reset);
    }

    backButton() {
        const { portalLogin } = this.props;
        this.props.history.push(routeCodes.USERS_LOGIN + '/' + portalLogin.workspace_name);
    }

    render() {
        const { portalLogin, loading } = this.props;
        return (
            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                {
                    (portalLogin.login_background_selected === 'NoBackground') ?
                        <div style={{ backgroundColor: portalLogin.login_background_color }} className="crop-welcome1820"> </div>
                        : <img src={"data:image/png;base64," + portalLogin.login_background_image}
                            className="crop-welcome1820" alt="" />

                }
                <div className="w-100 p-4 text-center">
                    {loading &&
                        <div className="loader-container">
                            <Loader
                                type="BallTriangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                    <button className="loginBackBtn" onClick={this.backButton}> <span className="icon-wrd-arrow-left"></span></button>
                    <div className="row" onKeyPress={this.enterSubmitReset}>
                        <div className="formWrap col-10 offset-1 col-md-8 offset-md-2">
                            <h2>Reset Password</h2>
                            <p className="mb-5">Enter your email to reset your password</p>
                            <div className="inputForm">
                                <input className="form-control" type="text" name="" onChange={this.handleChangeUsername} required />
                                <label>Username</label>
                            </div>
                            <div className="inputForm">
                                <input className="form-control" type="text" name="" onChange={this.handleChangeEmail} required />
                                <label>Email</label>
                            </div>
                            <button className="btn textBtn btn-wrd_default mt-5 w-50" onClick={this.SubmitReset}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = ({ portalLogin, detail, loading }) => ({
    resetPassword: detail.resetPassword,
    loading: loading.reset_password.loading,
    portalLogin: portalLogin.portal_login
});

const mapDispatchToProps = (dispatch) => ({
    userReset: (reset, workspaceId) => dispatch(resetPasswordWithThunk(reset, workspaceId)),
    dispatchReset: (email) => dispatch(updateResetPasswordAction(email))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersResetPasswordBody));