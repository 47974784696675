import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogWorkspacesLandingApplicationAction } from '../../../actions/updateDialogWorkspacesLandingApplicationAction';

class WorkspacesLandingApplicationModal extends Component {
    static propTypes = {
        dialog: PropTypes.object.isRequired
    };

    setDefaultDialogState() {
        let workspaces_landing_application = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            link: '',
            control_key: false,
            show_dialog: false
        };

        this.props.dispatchDialogWorkspacesLandingApplication(workspaces_landing_application);
    }

    closeModal() {
        this.setDefaultDialogState();
    }

    render() {
        const { dialog } = this.props;

        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{dialog.dialog_title}</h2>
                            <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody">
                        <div className="p-4">
                            <div className="summernote">
                                <p>
                                    You are trying to access an app that does not support secure connection (HTTPS) from a secure connection, which is not allowed. To access the app, use HTTP in the url of this page instead of HTTPS
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-2">
                            <div className="row">
                                <div className="col text-right">
                                    <button className="btn greyBtn icoTextBtn closePopup" onClick={() => { this.closeModal(); }}>
                                        <span className="icon-wrd-close"></span>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ dialog }) => ({
    dialog: dialog.workspaces_landing_application
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogWorkspacesLandingApplication: (dialog) => dispatch(updateDialogWorkspacesLandingApplicationAction(dialog)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesLandingApplicationModal));
