import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setupNewWorkspaceCategoryAction } from '../../../../actions/setupNewWorkspaceCategoryAction';
import WorkspacesCategoriesCreationErrorModal from '../Modal/WorkspacesCategoriesCreationErrorModal';
import { updateDialogWorkspacesCategoriesCreationErrorAction } from '../../../../actions/updateDialogWorkspacesCategoriesCreationErrorAction';
import { getCookies } from '../../../../backend/api/getCookies';
import { getPortalIcons, postPortalIcon } from 'apps/portal/src/backend/api/portal';
import { getWorkspaceCategoryAction } from '../../../../actions/getWorkspaceCategoryAction';

class WorkspacesCategoriesLogo extends Component {
    constructor(props) {
        super(props);
        this.onSelectIcon = this.onSelectIcon.bind(this);
    }

    state = {
        error: false,
        isUploading: false,
        selectedIconId: this.props.workspaceCategory.iconId ?? null,
        iconList: []
    };

    componentDidMount() {
        this.fetchPortalIcons();
    };

    async fetchPortalIcons() {
        const response = await getPortalIcons();
        if (response?.data) this.setState({ iconList: response.data });
    };

    onSelectIcon = (e) => {
        this.setState({ selectedIconId: e.currentTarget.value, error: false, isUploading: false });
        
        const workspace_category = {
            ...this.props.workspaceCategory,
            iconId: e.currentTarget.value,
        };
        
        this.props.editForm ? this.props.dispatchCategory(workspace_category) : this.props.setupCategory(workspace_category);
    }

    render() {
        const { error, iconList, selectedIconId } = this.state;

        return (
            <div className="">
                <label>Select category icon</label>
                <div className="row">
                    {
                        iconList.map((icon, index) => (
                            <div className='mx-3' key={icon.id}>
                                <label className="custom-pick-image">
                                    <input 
                                        type="radio" 
                                        className="custom-control-input" 
                                        name="icon" value={icon.id} 
                                        onChange={this.onSelectIcon} 
                                        checked={selectedIconId === icon.id}>
                                    </input>
                                    <div className="imgContainer">
                                        <img style={{ height: '75px', width: '75px' }} src={`${getCookies.baseURL}/icon/id/${icon.id}`} alt="" />
                                    </div>
                                </label>
                            </div>
                        ))
                    }
                </div>
                {
                    error && <WorkspacesCategoriesCreationErrorModal type="error" />
                }      
            </div>
        );
    }
}

const mapStateToProps = ({ detail, setup }, ownProps) => ({
    workspaceCategory: ownProps.editForm ? detail.workspace_category : setup.workspace_category,
});

const mapDispatchToProps = (dispatch) => ({
    setupCategory: (newCategory) => dispatch(setupNewWorkspaceCategoryAction(newCategory)),
    dispatchCategory: (category) => dispatch(getWorkspaceCategoryAction(category)),
    errorUploadImage: (category) => dispatch(updateDialogWorkspacesCategoriesCreationErrorAction(category)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesCategoriesLogo));
