import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateWorkspacesMembersShareAction } from '../../../../actions/updateWorkspacesMembersShareAction';

class WorkspacesMembersShareItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectRow: false,
        }
        this.onMemberSelected = this.onMemberSelected.bind(this);
        this.onMemberSelectedRow = this.onMemberSelectedRow.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        workspacesMembersShare: PropTypes.array.isRequired,
        role: PropTypes.object.isRequired,
        item: PropTypes.object.isRequired
    };

    onMemberSelected(e) {
        const { item, workspacesMembersShare } = this.props;
        let members = workspacesMembersShare;
        let index = members.findIndex(member => member.id === item.id);
        members[index].selected = e.target.checked;
        this.props.dispatchWorkspacesMembersShare(members);
    }

    onMemberSelectedRow() {
        this.setState(prevState => ({
            selectRow: !prevState.selectRow,
        }), () => {
            const { index, workspacesMembersShare } = this.props;
            let members = workspacesMembersShare;
            members[index].selected = this.state.selectRow;
            this.props.dispatchWorkspacesMembersShare(members);
        });

    }

    getRegistrationStatusClass() {
        const { item } = this.props;

        let registrationStatusClass = '';

        if (item.registration_status === 'Confirmed') {
            registrationStatusClass = 'statusActive';
        }
        else if (item.registration_status === 'Pending') {
            registrationStatusClass = 'statusPending';
        }

        return registrationStatusClass;
    }

    getUserStatusClass() {
        const { item } = this.props;

        let userStatusClass = '';

        if (item.user_status === 'Enabled') {
            userStatusClass = 'statusActive';
        }
        else if (item.user_status === 'Disabled') {
            userStatusClass = 'statusSuspend';
        }

        return userStatusClass;
    }

    render() {
        const { item } = this.props;
        return (
            <tr>
                <td>
                    <img src={((item.picture !== undefined) ? "data:image/jpeg;base64," : "") + item.picture} className="userPhoto" alt="" />
                    <label className="btn iconBtn customShareUsrCheckbox">
                        <input type="checkbox" className="shareUsr" onChange={this.onMemberSelected} checked={item.selected ? true: false} />
                        <span className="greenBlockChecked"></span>
                        <span className="darkBlockUncheck"></span>
                    </label>
                </td>
                <td onClick={() => this.onMemberSelectedRow()}>
                    <b className="userName">{item.id}</b>
                    <p className="fullName">{item.full_name}</p>
                    <p className="organization">{item.organization}</p>
                </td>
                <td onClick={() => this.onMemberSelectedRow()}>
                    <span className="icon icon-wrd-bookmark"></span>
                    <span className="date">{item.user_role}</span>
                </td>
                <td onClick={() => this.onMemberSelectedRow()}>
                    <span className="icon icon-wrd-registration"></span>
                    <span className={this.getRegistrationStatusClass()}>{item.registration_status}</span>
                </td>
                <td onClick={() => this.onMemberSelectedRow()}>
                    <span className="icon icon-wrd-unlock"></span>
                    <span className={this.getUserStatusClass()}>{item.user_status}</span>
                </td>
            </tr>
        );
    }
}

const mapStateToProps = ({ detail, workspacesMembersShare, role }) => ({
    workspace: detail.workspace,
    workspacesMembersShare,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchWorkspacesMembersShare: (workspacesMembersShare) => dispatch(updateWorkspacesMembersShareAction(workspacesMembersShare))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesMembersShareItem));