import { getCookies } from '../api/getCookies';

const configUsers = require('../../configurations/users');
const { getDefaultPersonImage, getThumbnailDefaultImage } = require('../../utils/image');
const moment = require('moment');

const workspacesApi = require('../api/workspaces');
const workspacesMembersApi = require('../api/workspacesMembers');
const usersApi = require('../api/users');

export async function getWorkspaceMemberListApi(workspaceId, pageIndex, pageSize) {
    try {
        const results = await workspacesMembersApi.getWorkspaceMemberList(workspaceId, pageIndex, pageSize);
        const data = mapToWorkspacesMembersFrontend(workspaceId, results.data);

        return data;
    }
    catch (err) {
        return mapToEmptyWorkspacesMembersFrontend();
    }
}

export async function getGlobalWorkspaceMemberListApi(workspaceId) {
    try {
        const results = await workspacesMembersApi.getGlobalWorkspaceMemberList(workspaceId, 1, configUsers.maximumGlobalUsers);
        const data = mapToGlobalWorkspacesMembersFrontend(workspaceId, results.data);

        return data;
    }
    catch (err) {
        return mapToEmptyWorkspacesMembersFrontend();
    }
}

export async function getWorkspacesMembersSearchApi(workspaceId, pageIndex, pageSize, searchKeyword) {
    try {
        const results = await workspacesMembersApi.getWorkspaceMemberSearch(workspaceId, pageIndex, pageSize, searchKeyword);
        const data = mapToWorkspacesMembersFrontend(workspaceId, results.data);

        return data;
    }
    catch (err) {
        if (err.response.status === 404) {
            return mapToEmptyWorkspacesMembersFrontend();
        }
    }
}

export async function getWorkspaceMemberByIdApi(workspaceId, userId) {
    try {
        const results = await workspacesMembersApi.getWorkspaceMemberById(workspaceId, getCookies.userId);
        if (results.data === "" && results.status === 204) {
            return mapToEmptyWorkspacesMemberByIdFrontend(getCookies.userId);
        }
        return await mapToWorkspacesMemberByIdFrontend(results.data);

    }
    catch (err) {
        if (err.response.status === 404) {
            return mapToEmptyWorkspacesMemberByIdFrontend(getCookies.userId);
        }
    }
}

export async function getWorkspacesMembersShareApi(workspaceId, userId, pageIndex, pageSize) {
    let result = {};

    const workspaceMembers = await getWorkspaceMemberListApi(workspaceId, pageIndex, pageSize);

    result.id = userId;
    result.members = workspaceMembers.members;
    return result;
}

export async function getWorkspacesMembersShareSearchApi(workspaceId, userId, pageIndex, pageSize, searchKeyword) {
    let result = {};

    const workspaceMembers = await getWorkspacesMembersSearchApi(workspaceId, pageIndex, pageSize, searchKeyword);

    result.id = userId;
    result.members = workspaceMembers.members;
    return result;
}

export async function getWorkspacesShareListApi(userId, userRole, pageIndex, pageSize) {
    pageSize = configUsers.maximumGlobalUsers;
    if (userRole === 'Administrator') {
        const results = await workspacesApi.getWorkspaceList(pageIndex, pageSize, 'Name', 'Ascending');
        return mapToUserWorkspacesFrontendAdminMode(userId, results.data);
    }
    else {
        let tempWorkspaces = [];
        const workspaces = await workspacesApi.getWorkspaceHeaderList(pageIndex, pageSize);

        const wsIds = await workspacesMembersApi.getUserAvailabeWorkspaces(userId);
        for (let i in wsIds.data) {
            let w = wsIds.data[i];
            if (w.owner) {
                let index = workspaces.data.results.findIndex(x => x.id === w.workspaceId);
                if (index) {
                    tempWorkspaces.push(workspaces.data.results[index]);
                }
            }
        }

        return mapToUserWorkspacesFrontendOwnerMode(userId, tempWorkspaces);
    }
}

export async function createWorkspaceMemberApi(accessToken, workspaceId, member) {
    let results = {};
    const mappingMember = mapToWorkspacesMembersBackendForCreate(member);
    results = await workspacesMembersApi.createWorkspaceMember(accessToken, workspaceId, mappingMember);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        if (results.data !== undefined) {
            const response = results.data.split('/');
            if (response.length > 0) {
                const user = response[1].split(':');
                const userId = user[1];
                if (member.is_owner) {
                    await updateWorkspaceMemberRoleApi(workspaceId, userId, "Workspace Owner");
                }
                await usersApi.updatePhoto(userId, member.picture);
                id = userId;
            }
        }
    }
    else if (results.status === 500 || results.status === 404) {
        if (results.data.UserName !== undefined) {
            error_messages = results.data.UserName;
        }
        else {
            let i = 0;
            for (const value of Object.entries(results.data)) {
                if (i === 0) {
                    error_messages = value[1];
                }
                i += 1;
            }
        }
    }
    else if (results.status === 401) {
        error_messages = results.data;
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }

    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages,
    };

    return data;
}

export async function registerWorkspaceMemberApi(workspaceId, member) {
    let results = {};
    const mappingMember = mapToWorkspacesMembersBackendForCreate(member);
    results = await workspacesMembersApi.registerWorkspaceMember(workspaceId, mappingMember);

    let id = '';
    let error_messages = '';
    if (results.status === 200) {
        if (results.data !== undefined) {
            const response = results.data.split('/');
            if (response.length > 0) {
                const user = response[1].split(':');
                const userId = user[1];
                await usersApi.updatePhoto(userId, member.picture);
                id = userId;
            }
        }
    }
    else if (results.status === 500 || results.status === 404) {
        if (results.data.UserName !== undefined) {
            error_messages = results.data.UserName;
        }
        else {
            let i = 0;
            for (const value of Object.entries(results.data)) {
                if (i === 0) {
                    error_messages = value[1];
                }
                i += 1;
            }
        }
    }
    else if (results.status === 401) {
        error_messages = results.data;
    }
    else {
        let i = 0;
        for (const value of Object.entries(results.data)) {
            if (i === 0) {
                error_messages = value[1];
            }
            i += 1;
        }
    }

    const data = {
        id: id,
        status: results.status,
        error_messages: error_messages,
    };

    return data;
}

export async function createWorkspacesShareListApi(accessToken, datas) {
    let members = [];
    let id = '';
    let error_messages = '';
    let data = {};

    for (let i in datas.members) {
        let m = datas.members[i];
        if (m.selected) {
            members.push(m.uid);
        }
    }

    for (let j in datas.workspaces) {
        let w = datas.workspaces[j];
        if (w.selected) {
            let results = await workspacesMembersApi.addWorkspaceMembers(accessToken, w.id, members);
            if (results.status === 200) {
                id = results.data;
            }
            else if (results.status === 500 || results.status === 404) {
                error_messages = "Internal server error, Please contact adminsitrator";
            }
            else {
                error_messages = results.data;
            }

            data = {
                id: id,
                status: results.status,
                error_messages: error_messages
            };
        }
    }

    return data;
}

export async function addWorkspaceMembersApi(accessToken, workspaceId, members) {
    const mappingMembers = mapToWorkspacesMembersBackendForAdd(members);
    const result = await workspacesMembersApi.addWorkspaceMembers(accessToken, workspaceId, mappingMembers);
    return result;
}

/* PUT */
export async function updateWorkspaceMemberRoleApi(workspaceId, userId, role) {
    const results = await workspacesMembersApi.updateWorkspaceMemberRole(workspaceId, userId, mapWorkspaceMemberRoleBackend(role));
    const data = {
        status: results.data
    }
    return data;
}

export async function updateWorkspaceMemberStatusApi(workspaceId, userId, status) {
    const results = await workspacesMembersApi.updateWorkspaceMemberStatus(workspaceId, userId, mapWorkspaceMemberStatusBackend(status));
    const data = {
        status: results.data
    }
    return data;
}

export async function removeWorkspaceMemberApi(workspaceId, userId) {
    const results = await workspacesMembersApi.removeWorkspaceMember(workspaceId, userId);
    const data = {
        id: results.data
    }
    return data;
}

function mapToWorkspacesMembersFrontend(id, data) {
    let results = {};
    let members = [];
    for (let i in data) {
        let m = data[i];
        let member = {
            workspace_id: id,
            uid: m.userId,
            id: m.userName,
            full_name: m.fullName,
            user_role: mapWorkspaceMemberRoleFrontend(m.role),
            registration_status: (m.accountConfirmed) ? "Confirmed" : "Pending",
            user_status: (m.userStatus === "Active") ? "Enabled" : "Disabled",
            member_status: (m.memberStatus === "Active") ? "Active" : "Suspended",
            picture: (m.photo === undefined|| m.photo ==="") ? getDefaultPersonImage() : m.photo,
            phone: m.phone,
            email_address: m.email,
            organization: m.organization
        };
        members.push(member);
    }

    results.members = members.sort(function (a, b) {
        return a.id.toLowerCase().localeCompare(b.id.toLowerCase());
    });

    return results;
}

function mapToGlobalWorkspacesMembersFrontend(id, data) {
    let results = {};
    let members = [];
    for (let i in data) {
        let m = data[i];
        let member = {
            workspace_id: id,
            uid: m.userId,
            id: m.userName,
            full_name: m.fullName,
            user_role: mapWorkspaceMemberRoleFrontend(m.role),
            registration_status: (m.accountConfirmed) ? "Confirmed" : "Pending",
            user_status: (m.userStatus === "Active") ? "Enabled" : "Disabled",
            member_status: (m.memberStatus === "Active") ? "Active" : "Suspended",
            picture: (m.photo === undefined|| m.photo ==="") ? getDefaultPersonImage() : m.photo,
            phone: m.phone,
            email_address: m.email,
            organization: m.organization
        };
        members.push(member);
    }

    results.members = members.sort(function (a, b) {
        return a.id.toLowerCase().localeCompare(b.id.toLowerCase());
    });

    return results;
}

function mapToUserWorkspacesFrontendAdminMode(userId, data) {
    let results = {};
    let workspaces = [];

    for (let i in data.results) {
        let w = data.results[i];
        let workspace = {
            id: w.id,
            url_image: (w.logo === undefined) ? getThumbnailDefaultImage() : w.logo,
            workspace_name: w.name,
            workspace_title: w.title,
            created_date: moment(w.createdDate).format("MM-DD-YYYY"),
            due_date: moment(w.dueDate).format("MM-DD-YYYY"),
            status: w.status,
            enabled: w.enabled
        }
        workspaces.push(workspace);
    }
    let pagination = {
        current_page: data.currentPage,
        page_size: data.pageSize,
        page_count: data.pageCount,
        start_row_number: data.startRowNumber,
        end_row_number: data.endRowNumber,
        total_count: data.rowCount
    }
    results.uid = userId;
    results.workspaces = workspaces;
    results.pagination = pagination;

    return results;
}


function mapToUserWorkspacesFrontendOwnerMode(userId, data) {
    let results = {};
    let workspaces = [];

    for (let i in data) {
        let w = data[i];
        let workspace = {
            id: w.id,
            url_image: (w.logo === undefined) ? getThumbnailDefaultImage() : w.logo,
            workspace_name: w.name,
            workspace_title: w.title,
            created_date: moment(w.createdDate).format("MM-DD-YYYY"),
            due_date: moment(w.dueDate).format("MM-DD-YYYY"),
            status: w.status,
            enabled: w.enabled
        }
        workspaces.push(workspace);
    }

    let pagination = {
        current_page: data.currentPage,
        page_size: data.pageSize,
        page_count: data.pageCount,
        start_row_number: data.startRowNumber,
        end_row_number: data.endRowNumber,
        total_count: data.rowCount
    }
    results.uid = userId;
    results.workspaces = workspaces;
    results.pagination = pagination;

    return results;
}

function mapToEmptyWorkspacesMembersFrontend() {
    let results = {};

    const pagination = {
        current_page: 1,
        page_size: 0,
        page_count: 1,
        start_row_number: 1,
        end_row_number: 1,
        total_count: 1
    }
    results.members = [];
    results.pagination = pagination;

    return results;
}

function mapToWorkspacesMemberByIdFrontend(data) {
    let results = {};

    const user = {
        user_id: data.userId,
        is_owner: (data.role.toLowerCase() === 'owner') ? true : false,
        is_suspended: (data.memberStatus.toLowerCase() !== 'active') ? true : false,
        is_member: true
    }

    results.user = user;
    return results;
}

function mapToEmptyWorkspacesMemberByIdFrontend(userId) {
    let results = {};

    const user = {
        user_id: userId,
        is_owner: false,
        is_suspended: false,
        is_member: false
    }

    results.user = user;
    return results;
}

function mapWorkspaceMemberRoleFrontend(role) {
    let memberRole = "";
    switch (role) {
        case "Member":
            memberRole = "Workspace Member"
            break;
        case "Owner":
            memberRole = "Workspace Owner"
            break;
        default:
            break;
    }

    return memberRole;
}

function mapWorkspaceMemberRoleBackend(role) {
    let memberRole = "";
    switch (role) {
        case "Workspace Member":
            memberRole = "Member"
            break;
        case "Workspace Owner":
            memberRole = "Owner"
            break;
        default:
            break;
    }

    return memberRole;
}

function mapWorkspaceMemberStatusBackend(status) {
    let userStatus = status;

    switch (status) {
        case "Active":
            userStatus = "Active"
            break;
        case "Suspended":
            userStatus = "Suspend"
            break;
        default:
            break;
    }

    return userStatus;
}

function mapToWorkspacesMembersBackendForCreate(data) {
    const profile = {
        fullName: data.full_name,
        gender: "Unspecified",
        email: data.personal.email_address,
        phone: data.personal.phone,
        mobile: data.personal.mobile_phone,
        defaultContact: (data.use_personal) ? 'Personal' : 'Business',
        userStatus: "Active",
        address: {
            address1: data.personal.address_1,
            address2: data.personal.address_2,
            zipCode: data.personal.zip_code,
            city: data.personal.city,
            country: data.personal.country,
            state: data.personal.state
        },
        businessInfo: {
            organization: data.public.organization,
            email: data.public.email_address,
            phone: data.public.phone,
            mobile: data.public.mobile_phone,
            att: data.public.att,
            address: {
                address1: data.public.address_1,
                address2: data.public.address_2,
                zipCode: data.public.zip_code,
                city: data.public.city,
                country: data.public.country,
                state: data.public.state
            }
        }
    };

    let user = {};
    user.userName = data.user_name;
    user.profile = profile;

    return user;
}

function mapToWorkspacesMembersBackendForAdd(data) {
    let results = [];

    for (let i in data.users) {
        let u = data.users[i];
        results.push(u.uid);
    }

    return results;
}
