import {
  axios,
  getCookies,
  queryBaseUrlV1,
  queryBaseUrlV2,
} from './getCookies';
import environment from 'environment';
import { getApplicationUrls } from '@wrd/helpers';
const { auth } = getApplicationUrls();
const config = require('../../environments/' + environment.settings);
const confirmationUrl = `${auth}/${config.emailConfirmationPage}`;

async function getWorkspaceMemberList(workspaceId, pageIndex, pageSize) {
  const results = await axios.get(
    queryBaseUrlV2 + 'workspace/' + workspaceId + '/member/list',
    {
      params: {
        page: pageIndex,
        pageSize: pageSize,
        picture: true,
      },
    }
  );
  return results;
}

async function getGlobalWorkspaceMemberList(workspaceId, pageIndex, pageSize) {
  const results = await axios.get(
    queryBaseUrlV2 + 'workspace/' + workspaceId + '/member/list',
    {
      params: {
        page: pageIndex,
        pageSize: pageSize,
        picture: false,
      },
    }
  );
  return results;
}

async function getWorkspaceMemberSearch(
  workspaceId,
  pageIndex,
  pageSize,
  searchKeyword
) {
  const results = await axios.get(
    queryBaseUrlV2 + 'workspace/' + workspaceId + '/member/search',
    {
      params: {
        keyword: searchKeyword,
        page: pageIndex,
        pageSize: pageSize,
        picture: true,
      },
    }
  );
  return results;
}

async function getWorkspaceMemberById(workspaceId, userId) {
  const results = await axios.get(
    queryBaseUrlV2 +
      'workspace/' +
      workspaceId +
      '/member/' +
      getCookies.user_id
  );
  return results;
}

async function getUserAvailabeWorkspaces(userId) {
  const results = await axios.get(
    queryBaseUrlV2 + 'workspace/member/' + userId
  );
  return results;
}

async function createWorkspaceMember(accessToken, workspaceId, member) {
  let requestHeaders = {
    headers: {
      Authorization: 'Bearer ' + getCookies.accessToken,
    },
  };
  const results = await axios
    .post(
      queryBaseUrlV2 +
        'workspace/' +
        workspaceId +
        '/member/create?confirmationUrl=' +
        confirmationUrl,
      member,
      requestHeaders
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function registerWorkspaceMember(workspaceId, member) {
  const results = await axios
    .post(
      queryBaseUrlV2 +
        'workspace/' +
        workspaceId +
        '/member/register?confirmationUrl=' +
        confirmationUrl,
      member
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function addWorkspaceMembers(accessToken, workspaceId, members) {
  let requestHeaders = {
    headers: {
      Authorization: 'Bearer ' + getCookies.accessToken,
    },
  };
  const results = await axios
    .post(
      queryBaseUrlV1 + 'workspace/' + workspaceId + '/member/add',
      members,
      requestHeaders
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

/* PUT */
async function updateWorkspaceMemberRole(workspaceId, userId, role) {
  const results = await axios.put(
    queryBaseUrlV1 +
      'workspace/' +
      workspaceId +
      '/member/' +
      userId +
      '/role/' +
      role
  );
  return results;
}

async function updateWorkspaceMemberStatus(workspaceId, userId, status) {
  const results = await axios.put(
    queryBaseUrlV1 +
      'workspace/' +
      workspaceId +
      '/member/' +
      userId +
      '/status/' +
      status
  );
  return results;
}

/* DELETE */
async function removeWorkspaceMember(workspaceId, userId) {
  const results = await axios.delete(
    queryBaseUrlV1 + 'workspace/' + workspaceId + '/member/' + userId
  );
  return results;
}

export {
  getWorkspaceMemberList,
  getGlobalWorkspaceMemberList,
  getWorkspaceMemberSearch,
  getWorkspaceMemberById,
  getUserAvailabeWorkspaces,
  createWorkspaceMember,
  registerWorkspaceMember,
  addWorkspaceMembers,
  updateWorkspaceMemberRole,
  updateWorkspaceMemberStatus,
  removeWorkspaceMember,
};
