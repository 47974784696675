import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';
import ApplicationsListItem from './ApplicationsListItem';
import { getApplicationsAction } from '../../../actions/getApplicationsAction';
import { getCategoriesAction } from '../../../actions/getCategoriesAction';
import { updateApplicationCategoryWithThunk } from '../../../api/applicationsThunk';
import { deleteCategoryWithThunk } from '../../../api/categoriesThunk';
import { reOrderCategoryItems } from '../../../utils/index';
import { mergeApplicationsCategories } from '../../../utils/categories';
import { defaultApplicationsSortingBy, defaultApplicationsSortingType } from '../../../configurations/app';
import { routeCodes } from '../../../configurations/routes';

class ApplicationsListCategory extends Component {
    constructor(props) {
        super(props);
        this.categoryDrop = React.createRef();
    }

    static propTypes = {
        category: PropTypes.object.isRequired,
        items: PropTypes.array.isRequired,
        categories: PropTypes.array.isRequired,
        applicationId: PropTypes.string.isRequired,
        categoryTitle: PropTypes.string.isRequired,
        updateApplicationCategory: PropTypes.func.isRequired,
        setDragInfoState: PropTypes.func.isRequired
    };

    onDragOver(e, isHaveApps) {
        e.stopPropagation();
        e.preventDefault();

        const draggedItem = this.props.items.filter(item => item.selected)[0];
        if (draggedItem === undefined) {
            return;
        }

        this.categoryDrop.current.setAttribute("class", (isHaveApps) ? "drop-area-app drop-area-hover mb-4" : "drop-area drop-area-hover mb-4");
    }

    onDragLeave(e, isHaveApps) {
        e.stopPropagation();
        e.preventDefault();

        let viewportOffset = this.categoryDrop.current.getBoundingClientRect();
        let boxTop = viewportOffset.top;
        let boxBottom = boxTop + viewportOffset.height;
        let y = e.clientY;

        if (y > boxTop && y < boxBottom) {
            return;
        }
        this.categoryDrop.current.setAttribute("class", (isHaveApps) ? "drop-area-app mb-4" : "drop-area mb-4");
    }

    onDrop(e, isHaveApps) {
        e.stopPropagation();
        e.preventDefault();

        const { category } = this.props;

        let applicationId = e.dataTransfer.getData("application_id");
        this.categoryDrop.current.setAttribute("class", (isHaveApps) ? "drop-area-app mb-4" : "drop-area mb-4");

        const draggedItem = this.props.items.filter(item => item.selected)[0];
        if (draggedItem !== undefined) {
            draggedItem.category_title = this.props.category.category_title;

            const applications = reOrderCategoryItems(
                this.props.items,
                this.props.items.length - 1,
                draggedItem
            );

            this.props.dispatchApplications(applications);

            let withCategories = this.props.categories.filter((item) => item.category_title !== "");
            let mergeCategories = mergeApplicationsCategories(withCategories, applications);
            this.props.dispatchCategories(mergeCategories);

            this.props.updateApplicationCategory(applicationId, category.category_id, 1, 10000, defaultApplicationsSortingBy, defaultApplicationsSortingType);
            this.props.setDragInfoState("", "");
        }
    }

    onCategoryItemSelect = (app) => {
        const { items } = this.props;
        const applications = items.map((item, i) =>
            (app.id === item.id)
                ? { ...item, selected: true }
                : { ...item, selected: false }
        )
        this.props.dispatchApplications(applications)
    }

    removeCategory() {
        const { category } = this.props;
        this.props.deleteCategory(category.category_id, 1, 10000, defaultApplicationsSortingBy, defaultApplicationsSortingType, "", this.props.history);
    }

    render() {
        const { category } = this.props;
        return (
            <>
                {(category.count > 0) ?
                    <div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="category-header mb-3">
                                    <span className="icon-wrd-binders"></span>
                                    <h5>{category.category_title}</h5>
                                    <Link to={routeCodes.CATEGORY_LIST + "/" + category.category_title.toLowerCase().replaceAll(" ", "+")} className="edit-Category">Edit</Link>
                                    <Link to="#" onClick={() => { this.removeCategory(); return false; }} className="remove-Category">Remove</Link>
                                </div>
                            </div>
                        </div>
                        <div onDrop={(e) => this.onDrop(e, true)} onDragOver={(e) => this.onDragOver(e, true)} onDragLeave={(e) => this.onDragLeave(e, true)}>
                            <div ref={this.categoryDrop} className="drop-area-app mb-4">
                                <div className="row">
                                    {category.applications.map((application, index) => (
                                        <div className="col-3" draggable={true} key={application.id}>
                                            <ApplicationsListItem item={application} onSelect={this.onCategoryItemSelect} setDragInfoState={this.props.setDragInfoState} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="category-header mb-3">
                                    <span className="icon-wrd-binders"></span>
                                    <h5>{category.category_title}</h5>
                                    <Link to={routeCodes.CATEGORY_LIST + "/" + category.category_title.toLowerCase().replaceAll(" ", "+")} className="edit-Category">Edit</Link>
                                    <Link to="#" onClick={() => { this.removeCategory(); return false; }} className="remove-Category">Remove</Link>
                                </div>
                            </div>
                        </div>
                        <div ref={this.categoryDrop} className="drop-area mb-4" onDrop={(e) => this.onDrop(e, false)} onDragOver={(e) => this.onDragOver(e, false)} onDragLeave={(e) => this.onDragLeave(e, false)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <p>No available app(s) in this category, drag and drop an app here to add to the category</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    dispatchApplications: (apps) => dispatch(getApplicationsAction(apps)),
    dispatchCategories: (categories) => dispatch(getCategoriesAction(categories)),
    updateApplicationCategory: (applicationId, categoryId, pageIndex, pageSize, sortingBy, sortingType) => dispatch(updateApplicationCategoryWithThunk(applicationId, categoryId, pageIndex, pageSize, sortingBy, sortingType)),
    deleteCategory: (categoryId, pageIndex, pageSize, sortingBy, sortingType, routeBackTo, historyProps) => dispatch(deleteCategoryWithThunk(categoryId, pageIndex, pageSize, sortingBy, sortingType, routeBackTo, historyProps))
});

export default withRouter(connect(null, mapDispatchToProps)(ApplicationsListCategory));
