import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setupNewUserAction } from '../../../actions/setupNewUserAction';
import { createUserWithThunk } from '../../../api/usersThunk';
import { createWorkspacesMembersWithThunk } from '../../../api/workspacesMembersThunk';
import { getUrlWorkspaceId } from '../../../utils/url';
import { overrideWorkspaceId } from '../../../utils/workspaces';
import { routeCodes } from '../../../configurations/routes';

class UserCreateInput extends Component {
  privateContact = React.createRef();
  businessContact = React.createRef();
  privateEmail = React.createRef();
  businessEmail = React.createRef();
  selectEmail = React.createRef();
  emailAddress = React.createRef();
  userName = React.createRef();
  fullName = React.createRef();
  advancedUserName = React.createRef();
  advancedFullName = React.createRef();

  constructor(props) {
    super(props);
    this.enterSubmit = this.enterSubmit.bind(this);
    this.onFullNameChange = this.onFullNameChange.bind(this);

    this.state = {
      showAdvanced: false,
      emailAddress: '',
      isBusinessEmail: false,
      isPrivateContactChecked: props.user.private_default_contact,
    };
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onToggleButton = this.onToggleButton.bind(this);
  }

  static propTypes = {
    user: PropTypes.object.isRequired,
    routeBack: PropTypes.object.isRequired,
    setupNewUser: PropTypes.func.isRequired,
    scrollTop: PropTypes.func.isRequired,
    workspace: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.id = `toggle_${Math.random().toString().replace(/0\./, '')}`;
    this.id2 = `toggle_${Math.random().toString().replace(/0\./, '')}`;
  }

  async onToggleButton() {
    const status = !this.state.showAdvanced;
    await this.setState({ showAdvanced: status });

    if (status) {
      this.advancedUserName.current.value = this.props.user.user_name;
      this.advancedFullName.current.value = this.props.user.full_name;
      this.privateContact.current.checked =
        this.props.user.private_default_contact;
      this.businessContact.current.checked =
        !this.props.user.private_default_contact;
      if (this.props.user.private_default_contact) {
        this.privateEmail.current.value = this.props.user.private_email;
        this.privateContact.current.checked = true;
        this.businessEmail.current.value = this.props.user.business_email;
        this.businessContact.current.checked = false;
      } else {
        this.privateEmail.current.value = this.props.user.private_email;
        this.privateContact.current.checked = false;
        this.businessEmail.current.value = this.props.user.business_email;
        this.businessContact.current.checked = true;
      }
    } else {
      this.userName.current.value = this.props.user.user_name;
      this.fullName.current.value = this.props.user.full_name;
      this.selectEmail.current.value = this.props.user.private_default_contact
        ? 'PE'
        : 'BE';
      this.emailAddress.current.value = this.props.user.private_default_contact
        ? this.props.user.private_email
        : this.props.user.business_email;
    }

    this.scrollToTop();
  }

  scrollToTop() {
    this.props.scrollTop();
  }

  onEmailChange(e) {
    this.setState({ emailAddress: e.target.value });
    let user = this.state.isBusinessEmail
      ? {
          ...this.props.user,
          business_email: e.target.value,
          private_default_contact: false,
        }
      : {
          ...this.props.user,
          private_email: e.target.value,
          private_default_contact: true,
        };
    this.props.setupNewUser({ ...user, user_name: e.target.value });
  }

  onFullNameChange(e) {
    let user = {
      ...this.props.user,
      full_name: e.target.value,
    };
    this.props.setupNewUser(user);
  }

  async enterSubmit(e) {
    if (e.key === 'Enter') {
      const { user, routeBack } = this.props;

      this.props.scrollTop();
      if (routeBack.source_page === '') {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        if (id !== '') {
          let wId = await overrideWorkspaceId(id);
          this.props.createWorkspaceMember(
            wId,
            user,
            routeCodes.WORKSPACES_LIST + '/' + id + '/members'
          );
        } else {
          this.props.createUser(user, routeCodes.USERS_LIST);
        }
      } else if (routeBack.source_page === 'workspace_member') {
        const { workspace } = this.props;
        this.props.createWorkspaceMember(workspace.id, user, routeBack.url);
      } else {
        this.props.createUser(user, routeBack.url);
      }
    }
  }

  render() {
    const { showAdvanced } = this.state;
    let isWorkspaceMember = false;

    let id = getUrlWorkspaceId(this.props.location.pathname);
    if (id !== '') {
      isWorkspaceMember = true;
    }

    return (
      <div
        className="col-lg-6 col-xl-7 mb-5 pt-5"
        onKeyPress={this.enterSubmit}
      >
        {!showAdvanced && (
          <div>
            <div className="inputForm">
              <input
                ref={this.emailAddress}
                className="form-control"
                type="text"
                name=""
                onChange={this.onEmailChange}
                required
              />
              <label>Email</label>
            </div>
            <div className="inputForm">
              <input
                ref={this.fullName}
                className="form-control"
                type="text"
                name=""
                onChange={this.onFullNameChange}
                required
              />
              <label>Full Name</label>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ setup, routeBack, detail }) => ({
  user: setup.user,
  routeBack: routeBack.create_user,
  workspace: detail.workspace,
});

const mapDispatchToProps = (dispatch) => ({
  setupNewUser: (item) => dispatch(setupNewUserAction(item)),
  createUser: (user, url) => dispatch(createUserWithThunk(user, url)),
  createWorkspaceMember: (id, member, url) =>
    dispatch(createWorkspacesMembersWithThunk(id, member, url)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UserCreateInput)
);
