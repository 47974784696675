import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

import { routeCodes } from '../../../configurations/routes';
import { updateStickyWorkspacesAction } from '../../../actions/updateStickyWorkspacesAction';
import {
  updateStickyWorkspaceStatusWithThunk,
  updateStickyWorkspaceActiveWithThunk,
} from '../../../api/workspacesThunk';
import { getWorkspaceThemeSettingImplementationWithThunk } from '../../../api/workspacesThemeSettingsThunk';
import { updateDialogWorkspacesAction } from '../../../actions/updateDialogWorkspacesAction';
import { changeRouteAction } from '../../../actions/changeRouteAction';
import { getUserFromCookie } from '@wrd/helpers';
import { getCookies } from 'apps/portal/src/backend/api/getCookies';
import { getApplicationUrls } from '@wrd/helpers';

class GridItem extends Component {
  constructor(props) {
    super(props);
    this.displayWorkspace = this.displayWorkspace.bind(this);
    this.displayAction = this.displayAction.bind(this);
    this.hideAction = this.hideAction.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
    this.getRoute = this.getRoute.bind(this);
    this.getDetail = this.getDetail.bind(this);
  }

  static propTypes = {
    headers: PropTypes.array.isRequired,
    item: PropTypes.object.isRequired,
    sticky: PropTypes.object.isRequired,
    sorting: PropTypes.object.isRequired,
    dispatchStickyWorkspaces: PropTypes.func.isRequired,
    dispatchDialogWorkspaces: PropTypes.func.isRequired,
    updateWorkspaceStatus: PropTypes.func.isRequired,
    updateStickyWorkspaceActive: PropTypes.func.isRequired,
    pagination: PropTypes.func.isRequired,
  };

  getRoute() {
    const { item } = this.props;
    if (item.owner_count === 0) {
      return;
    }

    this.hideAction();
    let navigation = {
      name: routeCodes.WORKSPACES_LIST,
    };
    this.props.dispatchRoute(navigation);
    this.props.history.push(
      routeCodes.WORKSPACES_LIST +
        '/' +
        item.workspace_name.toLowerCase().replaceAll(' ', '+') +
        '/contactOwners'
    );
  }

  getDetail() {
    this.hideAction();
    const { item } = this.props;

    let name = item.workspace_name.toLowerCase().replaceAll(' ', '+');
    let url = routeCodes.WORKSPACES_LIST + '/' + name + '/properties';
    getWorkspaceThemeSettingImplementationWithThunk(
      name,
      this.props.history,
      url
    );
  }

  getWorkspaces() {
    this.hideAction();
    const { item } = this.props;

    let name = item.workspace_name.toLowerCase().replaceAll(' ', '+');
    let url = routeCodes.WORKSPACES_LIST + '/' + name ;
    getWorkspaceThemeSettingImplementationWithThunk(
      name,
      this.props.history,
      url
    );
  }

  enterBetaWorkspace() {
    const { workspaces } = getApplicationUrls();
    const returnUrl = window.location.href;

    const url = `${workspaces}/${this.props.item.id}?returnUrl=${returnUrl}`;

    window.location.href = url;
  }

  displayWorkspace(workspaceName) {
    const { workspaces } = getApplicationUrls();
    const returnUrl = window.location.href;
    const urBetaWorkspace= `${workspaces}/${this.props.item.id}?returnUrl=${returnUrl}`;
    let name = workspaceName.toLowerCase().replaceAll(' ', '+');
    let url = (getCookies.user_role === 'Administrator') ? routeCodes.WORKSPACES_LIST + "/" + name + "/properties" : routeCodes.WORKSPACES_LIST + "/" + name;

    {!getCookies.isMikeMine && getCookies.user_role !== 'Administrator' ?      window.location.href = urBetaWorkspace :
    getWorkspaceThemeSettingImplementationWithThunk(
      name,
      this.props.history,
      url
    )
    };
  }

  displayAction(id) {
    this.props.dispatchStickyWorkspaces({
      workspace_id: id,
      show_action: !this.props.sticky.show_action,
    });
  }

  hideAction() {
    this.props.dispatchStickyWorkspaces({
      workspace_id: '',
      show_action: false,
    });
  }

  updateStatus() {
    let workspaces = {
      id: this.props.item.id,
      name: this.props.item.workspace_name,
      status: this.props.item.enabled ? 'Enabled' : 'Disabled',
      dialog_title: 'Warning',
      dialog_type: this.props.item.enabled ? 'disable' : 'enable',
      route_back_to: '',
      show_dialog: this.props.item.enabled ? true : false,
    };

    this.props.dispatchDialogWorkspaces(workspaces);

    if (!this.props.item.enabled) {
      let workspace = {
        id: this.props.item.id,
        enabled: true,
      };

      this.props.updateWorkspaceStatus(workspace);
    }
  }

  updateActivation() {
    let workspaces = {
      id: this.props.item.id,
      name: this.props.item.workspace_name,
      status: this.props.item.status === 'Active' ? 'Active' : 'Suspend',
      dialog_title: 'Warning',
      dialog_type:
        this.props.item.status === 'Active' ? 'suspend' : 'reinstate',
      route_back_to: '',
      show_dialog: this.props.item.status === 'Active' ? true : false,
    };

    this.props.dispatchDialogWorkspaces(workspaces);

    if (this.props.item.status !== 'Active') {
      let workspace = {
        id: this.props.item.id,
        status: 'Active',
      };

      this.props.updateStickyWorkspaceActive(workspace);
    }
  }

  removeWorkspace() {
    let workspaces = {
      id: this.props.item.id,
      name: this.props.item.workspace_name,
      status: this.props.item.status,
      dialog_title: 'Warning',
      dialog_type: 'trash',
      route_back_to: '',
      show_dialog: true,
    };

    this.props.dispatchDialogWorkspaces(workspaces);
  }

  render() {
    const { headers, item } = this.props;
    const { show_action, workspace_id } = this.props.sticky;
    const { isAdministrator, userMikeCloud, isUser } = getUserFromCookie();

    return (
      <tr id={'grid-row-' + item.id}>
        <td
          id={'workspaces-list-id-' + item.id}
          onClick={() => this.displayWorkspace(item.workspace_name)}
        >
          {item.url_image ?
            <img
              style={{ height: 'auto', width: '90px', maxHeight: '70px' }}
              src={
                (item.url_image !== undefined ? 'data:image/jpeg;base64,' : '') +
                item.url_image
              }
              alt=""
            /> : 
            <div 
              style={{ 
                height: '70px', 
                width: 'auto', 
                color: 'lightgray', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center' 
              }}
            >
              No Logo
            </div>
          }
        </td>
        <td onClick={() => this.displayWorkspace(item.workspace_name)}>
          <b>{item.workspace_name}</b>
          <span className="desc">{item.workspace_title}</span>
        </td>
        <td onClick={() => this.displayWorkspace(item.workspace_name)}>
          <span className="icon icon-wrd-calendar"></span>
          <span className="date">{item.created_date}</span>
        </td>
        <td onClick={() => this.displayWorkspace(item.workspace_name)}>
          <span className={item.enabled ? 'statusActive' : 'statusSuspend'}>
            {item.enabled ? 'Enabled' : 'Disabled'}
          </span>
        </td>
        <td onClick={() => this.displayWorkspace(item.workspace_name)}>
          <span
            className={
              item.status === 'Active' ? 'statusActive' : 'statusSuspend'
            }
          >
            {item.status === 'Active' ? 'Active' : 'Suspended'}
          </span>
        </td>
        <td
          id={'grid-item-properties-' + item.id}
          onClick={() => this.displayWorkspace(item.workspace_name)}
          style={{ display: 'none' }}
        >
          {headers
            .filter((header, i) => i !== 0)
            .map((header, i) => (
              <div key={header.id}>
                <div className="grid-properties-header">
                  {header.header_name}
                  {i === 0 && (
                    <div className="grid-properties-item">
                      <b>{item.workspace_name}</b>
                      <span className="desc">{item.workspace_title}</span>
                    </div>
                  )}
                  {i === 1 && (
                    <div className="grid-properties-item">
                      <span className="icon icon-wrd-calendar"></span>
                      <span className="date">{item.created_date}</span>
                    </div>
                  )}
                  {i === 2 && (
                    <div className="grid-properties-item">
                      <span
                        className={
                          item.enabled ? 'statusActive' : 'statusSuspend'
                        }
                      >
                        {item.enabled ? 'Enabled' : 'Disabled'}
                      </span>
                    </div>
                  )}
                  {i === 3 && (
                    <div className="grid-properties-item-last">
                      <span
                        className={
                          item.status === 'Active'
                            ? 'statusActive'
                            : 'statusSuspend'
                        }
                      >
                        {item.status === 'Active' ? 'Active' : 'Suspended'}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </td>

        <td
          id={'workspaces-list-sticky-action-' + item.id}
          className="stickyAction"
        >
          <div>
            <button
              className="tableActionBtn"
              onClick={() => this.displayAction(item.id)}
              onBlur={() => this.hideAction()}
            >
              <span className="icon-wrd-more"></span>
            </button>
            <ul
              className={
                workspace_id === item.id && show_action
                  ? 'action show-action'
                  : 'action hide-action'
              }
            >
              {userMikeCloud && isUser && (
                <Button variant="text">Request Access</Button>
              )}
              {isUser && (
                <>
                <li>
                    <Link
                      to={routeCodes.WORKSPACES_LIST}
                      onMouseDown={!getCookies.isMikeMine ? () => this.enterBetaWorkspace():() => this.getWorkspaces()}
                      className="remove"
                    >
                      <span className="icon-wrd-eye"></span> View Workspace
                    </Link>
                  </li>
                </>
              )}
              {isAdministrator && (
                <>
                  <li>
                    <Link
                      to={routeCodes.WORKSPACE_LANDING}
                      onMouseDown={() => this.getDetail()}
                      className="remove"
                    >
                      <span className="icon-wrd-eye"></span> View Detail
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={routeCodes.WORKSPACES_LIST}
                      onMouseDown={() => this.getRoute()}
                      className={
                        item.owner_count === 0
                          ? 'contact isDisabled'
                          : 'contact'
                      }
                    >
                      <span className="icon-wrd-mail-closed"></span>Contact
                      Owner(s)
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={routeCodes.WORKSPACES_LIST}
                      onMouseDown={() => this.updateStatus()}
                    >
                      <span
                        className={
                          item.enabled
                            ? 'icon-lock icon-wrd-lock'
                            : 'icon-unlock icon-wrd-unlock'
                        }
                      ></span>
                      <label
                        className={
                          item.enabled
                            ? 'sticky-action-disable'
                            : 'sticky-action-enable'
                        }
                      >
                        {item.enabled ? 'Disable' : 'Enable'}
                      </label>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={routeCodes.WORKSPACES_LIST}
                      onMouseDown={() => this.updateActivation()}
                    >
                      <span
                        className={
                          item.status === 'Active'
                            ? 'icon-lock icon-wrd-lock'
                            : 'icon-unlock icon-wrd-unlock'
                        }
                      ></span>
                      <label
                        className={
                          item.status === 'Active'
                            ? 'sticky-action-disable'
                            : 'sticky-action-enable'
                        }
                      >
                        {item.status === 'Active' ? 'Suspend' : 'Reinstate'}
                      </label>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={routeCodes.WORKSPACES_LIST}
                      onMouseDown={() => this.removeWorkspace()}
                      className="remove"
                    >
                      <span className="icon-wrd-trash"></span>
                      <label className="sticky-action-disable">Remove</label>
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = ({ sticky, search, sorting, portalRouter }) => ({
  sticky: sticky.workspaces,
  search: search.workspaces,
  sorting: sorting.workspace,
  portalRouter,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchRoute: (navigation) => dispatch(changeRouteAction(navigation)),
  dispatchStickyWorkspaces: (data) =>
    dispatch(updateStickyWorkspacesAction(data)),
  dispatchDialogWorkspaces: (dialog) =>
    dispatch(updateDialogWorkspacesAction(dialog)),
  updateWorkspaceStatus: (workspace) =>
    dispatch(updateStickyWorkspaceStatusWithThunk(workspace)),
  updateStickyWorkspaceActive: (workspace) =>
    dispatch(updateStickyWorkspaceActiveWithThunk(workspace)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GridItem)
);
