import { updateImage } from '../../utils/snapshot';
import { axios, queryBaseUrlV1, queryBaseUrlV2 } from './getCookies';
import { getUserFromCookie } from '@wrd/helpers';
const FormData = require('form-data');

const qs = require('qs');

async function getWorkspaceById(workspaceId, includeLockStatus) {
  const results = includeLockStatus
    ? await axios.get(
      queryBaseUrlV2 + 'workspace/' + workspaceId + '?includeLockStatus=true'
      )
    : await axios.get(queryBaseUrlV2 + 'workspace/' + workspaceId);

  return results;
}

async function getWorkspaceByName(workspacename) {
  const results = await axios.get(
    queryBaseUrlV2 + 'workspace/id/' + workspacename
  );

  return results;
}

async function getWorkspaceWithLogoById(workspaceId) {
  const results = await axios.get(queryBaseUrlV1 + 'workspace/' + workspaceId);

  return results;
}

async function getWorkspaceHeaderById(workspaceId) {
  const results = await axios.get(
    queryBaseUrlV1 + 'workspace/header/' + workspaceId
  );
  return results;
}

async function getWorkspaceList(pageIndex, pageSize, sortingBy, sortingType) {
  const { oldBaseURL } = getUserFromCookie();

  const results = await axios.get(oldBaseURL + '/v2/workspace/list', {
    params: {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sort: sortingBy,
      order: sortingType,
      logo: true,
    },
  });

  return results;
}

async function getWorkspaceSearchList(
  keyword,
  pageIndex,
  pageSize,
  sortingBy,
  sortingType
) {
  const results = await axios.get(
    queryBaseUrlV2 + 'workspace/search/' + pageIndex + '/' + pageSize,
    {
      params: {
        keyword: keyword,
        sort: sortingBy,
        order: sortingType,
        logo: true,
      },
    }
  );

  return results;
}

async function getWorkspaceHeaderList(
  pageIndex,
  pageSize,
  sortingBy,
  sortingType
) {
  const results = await axios.get(
    queryBaseUrlV1 + 'workspace/header/list/' + pageIndex + '/' + pageSize,
    {
      params: {
        sort: sortingBy,
        order: sortingType,
      },
    }
  );

  return results;
}

async function getContactOwnerId(workspaceId) {

  const results = await axios
    .get(queryBaseUrlV1 + 'workspace/' + workspaceId + '/owner?status=OnlyActive')
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getWorkspaceNameByCustomDomain(domain) {
  const results = await axios
    .get(queryBaseUrlV2 + 'workspace/domain/' + domain)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getContactOwnerUserId(ids) {
  const results = await axios
    .get(queryBaseUrlV1 + 'management/account/emails?', {
      params: {
        Ids: ids,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
  return results;
}

async function getWorkspaceLogo(workspaceId, width, height) {
  const url =
  queryBaseUrlV2 + 'workspace/' + workspaceId + '/logo/' + width + '/' + height;

  let results;
  try {
    const response = await axios.get(url, { responseType: 'arraybuffer' });
    results = Buffer.from(response.data, 'binary').toString('base64');
  } catch (err) {
    if (err.response !== undefined) {
      if (err.response.status === 404) {
        return results;
      }
    }
  }
  return results;
}

async function createWorkspace(workspace) {
  const results = await axios
    .post(queryBaseUrlV1 + 'workspace', workspace)
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function updateWorkspace(workspace) {
  const results = await axios
    .put(queryBaseUrlV1 + 'workspace', workspace)
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function updateWorkspaceStatus(workspaceId, status) {
  const results = await axios.put(
    queryBaseUrlV1 + 'workspace/' + workspaceId + '/' + status
  );
  return results;
}

async function deleteWorkspace(workspaceId) {
  const results = await axios.delete(
    queryBaseUrlV1 + 'workspace/' + workspaceId
  );
  return results;
}

async function updateWorkspaceLogo(workspaceId, content) {
  const url = queryBaseUrlV2 + 'workspace/' + workspaceId + '/logo';

  return await updateImage(content, url);
}

/* POST */
async function sendEmailToWorkspaceOwners(workspaceId, model) {
const formData = new FormData();

formData.append('Content', model.Content);
formData.append('SenderAddress', model.SenderAddress);
formData.append('SenderName', model.SenderName);
formData.append('Subject', model.Subject);
formData.append('WorkspaceId', workspaceId);

const results = await axios.post(
    queryBaseUrlV2 + 'workspace/' + workspaceId + '/contactowners',
    formData
  );
  return results;
}

export {
  getWorkspaceById,
  getWorkspaceByName,
  getWorkspaceWithLogoById,
  getWorkspaceHeaderById,
  getWorkspaceHeaderList,
  getWorkspaceList,
  getWorkspaceSearchList,
  getContactOwnerId,
  getWorkspaceNameByCustomDomain,
  getContactOwnerUserId,
  createWorkspace,
  updateWorkspace,
  updateWorkspaceStatus,
  deleteWorkspace,
  getWorkspaceLogo,
  updateWorkspaceLogo,
  sendEmailToWorkspaceOwners,
};
