import { GET_WORKSPACES_SEARCH, GET_USERS_SEARCH, GET_WORKSPACES_MEMBERS_SEARCH, 
    GET_USER_AVAILABLE_WORKSPACES_SEARCH, CLEAR_USER_AVAILABLE_WORKSPACES_SEARCH,
    GET_APPLICATIONS_SEARCH,
} from '../actions/index';

const initialSearchState = {
    user: {
        search_text: ''
    },
    member: {
        search_text: ''
    },
    available_workspace: {
        search_text: ''
    },
    workspaces: {
        search_text: ''
    },
    applications: {
        search_text: ''
    },
}

export const search = (state = initialSearchState, action) => {
    const { user, member, available_workspace, workspaces, applications } = action

    switch (action.type) {
        case GET_APPLICATIONS_SEARCH:
            return {
                ...state,
                applications
            }
        case GET_USERS_SEARCH:
            return {
                ...state,
                user
            }
        case GET_WORKSPACES_SEARCH:
            return {
                ...state,
                workspaces
            }
        case GET_WORKSPACES_MEMBERS_SEARCH:
            return {
                ...state,
                member
            }
        case GET_USER_AVAILABLE_WORKSPACES_SEARCH:
            return {
                ...state,
                available_workspace
            }
        case CLEAR_USER_AVAILABLE_WORKSPACES_SEARCH:
            return {
                ...state,
                available_workspace: initialSearchState.available_workspace
            }
        default:
            return state;
    }
};