import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import GridItem from './GridItem';

class GridBody extends Component {
    static propTypes = {
        items: PropTypes.array.isRequired,
        pagination: PropTypes.func.isRequired
    };

    render() {
        const { items } = this.props;

        return (
            <tbody>
                {items.map((item, index) => (
                    <GridItem key={index} item={item} pagination={this.props.pagination} />
                ))}
            </tbody>
        );
    }
}

export default withRouter(GridBody);
