import { CHANGE_ROUTE } from '../actions/index';

const initialNavigationState = {
    name: '/portal/workspaces/'
}

export const portalRouter = (state = initialNavigationState, action) => {
    const { navigation } = action
    switch (action.type) {
        case CHANGE_ROUTE:
            return navigation
        default:
            return state;
    }
};