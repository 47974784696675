import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearUsersAction } from '../../../actions/clearUsersAction';
import { clearUsersPaginationAction } from '../../../actions/clearUsersPaginationAction';
import { getUsersWithThunk, getUsersSearchWithThunk } from '../../../api/usersThunk';
import { changeNavigationAction } from '../../../actions/changeNavigationAction';
import { head, itemHeight, tHead, defaultUsersSortingBy, defaultUsersSortingType, defaultUsersPageSize } from '../../../configurations/app';
import SidebarContainer from '../../Sidebar/SidebarContainer';
import UsersListHeader from './UsersListHeader';
import UsersListBody from './UsersListBody';
import UsersModal from '../Modal/UsersModal';
import { getUserHeaders } from '../../../utils/users';
import { filterByValue } from '../../../utils/workspaces';

class UsersListContainer extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleGridResize = this.handleGridResize.bind(this);
        this.getUserList = this.getUserList.bind(this);
        this.updateMode = this.updateMode.bind(this);
    }

    static propTypes = {
        users: PropTypes.array.isRequired,
        getUsers: PropTypes.func.isRequired,
        getUsersSearch: PropTypes.func.isRequired,
        clearUsers: PropTypes.func.isRequired,
        clearUsersPagination: PropTypes.func.isRequired,
        navigation: PropTypes.object.isRequired,
        dispatchNavigation: PropTypes.func.isRequired
    };

    state = {
        page: 1,
        size: 0,
        widthLimit: 0,
        maxLimit: 0,
        users: [],
        mode: 'list'
    }

    changeNavigation(name) {
        let navigation = {
            name
        };
        this.props.dispatchNavigation(navigation);
    }

    calculatePageSize() {
        let size = defaultUsersPageSize;

        this.setState({ page: 1, size });
        return size;
    }

    getUserList(currentPage) {
        const { sort_by, sort_type } = this.props.sort
        const { search } = this.props;
        const { page, size, mode } = this.state;

        if (page < currentPage) {
            if (mode === 'list') {
                this.props.getUsers(currentPage, size, sort_by, sort_type);
            }
            else {
                this.props.getUsersSearch(currentPage, size, search.search_text, defaultUsersSortingBy, defaultUsersSortingType);
            }
            this.setState({ page: currentPage });
        }
    }

    handleScroll() {
        const { users } = this.props;

        let { size } = this.state;
        let top = document.documentElement.scrollTop;
        let height = document.documentElement.scrollHeight;
        let h = itemHeight;
        if (users.length > 0) {
            const usersListId = document.getElementById('users-list-id-' + users[0].uid);
            if (usersListId !== null) {
                h = usersListId.offsetHeight;
            }
        }

        let page = (height - head - tHead) / (size * h);
        page = (Math.abs(page - Math.floor(page)) > 0.2) ? Math.ceil(page) : Math.floor(page);

        if (Math.ceil(top + window.innerHeight) >= height) {
            page = page + 1;
            this._isMounted && this.getUserList(page);
        }

        setTimeout(() => {
            this.handleResize();
        }, 500);
    }

    handleResize() {
        const { users } = this.props;

        for (let i in users) {
            let user = users[i];
            const usersListId = document.getElementById('users-list-id-' + user.uid);
            const usersListStickyAction = document.getElementById('users-list-sticky-action-' + user.uid);
            if (usersListId !== null && usersListStickyAction !== null) {
                if (usersListId.offsetHeight !== usersListStickyAction.offsetHeight) {
                    usersListStickyAction.style.height = usersListId.offsetHeight + 'px';
                }
            }
        }

        this.handleGridResize();
    }

    handleGridResize() {
      const headers = getUserHeaders();
      const { widthLimit, maxLimit, users } = this.state;
      const items = users;
      const gridResponsive = document.getElementById('grid-responsive');
      const gridStriped = document.getElementById('grid-striped');

      if (gridResponsive && gridStriped) { // Check if elements are not null
        let limit = (widthLimit === 0) ? gridStriped.offsetWidth : widthLimit;
        if ((gridResponsive.offsetWidth < limit && limit < maxLimit) || (gridResponsive.offsetWidth < maxLimit && limit > maxLimit)) {
          for (let i in headers) {
            let header = headers[i];
            if (parseInt(i, 10) !== 0) {
              const gridHeader = document.getElementById('grid-header-' + header.id);
              if (gridHeader) {
                gridHeader.style.display = "none";
              }
            }
          }
          const gridHeaderProperties = document.getElementById('grid-header-properties');
          if (gridHeaderProperties) {
            gridHeaderProperties.style.display = null;
          }

          for (let i in items) {
            let item = items[i];
            const gridRow = document.getElementById('grid-row-' + item.id);
            if (gridRow) {
              for (let j in gridRow.childNodes) {
                let n = parseInt(j, 10);
                if (n <= headers.length - 1 && n !== 0) {
                  let node = gridRow.childNodes[j];
                  if (node) {
                    node.style.display = "none";
                  }
                }
              }
            }
            const gridItemProperties = document.getElementById('grid-item-properties-' + item.id);
            if (gridItemProperties) {
              gridItemProperties.style.display = null;
            }
          }

          this.setState({ widthLimit: limit });
        } else {
          for (let i in headers) {
            let header = headers[i];
            if (parseInt(i, 10) !== 0) {
              const gridHeader = document.getElementById('grid-header-' + header.id);
              if (gridHeader) {
                gridHeader.style.display = null;
              }
            }
          }
          const gridHeaderProperties = document.getElementById('grid-header-properties');
          if (gridHeaderProperties) {
            gridHeaderProperties.style.display = "none";
          }

          for (let i in items) {
            let item = items[i];
            const gridRow = document.getElementById('grid-row-' + item.id);
            if (gridRow) {
              for (let j in gridRow.childNodes) {
                let n = parseInt(j, 10);
                if (n <= headers.length - 1 && n !== 0) {
                  let node = gridRow.childNodes[j];
                  if (node) {
                    node.style.display = null;
                  }
                }
              }
            }
            const gridItemProperties = document.getElementById('grid-item-properties-' + item.id);
            if (gridItemProperties) {
              gridItemProperties.style.display = "none";
            }
          }
          this.setState({ widthLimit: 0 });
        }
      }
    }


    updateMode(page, mode) {
        this.setState({ page, mode });
    }

    componentDidMount() {
        this._isMounted = true;
        const { sort_by, sort_type } = this.props.sort
        const size = this.calculatePageSize();
        this.props.getUsers(1, size, sort_by, sort_type);
        this.changeNavigation('Users');
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("resize", this.handleResize);
        this.setState({ page: 1, size, mode: 'list' });
        setTimeout(() => {
            const gridStriped = document.getElementById('grid-striped');
            if (gridStriped !== null) {
                this.setState({ maxLimit: gridStriped.offsetWidth })
            }
            this.handleResize();
        }, 1000);
    }

    componentDidUpdate(prevProps) {
        const { users, search } = this.props;
        if (prevProps.users.length !== users.length && users.length > 0) {
            setTimeout(() => {
                this.handleResize();
            }, 200);
        }

        if (prevProps.users !== users && users.length > 0) {
            const userFilters = filterByValue(users, search.search_text);
            this.setState({ users: userFilters });
        }

    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.clearUsers();
        this.props.clearUsersPagination();
        this.setState({ page: 1, size: 0, mode: 'list' });
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener("resize", this.handleResize);
    }

    render() {
        return (
            <>
                <SidebarContainer />
                <main>
                    <div className="container-fluid h-100">
                        <div className="mcp">
                            <UsersListHeader updateMode={this.updateMode} />
                            <UsersListBody users={this.state.users} />
                        </div>
                    </div>
                </main>
                <UsersModal type="list" />
            </>
        );
    }
}

const mapStateToProps = ({ users, navigation, sorting, search }) => ({
    users,
    navigation,
    sort: sorting.user,
    search: search.user
});

const mapDispatchToProps = (dispatch) => ({
    getUsers: (pageIndex, pageSize, sortingBy, sortingType) => dispatch(getUsersWithThunk(pageIndex, pageSize, sortingBy, sortingType)),
    getUsersSearch: (pageIndex, pageSize, searchKeyword, SortingBy, SortingType) => dispatch(getUsersSearchWithThunk(pageIndex, pageSize, searchKeyword, SortingBy, SortingType)),
    clearUsers: () => dispatch(clearUsersAction([])),
    clearUsersPagination: () => dispatch(clearUsersPaginationAction([])),
    dispatchNavigation: (navigation) => dispatch(changeNavigationAction(navigation))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersListContainer));
