import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateAccountAction } from '../../../actions/updateAccountAction';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../configurations/routes';

class ProfileHeader extends Component {
    constructor(props) {
        super(props);
        this.onUserNameChange = this.onUserNameChange.bind(this);
    }

    static propTypes = {
        account: PropTypes.object.isRequired,
        dispatchAccount: PropTypes.func.isRequired,
    };

    onUserNameChange(e) {
        let account = {
            ...this.props.account,
            full_name: e.target.value
        };

        this.props.dispatchAccount(account);
    }

    render() {
        const { account } = this.props;

        return (
            <div className="mainHead">
                <div className="row">
                    <div className="col-12">
                        <h2 className="float-left mt-2 mr-4">My Profile</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="workspaceInfo">
                            <div className="row">
                                <div className="workspaceName col-xl-7 offset-xl-3">
                                    <div className="float-left">
                                        <div className="formWrap">
                                            <div className="inputForm wsNameEdit">
                                                <input className="form-control appNameInput wsNameEditWider" type="text" name=""
                                                    required defaultValue={account.full_name} onChange={this.onUserNameChange} />
                                                <label>Full Name</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="userRole col-xl-7 offset-xl-3 mt-2">
                                    {account.id} <span className={(account.registration_status === 'Pending') ? 'confirmStat ml-2' : 'hide-registration-status'}>{account.registration_status}</span>
                                </div>
                            </div>
                            <nav className="col-lg-5 col-xl-4">
                                <div className="row text-center">
                                    <div className="col px-0">
                                        <Link to={'#'}
                                            className="workspaceMenu py-3 active">Detail
                                        </Link>
                                    </div>
                                    <div className="col px-0">
                                        <Link to={routeCodes.PROFILE_ACCESS}
                                            className="workspaceMenu py-3">Access
                                        </Link>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>                
            </div>
        );
    }
}

const mapStateToProps = ({ detail }) => ({
    account: detail.account
});

const mapDispatchToProps = (dispatch) => ({
    dispatchAccount: (account) => dispatch(updateAccountAction(account))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileHeader));