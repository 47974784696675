import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GridHeader from './GridHeader';
import GridBody from './GridBody';

class GridContainer extends Component {
    static propTypes = {
        headers: PropTypes.array.isRequired,
        items: PropTypes.array.isRequired,
        loading: PropTypes.bool.isRequired
    };

    render() {
        const { headers, items, loading } = this.props;

        return (
            <div className="mainBody">
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="BallTriangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                <div className="row">
                    <div className=" col-xl-12">
                        <div className="userSection">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="tableWrap userList mb-3">
                                        <div id="grid-responsive" className="table-responsive" style={{ overflowX: "hidden" }}>
                                            <table id="grid-striped" className="table table-striped" cellSpacing="0" width="100%">
                                                <GridHeader headers={headers} />
                                                <GridBody headers={headers} items={items} />
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ loading }) => ({
    loading: loading.users.loading
});

export default withRouter(connect(mapStateToProps)(GridContainer));