import { getPathArray } from '../utils/index';
import { appFolder } from '../configurations/app';

const virtualFolder = "/" + appFolder + "/";

export const generatePaths = (location) => {
    let paths = [];
    let urlLocation = "";
    for (let i in location) {
        let loc = location[i];
        if (parseInt(i, 10) >= 1) {
            if (parseInt(i, 10) === 1) {
                urlLocation += loc;
            }
            else {
                urlLocation += (urlLocation !== "") ? "/" + appFolder : appFolder;
                urlLocation += "/" + loc;
            }
        }
    }

    paths = getPathArray("/" + urlLocation);
    return paths;
}

export const getUrlWorkspaceId = (url) => {
    let id = '';
    let location = url.split(virtualFolder);

    if (location.length > 1) {
        let paths = generatePaths(location);
        if (paths.length > 2) {
            if (paths[1].toLowerCase() === 'workspaces') {
                id = paths[2];
            }
        }
    }

    return id;
}

export const getUrlUserWorkspaceId = (url) => {
    let id = '';
    let location = url.split(virtualFolder);

    if (location.length > 1) {
        let paths = generatePaths(location);
        if (paths.length > 3) {
            if (paths[2].toLowerCase() === 'users') {
                id = paths[3];
            }
        }
    }

    return id;
}

export const getUrlUserPasswordWorkspaceId = (url) => {
    let id = '';
    let location = url.split(virtualFolder);

    if (location.length > 1) {
        let paths = generatePaths(location);
        if (paths.length > 4) {
            if (paths[1].toLowerCase() === 'user') {
                id = paths[4];
            }
        }
    }

    return id;
}

export const getUrlApplicationId = (url) => {
    let id = '';
    let location = url.split(virtualFolder);

    if (location.length > 1) {
        let paths = generatePaths(location);
        if (paths.length > 2) {
            id = paths[2];
        }
    }

    return id;
}

export const getUrlWorkspaceApplicationId = (url) => {
    let id = '';
    let location = url.split(virtualFolder);

    if (location.length > 1) {
        let paths = generatePaths(location);
        if (paths.length > 2) {
            id = paths[4];
        }
    }

    return id;
}

export const getUrlWorkspaceApplicationFirstParameter = (url) => {
    let id = '';
    let location = url.split(virtualFolder);

    if (location.length > 1) {
        let paths = generatePaths(location);
        if (paths.length > 6) {
            id = paths[6];
        }
    }

    return id;
}

export const getUrlWorkspaceApplicationSecondParameter = (url) => {
    let id = '';
    let location = url.split(virtualFolder);

    if (location.length > 1) {
        let paths = generatePaths(location);
        if (paths.length > 7) {
            id = paths[7];
        }
    }

    return id;
}

export const getUrlWorkspaceCategoryId = (url) => {
    let id = '';
    let location = url.split(virtualFolder);

    if (location.length > 1) {
        let paths = generatePaths(location);
        if (paths.length > 4) {
            if (paths[1].toLowerCase() === 'workspaces' && paths[3].toLowerCase() === 'categories') {
                id = paths[4];
            }
        }
    }

    return id;
}

export const getUrlCategoryId = (url) => {
    let id = '';
    let location = url.split(virtualFolder);

    if (location.length > 1) {
        let paths = generatePaths(location);
        if (paths.length > 1) {
            id = paths[2];
        }
    }

    return id;
}

export const getUrlUserId = (url) => {
    let id = '';
    let location = url.split(virtualFolder);

    if (location.length > 1) {
        let paths = generatePaths(location);
        if (paths.length > 2) {
            if (paths[1].toLowerCase() === 'users') {
                id = paths[2];
            }
        }
    }

    return id;
}

export const getUrlWorkspaceMemberId = (url) => {
    let id = '';
    let location = url.split(virtualFolder);

    if (location.length > 1) {
        let paths = generatePaths(location);
        if (paths.length > 4) {
            if (paths[1].toLowerCase() === 'workspaces' && paths[3].toLowerCase() === 'members') {
                id = paths[4];
            }
        }
    }

    return id;
}

export const getUrlLoginConfirmationUserId = (url) => {
    let id = '';
    let location = url.split(virtualFolder);

    if (location.length > 1) {
        let paths = generatePaths(location);
        if (paths.length > 3) {
            if (paths[1].toLowerCase() === 'login' && paths[2].toLowerCase() === 'users' && paths[3].toLowerCase() === 'confirmation') {
                id = paths[4];
            }
        }
    }

    return id;
}