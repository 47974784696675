import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { getUrlUserWorkspaceId } from '../../../utils/url';
import PortalInfoContainer from '../../Portal/Info/PortalInfoContainer';
import UsersLoginBody from './UsersLoginBody';
import UsersInfoWorkspacesContainer from '../Info/UsersInfoWorkspacesContainer';
import UsersLoginErrorModal from '../Modal/UsersLoginErrorModal';
import { clearPortalLoginAction } from '../../../actions/clearPortalLoginAction';
import { getPortalLoginWithThunk } from '../../../api/portalLoginThunk';

class UsersLoginContainer extends Component {
  static propTypes = {
    portalLogin: PropTypes.object.isRequired,
    getPortalLogin: PropTypes.func.isRequired,
    clearPortalLogin: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    role: PropTypes.object.isRequired,
  };

  getCurrentWorkspaceId() {
    let id = getUrlUserWorkspaceId(this.props.location.pathname);
    return id;
  }

  componentDidMount() {
    const workspaceId = this.getCurrentWorkspaceId();
    this.props.getPortalLogin(workspaceId);
  }

  componentWillUnmount() {
    this.props.clearPortalLogin();
  }

  render() {
    const { role } = this.props;
    const { portalLogin, loading } = this.props;
    if (
      portalLogin.welcome_text_selected === undefined ||
      portalLogin.welcome_text_selected === ''
    )
      return (
        <div>
          {/* {loading && (
            <div className="loader-container">
              <Loader
                type="BallTriangle"
                color="#00BFFF"
                height="100"
                width="100"
              />
            </div>
          )} */}
        </div>
      );

    // return <div className="container-fluid h-100"></div>;
    return (
      <div className="container-fluid h-100">
          <div className="row h-100">
              <div className="col-lg-6 p-0 left">
                  <PortalInfoContainer />
              </div>
              {/* {(!role.is_logged_in || (role.is_logged_in && role.user_id !== '' && role.is_processing_login)) && */}
                  {/* <div className="col-lg-6 p-0 right"> */}
                      {/* <UsersLoginBody /> */}
                      {/* <UsersLoginErrorModal type="error" /> */}
                  {/* </div> */}
              {/* } */}
              {/* {role.is_logged_in && role.user_id !== '' && !role.is_processing_login && */}
                  <div className="col-lg-6 p-0 right">
                      <UsersInfoWorkspacesContainer />
                  </div>
              {/* } */}
          </div>
      </div>
  );
  }
}

const mapStateToProps = ({ role, portalLogin, loading }) => ({
  role,
  portalLogin: portalLogin.portal_login,
  loading: loading.portal_login.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getPortalLogin: (workspaceId) =>
    dispatch(getPortalLoginWithThunk(workspaceId)),
  clearPortalLogin: () => dispatch(clearPortalLoginAction([])),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersLoginContainer)
);
