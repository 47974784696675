'use strict';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import environment from 'environment';
import { createStore, applyMiddleware, compose } from 'redux';
import reducer from './reducers';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import ThemeProvider from '@wrd/components/ThemeProvider';

import 'bootstrap/dist/js/bootstrap';
import './css/fonts.css';
import './css/style.css';
import './css/pagination.css';
import './css/editor.css';
import './css/sorting.css';
import './css/portal.css';
import './css/landing.scss';
import 'flatpickr/dist/themes/dark.css';
import Main from './Main';

let composeEnhancers = compose;
if (environment.settings === 'local') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunk))
);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ThemeProvider>
      <BrowserRouter>
        <Main />
      </BrowserRouter>
    </ThemeProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
