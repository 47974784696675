const crypto = require('crypto');
const { key, algorithm } = require('../configurations/key');

export const encryptPassword = (password) => {
    var cipher = crypto.createCipher(algorithm, key);
    var encrypted = cipher.update(password, 'utf8', 'base64') + cipher.final('base64');

    return encrypted;
}

export const checkPassword = (password) => {
    if (password.length < 8) {
        return "Password must be at least 8 characters"
    }
    else if (password.search(/[a-z]/) < 0) {
        return "Password must contain at least a lowercase character"
    }
    else if (password.search(/[A-Z]/) < 0) {
        return "Password must contain at least an uppercase character"
    }
    else if (password.search(/[0-9]/) < 0) {
        return "Password must contain at least a number"
    }
    else {
        return "OK"
    }     
}