import environment from 'environment';
const config = require('../environments/' + environment.settings);

/* GET */
export const getSpatialReferenceStringApi = async (srId) => {
	const spatialUrl = "https://" + config.spatialServer + "/";
	let url = spatialUrl + srId + ".wkt";

	const requestOptions = {
		method: 'GET',
	};

	return fetch(url, requestOptions)
		.then(response => response.text())
		.catch(res => console.log(res));
}