import cookies from 'js-cookie';
const axios = require('axios');

const getCookies = JSON.parse(cookies.get('waterdss') || '{}');

const queryBaseUrlV1 = getCookies.oldBaseURL + '/v1/'
const queryBaseUrlV2 = getCookies.oldBaseURL + '/v2/'

axios.defaults.headers.common['Authorization'] = `Bearer ${getCookies.accessToken}`;
axios.defaults.headers.common['Cache-Control'] = 'no-cache, no-store';
axios.defaults.headers.common['Expires'] = '0';
axios.defaults.headers.common['Pragma'] = 'no-cache';
axios.defaults.headers.common['Content-Type'] = 'application/javascript; charset=UTF-8';

const handleCookieReset = () => {
  return new Promise((resolve) => {
    cookies.remove('waterdss', {
      domain: window.location.hostname,
      path: '/',
    });

    setTimeout(() => {
      resolve();
    }, 1000);
  });
};


export { getCookies, axios, queryBaseUrlV1, queryBaseUrlV2 , handleCookieReset};
