import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import GridItem from './GridItem';

class GridBody extends Component {
    static propTypes = {
        headers: PropTypes.array.isRequired,
        items: PropTypes.array.isRequired
    };

    render() {
        const { headers, items } = this.props;

        return (
            <tbody>
                {items.map((item) => (
                    <GridItem key={item.id} headers={headers} item={item} />
                ))}
            </tbody>
        );
    }
}

export default withRouter(GridBody);
