import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { routeCodes } from '../../../../configurations/routes';
import { updateWorkspaceAction } from '../../../../actions/updateWorkspaceAction';

class WorkspacesApplicationsHeader extends Component {
    constructor(props) {
        super(props);
        this.onWorkspaceNameChange = this.onWorkspaceNameChange.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
        dispatchWorkspace: PropTypes.func.isRequired
    };

    onWorkspaceNameChange(e) {
        let workspace = {
            ...this.props.workspace,
            workspace_name: e.target.value
        };

        this.props.dispatchWorkspace(workspace);
    }

    render() {
        const { workspace, role } = this.props;

        return (
            <div className="mainHead">
                <div className="row">
                    <div className="col-12">
                        <h2 className="float-left mt-2 mr-4">Workspace Applications</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="workspaceInfo">
                            <div className="row">
                                <div className="workspaceName col-xl-12">
                                    <div className="float-left">
                                        <h3 className="mr-4">{workspace.workspace_name}</h3>
                                    </div>
                                </div>
                                <div className="userRole col-xl-12">{(role.user_role === 'Administrator') ? "Administrator" : "Workspace Owner"}</div>
                            </div>
                            <nav className="col-lg-5 col-xl-4">
                                <div className="row text-center">
                                    <div className="col px-0">
                                        <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/properties"} title="Workspace" className="workspaceMenu py-3">
                                            Workspace
                                        </Link>
                                    </div>
                                    <div className="col px-0">
                                        <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/applications' } title="Workspace Application" className="workspaceMenu py-3 active">
                                            Applications
                                        </Link>
                                    </div>
                                    <div className="col px-0">
                                        <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/members' } title="Workspace Members" className="workspaceMenu py-3">
                                            Members
                                        </Link>
                                    </div>
                                    <div className="col px-0">
                                        <Link
                                            to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/general'}
                                            title="Workspace Settings"
                                            className="workspaceMenu py-3">Settings
                                        </Link>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role }) => ({
    workspace: detail.workspace,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchWorkspace: (workspace) => dispatch(updateWorkspaceAction(workspace))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsHeader));
