import {
    getUsersApi,
    getUsersSearchApi,
    getUserByIdApi,
    getUserAccessApi,
    getProfileAccessApi,
    createUserApi,
    updateUserApi,
    updateUserStatusApi,
    updateUserRoleApi,
    updateUserAccessApi,
    updateUserWorkspaceAccessApi,
    removeUserApi,
    removeUserWorkspaceAccessApi
} from '../backend/domain/users';

import { getUsersAction } from '../actions/getUsersAction';
import { getUserAction } from '../actions/getUserAction';
import { getUserAccessAction } from '../actions/getUserAccessAction';
import { updateUserAction } from '../actions/updateUserAction';
import { clearUsersAction } from '../actions/clearUsersAction';
import { getUsersPaginationAction } from '../actions/getUsersPaginationAction';
import { loadingUsersAction } from '../actions/loadingUsersAction';
import { loadingUserAction } from '../actions/loadingUserAction';
import { loadingCreateUserAction } from '../actions/loadingCreateUserAction';
import { handleUserCreationError, handleUserUpdateError, messageHandleUpdateError } from '../utils/errorMessages';
import { overrideUserId, updateUsers, updateUsersStatus, updateUsersRole, updateUsersRemove } from '../utils/users';

export const getUsersWithThunk = (pageIndex, pageSize, sortingBy, sortingType) =>
    (dispatch) => {
        dispatch(loadingUsersAction(true));
        getUsersApi(pageIndex, pageSize, sortingBy, sortingType)
            .then((response) => {
                if (response.pagination.total_count !== undefined)
                    dispatch(getUsersPaginationAction(response.pagination));
                return response;
            })
            .then(response => {
                updateUsers(response.users);
            })
            .then((response) => {
                dispatch(loadingUsersAction(false));
                return response;
            });
    };

export const getUsersSortingWithThunk = (pageIndex, pageSize, sortingBy, sortingType) =>
    (dispatch) => {
        dispatch(loadingUsersAction(true));
        getUsersApi(pageIndex, pageSize, sortingBy, sortingType)
            .then(async () => {
                dispatch(clearUsersAction([]));
                let users = [];
                for (let i = 1; i <= pageIndex; i++) {
                    let r = await getUsersApi(i, pageSize, sortingBy, sortingType)
                    users = users.concat(r.users);
                }
                let response = { users };
                return response;
            })
            .then(response => dispatch(getUsersAction(response.users)))
            .then((response) => {
                dispatch(loadingUsersAction(false));
                return response;
            });
    };

export const getUserByIdWithThunk = (id, withPicture, width, height) =>
    async (dispatch) => {
        let uid = await overrideUserId(id);
        dispatch(loadingUserAction(true));
        getUserByIdApi(uid, withPicture, width, height)
            .then((response) => {
                if (!response.error) {
                    dispatch(getUserAction(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingUserAction(false));
                return response;
            });
    };

export const getUserAccessWithThunk = (targetId, width, height) =>
    async (dispatch) => {
        let uid = await overrideUserId(targetId);
        dispatch(loadingUserAction(true));
        getUserAccessApi(uid, width, height)
            .then((response) => {
                if (!response.error) {
                    dispatch(getUserAccessAction(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingUserAction(false));
                return response;
            });
    };

export const getProfileAccessWithThunk = (id, width, height) =>
    (dispatch) => {
        dispatch(loadingUserAction(true));
        getProfileAccessApi(id, width, height)
            .then((response) => {
                if (!response.error) {
                    dispatch(getUserAccessAction(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingUserAction(false));
                return response;
            });
    };

export const updateProfileAccessWithThunk = (userAccess) =>
    (dispatch) => {
        dispatch(loadingUserAction(true));
        updateUserAccessApi(userAccess)
            .then(response => {
                const messages = response.status === 200 ? 'User Settings updated' : 'User Settings Update Failed'
                messageHandleUpdateError(dispatch, response.status, messages);
                dispatch(loadingUserAction(false));
            })
    };

export const updateUserAccessWithThunk = (userAccess) =>
    (dispatch) => {
        dispatch(loadingUserAction(true));
        updateUserAccessApi(userAccess)
            .then(response => {
                const messages = response.status === 200 ? 'User Settings updated' : 'User Settings Update Failed'
                messageHandleUpdateError(dispatch, response.status, messages);
                dispatch(loadingUserAction(false));
            })
    };

export const updateUserAccessWorkspaceWithThunk = (workspaceId, userId, status, width, height) =>
    (dispatch) => {
        dispatch(loadingUserAction(true));
        let dataStatus = {
            workspaceId: workspaceId,
            status: status
        };
        updateUserWorkspaceAccessApi(userId, dataStatus)
            .then(() => {
                getUserAccessApi(userId, width, height)
                    .then((response) => {
                        dispatch(getUserAccessAction(response));
                        return response;
                    })
                    .then((response) => {
                        dispatch(loadingUserAction(false));
                        return response;
                    });
            })
    };

export const removeUserAccessWorkspaceWithThunk = (workspaceId, userId, width, height) =>
    (dispatch) => {
        dispatch(loadingUserAction(true));
        let dataRemove = {
            workspaceId: workspaceId
        };
        removeUserWorkspaceAccessApi(userId, dataRemove)
            .then(() => {
                getUserAccessApi(userId, width, height)
                    .then((response) => {
                        dispatch(getUserAccessAction(response));
                        return response;
                    })
                    .then((response) => {
                        dispatch(loadingUserAction(false));
                        return response;
                    });
            })
    };

export const updateUserWithThunk = (user) =>
    (dispatch) => {
        dispatch(loadingUserAction(true));
        let data = {
            uid: String(user.uid),
            id: String(user.id),
            full_name: String(user.full_name),
            role: String(user.role),
            registration_status: String(user.registration_status),
            user_status: String(user.user_status),
            user_role: String(user.user_role),
            use_personal: user.use_personal,
            picture: String(user.picture),
            is_picture_changed: user.is_picture_changed,
            image_aspect_ratio: String(user.image_aspect_ratio),
        }

        const user_personal = {
            address_1: String(user.personal.address_1),
            address_2: String(user.personal.address_2),
            zip_code: String(user.personal.zip_code),
            country: String(user.personal.country),
            city: String(user.personal.city),
            state: String(user.personal.state),
            phone: String(user.personal.phone),
            mobile_phone: String(user.personal.mobile_phone),
            email_address: String(user.id)
            // email_address: String(user.personal.email_address)
        }

        let user_public = {
            organization: String(user.public.organization),
            att: String(user.public.att),
            address_1: String(user.public.address_1),
            address_2: String(user.public.address_2),
            zip_code: String(user.public.zip_code),
            country: String(user.public.country),
            city: String(user.public.city),
            state: String(user.public.state),
            phone: String(user.public.phone),
            mobile_phone: String(user.public.mobile_phone),
            // email_address: String(user.public.email_address)
            email_address: String(user.id)
        }

        data.personal = user_personal;
        data.public = user_public;

        updateUserApi(data)
            .then((response) => {
                dispatch(updateUserAction(user));
                return response;
            })
            .then((response) => {
                handleUserUpdateError(dispatch, response.status, response.error_messages, false);
                return response;
            })
            .then((response) => {
                dispatch(loadingUserAction(false));
                return response;
            });
    };

export const updateUserStatusWithThunk = (user) =>
    (dispatch) => {
        dispatch(loadingUserAction(true));
        updateUserStatusApi(user.uid, user.user_status)
            .then((response) => {
                dispatch(updateUserAction(user));
                return response;
            })
            .then((response) => {
                dispatch(loadingUserAction(false));
                return response;
            });
    };

export const updateUserRoleWithThunk = (user) =>
    (dispatch) => {
        dispatch(loadingUserAction(true));
        updateUserRoleApi(user.uid, user.user_role)
            .then((response) => {
                dispatch(updateUserAction(user));
                return response;
            })
            .then((response) => {
                dispatch(loadingUserAction(false));
                return response;
            });
    };

export const updateStickyUserStatusWithThunk = (user) =>
    (dispatch) => {
        dispatch(loadingUsersAction(true));
        updateUserStatusApi(user.uid, user.user_status)
            .then(async () => {
                updateUsersStatus(user.uid, user.user_status);
            })
            .then(() => {
                dispatch(loadingUsersAction(false));
            });
    };

export const updateStickyUserRoleWithThunk = (user) =>
    (dispatch) => {
        dispatch(loadingUsersAction(true));
        updateUserRoleApi(user.uid, user.user_role)
            .then(async () => {
                updateUsersRole(user.uid, user.user_role);
            })
            .then(() => {
                dispatch(loadingUsersAction(false));
            });
    };

export const removeStickyUserWithThunk = (user) =>
    (dispatch) => {
        dispatch(loadingUsersAction(true));
        removeUserApi(user.id)
            .then(async () => {
                updateUsersRemove(user.id);
            })
            .then(() => {
                dispatch(loadingUsersAction(false));
            });
    };

export const removeUserWithThunk = (user) =>
    (dispatch) => {
        dispatch(loadingUsersAction(true));
        removeUserApi(user.id)
            .then(() => {
                dispatch(loadingUsersAction(false));
            });
    };

export const createUserWithThunk = (user, url) =>
    (dispatch) => {
        dispatch(loadingCreateUserAction(true));
        let data = {
            is_admin: user.is_admin,
            user_name: String(user.user_name),
            full_name: String(user.full_name),
            company: String(user.business_organization),
            registration_status: String('Pending'),
            user_status: String('Enabled'),
            use_personal: user.private_default_contact,
            picture: String(user.url_image),
            image_aspect_ratio: String(user.image_aspect_ratio),
            personal: {
                address_1: String(user.private_address1),
                address_2: String(user.private_address2),
                zip_code: String(user.private_zipcode),
                city: String(user.private_city),
                country: String(user.private_country),
                state: String(user.private_state),
                phone: String(user.private_phone),
                mobile_phone: String(user.private_mobile),
                email_address: String(user.private_email)
            },
            public: {
                organization: String(user.business_organization),
                att: String(user.business_att),
                address_1: String(user.business_address1),
                address_2: String(user.business_address2),
                zip_code: String(user.business_zipcode),
                city: String(user.business_city),
                country: String(user.business_country),
                state: String(user.business_state),
                phone: String(user.business_phone),
                mobile_phone: String(user.business_mobile),
                email_address: String(user.business_email)
            }
        };

        createUserApi(data)
            .then((response) => {
                handleUserCreationError(dispatch, response.status, response.error_messages, url);
            })
            .then(() => {
                dispatch(loadingCreateUserAction(false));
            });
    };

export const getUsersSearchWithThunk = (pageIndex, pageSize, searchKeyword, sortingBy, sortingType) =>
    (dispatch) => {
        dispatch(loadingUsersAction(true));
        getUsersSearchApi(pageIndex, pageSize, searchKeyword, sortingBy, sortingType)
            .then((response) => {
                if (response.pagination.total_count !== undefined)
                    dispatch(getUsersPaginationAction(response.pagination));
                updateUsers(response.users);
            })
            .then((response) => {
                dispatch(loadingUsersAction(false));
                return response;
            });
    };
