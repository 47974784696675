import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../configurations/routes';
import { Element } from 'react-scroll';
import dompurify from 'dompurify';
import { getPortalLandingFeatureWithThunk } from '../../../api/portalLandingThunk';

const sanitizer = dompurify.sanitize;

class PortalLandingFeature extends Component {

    static propTypes = {
        landingFeature: PropTypes.array,
        loading: PropTypes.bool.isRequired
    };

    componentDidMount() {
        this.props.getPortalLandingFeature();
    }

    getAspectRatioClass(index) {
        const { landingFeature } = this.props;
        let filteredBanner = landingFeature[index];
        const image_aspect_ratio = filteredBanner.image_aspect_ratio;

        let aspectRatioClass = 'crop-feature43';
        if (image_aspect_ratio >= 1 && image_aspect_ratio < 1.2) {
            aspectRatioClass = 'crop-feature11';
        }
        else if (image_aspect_ratio >= 1.2 && image_aspect_ratio < 1.4) {
            aspectRatioClass = 'crop-feature43';
        }
        else if (image_aspect_ratio >= 1.4 && image_aspect_ratio < 1.6) {
            aspectRatioClass = 'crop-feature32';
        }
        else if (image_aspect_ratio >= 1.6 && image_aspect_ratio < 2.3) {
            aspectRatioClass = 'crop-feature169';
        }
        else if (image_aspect_ratio >= 2.3) {
            aspectRatioClass = 'crop-feature219';
        }

        return aspectRatioClass;
    }

    render() {
        const { landingFeature, loading } = this.props;
        return (
            <Element name="features">
                <div className="featureSection w-100 py-5">
                    {
                        loading &&
                        <div className="loader-portal-feature">
                            <Loader
                                type="BallTriangle"
                                color="#00BFFF"
                                height="70"
                                width="70"
                            />
                        </div>
                    }
                    <div className="container">
                        <div className="row">
                            {(landingFeature !== undefined && landingFeature.length > 0) ?
                                landingFeature.map((item, index) => (
                                    <div key={index} className="col-md-4 mb-5">
                                        <div className="featureItemPanel featureLandingWidth">
                                            <div><img src={((item.image !== undefined) ? "data:image/jpeg;base64," : "") + item.image} className={this.getAspectRatioClass(index)} alt="" /></div>
                                            <div className="p-3 my-2">
                                                <span className="icon-wrd-badge"></span>
                                                <h4 className="headline">Headline</h4>
                                                <p className="tag">{item.feature_title}</p>
                                                <div dangerouslySetInnerHTML={{ __html: sanitizer(item.feature_description).substr(0, 200) + "..." }} />

                                                <Link to={routeCodes.HOME + item.id + '/blog/features'} style={{ color: "#007bff" }}> Read More </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : null
                            }
                        </div>
                    </div>
                </div>
            </Element>
        );
    }
}

const mapStateToProps = ({ portalLanding, loading }) => ({
    landingFeature: portalLanding.portal_feature.features,
    loading: loading.portal_landing_feature.loading
});


const mapDispatchToProps = (dispatch) => ({
    getPortalLandingFeature: () => dispatch(getPortalLandingFeatureWithThunk()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalLandingFeature));