import { getPortalLoginApi } from '../backend/domain/portal';

import { getPortalLoginAction } from '../actions/getPortalLoginAction';
import { loadingPortalLoginAction } from '../actions/loadingPortalLoginAction';
import { overrideWorkspaceId } from '../utils/workspaces';

export const getPortalLoginWithThunk = (id) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(id);
		dispatch(loadingPortalLoginAction(true));
        getPortalLoginApi(wId)
            .then(response => {
            	if (!response.error) {
                    response.workspace_name = id;
            		dispatch(getPortalLoginAction(response));
            	}
            	return response;
            })
            .then((response) => {
            	dispatch(loadingPortalLoginAction(false));
            	return response;
            });
	};