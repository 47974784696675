import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class UsersChangePasswordHeader extends Component {    
    static propTypes = {
        account: PropTypes.object.isRequired,
    };    

    render() {
        const { account } = this.props;

        return (
            <div className="mainHead">
                <div className="row">
                    <div className="col-12">
                        <h2 className="float-left mt-2 mr-4">Change Password</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="workspaceInfo">
                            <div className="row">
                                <div className="workspaceName col-xl-7 offset-xl-3">
                                    <h3>{account.full_name}</h3>
                                </div>
                                <div className="userRole col-xl-7 offset-xl-3">{account.id}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail }) => ({
    account: detail.account
});

export default withRouter(connect(mapStateToProps)(UsersChangePasswordHeader));