exports.settings = 'staging';
exports.server = 'watertools-portal-server-staging.azurewebsites.net';
exports.domain = 'watertools-portal-staging.azurewebsites.net';
exports.port = 80;
exports.portalServer = 'watertools-portal-staging.azurewebsites.net';
exports.portalPort = 80;
exports.protocol = 'https://';
exports.useContainer = true;
exports.anonymous = '00000000-0000-0000-0000-000000000000';
exports.emptyConnectionString = '_$$_';
exports.spatialServer = 'epsg.io';
exports.protocol = 'https://';
exports.gatewayServerQuery =
  'waterdssapirouterlocal-wrd-dev.eu.mike-cloud-dev.com';
exports.gatewayPortQuery = '80';
exports.gatewayAPiVersionQuery = 'v1';
exports.gatewayAPiVersionQueryV2 = 'v2';
exports.gatewayServerCommand =
  'waterdssapirouterlocal-wrd-dev.eu.mike-cloud-dev.com';
exports.gatewayPortCommand = '80';
exports.gatewayAPiVersionCommand = 'v1';
exports.gatewayAPiVersionCommandV2 = 'v2';
exports.frontendServer = 'waterdss-wrd-dev.eu.mike-cloud-dev.com';
exports.emailConfirmationPage = 'portal/login/users/confirmation';
exports.resetPasswordLoginPage = 'portal/login/users';
exports.portalServerAppFolder = '';
