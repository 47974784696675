import { clearAccountAction } from '../actions/clearAccountAction';
import { clearUserAvailableWorkspacesAction } from '../actions/clearUserAvailableWorkspacesAction';
import { clearWorkspaceAvailableApplicationsAction } from '../actions/clearWorkspaceAvailableApplicationsAction';
import { updatePortalSettingTabAction } from '../actions/updatePortalSettingTabAction';
import { clearRoleAction } from '../actions/clearRoleAction';
import { removeSessionStorage } from '../utils/sessionStorage';
import { clearLoginAction } from '../actions/clearLoginAction';

export const userLogout = () =>
    (dispatch) => {
        dispatch(clearRoleAction([]));
        dispatch(clearLoginAction([]));
        dispatch(clearAccountAction([]));
        dispatch(clearUserAvailableWorkspacesAction([]));
        dispatch(clearWorkspaceAvailableApplicationsAction([]));
        dispatch(updatePortalSettingTabAction('Banners'));
        removeSessionStorage("role");
        removeSessionStorage("login_date");
        removeSessionStorage("allAvailableWorkspaces");        
    };