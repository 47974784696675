import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setupNewUserAction } from '../../../actions/setupNewUserAction';
import CountrySelector from '../../Editor/CountrySelector'
import { users } from '../../../reducers/users';
import { registerWorkspacesMembersWithThunk } from '../../../api/workspacesMembersThunk';
import { getUrlWorkspaceId } from '../../../utils/url';
import { overrideWorkspaceId } from '../../../utils/workspaces';
import { routeCodes } from '../../../configurations/routes';

class UserRegisterInput extends Component {
    privateContact = React.createRef();
    businessContact = React.createRef();
    privateEmail = React.createRef();
    businessEmail = React.createRef();
    selectEmail = React.createRef();
    emailAddress = React.createRef();
    userName = React.createRef();
    fullName = React.createRef();
    advancedUserName = React.createRef();
    advancedFullName = React.createRef();

    constructor(props) {
        super(props);
        this.enterSubmit = this.enterSubmit.bind(this);
        this.onToggleIsOwner = this.onToggleIsOwner.bind(this);
        this.onToggleIsAdmin = this.onToggleIsAdmin.bind(this);
        this.onUserNameChange = this.onUserNameChange.bind(this);
        this.onFullNameChange = this.onFullNameChange.bind(this);
        this.onPrivateAddress1Change = this.onPrivateAddress1Change.bind(this);
        this.onPrivateAddress2Change = this.onPrivateAddress2Change.bind(this);
        this.onPrivateZipcodeChange = this.onPrivateZipcodeChange.bind(this);
        this.onPrivateCityChange = this.onPrivateCityChange.bind(this);
        this.onPrivateCountryChange = this.onPrivateCountryChange.bind(this);
        this.onPrivateStateChange = this.onPrivateStateChange.bind(this);
        this.onPrivatePhoneChange = this.onPrivatePhoneChange.bind(this);
        this.onPrivateMobileChange = this.onPrivateMobileChange.bind(this);
        this.onPrivateEmailChange = this.onPrivateEmailChange.bind(this);
        this.onBusinessOrganizationChange = this.onBusinessOrganizationChange.bind(this);
        this.onBusinessAttChange = this.onBusinessAttChange.bind(this);
        this.onBusinessAddress1Change = this.onBusinessAddress1Change.bind(this);
        this.onBusinessAddress2Change = this.onBusinessAddress2Change.bind(this);
        this.onBusinessZipcodeChange = this.onBusinessZipcodeChange.bind(this);
        this.onBusinessCityChange = this.onBusinessCityChange.bind(this);
        this.onBusinessCountryChange = this.onBusinessCountryChange.bind(this);
        this.onBusinessStateChange = this.onBusinessStateChange.bind(this);
        this.onBusinessPhoneChange = this.onBusinessPhoneChange.bind(this);
        this.onBusinessMobileChange = this.onBusinessMobileChange.bind(this);
        this.onBusinessEmailChange = this.onBusinessEmailChange.bind(this);
        this.state = {
            showAdvanced: false,
            emailAddress: "",
            isBusinessEmail: false,
            isPrivateContactChecked: props.user.private_default_contact
        };
        this.handlePrivateContactChange = this.handlePrivateContactChange.bind(this);
        this.handleBusinessContactChange = this.handleBusinessContactChange.bind(this);
        this.onEmailSelectChange = this.onEmailSelectChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onToggleButton = this.onToggleButton.bind(this);
    }

    static propTypes = {
        user: PropTypes.object.isRequired,
        routeBack: PropTypes.object.isRequired,
        setupNewUser: PropTypes.func.isRequired,
        scrollTop: PropTypes.func.isRequired,
        workspace: PropTypes.object.isRequired
    };

    componentWillMount() {
        this.id = `toggle_${Math.random().toString().replace(/0\./, '')}`;
        this.id2 = `toggle_${Math.random().toString().replace(/0\./, '')}`;
    }

    async onToggleButton() {
        const status = !this.state.showAdvanced;
        await this.setState({ showAdvanced: status })

        if (status) {
            this.advancedUserName.current.value = this.props.user.user_name;
            this.advancedFullName.current.value = this.props.user.full_name;
            this.privateContact.current.checked = this.props.user.private_default_contact;
            this.businessContact.current.checked = !this.props.user.private_default_contact;
            if (this.props.user.private_default_contact) {
                this.privateEmail.current.value = this.props.user.private_email;
                this.privateContact.current.checked = true;
                this.businessEmail.current.value = this.props.user.business_email;
                this.businessContact.current.checked = false;
            }
            else {
                this.privateEmail.current.value = this.props.user.private_email;
                this.privateContact.current.checked = false;
                this.businessEmail.current.value = this.props.user.business_email;
                this.businessContact.current.checked = true;
            }
        }
        else {
            this.userName.current.value = this.props.user.user_name;
            this.fullName.current.value = this.props.user.full_name;
            this.selectEmail.current.value = (this.props.user.private_default_contact) ? "PE" : "BE";
            this.emailAddress.current.value = (this.props.user.private_default_contact) ? this.props.user.private_email : this.props.user.business_email;
        }

        this.scrollToTop();
    }

    scrollToTop() {
        this.props.scrollTop();
    }

    onToggleIsOwner(e) {
        let user = {
            ...this.props.user,
            is_owner: e.target.checked
        };

        this.props.setupNewUser(user);
    }

    onToggleIsAdmin(e) {
        let user = {
            ...this.props.user,
            is_admin: e.target.checked
        };

        this.props.setupNewUser(user);
    }

    onEmailSelectChange(e) {
        let isBusinessEmail = (e.target.value === "BE") ? true : false;
        this.setState({ isBusinessEmail, isPrivateContactChecked: !isBusinessEmail })
        let user = isBusinessEmail ?
            {
                ...this.props.user,
                private_email: "",
                business_email: this.emailAddress.current.value,
                private_default_contact: false
            } :
            {
                ...this.props.user,
                private_email: this.emailAddress.current.value,
                business_email: "",
                private_default_contact: true
            }
        this.props.setupNewUser(user);
    }

    onEmailChange(e) {
        this.setState({ emailAddress: e.target.value });
        let user = this.state.isBusinessEmail ?
            {
                ...this.props.user,
                business_email: e.target.value,
                private_default_contact: false
            } :
            {
                ...this.props.user,
                private_email: e.target.value,
                private_default_contact: true
            }
        this.props.setupNewUser(user);
    }

    handlePrivateContactChange() {
        let user = {
            ...this.props.user,
            private_default_contact: true
        };
        this.props.setupNewUser(user);
        this.setState({ isPrivateContactChecked: true });
    }

    onPrivateEmailChange(e) {
        let user = {
            ...this.props.user,
            private_email: e.target.value
        };
        this.props.setupNewUser(user);
    }

    handleBusinessContactChange() {
        let user = {
            ...this.props.user,
            private_default_contact: false
        };
        this.props.setupNewUser(user);
        this.setState({ isPrivateContactChecked: false });
    }

    onBusinessEmailChange(e) {
        let user = {
            ...this.props.user,
            business_email: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onUserNameChange(e) {
        let user = {
            ...this.props.user,
            user_name: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onFullNameChange(e) {
        let user = {
            ...this.props.user,
            full_name: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onPrivateAddress1Change(e) {
        let user = {
            ...this.props.user,
            private_address1: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onPrivateAddress2Change(e) {
        let user = {
            ...this.props.user,
            private_address2: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onPrivateZipcodeChange(e) {
        let user = {
            ...this.props.user,
            private_zipcode: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onPrivateCountryChange(e) {
        let user = {
            ...this.props.user,
            private_country: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onPrivateCityChange(e) {
        let user = {
            ...this.props.user,
            private_city: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onPrivateStateChange(e) {
        let user = {
            ...this.props.user,
            private_state: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onPrivatePhoneChange(e) {
        let user = {
            ...this.props.user,
            private_phone: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onPrivateMobileChange(e) {
        let user = {
            ...this.props.user,
            private_mobile: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onBusinessOrganizationChange(e) {
        let user = {
            ...this.props.user,
            business_organization: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onBusinessAttChange(e) {
        let user = {
            ...this.props.user,
            business_att: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onBusinessAddress1Change(e) {
        let user = {
            ...this.props.user,
            business_address1: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onBusinessAddress2Change(e) {
        let user = {
            ...this.props.user,
            business_address2: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onBusinessZipcodeChange(e) {
        let user = {
            ...this.props.user,
            business_zipcode: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onBusinessCountryChange(e) {
        let user = {
            ...this.props.user,
            business_country: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onBusinessCityChange(e) {
        let user = {
            ...this.props.user,
            business_city: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onBusinessStateChange(e) {
        let user = {
            ...this.props.user,
            business_state: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onBusinessPhoneChange(e) {
        let user = {
            ...this.props.user,
            business_phone: e.target.value
        };
        this.props.setupNewUser(user);
    }

    onBusinessMobileChange(e) {
        let user = {
            ...this.props.user,
            business_mobile: e.target.value
        };
        this.props.setupNewUser(user);
    }

    async enterSubmit(e) {
        if (e.key === 'Enter') {
            const { user } = this.props;

            this.props.scrollTop();
            let id = getUrlWorkspaceId(this.props.location.pathname);
            if (id !== "") {
                let wId = await overrideWorkspaceId(id);
                this.props.registerWorkspaceMember(wId, user, routeCodes.WORKSPACES_LIST + "/" + id);
            }
        }
    }

    render() {
        const { showAdvanced } = this.state;
        return (
            <div className="col-lg-6 col-xl-7 mb-5 pt-5" onKeyPress={this.enterSubmit}>
                {!showAdvanced &&
                    <div>
                        {/* <div className="inputForm">
                            <input ref={this.emailAddress} className="form-control" type="text" name="" onChange={this.onEmailChange} required />
                            <label>Email</label>
                        </div> */}
                        <div className="inputForm">
                            <input ref={this.fullName} className="form-control" type="text" name="" onChange={this.onFullNameChange} required />
                            <label>Full Name</label>
                        </div>
                        <div className="row">
                            <div className="col-md-3">
                                <div className="inputForm">
                                    <select ref={this.selectEmail} onChange={this.onEmailSelectChange}>
                                        <option value="PE">Private Email</option>
                                        <option value="BE">Business Email</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <div className="inputForm">
                                    <input ref={this.emailAddress} className="form-control" type="text" name="" onChange={this.onEmailChange} required />
                                    <label>Email</label>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {showAdvanced &&
                    <div>
                        <div className="inputForm">
                            <input ref={this.advancedUserName} className="form-control" type="text" name="" onChange={this.onUserNameChange} required />
                            <label>User Name</label>
                        </div>
                        <div className="inputForm">
                            <input ref={this.advancedFullName} className="form-control" type="text" name="" onChange={this.onFullNameChange} required />
                            <label>Full Name</label>
                        </div>
                        <p className="subLabel px-3 py-4 mt-5">Private Address</p>
                        <div className="inputForm">
                            <input className="form-control" type="text" name="" onChange={this.onPrivateAddress1Change} required />
                            <label>Address 1</label>
                        </div>
                        <div className="inputForm">
                            <input className="form-control" type="text" name="" onChange={this.onPrivateAddress2Change} required />
                            <label>Address 2</label>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onPrivateZipcodeChange} required />
                                    <label>Zip Code</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <CountrySelector value={users.private_country} onChange={this.onPrivateCountryChange} />
                            </div>
                            <div className="col-md-6">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onPrivateCityChange} required />
                                    <label>City</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onPrivateStateChange} required />
                                    <label>State</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onPrivatePhoneChange} required />
                                    <label>Phone</label>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onPrivateMobileChange} required />
                                    <label>Mobile</label>
                                </div>
                            </div>
                            <div className="col-lg-6 pb-5">
                                <div className="inputForm mb-3">
                                    <input ref={this.privateEmail} className="form-control" type="text" name="" onChange={this.onPrivateEmailChange} required />
                                    <label>Email</label>
                                </div>
                                <div className="custom-control custom-checkbox">
                                    <input ref={this.privateContact} type="radio" className="custom-control-input" name="defaultContact"
                                        onChange={this.handlePrivateContactChange} id={this.id} checked={this.state.isPrivateContactChecked} />
                                    <label className="custom-control-label pl-2" htmlFor={this.id}>Use private email and phone as default contact</label>
                                </div>
                            </div>
                        </div>
                        <p className="subLabel px-3 py-4 mt-5">Business Address</p>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onBusinessOrganizationChange} required />
                                    <label>Organization</label>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onBusinessAttChange} required />
                                    <label>Att</label>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onBusinessAddress1Change} required />
                                    <label>Address 1</label>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onBusinessAddress2Change} required />
                                    <label>Address 2</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onBusinessZipcodeChange} required />
                                    <label>Zip Code</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <CountrySelector value={users.business_country} onChange={this.onBusinessCountryChange} />
                            </div>
                            <div className="col-md-6">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onBusinessCityChange} required />
                                    <label>City</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onBusinessStateChange} required />
                                    <label>State</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onBusinessPhoneChange} required />
                                    <label>Phone</label>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onBusinessMobileChange} required />
                                    <label>Mobile</label>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="inputForm mb-3">
                                    <input ref={this.businessEmail} className="form-control" type="text" name="" onChange={this.onBusinessEmailChange} required />
                                    <label>Email</label>
                                </div>
                                <div className="custom-control custom-checkbox">
                                    <input ref={this.businessContact} type="radio" className="custom-control-input" name="defaultContact"
                                        onChange={this.handleBusinessContactChange} id={this.id2} checked={!this.state.isPrivateContactChecked} />
                                    <label className="custom-control-label pl-2" htmlFor={this.id2}>Use business email and phone as default contact</label>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="row mt-5">
                    <div className="col-12 text-center">
                        <button onClick={() => { this.onToggleButton() }} className="advanceBtn">
                            <b>Advanced</b>
                            <span className={(showAdvanced) ? "icon-wrd-sorting-up" : "icon-wrd-sorting-down"}></span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ setup, routeBack, detail }) => ({
    user: setup.user,
    routeBack: routeBack.create_user,
    workspace: detail.workspace
});

const mapDispatchToProps = (dispatch) => ({
    setupNewUser: (item) => dispatch(setupNewUserAction(item)),
    registerWorkspaceMember: (id, member, url) => dispatch(registerWorkspacesMembersWithThunk(id, member, url))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserRegisterInput));
