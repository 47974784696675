import { GET_WORKSPACES_MEMBERS_SHARE, UPDATE_WORKSPACES_MEMBERS_SHARE, CLEAR_WORKSPACES_MEMBERS_SHARE } from '../actions/index';

const getWorkspacesMembersShare = (workspacesMembersShare = [], action) => {
    let itemsKeys = [];    
    let membersKeys = action.members.reduce((keys, member) => {
        itemsKeys.push(member.id);
        keys = itemsKeys;
        return keys;
    }, []);

    let result = workspacesMembersShare.filter((member) => {
        return membersKeys.indexOf(member.id) === -1;
    });

    return result.concat(action.members);
}

const updateWorkspacesMembersShare = (workspacesMembersShare = [], action) => {
    let itemsKeys = [];
    let membersKeys = action.members.reduce((keys, member) => {
        itemsKeys.push(member.id);
        keys = itemsKeys;
        return keys;
    }, []);

    let result = workspacesMembersShare.filter((member) => {
        return membersKeys.indexOf(member.id) === -1;
    });

    return result.concat(action.members);
}

const clearWorkspacesMembersShare = (action) => {
    return action.members;
}

export const workspacesMembersShare = (workspacesMembersShare = [], action) => {
    switch (action.type) {
        case GET_WORKSPACES_MEMBERS_SHARE: return getWorkspacesMembersShare(workspacesMembersShare, action);
        case UPDATE_WORKSPACES_MEMBERS_SHARE: return updateWorkspacesMembersShare(workspacesMembersShare, action);
        case CLEAR_WORKSPACES_MEMBERS_SHARE: return clearWorkspacesMembersShare(action);
        default: return workspacesMembersShare;
    }
};
