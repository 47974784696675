import { SETUP_NEW_WORKSPACE } from '../actions/index';
import { CLEAR_SETUP_NEW_WORKSPACE } from '../actions/index';
import { SETUP_NEW_APPLICATION } from '../actions/index';
import { CLEAR_SETUP_NEW_APPLICATION } from '../actions/index';
import { SETUP_NEW_APPLICATIONS_SETTINGS } from '../actions/index';
import { SETUP_NEW_USER } from '../actions/index';
import { CLEAR_SETUP_NEW_USER } from '../actions/index';
import { SETUP_NEW_WORKSPACE_FOCUS_AREAS } from '../actions/index';
import { CLEAR_SETUP_NEW_WORKSPACE_CATEGORY } from '../actions/index';
import { SETUP_NEW_WORKSPACE_CATEGORY } from '../actions/index';
import { CLEAR_SETUP_NEW_CATEGORY } from '../actions/index';
import { SETUP_NEW_CATEGORY } from '../actions/index';
import { getComponentTypes } from '../utils/componentTypes';

const initialSetupState = {
    workspace: {
        id: '',
        url_image: '',
        default_url_image: '',
        image_aspect_ratio: '',
        workspace_name: '',
        workspace_title: '',
        workspace_desc: '',
        domain_url: '',
        video_url: '',
        workspace_url: '',
        created_date: '',
        due_date: '',
        username: '',
        status: '',
        allow_public_access: false,
        allow_users_to_register: false,
        all_focus_area_access: true,
        all_application_access: true
    },
    application: {
        id: '',
        url_image: '',
        default_url_image: '',
        image_aspect_ratio: '',
        application_name: '',
        application_title: '',
        application_desc: '',
        url: '',
        allow_public_access: false,
        snapshot_type: 0,
        snapshot_url_image: '',
        snapshot_image_aspect_ratio: 0,
        keywords: [],
        created_date: '',
        status: '',
        is_new: false,
        is_deleted: false
    },
    applications_settings: {
        application_id: '',
        group_settings: '',
        title: '',
        component_type: '',
        component_types: getComponentTypes(),
        value: ''
    },
    user: {
        is_owner: false,
        is_admin: false,
        user_name: '',
        full_name: '',
        private_address1: '',
        private_address2: '',
        private_zipcode: '',
        private_city:'',
        private_country: '',
        private_state: '',
        private_phone: '',
        private_mobile: '',
        private_email: '',
        private_default_contact: true,
        business_organization: '',
        business_att: '',
        business_address1: '',
        business_address2: '',
        business_zipcode: '',
        business_city:'',
        business_country: '',
        business_state: '',
        business_phone: '',
        business_mobile: '',
        business_email: ''
    },
    workspace_focus_area: {
        area_name:''
    },
    workspace_category: {
        category_id: '',
        category_title: '',
        category_description: '',
        mapUrl: '',
        imageId: '',
        iconId: '',
    },
    category: {
        category_id: '',
        category_title: '',
        category_description: ''
    }
}

export const setup = (state = initialSetupState, action) => {
    switch (action.type) {
        case SETUP_NEW_WORKSPACE:
            const { workspace } = action
            return {
                ...state,
                workspace
            }
        case CLEAR_SETUP_NEW_WORKSPACE:
            return {
                ...state,
                workspace: initialSetupState.workspace
            }
        case SETUP_NEW_APPLICATION:
            const { application } = action
            return {
                ...state,
                application
            }
        case CLEAR_SETUP_NEW_APPLICATION:
            return {
                ...state,
                application: initialSetupState.application
            }
        case SETUP_NEW_APPLICATIONS_SETTINGS:
            const { applications_settings } = action
            return {
                ...state,
                applications_settings
            }
        case SETUP_NEW_USER:
            const { user } = action
            return {
                ...state,
                user
            }
        case CLEAR_SETUP_NEW_USER:
            return {
                ...state,
                user: initialSetupState.user
            }
        case SETUP_NEW_WORKSPACE_FOCUS_AREAS:
            const { workspace_focus_area } = action
            return {
                ...state,
                workspace_focus_area
            }
        case SETUP_NEW_WORKSPACE_CATEGORY:
            const { workspace_category } = action
            return {
                ...state,
                workspace_category
            }
        case CLEAR_SETUP_NEW_WORKSPACE_CATEGORY:
            return {
                ...state,
                workspace_category: initialSetupState.workspace_category
            }

        case SETUP_NEW_CATEGORY:
            const { category } = action
            return {
                ...state,
                category
            }
        case CLEAR_SETUP_NEW_CATEGORY:
            return {
                ...state,
                category: initialSetupState.category
            }
        default:
            return state;
    }
};
