import { GET_CLIENT_IP } from '../actions/index';

const initialDetailState = {
    ipAddress: ''
}

export const clientIp = (state = initialDetailState, action) => {
    const { ipAddress } = action
    switch (action.type) {
        case GET_CLIENT_IP:
            return {
                ...state,
                ipAddress
            }
        default:
            return state;
    }
};