import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RichTextEditor from '../../Editor/RichTextEditor';
import { setupNewCategoryAction } from '../../../actions/setupNewCategoryAction';
import { createCategoryWithThunk } from '../../../api/categoriesThunk';
import { routeCodes } from '../../../configurations/routes';

class CategoriesCreateBody extends Component {
    constructor(props) {
        super(props);
        this.onTitleChange = this.onTitleChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.openApplicationList = this.openApplicationList.bind(this);
        this.createNewCategory = this.createNewCategory.bind(this);
    }

    static propTypes = {
        category: PropTypes.object.isRequired
    };

    onTitleChange(e) {
        let newCategory = {
            ...this.props.category,
            category_title: e.target.value,
        };

        this.props.setupNewCategory(newCategory);
    }

    onDescriptionChange(e) {
        let newCategory = {
            ...this.props.category,
            category_description: e.target.value
        };
        this.props.setupNewCategory(newCategory);
    }

    openApplicationList() {
        this.props.history.push(routeCodes.APPLICATIONS_LIST);
    }

    createNewCategory() {
        const { category } = this.props;
        this.props.createCategory(category);
    }

    render() {
        return (
            <>
                <div className="mainHead">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="float-left mt-2 mr-4">New Category</h2>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="mainBody">
                    <div className="formWrap">
                        <div className="row">
                            <div className="col-7">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onTitleChange} required />
                                    <label>Title</label>
                                </div>
                                {/* <label>Description</label>
                                <RichTextEditor onChange={this.onDescriptionChange} required /> */}
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onDescriptionChange} required />
                                    <label>Description</label>
                                </div>
                                <div className="col-md-12">
                                    <div className="inputForm buttonGroup mt-2 text-center">
                                        <button className="btn greyBtn icoTextBtn mx-2" onClick={this.openApplicationList}><span className="icon-wrd-close"></span>Cancel</button>
                                        <button className="btn greenBtn icoTextBtn mx-2" onClick={this.createNewCategory}><span className="icon-wrd-add"></span> Create Category</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = ({ setup }) => ({
    category: setup.category
});

const mapDispatchToProps = (dispatch) => ({
    setupNewCategory: (newCategory) => dispatch(setupNewCategoryAction(newCategory)),
    createCategory: (category) => dispatch(createCategoryWithThunk(category))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoriesCreateBody));
