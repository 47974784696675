import {
    getApplicationPropertiesByIdApi,
    createApplicationApi,
    updateApplicationStatusApi,
    updateApplicationPropertiesApi,
    updateApplicationCategoryApi,
    deleteApplicationApi,
    getApplicationSearchListApi,
    getApplicationListApi
} from '../backend/domain/applications';

import {
    getCategoryListApi
} from '../backend/domain/categories';

import { clearApplicationsAction } from '../actions/clearApplicationsAction';
import { loadingApplicationsAction } from '../actions/loadingApplicationsAction';
import { getApplicationAction } from '../actions/getApplicationAction';
import { updateApplicationAction } from '../actions/updateApplicationAction';
import { loadingApplicationAction } from '../actions/loadingApplicationAction';
import { getApplicationsPaginationAction } from '../actions/getApplicationsPaginationAction';
import { loadingCreateApplicationAction } from '../actions/loadingCreateApplicationAction';
import { getCategoriesAction } from '../actions/getCategoriesAction';
import { routeCodes } from '../configurations/routes';
import { handleApplicationCreationError, handleApplicationUpdateError } from '../utils/errorMessages';
import { overrideApplcationId, getApplicationNameById, updateApplications, getApplicationSearchText } from '../utils/applications';
import { mergeApplicationsCategories } from '../utils/categories';

export const getApplicationsSearchWithThunk = (keyword, pageIndex, pageSize, sortingBy, sortingType) =>
    (dispatch) => {
        dispatch(loadingApplicationsAction(true));
        getApplicationSearchListApi(keyword, pageIndex, pageSize, sortingBy, sortingType)
            .then((response) => {
                dispatch(getApplicationsPaginationAction(response.pagination));
                updateApplications(response.applications);
            })
            .then((response) => {
                dispatch(loadingApplicationsAction(false));
                return response;
            });
    };

export const getApplicationsWithThunk = (pageIndex, pageSize, sortingBy, sortingType) =>
    (dispatch) => {
        dispatch(loadingApplicationsAction(true));
        getApplicationListApi(pageIndex, pageSize, sortingBy, sortingType)
            .then((response) => {
                dispatch(getApplicationsPaginationAction(response.pagination));
                return response;
            })
            .then(async (response) => {
                let categories = await getCategoryListApi();
                let applications = updateApplications(response.applications);

                let mergeCategories = mergeApplicationsCategories(categories, applications);
                dispatch(getCategoriesAction(mergeCategories));
            })
            .then((response) => {
                dispatch(loadingApplicationsAction(false));
                return response;
            });
    };

export const getApplicationByIdWithThunk = (id) =>
    async (dispatch) => {
        let appId = await overrideApplcationId(id);
        dispatch(loadingApplicationAction(true));
        getApplicationPropertiesByIdApi(appId)
            .then((response) => {
                if (!response.error) {
                    dispatch(getApplicationAction(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingApplicationAction(false));
                return response;
            });
    };

export const updateApplicationWithThunk = (application, bypassUrl, historyProps) =>
    (dispatch) => {
        dispatch(loadingApplicationAction(true));
        let originalApplicationName = application.application_name;
        application.application_name = (application.temp_application_name !== undefined) ? application.temp_application_name : originalApplicationName;
        updateApplicationPropertiesApi(application, bypassUrl)
            .then(async (response) => {
                if (response.status === 200) {
                    application.is_logo_changed = false;
                    application.is_snapshot_changed = false;
                    dispatch(updateApplicationAction(application));
                    try {
                      let id = response.id.split(':')[1];
                      let a = await getApplicationNameById(id);
                      historyProps.push(routeCodes.APPLICATION_LIST + "/" + a.application.name);
                    } catch (error) {
                      // Handle the error gracefully or log it for debugging purposes.
                      console.error("Error occurred while processing the response:", error);
                    }

                }
                else {
                    application.application_name = originalApplicationName;
                }
                return response;
            })
            .then((response) => {
                handleApplicationUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                dispatch(loadingApplicationAction(false));
                return response;
            });
    };

export const updateApplicationStatusWithThunk = (application, sortingBy, sortingType) =>
    (dispatch) => {
        dispatch(loadingApplicationsAction(true));
        updateApplicationStatusApi(application.id, application.status)
            .then(async () => {
                dispatch(clearApplicationsAction([]));

                let response;
                let searchText = getApplicationSearchText();
                if (searchText !== "") {
                    response = await getApplicationSearchListApi(searchText, 1, 10000, sortingBy, sortingType)
                }
                else {
                    response = await getApplicationListApi(1, 10000, sortingBy, sortingType);
                }
                dispatch(getApplicationsPaginationAction(response.pagination));
                let categories = await getCategoryListApi();
                let applications = updateApplications(response.applications);
                let mergeCategories = mergeApplicationsCategories(categories, applications);
                dispatch(getCategoriesAction(mergeCategories));
            })
            .then((response) => {
                dispatch(loadingApplicationsAction(false));
                return response;
            });
    };

export const deleteApplicationWithThunk = (applicationId, sortingBy, sortingType, routeBackTo, historyProps) =>
    (dispatch) => {
        if (routeBackTo !== "") {
            dispatch(loadingApplicationsAction(true));
            deleteApplicationApi(applicationId)
                .then(() => {
                    dispatch(loadingApplicationsAction(false));
                    historyProps.push(routeBackTo);
                });
        }
        else {
            dispatch(loadingApplicationsAction(true));
            deleteApplicationApi(applicationId)
                .then(async () => {
                    dispatch(clearApplicationsAction([]));

                    let response;
                    let searchText = getApplicationSearchText();
                    if (searchText !== "") {
                        response = await getApplicationSearchListApi(searchText, 1, 10000, sortingBy, sortingType)
                    }
                    else {
                        response = await getApplicationListApi(1, 10000, sortingBy, sortingType);
                    }
                    dispatch(getApplicationsPaginationAction(response.pagination));
                    let categories = await getCategoryListApi();
                    let applications = updateApplications(response.applications);
                    let mergeCategories = mergeApplicationsCategories(categories, applications);
                    dispatch(getCategoriesAction(mergeCategories));
                })
                .then((response) => {
                    dispatch(loadingApplicationsAction(false));
                    return response;
                });
        }
    };

export const updateApplicationDetailStatusWithThunk = (application) =>
    (dispatch) => {
        dispatch(loadingApplicationAction(true));
        updateApplicationStatusApi(application.id, application.status)
            .then((response) => {
                dispatch(updateApplicationAction(application));
                return response;
            })
            .then((response) => {
                dispatch(loadingApplicationAction(false));
                return response;
            });
    };

export const updateApplicationCategoryWithThunk = (applicationId, categoryId, pageIndex, pageSize, sortingBy, sortingType) =>
    (dispatch) => {
        dispatch(loadingApplicationsAction(true));
        updateApplicationCategoryApi(applicationId, categoryId)
            .then(() => {
                getApplicationListApi(pageIndex, pageSize, sortingBy, sortingType)
                    .then(async response => {
                        let categories = await getCategoryListApi();
                        let mergeCategories = mergeApplicationsCategories(categories, response.applications);
                        dispatch(getCategoriesAction(mergeCategories));
                    })
                    .then((response) => {
                        dispatch(loadingApplicationsAction(false));
                        return response;
                    });
            });
    };

export const createApplicationWithThunk = (application, bypassUrl) =>
    (dispatch) => {
        dispatch(loadingCreateApplicationAction(true));
        createApplicationApi(application, bypassUrl)
            .then(async(response) => {
                handleApplicationCreationError(dispatch, response.status, response.error_messages, routeCodes.APPLICATION_LIST);
            })
            .then(() => {
                dispatch(loadingCreateApplicationAction(false));
            });
    };
