import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogWorkspacesAction } from '../../../actions/updateDialogWorkspacesAction';
import { updateStickyWorkspaceStatusWithThunk, updateStickyWorkspaceActiveWithThunk, updateWorkspaceActiveWithThunk, deleteStickyWorkspaceWithThunk } from '../../../api/workspacesThunk';
import { updateWorkspaceStatusWithThunk } from '../../../api/workspacesThunk';

class WorkspacesModal extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        dialog: PropTypes.object.isRequired,
        workspace: PropTypes.object.isRequired,
        sorting: PropTypes.object.isRequired,
        dispatchDialogWorkspaces: PropTypes.func.isRequired,
        updateWorkspaceStatus: PropTypes.func.isRequired,
        updateStickyWorkspaceActive: PropTypes.func.isRequired,
        updateWorkspaceDetailStatus: PropTypes.func.isRequired,
        updateWorkspaceActive: PropTypes.func.isRequired,
        pagination: PropTypes.func
    };

    setDefaultDialogState() {
        let workspaces = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            route_back_to: '',
            show_dialog: false
        };

        this.props.dispatchDialogWorkspaces(workspaces);
    }

    updateWorkspace() {
        const { dialog, type } = this.props;

        this.setDefaultDialogState();

        if (dialog.dialog_type !== 'trash') {
            if (type === 'list') { 
                let workspace = {};
                if (dialog.status === 'Enabled') {
                    workspace = {
                        id: dialog.id,
                        enabled: false
                    }

                    this.props.updateWorkspaceStatus(workspace);
                }
                else if (dialog.status === 'Active') {
                    workspace = {
                        id: dialog.id,
                        status: 'Suspend'
                    }
                    
                    this.props.updateStickyWorkspaceActive(workspace);
                }
            }
            else {
                let workspace = {};
                if (dialog.status === 'Enabled') {
                    workspace = {
                        ...this.props.workspace,
                        enabled: false
                    }

                    this.props.updateWorkspaceDetailStatus(workspace);
                }
                else if (dialog.status === 'Active') {
                    workspace = {
                        ...this.props.workspace,
                        status: 'Suspend'
                    }
                    this.props.updateWorkspaceActive(workspace);
                }                
            }
        }
        else {
            if (dialog.route_back_to !== "") {
                this.props.deleteStickyWorkspace(dialog.id, dialog.route_back_to, this.props.history);
            }
            else {
                this.props.deleteStickyWorkspace(dialog.id, "", null);
            }
        }
    }

    closeModal() {
        this.setDefaultDialogState();
    }

    render() {
        const { dialog } = this.props;
        const dialogCommand = (dialog.dialog_type === 'trash') ? 'REMOVE' : (dialog.status === 'Enabled') ? 'DISABLE' : (dialog.status === 'Active') ? 'SUSPEND' : '';

        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{dialog.dialog_title}</h2>
                            <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody">
                        <div className="p-4">
                            <div className="summernote">
                                <p>
                                    Are you sure you want to <b className={(dialog.dialog_type === 'trash') ? 'actionRemove' : ''}>{dialogCommand}</b> workspace
                                    &nbsp;<b className="appName">{dialog.name}</b>&nbsp;?
                                    <br />
                                    {(dialog.dialog_type === 'trash') ? 'All data related to this Workspace including global and user preference will no longer be available.' : ''}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-2">
                            <div className="row">
                                <div className="col">
                                    <button className="btn greyBtn icoTextBtn closePopup" onClick={() => { this.closeModal(); }}>
                                        <span className="icon-wrd-close"></span>
                                        Cancel
                                    </button>
                                </div>
                                <div className="col text-right">
                                    <button className={((dialog.dialog_type === 'trash') ? 'redBtn' : (dialog.status === 'Enabled' || dialog.status === 'Active') ? 'redBtn' : 'greenBtn') + ' btn icoTextBtn mr-3 closePopup'}
                                        onClick={() => { this.updateWorkspace(); }}
                                    >
                                        <span className={(dialog.dialog_type === 'trash') ? "icon-wrd-trash" : ((dialog.status === 'Enabled') ? "icon-wrd-lock" : "icon-wrd-unlock")}></span>
                                        {(dialog.dialog_type === 'trash') ? 'Remove' : (dialog.status === 'Enabled') ? 'Disable' : (dialog.status === 'Active') ? 'Suspend' : ''}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ dialog, detail, search, sorting }) => ({
    dialog: dialog.workspaces,
    workspace: detail.workspace,
    search: search.workspaces,
    sorting: sorting.workspace
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogWorkspaces: (dialog) => dispatch(updateDialogWorkspacesAction(dialog)),
    updateWorkspaceStatus: (workspace) => dispatch(updateStickyWorkspaceStatusWithThunk(workspace)),
    updateStickyWorkspaceActive: (workspace) => dispatch(updateStickyWorkspaceActiveWithThunk(workspace)),
    updateWorkspaceDetailStatus: (workspace) => dispatch(updateWorkspaceStatusWithThunk(workspace)),
    updateWorkspaceActive: (workspace) => dispatch(updateWorkspaceActiveWithThunk(workspace)),
    deleteStickyWorkspace: (workspaceId, routeBackTo, historyProps) => dispatch(deleteStickyWorkspaceWithThunk(workspaceId, routeBackTo, historyProps)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesModal));
