'use strict';
import React, { Component, useEffect, useLayoutEffect } from 'react';
import {
  Route,
  withRouter,
  useLocation,
  RouteComponentProps,
  Redirect,
} from 'react-router-dom';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { routeCodes } from './configurations/routes';
import {
  checkBeforeAccess,
  redirectToPage,
} from './utils/pageRedirectionRules';
import { isCustomDomainPage } from './utils/login';
import { getWorkspaceNameByCustomDomainApi } from './backend/domain/workspaces';

import PortalLandingContainer from './components/Portal/Landing/PortalLandingContainer';
import PortalSettingsContainer from './components/Portal/Settings/PortalSettingsContainer';
import WorkspacesListContainer from './components/Workspaces/List/WorkspacesListContainer';
import WorkspacesCreateContainer from './components/Workspaces/Create/WorkspacesCreateContainer';
import WorkspacesDetailContainer from './components/Workspaces/Detail/WorkspacesDetailContainer';
import WorkspacesLandingContainer from './components/Workspaces/Landing/WorkspacesLandingContainer';
import ApplicationsListContainer from './components/Applications/List/ApplicationsListContainer';
import ApplicationsDetailContainer from './components/Applications/Detail/ApplicationsDetailContainer';
import WorkspacesApplicationsContainer from './components/Workspaces/Applications/List/WorkspacesApplicationsContainer';
import WorkspacesMembersContainer from './components/Workspaces/Members/List/WorkspacesMembersContainer';
import WorkspacesMembersShareContainer from './components/Workspaces/Members/Share/WorkspacesMembersShareContainer';
import WorkspacesGeneralSettingsContainer from './components/Workspaces/Settings/General/WorkspacesGeneralSettingsContainer';
import WorkspacesLoginSettingsContainer from './components/Workspaces/Settings/Login/WorkspacesLoginSettingsContainer';
import WorkspacesEmailSettingsContainer from './components/Workspaces/Settings/Email/WorkspacesEmailSettingsContainer';
import WorkspacesThemeSettingsContainer from './components/Workspaces/Settings/Theme/WorkspacesThemeSettingsContainer';
import WorkspacesApplicationsSettingsContainer from './components/Workspaces/Applications/Settings/WorkspacesApplicationsSettingsContainer';
import WorkspacesApplicationsUserSettingsContainer from './components/Workspaces/Applications/UserSettings/WorkspacesApplicationsUserSettingsContainer';
import WorkspacesApplicationsLandingContainer from './components/Workspaces/Applications/Landing/WorkspacesApplicationsLandingContainer';
import WorkspacesFocusAreasContainer from './components/Workspaces/FocusArea/List/WorkspacesFocusAreasContainer';
import WorkspacesFocusAreasShapeContainer from './components/Workspaces/FocusArea/Shape/WorkspacesFocusAreasShapeContainer';
import ApplicationsCreateContainer from './components/Applications/Create/ApplicationsCreateContainer';
import WorkspacesApplicationsDetailContainer from './components/Workspaces/Applications/Detail/WorkspacesApplicationsDetailContainer';
import WorkspacesCategoriesCreateContainer from './components/Workspaces/Categories/Create/WorkspacesCategoriesCreateContainer';
import WorkspacesCategoriesDetailContainer from './components/Workspaces/Categories/Detail/WorkspacesCategoriesDetailContainer';
import CategoriesCreateContainer from './components/Categories/Create/CategoriesCreateContainer';
import CategoriesDetailContainer from './components/Categories/Detail/CategoriesDetailContainer';
import UsersListContainer from './components/Users/List/UsersListContainer';
import UsersDetailContainer from './components/Users/Detail/UsersDetailContainer';
import UsersAccessContainer from './components/Users/Access/UsersAccessContainer';
import UserCreateContainer from './components/Users/Create/UserCreateContainer';
import ProfileContainer from './components/Profile/Detail/ProfileContainers';
import ProfileAccessContainer from './components/Profile/Access/ProfileAccessContainer';
import GlobalUsersListContainer from './components/GlobalUsers/List/GlobalUsersListContainer';
import UsersConfirmationContainer from './components/Users/Confirmation/UsersConfirmationContainer';
import UsersLoginContainer from './components/Users/Login/UsersLoginContainer';
import UsersLogoutContainer from './components/Users/Logout/UsersLogoutContainer';
import UsersChangePasswordContainer from './components/Users/ChangePassword/UsersChangePasswordContainer';
import UsersResetPasswordContainer from './components/Users/ResetPassword/UsersResetPasswordContainer';
import UnavailablePagesContainer from './components/UnavailablePages/UnavailablePagesContainer';
import ContactOwnersContainer from './components/ContactOwners/ContactOwnersContainer';
import ContactAdministratorsContainer from './components/ContactAdministrators/ContactAdministratorsContainer';
import UserRegisterContainer from './components/Users/Register/UserRegisterContainer';
import { store } from '.';
import { updateRoleAction } from './actions/updateRoleAction';
import { getNewExpirationDate, getApplicationUrls } from '@wrd/helpers';
import { stringify } from 'querystring';
import { getUserFromCookie } from '@wrd/helpers';

const Main = () => {
  const { auth, portal } = getApplicationUrls();
  const userFromCookie = getUserFromCookie();

  useEffect(() => {
    document.getElementById('PortalTheme')!.href =
      process.env.PUBLIC_URL + '/portal/css/white-theme.css';

    const expirationDate = getNewExpirationDate();

    store.dispatch(
      updateRoleAction({
        user_id: userFromCookie.user_id,
        user_name: userFromCookie.user_name,
        user_role: userFromCookie.user_role,
        full_name: '',
        token: {
          accessToken: userFromCookie.accessToken,
          expires: expirationDate,
          userStatus: 'Active',
          accountConfirmed: userFromCookie.accountConfirmed,
        },
        tenantId: userFromCookie.tenantId,
        is_logged_in: true,
        is_processing_login: true,
        error: '',
      })
    );
  }, []);

  useLayoutEffect(() => {
    if (!userFromCookie.isExistedAccessToken) {

      const queryParameters = stringify({
        returnUrl: portal,
      });

      window.location.href = `${auth}?${queryParameters}`;
    }
  }, []);


  {userFromCookie.isExpiredTokenTrue ? window.location.href = auth:portal}

  return (
    <div className="h-100">
      <Route exact path="/" component={WorkspacesListContainer} />
      <Route
        exact
        path={routeCodes.WORKSPACES_LIST}
        component={WorkspacesListContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACE_CREATE}
        component={WorkspacesCreateContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACE_DETAIL}
        component={WorkspacesDetailContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACE_LANDING}
        component={WorkspacesLandingContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACE_LANDING_HOME}
        component={WorkspacesLandingContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_APPLICATIONS_LIST}
        component={WorkspacesApplicationsContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_MEMBERS_LIST}
        component={WorkspacesMembersContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_MEMBERS_DETAIL}
        component={UsersDetailContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_MEMBERS_ACCESS}
        component={UsersAccessContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_MEMBERS_APPLICATION_SETTINGS}
        component={WorkspacesApplicationsUserSettingsContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_MEMBERS_CREATE}
        component={UserCreateContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_MEMBERS_REGISTER}
        component={UserRegisterContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_CATEGORIES_CREATE}
        component={WorkspacesCategoriesCreateContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_MEMBERS_SHARE}
        component={WorkspacesMembersShareContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_USERS_LIST}
        component={GlobalUsersListContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_GENERAL_SETTINGS}
        component={WorkspacesGeneralSettingsContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_LOGIN_SETTINGS}
        component={WorkspacesLoginSettingsContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_EMAIL_SETTINGS}
        component={WorkspacesEmailSettingsContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_THEME_SETTINGS}
        component={WorkspacesThemeSettingsContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_APPLICATIONS_SETTINGS}
        component={WorkspacesApplicationsSettingsContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_APPLICATIONS_USER_SETTINGS}
        component={WorkspacesApplicationsUserSettingsContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_APPLICATIONS_LANDING}
        component={WorkspacesApplicationsLandingContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_APPLICATIONS_LANDING_FOCUS_AREA_MAP}
        component={WorkspacesApplicationsLandingContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_FOCUS_AREAS_LIST}
        component={WorkspacesFocusAreasContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACES_FOCUS_AREAS_SHAPE}
        component={WorkspacesFocusAreasShapeContainer}
      />
      {/* <Route
      exact
      path={routeCodes.WORKSPACES_LOGIN_USERS}
      component={UsersLoginContainer}
    /> */}
      <Route
        exact
        path={routeCodes.WORKSPACES_USERS_RESET_PASSWORD}
        component={UsersResetPasswordContainer}
      />

      <Route
        exact
        path={routeCodes.APPLICATIONS_LIST}
        component={ApplicationsListContainer}
      />
      <Route
        exact
        path={routeCodes.APPLICATION_CREATE}
        component={ApplicationsCreateContainer}
      />
      <Route
        exact
        path={routeCodes.APPLICATION_DETAIL}
        component={ApplicationsDetailContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACE_APPLICATION_DETAIL}
        component={WorkspacesApplicationsDetailContainer}
      />
      <Route
        exact
        path={routeCodes.WORKSPACE_CATEGORY_DETAIL}
        component={WorkspacesCategoriesDetailContainer}
      />
      <Route
        exact
        path={routeCodes.CATEGORY_CREATE}
        component={CategoriesCreateContainer}
      />
      <Route
        exact
        path={routeCodes.CATEGORY_DETAIL}
        component={CategoriesDetailContainer}
      />
      <Route
        exact
        path={routeCodes.USERS_LIST}
        component={UsersListContainer}
      />
      <Route
        exact
        path={routeCodes.USER_DETAIL}
        component={UsersDetailContainer}
      />
      <Route
        exact
        path={routeCodes.USER_ACCESS}
        component={UsersAccessContainer}
      />
      <Route
        exact
        path={routeCodes.USER_CREATE}
        component={UserCreateContainer}
      />
      <Route
        exact
        path={routeCodes.USERS_LOGIN_CONFIRMATION}
        component={UsersConfirmationContainer}
      />
      <Route
        exact
        path={routeCodes.USERS_LOGIN}
        component={UsersLoginContainer}
      />
      <Route
        exact
        path={routeCodes.USERS_LOGOUT}
        component={UsersLogoutContainer}
      />
      <Route
        exact
        path={routeCodes.USERS_CHANGE_PASSWORD}
        component={UsersChangePasswordContainer}
      />
      <Route
        exact
        path={routeCodes.USERS_RESET_PASSWORD}
        component={UsersResetPasswordContainer}
      />
      <Route exact path={routeCodes.PROFILE} component={ProfileContainer} />
      <Route
        exact
        path={routeCodes.PROFILE_ACCESS}
        component={ProfileAccessContainer}
      />
      <Route
        exact
        path={routeCodes.PORTAL_SETTINGS}
        component={PortalSettingsContainer}
      />
      <Route
        exact
        path={routeCodes.PORTAL_FEATURES}
        component={PortalSettingsContainer}
      />
      <Route
        exact
        path={routeCodes.PORTAL_NEWS}
        component={PortalSettingsContainer}
      />
      <Route
        exact
        path={routeCodes.PORTAL_OPTIONS}
        component={PortalSettingsContainer}
      />
      <Route
        exact
        path={routeCodes.PORTAL_OPTIONS_EMAIL}
        component={PortalSettingsContainer}
      />
      <Route
        exact
        path={routeCodes.PORTAL_OPTIONS_THEME}
        component={PortalSettingsContainer}
      />
      <Route
        exact
        path={routeCodes.UNAVAILABLE_PAGE}
        component={UnavailablePagesContainer}
      />
      <Route
        exact
        path={routeCodes.CONTACT_OWNERS}
        component={ContactOwnersContainer}
      />
      <Route
        exact
        path={routeCodes.CONTACT_ADMINISTRATORS}
        component={ContactAdministratorsContainer}
      />
    </div>
  );
};

const mapStateToProps = ({
  role,
  redirectUrl,
  availableWorkspaces,
  loading,
}) => ({
  role,
  redirectUrl,
  availableWorkspaces,
  loading: loading.page_redirection.loading,
});

export default withRouter(connect(mapStateToProps, null)(Main));
