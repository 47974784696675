import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../../configurations/routes';
import { updateStickyWorkspacesMembersAction } from '../../../../actions/updateStickyWorkspacesMembersAction';
import { updateStickyWorkspaceMemberRoleWithThunk, updateStickyWorkspaceMemberStatusWithThunk } from '../../../../api/workspacesMembersThunk';
import { updateDialogWorkspacesMembersAction } from '../../../../actions/updateDialogWorkspacesMembersAction';
import { registrationReminderWithThunk } from '../../../../api/registrationReminderThunk';

class GridItem extends Component {
    constructor(props) {
        super(props);
        this.displayUser = this.displayUser.bind(this);
        this.displayAction = this.displayAction.bind(this);
        this.hideAction = this.hideAction.bind(this);
        this.getDetail = this.getDetail.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        item: PropTypes.object.isRequired,
        sticky: PropTypes.object.isRequired,
        sorting: PropTypes.object.isRequired,
        dispatchStickyWorkspacesMembers: PropTypes.func.isRequired,
        dispatchDialogWorkspacesMembers: PropTypes.func.isRequired,
        updateWorkspaceMemberRole: PropTypes.func.isRequired,
        updateWorkspaceMemberStatus: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired,
        pagination: PropTypes.func.isRequired
    };

    async displayUser(userId, workspaceName) {
        const { role } = this.props;

        if (role.user_name.toLowerCase() === userId) {
            this.props.history.push(routeCodes.PROFILE);
        }
        else {
            this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspaceName.toLowerCase().replaceAll(" ", "+") + "/members/" + userId + '/detail');
        }
    }

    displayAction(id) {
        this.props.dispatchStickyWorkspacesMembers({ user_id: id, show_action: !this.props.sticky.show_action });
    }

    hideAction() {
        this.props.dispatchStickyWorkspacesMembers({ user_id: '', show_action: false });
    }

    getDetail() {
        this.hideAction();
        const { item } = this.props;
        this.props.history.push(routeCodes.USERS_LIST + "/" + item.id.toLowerCase())
    }

    getRegistrationStatusClass() {
        const { item } = this.props;

        let registrationStatusClass = '';

        if (item.registration_status === 'Confirmed') {
            registrationStatusClass = 'statusActive';
        }
        else if (item.registration_status === 'Pending') {
            registrationStatusClass = 'statusPending';
        }

        return registrationStatusClass;
    }

    getUserStatusClass() {
        const { item } = this.props;

        let userStatusClass = '';

        if (item.user_status === 'Enabled') {
            userStatusClass = 'statusActive';
        }
        else if (item.user_status === 'Disabled') {
            userStatusClass = 'statusSuspend';
        }

        return userStatusClass;
    }

    getMemberStatusClass() {
        const { item } = this.props;

        let userStatusClass = '';

        if (item.member_status === 'Active') {
            userStatusClass = 'statusActive';
        }
        else if (item.member_status === 'Suspended') {
            userStatusClass = 'statusSuspend';
        }

        return userStatusClass;
    }

    updateRole() {
        const { workspace, item } = this.props;
        const role = (item.user_role === "Workspace Owner") ? "Workspace Member" : "Workspace Owner";

        this.props.updateWorkspaceMemberRole(workspace.id, item.uid, role);
    }

    updateStatus() {
        const { workspace, item } = this.props;

        let members = {
            id: item.uid,
            name: item.id,
            status: item.member_status,
            dialog_title: 'Warning',
            dialog_type: 'update_status',
            show_dialog: (item.member_status === 'Active') ? true : false
        };

        this.props.dispatchDialogWorkspacesMembers(members);

        if (item.member_status === 'Suspended') {
            const status = "Active";
            this.props.updateWorkspaceMemberStatus(workspace.id, item.uid, status);
        }
    }

    removeMember() {
        const { item } = this.props;

        let members = {
            id: item.uid,
            name: item.id,
            status: item.user_status,
            dialog_title: 'Warning',
            dialog_type: 'remove_member',
            show_dialog: true
        };

        this.props.dispatchDialogWorkspacesMembers(members);
    }

    sendRegistratinReminder() {
        const { workspace, item } = this.props;
        this.props.registrationReminder(item.uid, workspace.id, item.id);
    }

    render() {
        const { workspace, item } = this.props;
        const { show_action, user_id } = this.props.sticky;

        return (
            <tr>
                <td id={"members-list-id-" + item.uid} onClick={() => this.displayUser(item.id.toLowerCase(), workspace.workspace_name)}>
                    <img src={((item.picture !== undefined) ? "data:image/jpeg;base64," : "") + item.picture} className="userPhoto" alt="" />
                    <label className="btn iconBtn customPickExtUsrCheckbox">
                        <span className={(item.member_status === 'Active') ? "greenBlockChecked" : "darkBlockUncheck"}></span>
                    </label>
                </td>
                <td onClick={() => this.displayUser(item.id.toLowerCase(), workspace.workspace_name)}>
                    <b className="userName">{item.id}</b>
                    <p className="fullName">{item.full_name}</p>
                </td>
                <td onClick={() => this.displayUser(item.id.toLowerCase(), workspace.workspace_name)}>
                    <span className="icon icon-wrd-building"></span>
                    <span className="organization">{item.organization}</span>
                </td>
                <td onClick={() => this.displayUser(item.id.toLowerCase(), workspace.workspace_name)}>
                    <span className="icon icon-wrd-bookmark"></span>
                    <span className="userRole">{item.user_role}</span>
                </td>
                <td onClick={() => this.displayUser(item.id.toLowerCase(), workspace.workspace_name)}>
                    <span className="icon icon-wrd-registration"></span>
                    <span className={this.getRegistrationStatusClass()}>{item.registration_status}</span>
                </td>
                <td onClick={() => this.displayUser(item.id.toLowerCase(), workspace.workspace_name)}>
                    <span className={this.getUserStatusClass()}>{item.user_status}</span>
                </td>
                <td onClick={() => this.displayUser(item.id.toLowerCase(), workspace.workspace_name)}>
                    <span className={this.getMemberStatusClass()}>{item.member_status}</span>
                </td>
                <td id={"members-list-sticky-action-" + item.uid} onClick={() => this.displayAction(item.id.toLowerCase())} onBlur={() => this.hideAction()} className="stickyAction">
                    <div>
                        <button className="tableActionBtn">
                            <span className="icon-wrd-more"></span>
                        </button>
                        <ul className={((user_id.toLowerCase() === item.id.toLowerCase()) && show_action) ? "action show-action" : "action hide-action"}>
                            <li>
                                <Link to={routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/members"} onMouseDown={() => this.getDetail()} className="remove">
                                    <span className="icon-wrd-profile"></span>
                                    View Profile
                                </Link>
                            </li>
                            <li>
                                <Link to={routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/members"} onMouseDown={() => this.updateRole()} className="remove">
                                    <span className="icon-wrd-bookmark"></span>
                                    <label>{(item.user_role === "Workspace Owner") ? "Set as Member" : "Set as Owner"}</label>
                                </Link>
                            </li>
                            {(item.registration_status !== 'Confirmed') ?
                                <li>
                                    <Link to={routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/members"} onMouseDown={() => this.sendRegistratinReminder()} className="remove">
                                        <span className="icon-wrd-mail-closed"></span>
                                        <label>Registration Reminder</label>
                                    </Link>
                                </li>
                                : null
                            }
                            <li>
                                <Link to={routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/members"} onMouseDown={() => this.updateStatus()} className="remove">
                                    <span className={((item.member_status === 'Active') ? 'icon-lock icon-wrd-lock' : 'icon-unlock icon-wrd-unlock')}></span>
                                    <label className={(item.member_status === "Suspended") ? "sticky-action-enable" : "sticky-action-disable"}>{(item.member_status === "Suspended") ? "Reinstate" : "Suspend"}</label>
                                </Link>
                            </li>
                            <li>
                                <Link to={routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/members"} onMouseDown={() => this.removeMember()} className="remove revoke">
                                    <span className="icon-wrd-revoke"></span>
                                    <label className="sticky-action-disable">Remove Access</label>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </td>
            </tr>
        );
    }
}

const mapStateToProps = ({ detail, sticky, search, sorting, role }) => ({
    workspace: detail.workspace,
    sticky: sticky.members,
    search: search.member,
    sorting: sorting.member,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogWorkspacesMembers: (dialog) => dispatch(updateDialogWorkspacesMembersAction(dialog)),
    dispatchStickyWorkspacesMembers: (data) => dispatch(updateStickyWorkspacesMembersAction(data)),
    updateWorkspaceMemberRole: (workspaceId, userId, role) => dispatch(updateStickyWorkspaceMemberRoleWithThunk(workspaceId, userId, role)),
    updateWorkspaceMemberStatus: (workspaceId, userId, status) => dispatch(updateStickyWorkspaceMemberStatusWithThunk(workspaceId, userId, status)),
    registrationReminder: (user, workspaceId, username) => dispatch(registrationReminderWithThunk(user, workspaceId, username))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GridItem));