import { useState, useCallback } from 'react';
import { useUpdateEffect } from 'react-use';
import { WebMercatorViewport, FlyToInterpolator } from '@deck.gl/core/typed';

import { ViewportStateProps, ViewportProps } from '@wrd/types';

const defaultProps: ViewportStateProps = {
  latitude: 0.1,
  longitude: 0.1,
  zoom: 2,
  transitionDuration: 500,
  transitionInterpolator: new FlyToInterpolator(),
};

const useSimpleViewport = ({ bbox, width }: ViewportProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const [viewportState, setViewportState] = useState(defaultProps);
  const onViewportChange = (viewport: any) => setViewportState(viewport);

  const onResize = useCallback(
    (width: number) => {
      if (width) {
        setViewportState((state) => ({
          ...state,
          width,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width, viewportState]
  );

  const onDeckLoad = () => {
    if (bbox) {
      setIsLoaded(true);
    }
  };

  useUpdateEffect(() => {
    if (isLoaded && bbox) {
      setViewportState((state) => {
        const stateToSet = new WebMercatorViewport({ ...state }).fitBounds(
          bbox,
          {
            padding: 0,
          }
        );
        return {
          transitionDuration: 1000,
          transitionInterpolator: new FlyToInterpolator({ speed: 1 }),
          latitude: stateToSet.latitude,
          longitude: stateToSet.longitude,
          zoom: stateToSet.zoom,
        };
      });
    }
  }, [isLoaded]);

  return { viewportState, onViewportChange, onDeckLoad, onResize, isLoaded };
};

export default useSimpleViewport;
