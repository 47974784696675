import { getUserWorkspacePropertiesByIdApi } from '../backend/domain/workspaces';

import {
  getWorkspaceSettingByIdApi,
  getWorkspaceSettingIncludeByIdApi,
  getWorkspaceSettingLogoByIdApi,
  updateWorkspaceSettingApi,
} from '../backend/domain/workspacesSettings';
import { getWorkspaceLandingApplicationList } from '../backend/api/workspacesApplications';
import { getApplicationsLandingAction } from '../actions/getWorkspaceApplicationLandingAction';

import { getWorkspaceAction } from '../actions/getWorkspaceAction';
import { clearWorkspaceAction } from '../actions/clearWorkspaceAction';
import { getWorkspaceSettingAction } from '../actions/getWorkspaceSettingAction';
import { clearWorkspaceSettingAction } from '../actions/clearWorkspaceSettingAction';
import { getPortalLogoAction } from '../actions/getPortalLogoAction';
import { updateWorkspaceSettingAction } from '../actions/updateWorkspaceSettingAction';
import { loadingWorkspaceSettingAction } from '../actions/loadingWorkspaceSettingAction';
import { handleWorkspaceSettingsUpdateError } from '../utils/errorMessages';
import {
  overrideWorkspaceId,
  updateWorkspaceProperties,
} from '../utils/workspaces';
import { getSessionStorage } from '../utils/sessionStorage';
import { getUserInitialApi } from '../backend/domain/users';
import { getWorkspaceUrlApi } from '../backend/domain/workspaces';
import { anonymousUserId } from '../configurations/app';
import { getWorkspaceMemberByIdApi } from '../backend/domain/workspacesMembers';
// import { getUserAccessWorkspaceApplications } from '../backend/api/users';

export const getWorkspaceSettingByIdWithThunk =
  (id, userId, isAdmin, historyProps) => async (dispatch) => {
    let wId = await overrideWorkspaceId(id);
    dispatch(loadingWorkspaceSettingAction(true));
    dispatch(clearWorkspaceAction([]));
    dispatch(clearWorkspaceSettingAction([]));

    getUserWorkspacePropertiesByIdApi(
      wId,
      userId,
      isAdmin,
      historyProps,
      'general'
    )
      .then((response) => {
        if (!response.error) {
          dispatch(getWorkspaceAction(response));
        }
        return response;
      })
      .then(() => {
        getWorkspaceSettingByIdApi(wId)
          .then((response) => {
            if (!response.error) {
              dispatch(getWorkspaceSettingAction(response));
            }
            return response;
          })
          .then((response) => {
            dispatch(loadingWorkspaceSettingAction(false));
            return response;
          });
      });
  };

export const getWorkspaceSettingWithThunk = (id) => async (dispatch) => {
  let wId = await overrideWorkspaceId(id);
  dispatch(loadingWorkspaceSettingAction(true));

  getWorkspaceSettingByIdApi(wId)
    .then((response) => {
      if (!response.error) {
        dispatch(getWorkspaceSettingAction(response));
      }
      return response;
    })
    .then((response) => {
      dispatch(loadingWorkspaceSettingAction(false));
      return response;
    });
};

export const getWorkspaceLandingSettingWithThunk =
  (id, includeOnly) => async (dispatch) => {
    let wId = await overrideWorkspaceId(id);
    dispatch(loadingWorkspaceSettingAction(true));

    getWorkspaceSettingIncludeByIdApi(wId, includeOnly)
      .then((response) => {
        if (!response.error) {
          dispatch(getWorkspaceSettingAction(response));
        }
        return response;
      })
      .then((response) => {
        dispatch(loadingWorkspaceSettingAction(false));
        return response;
      });
  };

export const getWorkspaceApplicationLandingSettingWithThunk =
  (workspaceId, includeOnly, userId, role) => async (dispatch) => {
    let wId = await overrideWorkspaceId(workspaceId);
    dispatch(loadingWorkspaceSettingAction(true));
    getWorkspaceSettingIncludeByIdApi(wId, includeOnly)
      .then(async (response) => {
        if (!response.error) {
          dispatch(getWorkspaceSettingAction(response));
        }
        return response;
      })
      .then(async (response) => {
        let user = await getUserInitialApi(userId, 32, 32);
        let workspaceUrl = await getWorkspaceUrlApi(wId);
        updateWorkspaceProperties(user, workspaceUrl.url);
        return response;
      })
      .then((response) => {
        dispatch(loadingWorkspaceSettingAction(false));
        return response;
      });

    const { data: applications } = await getWorkspaceLandingApplicationList(
      wId,
      true,
      false
    );

    if (userId === anonymousUserId) {
      dispatch(
        getApplicationsLandingAction(
          applications.filter((app) => app.publicAccess)
        )
      );
    } else {
      if (role === '') {
        const sessionAcct = JSON.parse(getSessionStorage('role'));
        role = sessionAcct.user_role;
      }
      if (role === 'Administrator') {
        dispatch(getApplicationsLandingAction(applications));
      } else {
        const workspaceUser = await getWorkspaceMemberByIdApi(wId, userId);

        const isSuspend =
          workspaceUser !== undefined ? workspaceUser.user.is_suspended : false;

        let userApplications = []; // applications.filter(app => app.publicAccess);
        // const userApps = await getUserAccessWorkspaceApplications(wId, userId);

        // if (
        //   applications.length > 0 &&
        //   userApps.status === 200 &&
        //   userApps.data.length > 0
        // ) {
        //   if (!isSuspend) {
        //     userApplications = applications.filter((x) =>
        //       userApps.data.find(
        //         ({ applicationId }) => x.applicationId === applicationId
        //       )
        //     );
        //     dispatch(getApplicationsLandingAction(userApplications));
        //   }
        // }
      }
    }
  };

export const updateWorkspaceSettingWithThunk =
  (workspaceSetting) => (dispatch) => {
    dispatch(loadingWorkspaceSettingAction(true));
    updateWorkspaceSettingApi(workspaceSetting)
      .then((response) => {
        dispatch(updateWorkspaceSettingAction(workspaceSetting));
        return response;
      })
      .then((response) => {
        handleWorkspaceSettingsUpdateError(
          dispatch,
          response.status,
          response.error_messages
        );
        return response;
      })
      .then(() => {
        getWorkspaceSettingLogoByIdApi(workspaceSetting.id)
          .then((response) => {
            if (!response.error) {
              dispatch(getPortalLogoAction(response));
            }
            return response;
          })
          .then((response) => {
            dispatch(loadingWorkspaceSettingAction(false));
            return response;
          });
      });
  };
