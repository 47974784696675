import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setupNewWorkspaceAction } from '../../../actions/setupNewWorkspaceAction';
import RichTextEditor from '../../Editor/RichTextEditor';
import { workspaces } from '../../../reducers/workspaces';
import { createWorkspaceWithThunk } from '../../../api/workspacesThunk';
import { getCookies } from 'apps/portal/src/backend/api/getCookies';;

class WorkspacesCreateInput extends Component {
  constructor(props) {
    super(props);
    this.enterSubmit = this.enterSubmit.bind(this);
    this.onWorkspaceNameChange = this.onWorkspaceNameChange.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onDomainUrlChange = this.onDomainUrlChange.bind(this);
    this.onVideoUrlChange = this.onVideoUrlChange.bind(this);
    this.onWorkspaceUrlChange = this.onWorkspaceUrlChange.bind(this);
    this.state = {
      isChecked: false,
      fields: {},
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.onDueDateChange = this.onDueDateChange.bind(this);
    this.onWorkspaceAllowPublicAccessChange =
      this.onWorkspaceAllowPublicAccessChange.bind(this);
    this.onWorkspaceAllowUsersToRegisterChange =
      this.onWorkspaceAllowUsersToRegisterChange.bind(this);
    this.onToggleNoAreaAccess = this.onToggleNoAreaAccess.bind(this);
    this.onToggleNoApplicationAccess =
      this.onToggleNoApplicationAccess.bind(this);
  }

  static propTypes = {
    workspace: PropTypes.object.isRequired,
    setupNewWorkspace: PropTypes.func.isRequired,
  };

  onWorkspaceNameChange(e) {
    let workspace = {
      ...this.props.workspace,
      workspace_name: e.target.value,
    };
    this.props.setupNewWorkspace(workspace);
  }

  onTitleChange(e) {
    let workspace = {
      ...this.props.workspace,
      workspace_title: e.target.value,
    };
    this.props.setupNewWorkspace(workspace);
  }

  onDescriptionChange(e) {
    let workspace = {
      ...this.props.workspace,
      workspace_desc: e.target.value,
    };
    this.props.setupNewWorkspace(workspace);
  }

  onDomainUrlChange(e) {
    let workspace = {
      ...this.props.workspace,
      domain_url: e.target.value,
    };
    this.props.setupNewWorkspace(workspace);
  }

  onVideoUrlChange(e) {
    let workspace = {
      ...this.props.workspace,
      video_url: e.target.value,
    };
    this.props.setupNewWorkspace(workspace);
  }

  onWorkspaceUrlChange(e) {
    let workspace = {
      ...this.props.workspace,
      workspace_url: e.target.value,
    };
    this.props.setupNewWorkspace(workspace);
  }

  componentWillMount() {
    this.id = `toggle_${Math.random().toString().replace(/0\./, '')}`;
  }

  handleChange() {
    if (this.state.isChecked === true) {
      let workspace = {
        ...this.props.workspace,
        due_date: '',
      };
      this.props.setupNewWorkspace(workspace);
    }
    this.setState({ isChecked: !this.state.isChecked });
  }

  onDueDateChange(data) {
    let workspace = {
      ...this.props.workspace,
      due_date: data[0].toLocaleDateString(),
    };
    this.props.setupNewWorkspace(workspace);
  }

  onWorkspaceAllowPublicAccessChange() {
    const { workspace } = this.props;

    let setupworkspace = {
      ...this.props.workspace,
      allow_public_access: !workspace.allow_public_access,
    };
    this.props.setupNewWorkspace(setupworkspace);
  }

  onWorkspaceAllowUsersToRegisterChange() {
    const { workspace } = this.props;

    let setupworkspace = {
      ...this.props.workspace,
      allow_users_to_register: !workspace.allow_users_to_register,
    };
    this.props.setupNewWorkspace(setupworkspace);
  }

  onToggleNoAreaAccess() {
    const workspaceDetail = {
      ...this.props.workspace,
      all_focus_area_access: !this.props.workspace.all_focus_area_access,
    };

    this.props.setupNewWorkspace(workspaceDetail);
  }

  onToggleNoApplicationAccess() {
    const workspaceDetail = {
      ...this.props.workspace,
      all_application_access: !this.props.workspace.all_application_access,
    };

    this.props.setupNewWorkspace(workspaceDetail);
  }

  async enterSubmit(e) {
    if (e.key === 'Enter') {
      const { workspace } = this.props;
      this.props.createWorkspace(workspace);
    }
  }

  render() {
    const { workspace } = this.props;
    return (
      <div className="col-lg-12 col-xl-4 mb-5" onKeyPress={this.enterSubmit}>
        <div className="inputForm">
          <input
            className="form-control"
            type="text"
            name=""
            onChange={this.onWorkspaceNameChange}
            required
          />
          <label>Name</label>
        </div>
        <div className="inputForm">
          <input
            className="form-control"
            type="text"
            name=""
            onChange={this.onTitleChange}
            required
          />
          <label>Title</label>
        </div>
        {/* <label>Description</label>
        <RichTextEditor
          value={workspaces.workspace_desc}
          onChange={this.onDescriptionChange}
        /> */}
        <div className="inputForm">
          <input
            className="form-control"
            type="text"
            name=""
            onChange={this.onDescriptionChange}
            required
          />
          <label>Description</label>
        </div>
        {!getCookies.isMikeMine && (
        <div className="custom-controls-stacked custom-controls-knob">
          <label className="custom-control ios-switch">
            <input
              type="checkbox"
              onChange={this.onWorkspaceAllowPublicAccessChange}
              className="ios-switch-control-input"
              checked={workspace.allow_public_access}
            />
            <span className="ios-switch-control-indicator"></span>
            <span className="text">Allow Public Access</span>
          </label>
        </div>
        )}
        <div className="custom-controls-stacked custom-controls-knob">
          <label className="custom-control ios-switch">
            <input
              type="checkbox"
              onChange={this.onWorkspaceAllowUsersToRegisterChange}
              className="ios-switch-control-input"
              checked={workspace.allow_users_to_register}
            />
            <span className="ios-switch-control-indicator"></span>
            <span className="text">Allow Users to Register</span>
          </label>
        </div>
        <div className="inputForm">
          <input
            className="form-control"
            type="text"
            name=""
            onChange={this.onDomainUrlChange}
            required
          />
          <label>Domain Url</label>
        </div>
        <div className="inputForm">
          <input
            className="form-control"
            type="text"
            name=""
            onChange={this.onVideoUrlChange}
            required
          />
          <label>Video Url</label>
        </div>
        <div className="inputForm">
          <input
            className="form-control"
            type="text"
            name=""
            onChange={this.onWorkspaceUrlChange}
            required
          />
          <label>Workspace Url</label>
        </div>
        <label className="label-focus-area-create-workspace">
          Default member access to focus areas
        </label>
        <div className="custom-control custom-control-radio-create custom-checkbox">
          <input
            onChange={() => {
              this.onToggleNoAreaAccess();
            }}
            type="checkbox"
            className="custom-control-input"
            name="noFocusArea"
            id="noFocusArea"
            checked={!workspace.all_focus_area_access}
          />
          <label
            className="custom-control-label pl-2 orangeText"
            htmlFor="noFocusArea"
          >
            No focus area
          </label>
        </div>
        <div className="custom-control custom-control-radio-create custom-checkbox">
          <input
            onChange={() => {
              this.onToggleNoAreaAccess();
            }}
            type="checkbox"
            className="custom-control-input"
            name="allFocusArea"
            id="allFocusArea"
            checked={workspace.all_focus_area_access}
          />
          <label
            className="custom-control-label pl-2 orangeText"
            htmlFor="allFocusArea"
          >
            All focus areas
          </label>
        </div>
        <label className="label-focus-area-create-workspace">
          Default member access to applications
        </label>
        <div className="custom-control custom-control-radio-create custom-checkbox">
          <input
            onChange={() => {
              this.onToggleNoApplicationAccess();
            }}
            type="checkbox"
            className="custom-control-input"
            name="noApplication"
            id="noApplication"
            checked={!workspace.all_application_access}
          />
          <label
            className="custom-control-label pl-2 orangeText"
            htmlFor="noApplication"
          >
            No application
          </label>
        </div>
        <div className="custom-control custom-control-radio-create custom-checkbox">
          <input
            onChange={() => {
              this.onToggleNoApplicationAccess();
            }}
            type="checkbox"
            className="custom-control-input"
            name="allApplications"
            id="allApplications"
            checked={workspace.all_application_access}
          />
          <label
            className="custom-control-label pl-2 orangeText"
            htmlFor="allApplications"
          >
            All applications
          </label>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ setup }) => ({
  workspace: setup.workspace,
});

const mapDispatchToProps = (dispatch) => ({
  setupNewWorkspace: (item) => dispatch(setupNewWorkspaceAction(item)),
  createWorkspace: (workspace) => dispatch(createWorkspaceWithThunk(workspace)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkspacesCreateInput)
);
