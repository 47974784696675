import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { HashLink as Link } from 'react-router-hash-link';
import WorkspacesApplicationsItem from './WorkspacesApplicationsItem';
import { getWorkspacesApplicationsAction } from '../../../../actions/getWorkspacesApplicationsAction';
import { getWorkspacesCategoriesAction } from '../../../../actions/getWorkspacesCategoriesAction';
import { updateWpAppsOrderWithThunk } from '../../../../api/workspacesApplicationsThunk';
import { updateWorkspaceApplicationCategoryWithThunk, updateWorkspacesCategoriesOrderWithThunk } from '../../../../api/workspacesListThunk';
import { deleteWorkspaceCategoryWithThunk } from '../../../../api/workspacesCategoriesThunk';
import { reOrderCategoryItems } from '../../../../utils/index';
import { mergeWorkspacesApplicationsCategories } from '../../../../utils/workspacesCategories';
import { routeCodes } from '../../../../configurations/routes';

class WorkspacesApplicationsCategory extends Component {
    constructor(props) {
        super(props);
        this.categoryDrop = React.createRef();
        this.categoryHeaderDrop = React.createRef();
    }

    static propTypes = {
        role: PropTypes.object.isRequired,
        category: PropTypes.object.isRequired,
        workspace: PropTypes.object.isRequired,
        items: PropTypes.array.isRequired,
        workspacesCategories: PropTypes.array.isRequired,
        applicationId: PropTypes.string.isRequired,
        categoryTitle: PropTypes.string.isRequired,
        updateWorkspaceApplicationCategory: PropTypes.func.isRequired,
        setDragInfoState: PropTypes.func.isRequired
    };

    onDragOver(e, isHaveApps) {
        e.stopPropagation();
        e.preventDefault();

        const draggedItem = this.props.items.filter(item => item.selected)[0];
        if (draggedItem === undefined) {
            return;
        }

        this.categoryDrop.current.setAttribute("class", (isHaveApps) ? "drop-area-app drop-area-hover mb-4" : "drop-area drop-area-hover mb-4");
    }

    onDragLeave(e, isHaveApps) {
        e.stopPropagation();
        e.preventDefault();

        let viewportOffset = this.categoryDrop.current.getBoundingClientRect();
        let boxTop = viewportOffset.top;
        let boxBottom = boxTop + viewportOffset.height;
        let y = e.clientY;

        if (y > boxTop && y < boxBottom) {
            return;
        }
        this.categoryDrop.current.setAttribute("class", (isHaveApps) ? "drop-area-app mb-4" : "drop-area mb-4");
    }

    async onDrop(e, isHaveApps) {
        e.stopPropagation();
        e.preventDefault();

        const { category, workspace, role } = this.props;

        let applicationId = e.dataTransfer.getData("application_id");
        this.categoryDrop.current.setAttribute("class", (isHaveApps) ? "drop-area-app mb-4" : "drop-area mb-4");

        const draggedItem = this.props.items.filter(item => item.selected)[0];
        if (draggedItem !== undefined) {
            draggedItem.category_title = this.props.category.category_title;
            const applications = reOrderCategoryItems(
                this.props.items,
                this.props.items.length-1,
                draggedItem
            );

            this.props.dispatchWorkspacesApplications(applications);

            let withWorkspaceCategories = this.props.workspacesCategories.filter((item) => item.category_title !== "" && item.is_workspace_category);
            let withAppCategories = this.props.workspacesCategories.filter((item) => item.category_title !== "" && !item.is_workspace_category);
            let mergeWorkspaceCategories = await mergeWorkspacesApplicationsCategories(withWorkspaceCategories, applications);
            mergeWorkspaceCategories = mergeWorkspaceCategories.concat(withAppCategories);

            this.props.dispatchWorkspacesCategories(mergeWorkspaceCategories);

            const isWorkspaceCategory = category.is_workspace_category;
            const isAdmin = (role.user_role.toLowerCase() === 'administrator') ? true : false;
            this.props.updateWorkspaceApplicationCategory(workspace.id, applicationId, category.category_id, isWorkspaceCategory, isAdmin);
            this.props.setDragInfoState("","");
        }
    }

    onCategoryItemSelect = (app) => {
        const { items } = this.props;
        const applications = items.map((item, i) =>
            (app.id === item.id)
                ? { ...item, selected: true }
                : { ...item, selected: false }
        )
        this.props.dispatchWorkspacesApplications(applications)
    }

    onCategoryItemDrop = async (e, isHaveApps) => {
        e.stopPropagation();
        e.preventDefault();

        const { category, workspace, role } = this.props;

        this.categoryDrop.current.setAttribute("class", (isHaveApps) ? "drop-area-app mb-4" : "drop-area mb-4");
        let applicationId = e.dataTransfer.getData("application_id");

        const draggedItem = this.props.items.filter(item => item.selected)[0];
        if (draggedItem !== undefined) {
            if (draggedItem.category_title !== this.props.category.category_title) {
                draggedItem.category_title = this.props.category.category_title;
                const applications = reOrderCategoryItems(
                    this.props.items,
                    this.props.items.length - 1,
                    draggedItem
                );

                this.props.dispatchWorkspacesApplications(applications);

                let withWorkspaceCategories = this.props.workspacesCategories.filter((item) => item.category_title !== "" && item.is_workspace_category);
                let withAppCategories = this.props.workspacesCategories.filter((item) => item.category_title !== "" && !item.is_workspace_category);
                let mergeWorkspaceCategories = await mergeWorkspacesApplicationsCategories(withWorkspaceCategories, applications);
                mergeWorkspaceCategories = mergeWorkspaceCategories.concat(withAppCategories);

                this.props.dispatchWorkspacesCategories(mergeWorkspaceCategories);

                const isWorkspaceCategory = category.is_workspace_category;
                const isAdmin = (role.user_role.toLowerCase() === 'administrator') ? true : false;
                this.props.updateWorkspaceApplicationCategory(workspace.id, applicationId, category.category_id, isWorkspaceCategory, isAdmin);
            }
            else {
                const workspacesId = this.props.workspace.id
                const apps = this.props.items.map((app, i) => {
                    return {
                        applicationId: app.id, index: i
                    }
                })

                this.props.updateWpAppsOrder(workspacesId, apps);
            }
        }

        this.props.setDragInfoState("","");
    }

    onCategoryItemDragOver = async (e, index, isHaveApps) => {
        e.stopPropagation();
        e.preventDefault();

        const draggedItem = this.props.items.filter(item => item.selected)[0];
        if (draggedItem === undefined) {
            return;
        }

        this.categoryDrop.current.setAttribute("class", (isHaveApps) ? "drop-area-app drop-area-hover mb-4" : "drop-area drop-area-hover mb-4");

        const { applicationId, categoryTitle, category } = this.props;
        if (category.category_title !== categoryTitle) {
            return;
        }

        let apps = this.props.items.filter((item) => item.id === applicationId && item.category_title === categoryTitle);
        if (apps.length === 0) {
            return;
        }

        let categApps = this.props.items.filter((item) => item.category_title === categoryTitle);
        const draggedOverItem = categApps[index];


        if (draggedItem === draggedOverItem || draggedOverItem.is_linked === false) {
            return;
        }

        const applications = reOrderCategoryItems(
            this.props.items,
            draggedOverItem.index,
            draggedItem
        );

        this.props.dispatchWorkspacesApplications(applications);

        let withWorkspaceCategories = this.props.workspacesCategories.filter((item) => item.category_title !== "" && item.is_workspace_category);
        let withAppCategories = this.props.workspacesCategories.filter((item) => item.category_title !== "" && !item.is_workspace_category);
        let mergeWorkspaceCategories = await mergeWorkspacesApplicationsCategories(withWorkspaceCategories, applications);
        mergeWorkspaceCategories = mergeWorkspaceCategories.concat(withAppCategories);

        this.props.dispatchWorkspacesCategories(mergeWorkspaceCategories);
    }

    onCategoryHeaderSelect(e, index) {
        const { workspacesCategories } = this.props;
        const categories = workspacesCategories.map((item, i) =>
            (item.order === index)
                ? { ...item, selected: true }
                : { ...item, selected: false }
        )
        this.props.dispatchWorkspacesCategories(categories)
    }

    onCategoryHeaderDragOver(e, index) {
        e.stopPropagation();
        e.preventDefault();

        const { category } = this.props;
        if (!category.is_workspace_category) {
            return;
        }

        const draggedItem = this.props.workspacesCategories.filter(item => item.selected)[0];
        if (draggedItem === undefined) {
            return;
        }

        if (draggedItem.index === index) {
            return;
        }

        this.categoryHeaderDrop.current.setAttribute("class", "category-header drop-area-hover mb-3");

        const { workspacesCategories } = this.props;
        let categories = workspacesCategories.filter(item => item !== draggedItem);
        categories.splice(index, 0, draggedItem);

        for (let i in categories) {
            let categ = categories[i];
            categ.order = parseInt(i, 10);
        }

        this.props.dispatchWorkspacesCategories(categories)
    }

    onCategoryHeaderDragLeave(e) {
        e.stopPropagation();
        e.preventDefault();

        const { category } = this.props;
        if (!category.is_workspace_category) {
            return;
        }

        const draggedItem = this.props.workspacesCategories.filter(item => item.selected)[0];
        if (draggedItem === undefined) {
            return;
        }

        let viewportOffset = this.categoryHeaderDrop.current.getBoundingClientRect();
        let boxTop = viewportOffset.top;
        let boxBottom = boxTop + viewportOffset.height;
        let y = e.clientY;

        if (y > boxTop && y < boxBottom) {
            return;
        }

        this.categoryHeaderDrop.current.setAttribute("class", "category-header mb-3");
    }

    onCategoryHeaderDrop(e) {
        e.stopPropagation();
        e.preventDefault();

        const { category } = this.props;
        if (!category.is_workspace_category) {
            return;
        }

        const draggedItem = this.props.workspacesCategories.filter(item => item.selected)[0];
        if (draggedItem === undefined) {
            return;
        }

        this.categoryHeaderDrop.current.setAttribute("class", "category-header mb-3");

        const { workspace, workspacesCategories } = this.props;

        let categoryOrders = [];
        let categories = workspacesCategories.filter((item) => item.category_title !== "");
        for (let i in categories) {
            let category = categories[i];
            categoryOrders.push({
                index: category.order,
                id: category.category_id
            });
        }
        this.props.updateWorkspacesCategoriesOrder(workspace.id, categoryOrders);
    }

    removeCategory() {
        const { workspace, category, role } = this.props;
        const isAdmin = (role.user_role.toLowerCase() === 'administrator') ? true : false;

        this.props.deleteWorkspaceCategory(workspace.id, category.category_id, "", this.props.history, isAdmin);
    }

    render() {
        const { workspace, category, role } = this.props;
        return (
            <div>
                {(category.count > 0) ?
                    <div>
                        <div className="row" draggable={category.category_title !== "" && category.is_workspace_category} style={{ cursor: 'pointer' }} onDragStart={e => this.onCategoryHeaderSelect(e, category.order)} onDragOver={(e) => this.onCategoryHeaderDragOver(e, category.order)} onDragLeave={(e) => this.onCategoryHeaderDragLeave(e)} onDrop={(e) => this.onCategoryHeaderDrop(e)}>
                            <div className="col-md-12">
                                <div ref={this.categoryHeaderDrop} className="category-header mb-3">
                                    <span className="icon-wrd-binders"></span>
                                    <h5>{category.category_title}</h5>
                                    {(!category.is_workspace_category) &&
                                        <div className="portal-Category"><span>Portal Category</span></div>
                                    }
                                    {(category.is_workspace_category) &&
                                        <div>
                                            <Link to={routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/categories/" + category.category_title.toLowerCase().replaceAll(" ", "+")} className="edit-Category mr-2">Edit</Link>
                                            <Link to="#" onClick={() => { this.removeCategory(); return false; }} className="remove-Category">Remove</Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div onDrop={(e) => this.onDrop(e, true)} onDragOver={(e) => this.onDragOver(e, true)} onDragLeave={(e) => this.onDragLeave(e, true)}>
                            <div ref={this.categoryDrop} className="drop-area-app mb-4">
                                <div className="row">
                                    {category.applications.map((application, index) => (
                                        (application.is_enabled_global) &&
                                        <div className="col-3" draggable={application.is_linked} key={application.id} onDragOver={(e) => this.onCategoryItemDragOver(e, index, true)}>
                                            <WorkspacesApplicationsItem role={role} item={application} onSelect={this.onCategoryItemSelect} onDrop={(e) => this.onCategoryItemDrop(e, true)} setDragInfoState={this.props.setDragInfoState} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className="row" draggable={category.category_title !== "" && category.is_workspace_category} style={{ cursor: 'pointer' }} onDragStart={e => this.onCategoryHeaderSelect(e, category.order)} onDragOver={(e) => this.onCategoryHeaderDragOver(e, category.order)} onDragLeave={(e) => this.onCategoryHeaderDragLeave(e)} onDrop={(e) => this.onCategoryHeaderDrop(e)}>
                            <div className="col-md-12">
                                <div ref={this.categoryHeaderDrop} className="category-header mb-3">
                                    <span className="icon-wrd-binders"></span>
                                    <h5>{category.category_title}</h5>
                                    {(!category.is_workspace_category) &&
                                        <div className="portal-Category"><span>Portal Category</span></div>
                                    }
                                    {(category.is_workspace_category) &&
                                        <div>
                                            <Link to={routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/categories/" + category.category_title.toLowerCase().replaceAll(" ", "+")} className="edit-Category mr-2">Edit</Link>
                                            <Link to="#" onClick={() => { this.removeCategory(); return false; }} className="remove-Category">Remove</Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div ref={this.categoryDrop} className="drop-area mb-4" onDrop={(e) => this.onDrop(e, false)} onDragOver={(e) => this.onDragOver(e, false)} onDragLeave={(e) => this.onDragLeave(e, false)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <p>No available app(s) in this category, drag and drop an app here to add to the category</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateWpAppsOrder: (workspacesId, apps) => dispatch(updateWpAppsOrderWithThunk(workspacesId, apps)),
    dispatchWorkspacesApplications: (apps) => dispatch(getWorkspacesApplicationsAction(apps)),
    dispatchWorkspacesCategories: (workspaceCategories) => dispatch(getWorkspacesCategoriesAction(workspaceCategories)),
    updateWorkspaceApplicationCategory: (workspaceId, applicationId, categoryId, isWorkspaceCategory, isAdmin) => dispatch(updateWorkspaceApplicationCategoryWithThunk(workspaceId, applicationId, categoryId, isWorkspaceCategory, isAdmin)),
    updateWorkspacesCategoriesOrder: (workspaceId, categoryOrders) => dispatch(updateWorkspacesCategoriesOrderWithThunk(workspaceId, categoryOrders)),
    deleteWorkspaceCategory: (workspaceId, categoryId, routeBackTo, historyProps, isAdmin) => dispatch(deleteWorkspaceCategoryWithThunk(workspaceId, categoryId, routeBackTo, historyProps, isAdmin))
});

export default withRouter(connect(null, mapDispatchToProps)(WorkspacesApplicationsCategory));
