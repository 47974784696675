import {
    UPDATE_STICKY_WORKSPACES,
    UPDATE_STICKY_WORKSPACES_MEMBERS,
    UPDATE_STICKY_WORKSPACES_FOCUS_AREAS,
    UPDATE_STICKY_USERS,
    UPDATE_STICKY_APPLICATIONS_SETTINGS
} from '../actions/index';

const initialStickyState = {
    workspaces: {
        workspace_id: '',
        show_action: false
    },
    members: {
        user_id: '',
        show_action: false
    },
    areas: {
        area_id: '',
        show_action: false
    },
    users: {
        user_id: '',
        show_action: false
    },
    settings: {
        setting_id: '',
        show_action: false
    }
}

export const sticky = (state = initialStickyState, action) => {
    const { workspaces, members, areas, users, settings } = action

    switch (action.type) {
        case UPDATE_STICKY_WORKSPACES:
            return {
                ...state,
                workspaces
            }
        case UPDATE_STICKY_WORKSPACES_MEMBERS:
            return {
                ...state,
                members
            }
        case UPDATE_STICKY_WORKSPACES_FOCUS_AREAS:
            return {
                ...state,
                areas
            }
        case UPDATE_STICKY_USERS:
            return {
                ...state,
                users
            }
        case UPDATE_STICKY_APPLICATIONS_SETTINGS:
            return {
                ...state,
                settings
            }
        default:
            return state;
    }
};