import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { routeCodes } from '../../../configurations/routes';
import { userLoginWithThunk } from '../../../api/loginThunk';
import { updateLoginAction } from '../../../actions/updateLoginAction';
import { encryptPassword, checkPassword } from '../../../utils/password';
import { OidcProvider, OidcSecure } from '@axa-fr/react-oidc';

const oidcConfig = {
  client_id: 'LocalWaterToolsPortal',
  authority: 'https://login.mike-cloud-dev.com',
  redirect_uri: window.location.origin + '/authentication/callback',
  response_type: 'code',
  scope: 'openid offline_access',
  service_worker_only: false,
  // service_worker_relative_url: '/OidcServiceWorker.js',
};

function UsersLoginBody(props) {
  const [password_validation, setPasswordValidation] = React.useState('');
  const [autofill, setAutofill] = React.useState(-1);

  const login = async () => {
    const { login } = props;
    await props.userLogin(
      login.user_name,
      encryptPassword(login.password),
      props.history
    );
  };

  const enterLogin = (e) => {
    if (e.key === 'Enter') {
      login();
    }
  };

  const handleChangeUserName = (e) => {
    const login = {
      ...props.login,
      user_name: e.target.value,
    };

    props.dispatchLogin(login);
    if (e.target.value === '') {
      setAutofill(0);
    } else {
      setAutofill(1);
    }
  };

  const handleChangePassword = (e) => {
    const login = {
      ...props.login,
      password: e.target.value,
    };
    let passwordCheck = checkPassword(login.password);
    if (passwordCheck === 'OK') {
      setPasswordValidation('');
    } else {
      setPasswordValidation(passwordCheck);
    }

    props.dispatchLogin(login);
    if (e.target.value === '') {
      setAutofill(0);
    } else {
      setAutofill(1);
    }
  };

  // const backButton = () => {
  //     props.history.push(routeCodes.HOME);
  // };

  React.useEffect(() => {
    setTimeout(() => {
      var autofilled = document.querySelectorAll('input:-webkit-autofill');
      if (autofilled.length > 0) {
        setAutofill(1);
      } else {
        setAutofill(0);
      }
    }, 500);
  }, []);

  const { portalLogin, role, loading } = props;

  const [isWrapped, setIsWrapped] = React.useState(false);

  const handleWrapClick = () => {
    setIsWrapped(true);
  };

  return (
    <div className="h-100 w-100 d-flex align-items-center justify-content-center">
      {portalLogin.login_background_selected === 'NoBackground' ? (
        <div
          style={{ backgroundColor: portalLogin.login_background_color }}
          className="crop-welcome1820"
        ></div>
      ) : (
        <img
          src={'data:image/png;base64,' + portalLogin.login_background_image}
          className="crop-welcome1820"
          alt=""
        />
      )}
      <div className="w-100 p-4 text-center">
        {(loading ||
          (role.is_logged_in &&
            role.user_id !== '' &&
            role.is_processing_login)) && (
          <div className="loader-container">
            <Loader
              type="BallTriangle"
              color="#00BFFF"
              height="100"
              width="100"
            />
          </div>
        )}

        <div className="row" onKeyPress={enterLogin}>
          <div className="formWrap col-10 offset-1 col-md-8 offset-md-2">
            <h2>Login</h2>
            <p className="mb-5">Welcome, please login to your account.</p>
            <div className="inputForm">
              <input
                className="form-control"
                type="text"
                name=""
                onChange={handleChangeUserName}
                required
              />
              <label style={{ display: autofill !== 0 ? 'none' : 'block' }}>
                Username
              </label>
            </div>
            <div className="inputForm">
              <input
                className="form-control"
                type="password"
                name=""
                onChange={handleChangePassword}
                required
              />
              <label style={{ display: autofill !== 0 ? 'none' : 'block' }}>
                Password
              </label>
              <span className="password-validation">{password_validation}</span>
            </div>
            <div className="row mt-5">
              <div className="col-6 positionRemember">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="endDate"
                  />
                  <label
                    className="custom-control-label pl-2"
                    htmlFor="endDate"
                  >
                    Remember me
                  </label>
                </div>
              </div>
              <div className="col-6 positionForget">
                <Link
                  to={
                    routeCodes.USERS_RESET_PASSWORD +
                    '/' +
                    portalLogin.workspace_name
                  }
                >
                  Forgot Password?
                </Link>
              </div>
            </div>
            {!isWrapped && (
              <button
                className="btn textBtn updateBtn mt-5 w-50 loginBtn"
                onClick={handleWrapClick}
              >
                OpenId
              </button>
            )}
            {isWrapped && (
              <OidcProvider configuration={oidcConfig}>
                <OidcSecure>{/* <Page1 /> */}</OidcSecure>
              </OidcProvider>
            )}
            <button
              className="btn textBtn updateBtn greenBtn mt-5 w-50 loginBtn"
              onClick={login}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

UsersLoginBody.propTypes = {
  login: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  userLogin: PropTypes.func.isRequired,
  dispatchLogin: PropTypes.func.isRequired,
  portalLogin: PropTypes.object.isRequired,
};

const mapStateToProps = ({ role, detail, loading, portalLogin }) => ({
  role,
  login: detail.login,
  loading: loading.login.loading,
  portalLogin: portalLogin.portal_login,
});

const mapDispatchToProps = (dispatch) => ({
  userLogin: (userName, password, historyProps) =>
    dispatch(userLoginWithThunk(userName, password, historyProps)),
  dispatchLogin: (login) => dispatch(updateLoginAction(login)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UsersLoginBody)
);
