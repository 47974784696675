import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getWorkspaceApplicationByIdWithThunk, getWorkspaceApplicationPropertiesWithThunk } from '../../../../api/workspacesApplicationsThunk';
import Loader from 'react-loader-spinner';
import { getUrlWorkspaceId, getUrlWorkspaceApplicationId } from '../../../../utils/url';
import { getSessionStorage } from '../../../../utils/sessionStorage';
import SidebarContainer from '../../../Sidebar/SidebarContainer';
import WorkspacesApplicationsDetailHeader from './WorkspacesApplicationsDetailHeader';
import WorkspacesApplicationsDetailBody from './WorkspacesApplicationsDetailBody';
import WorkspacesApplicationsDetailModal from '../Modal/WorkspacesApplicationsDetailModal';
import { clearWorkspaceApplicationAction } from '../../../../actions/clearWorkspaceApplicationAction';
import { clearWorkspaceApplicationConnectionsAction } from '../../../../actions/clearWorkspaceApplicationConnectionsAction';
import WorkspacesApplicationsUpdateErrorModal from '../Modal/WorkspacesApplicationsUpdateErrorModal';

class WorkspacesApplicationsDetailContainer extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        workspaceApplication: PropTypes.object.isRequired,
        getWorkspaceApplicationById: PropTypes.func.isRequired,
        getWorkspaceApplicationProperties: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        clearWorkspaceApplication: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired
    };

    getCurrentWorkspaceId = () => {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    getCurrentApplicationId = () => {
        let id = getUrlWorkspaceApplicationId(this.props.location.pathname);
        return id;
    }

    componentDidMount() {
        const { workspace, role, loading } = this.props;

        if (!loading) {
            const workspaceId = this.getCurrentWorkspaceId();
            const applicationId = this.getCurrentApplicationId();

            if (workspaceId !== workspace.workspace_name.toLowerCase().replaceAll(" ", "+")) {
                let userId = role.user_id;
                if (userId === "") {
                    const sessionRole = getSessionStorage("role");

                    if (sessionRole !== "") {
                        const role = JSON.parse(sessionRole);
                        userId = role.user_id;
                    }
                }
                this.props.getWorkspaceApplicationById(workspaceId, applicationId, userId);
            }
            else {
                this.props.getWorkspaceApplicationProperties(workspaceId, applicationId);
            }
        }
    }

    render() {
        const { workspaceApplication, loading, loadingTheme } = this.props;
        if (workspaceApplication.workspace_id === undefined || workspaceApplication.workspace_id === '' ||
            (workspaceApplication.application_id === undefined || workspaceApplication.application_id === '') ||
            workspaceApplication.connections === undefined)
            return (
                <div>
                    {(loading || loadingTheme) &&
                        <div className="loader-container">
                            <Loader
                                type="BallTriangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            );

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="BallTriangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <WorkspacesApplicationsDetailHeader />
                                    <WorkspacesApplicationsDetailBody />
                                </div>
                            </div>
                        </main>
                        <WorkspacesApplicationsDetailModal type="detail" />
                        <WorkspacesApplicationsUpdateErrorModal type="error" />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, loading, role }) => ({
    workspace: detail.workspace,
    workspaceApplication: detail.workspace_application,
    loading: loading.workspace_application.loading,
    loadingTheme: loading.theme.loading,
    role
});

const mapDispatchToProps = (dispatch) => ({
    getWorkspaceApplicationById: (workspaceId, applicationId, userId) => dispatch(getWorkspaceApplicationByIdWithThunk(workspaceId, applicationId, userId)),
    getWorkspaceApplicationProperties: (workspaceId, applicationId) => dispatch(getWorkspaceApplicationPropertiesWithThunk(workspaceId, applicationId)),
    clearWorkspaceApplication: () => dispatch(clearWorkspaceApplicationAction([])),
    clearWorkspaceApplicationConnections: () => dispatch(clearWorkspaceApplicationConnectionsAction([]))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsDetailContainer));
