import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import yaml from 'yaml'

import { updateWorkspaceApplicationAction } from '../../../../actions/updateWorkspaceApplicationAction';
import { updateWorkspaceApplicationWithThunk } from '../../../../api/workspacesApplicationsThunk';
import WorkspacesApplicationsUpdateErrorModal from '../Modal/WorkspacesApplicationsUpdateErrorModal';
import WorkspacesApplicationsDetailModal from '../Modal/WorkspacesApplicationsDetailModal';
import '../../../../css/codeEditor.css'

class WorkspacesApplicationsYmlSettingsContainer extends Component {
    constructor(props) {
        super(props);
        this.updateWorkspaceApplicationDetail = this.updateWorkspaceApplicationDetail.bind(this);
        this.onConfigurationChange = this.onConfigurationChange.bind(this);
        this.state = {
            placeholder: '\nkey1: value1\nkey2:\n - item1\n - item2',
            errorMessage: '',
            ymlValue: this.props.workspaceApplication.yml_configuration,
            lines: this.props.workspaceApplication.yml_configuration?.split('\n') ?? Array.from({ length: 10 }, () => ''),
        };
        this.textareaRef = React.createRef();
    };

    static propTypes = {
        workspaceApplication: PropTypes.object.isRequired,
    };

    onConfigurationChange(e) {      
        const workspaceApplication = {
            ...this.props.workspaceApplication,
            yml_configuration: e.target.value,
        };
        
        this.props.dispatchWorkspaceApplication(workspaceApplication);
        this.setState({errorMessage: '', ymlValue: this.textareaRef.current.value})
    };

    updateWorkspaceApplicationDetail() {
        try {
            yaml.parse(this.state.ymlValue)
            
            const { workspaceApplication } = this.props;
            
            this.props.updateWorkspaceApplication(
                workspaceApplication,
                false,
                this.props.history
            );
        } catch (error) {
            this.setState({errorMessage: 'invalid yml configuration'})
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.ymlValue !== this.state.ymlValue) this.updateLineNumbers()
    }

    updateLineNumbers = () => {
        this.state.lines = this.state.ymlValue.split('\n');
    };
    
    render() {
        const { workspaceApplication, loading } = this.props;
        const { errorMessage, ymlValue, lines } = this.state;

        return (
            <div className="mainBody">
                <div className="row mt-5">
                    <div className="col-md-5 col-lg-4 col-xl-3">
                        <div className="wsLogo pb-5 text-center">
                            <div className="logoContainer wsHome text-center">
                                <img src={process.env.PUBLIC_URL + "/images/transCircle.png"} className="insideCircle" alt="" />
                                <img src={((workspaceApplication.url_image !== undefined) ? "data:image/png;base64," : "") + workspaceApplication.url_image}
                                    className="crop-logo43" alt="" />
                            </div>
                        </div>
                        <div className='text-center'>
                            <button
                                className="btn mt-4 textBtn greenBtn"
                                onClick={() => this.updateWorkspaceApplicationDetail()}
                                disabled={errorMessage || loading}
                            >
                                Update Changes
                            </button>
                        </div>
                    </div>
                    <div className="formWrap col-8">
                        <div className="inputForm mb-5 code-editor-container" style={{ minHeight: '250px' }}>
                            <div className="line-numbers">
                                {lines.map((_, index) => (
                                    <div className='numbers'>{index}</div>
                                ))}
                            </div>
                            <textarea
                                ref={this.textareaRef}
                                className={`code-editor ${errorMessage && 'border-danger text-danger'}`}
                                value={ymlValue}
                                onChange={this.onConfigurationChange}
                                rows="10"
                                cols="100"
                                spellCheck="false"
                            >                               
                            </textarea>
                            <label style={{ marginBottom: '25px' }}>YML Configuration</label>
                        </div>
                        <p className='text-danger'>{errorMessage}</p>
                    </div>
                </div>
                <WorkspacesApplicationsDetailModal type="detail" />
                <WorkspacesApplicationsUpdateErrorModal type="error" />
            </div>
        );
    }
}

const mapStateToProps = ({ detail, loading }) => ({
    workspaceApplication: detail.workspace_application,
    loading: loading.workspace_application.loading,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchWorkspaceApplication: (workspaceApplication) =>
      dispatch(updateWorkspaceApplicationAction(workspaceApplication)),
    updateWorkspaceApplication: (workspaceApplication, bypassUrl, historyProps) =>
      dispatch(
        updateWorkspaceApplicationWithThunk(
          workspaceApplication,
          bypassUrl,
          historyProps
        )),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsYmlSettingsContainer));
