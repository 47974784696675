import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { routeCodes } from '../../../configurations/routes';
import { updateDialogWorkspacesCreationErrorAction } from '../../../actions/updateDialogWorkspacesCreationErrorAction';
import { updateWorkspaceAction } from '../../../actions/updateWorkspaceAction';
import { updateWorkspaceWithThunk } from '../../../api/workspacesThunk';
import { encodeToken } from '../../../utils/token';
import { store } from '../../../index';

class WorkspacesCreationErrorModal extends Component {
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
    }

    static propTypes = {
        type: PropTypes.string.isRequired,
        dialog: PropTypes.object.isRequired,
        dispatchDialogWorkspacesCreationError: PropTypes.func.isRequired,
        navigateTo: PropTypes.func
    };

    setDefaultDialogState() {
        let workspaces_creation = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            show_dialog: false
        };

        this.props.dispatchDialogWorkspacesCreationError(workspaces_creation);
    }

    closeModal() {
        const { dialog, workspace } = this.props;
        this.setDefaultDialogState();

        if (dialog.status !== null) {
            if (dialog.status === 200 && dialog.dialog_title === 'Creation Success') {
                this.props.history.push(dialog.name);
            }
            else if (dialog.status === 200 && dialog.dialog_title === 'Update Success') {
                if (dialog.domain_url !== "" && dialog.workspace_name !== "") {
                    const role = JSON.stringify(store.getState().role);
                    const token = encodeToken(role);
                    const url = window.location.protocol + "//" + dialog.domain_url + routeCodes.WORKSPACES_LIST + "/" + dialog.workspace_name + "/properties?role=" + token;
                    this.props.navigateTo(url);
                }
            }
        }
        else {
            let dialogInfo = dialog.dialog_info;
            workspace.temp_workspace_name = dialogInfo.workspace_name;
            this.props.updateWorkspaceName(workspace);

            let workspaceName = document.getElementById("detail-workspace-name");
            if (workspaceName !== null) {
                workspaceName.value = dialogInfo.workspace_name;
            }
        }
    }

    updateWorkspace() {
        const { dialog } = this.props;
        this.setDefaultDialogState();

        let workspace = dialog.dialog_info;
        workspace.workspace_name = workspace.temp_workspace_name;
        this.props.updateWorkspace(workspace, this.props.history);
    }

    componentDidUpdate(nextProps) {
        if (nextProps.dialog.show_dialog) {
            this.textInput.current.focus();
        }
    }

    render() {
        const { dialog } = this.props;
        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{dialog.dialog_title}</h2>
                            <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody">
                        {(dialog.status !== null) ?
                            <div className="p-4">
                                <div className="summernote">
                                    {(dialog.status === 200) ?
                                        <p>
                                            <b className="actionEnable">Success:</b> {dialog.dialog_type}
                                        </p>
                                        :
                                        <p>
                                            <b className="actionRemove">ERROR:</b> {dialog.dialog_type}
                                        </p>
                                    }
                                </div>
                            </div>
                            :
                            <div className="p-4">
                                <div className="summernote">
                                    <p>
                                        {dialog.dialog_type}
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="popupFooter">
                        {(dialog.status !== null) ?
                            <div className="px-4 pt-4 pb-2">
                                <div className="row">
                                    <div className="col text-right">
                                        {(dialog.status === 200) ?
                                            <button ref={this.textInput} className="greenBtn btn icoTextBtn mr-3 closePopup" onClick={() => { this.closeModal(); }}>
                                                Close
                                            </button>
                                            :
                                            <button ref={this.textInput} className="greyBtn btn icoTextBtn mr-3 closePopup" onClick={() => { this.closeModal(); }}>
                                                Close
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="px-4 pt-4 pb-2">
                                <div className="row">
                                    <div className="col">
                                        <button className="btn greyBtn icoTextBtn closePopup width-100px" onClick={() => { this.closeModal(); }}>
                                            Cancel
                                        </button>
                                    </div>
                                    <div className="col text-right">
                                        <button ref={this.textInput} className="greenBtn btn icoTextBtn mr-3 closePopup width-100px" onClick={() => { this.updateWorkspace(); }}>
                                            Yes
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, dialog }) => ({
    dialog: dialog.workspace_creation_error,
    workspace: detail.workspace
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogWorkspacesCreationError: (dialog) => dispatch(updateDialogWorkspacesCreationErrorAction(dialog)),
    updateWorkspace: (workspace, historyProps) => dispatch(updateWorkspaceWithThunk(workspace, historyProps)),
    updateWorkspaceName: (workspace) => dispatch(updateWorkspaceAction(workspace)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesCreationErrorModal));
