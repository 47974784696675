import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setupNewApplicationAction } from '../../../actions/setupNewApplicationAction'
import Modal from 'react-modal';
import ImageEditor from '../../Editor/ImageEditor';
import { modalImageEditorStyle } from '../../../utils/modal';

class ApplicationCreateLogo extends Component {
    constructor(props) {
        super(props);
        this.onSelectFile = this.onSelectFile.bind(this);
        this.onCloseImageEditor = this.onCloseImageEditor.bind(this);
    }

    static propTypes = {
        application: PropTypes.object.isRequired,
        setupNewApplication: PropTypes.func.isRequired
    };

    state = {
        src: null,
        imageEditorIsOpen: false,
        fileName: null
    };

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const name = e.target.files[0].name;
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ src: reader.result, imageEditorIsOpen: true, fileName: name });
            }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onCloseImageEditor(image, aspectRatio) {
        this.setState({ imageEditorIsOpen: false });

        const application = {
            ...this.props.application,
            url_image: image,
            image_aspect_ratio: aspectRatio
        };

        this.props.setupNewApplication(application);
    }

    getAspectRatioClass() {
        const { image_aspect_ratio } = this.props.application;

        let aspectRatioClass = 'crop-logo43';
        if (image_aspect_ratio >= 1 && image_aspect_ratio < 1.2) {
            aspectRatioClass = 'crop-logo11';
        }
        else if (image_aspect_ratio >= 1.2 && image_aspect_ratio < 1.4) {
            aspectRatioClass = 'crop-logo43';
        }
        else if (image_aspect_ratio >= 1.4 && image_aspect_ratio < 1.6) {
            aspectRatioClass = 'crop-logo32';
        }
        else if (image_aspect_ratio >= 1.6 && image_aspect_ratio < 2.3) {
            aspectRatioClass = 'crop-logo169';
        }
        else if (image_aspect_ratio >= 2.3) {
            aspectRatioClass = 'crop-logo219';
        }

        return aspectRatioClass;
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
        const { src, imageEditorIsOpen, fileName } = this.state;
        const { application } = this.props;

        return (
            <div className="col-lg-4 col-xl-3">
                <div className="pt-4">
                    <label>Logo</label>
                    <div className="wsOwner pb-5 text-center">
                        <div className="logoContainer text-center">
                            <img src={process.env.PUBLIC_URL + "/images/transCircle.png"} className="insideCircle" alt="" />
                            {<img src={((application.url_image !== '') ? "data:image/png;base64," + application.url_image : application.default_url_image)} className={this.getAspectRatioClass()} alt="" />
                            }
                        </div>
                        <button className="btn btn-wrd_default icoTextBtn browseLogo">
                            <label className="custom-file-upload">
                                <input type="file" onChange={this.onSelectFile} />
                                <span className="icon-wrd-folder icon-upload-folder"></span>
                                Browse
                            </label>
                        </button>
                        <span className="d-block mt-4 font-italic text-greyLight">{(fileName !== null) ? fileName : "Browse to change Logo"}</span>
                    </div>
                </div>
                {src &&
                    <Modal
                        isOpen={imageEditorIsOpen}
                        style={modalImageEditorStyle}
                        contentLabel="Image Editor"
                    >
                        <ImageEditor srcImage={src} onClose={this.onCloseImageEditor} />
                    </Modal>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ setup }) => ({
    application: setup.application
});

const mapDispatchToProps = (dispatch) => ({
    setupNewApplication: (item) => dispatch(setupNewApplicationAction(item))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationCreateLogo));