import {    
    GET_USER_ACCESS,
    UPDATE_USER_ACCESS,
    CLEAR_USER_ACCESS,
} from '../actions/index';

const initialDetailState = {    
    userAccess: {}
}

export const userAccess = (state = initialDetailState, action) => {    
    const { userAccess } = action
    switch (action.type) {               
        case GET_USER_ACCESS:
            return {                      
                user: userAccess.user,
                access: userAccess.access
            }    
        case UPDATE_USER_ACCESS:
            return {
                user: userAccess.user,
                access: userAccess.access
            }     
        case CLEAR_USER_ACCESS:
            return {
                userAccess: initialDetailState.userAccess
            } 
        default:
            return state;
    }
};