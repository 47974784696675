import { styled } from './utils-style';

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  box-sizing: border-box;
`;

export const StyledCol = styled.div<{ grow?: boolean; bg?: string }>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  ${({ grow }) => (grow ? 'flex-grow: 1;' : '')}
  ${({ bg }) => (bg ? 'background:' + bg + ';' : '')}
`;