import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { routeCodes } from '../../../../configurations/routes';

class WorkspacesThemeSettingsHeader extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired
    };

    render() {
        const { workspace, role } = this.props;

        return (
            <div className="mainHead">
                <div className="row">
                    <div className="col-12">
                        <h2 className="float-left mt-2 mr-4">Workspace Settings</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="workspaceInfo">
                            <div className="row">
                                <div className="workspaceName col-xl-7 offset-xl-3">
                                    <div className="float-left">
                                        <h3 className="mr-4">{workspace.workspace_name}</h3>
                                    </div>
                                </div>
                                <div className="userRole col-xl-7 offset-xl-3">{(role.user_role === 'Administrator') ? "Administrator" : "Workspace Owner"}</div>
                            </div>
                            <nav className="col-lg-5 col-xl-4">
                                <div className="row text-center">
                                    <div className="col px-0">
                                        <Link
                                            to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/properties"}
                                            title="Workspace"
                                            className="workspaceMenu py-3">Workspace
                                        </Link>
                                    </div>
                                    <div className="col px-0">
                                        <Link
                                            to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/applications'}
                                            title="Workspace Applications"
                                            className="workspaceMenu py-3">Applications
                                        </Link>
                                    </div>
                                    <div className="col px-0">
                                        <Link
                                            to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/members'}
                                            title="Workspace Members"
                                            className="workspaceMenu py-3">Members
                                        </Link>
                                    </div>
                                    <div className="col px-0">
                                        <Link
                                            to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/settings/general'}
                                            title="Workspace Settings"
                                            className="workspaceMenu py-3 active">Settings
                                        </Link>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role }) => ({
    workspace: detail.workspace,
    role
});

export default withRouter(connect(mapStateToProps)(WorkspacesThemeSettingsHeader));
