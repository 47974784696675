import { format } from 'date-fns';

export interface LegendLabelProps {
  name: string;
}

export interface LineStyleProps {
  color: string;
  width: number;
}
export interface SeriesProps {
  type: 'line';
  data: any;
  showSymbol: false;
  name: string;
  lineStyle: LineStyleProps;
  yAxisIndex: number;
}

export interface yAxisProps {
  type: 'value';
  name: string;
  nameLocation?: string;
  nameGap?: number;
  nameRotate?: number;
  nameTextStyle?: {
    align: string;
    verticalAlign: string;
    padding: number[];
    color: string;
    fontWeight: string;
  };
}

export interface PlotProps {
  title?: string;
  yAxis: yAxisProps[];
  series: SeriesProps[];
}

interface YAxisEcharts {
  name: string;
}

export interface OptionsToContentProps {
  series: any[];
  yAxis: YAxisEcharts[];
}

const gridLabels = { top: '55', left: '5%', right: '5%', bottom: '5' };

const xAxisDateAsString = (date: Date) => {
  return `${format(date, 'yyyy')}\n${format(date, 'MM-dd')}`;
};

const excision = ', ';

const getPlot = ({ yAxis, series, title }: PlotProps) => {
  const color = series.map((item) => item.lineStyle.color);
  const legenddata = series.map(({ name }) => ({ name }));
  const option = {
    title: {
      text: title,
      left: '5%',
      textStyle: {
        fontSize: 18,
      },
    },
    color,
    legend: {
      data: legenddata,
      padding: 7,
    },
    xAxis: {
      type: 'time',
      axisLabel: {
        formatter(value: string | number | Date) {
          const date = new Date(value);
          return xAxisDateAsString(date);
        },
      },
    },
    yAxis,
    grid: gridLabels,
    tooltip: {
      show: true,
      trigger: 'axis',
    },
    toolbox: {
      top: '2.5%',
      right: '5%',
      zlevel: 9999,
      feature: {
        dataZoom: {},
        dataView: {
          optionToContent: (props: OptionsToContentProps) => {
            const { series: optSeries, yAxis } = props;
            const tableHeaders: string[] = [];
            const units: string[] = [];
            const timeSteps = new Set<string>();
            units.push('Time');
            const list = optSeries
              .filter(({ data: isData }) => isData)
              .reduce((all, now) => {
                const { data: seriesData, name } = now;
                const itemName = name.replace('\n', ' ');
                units.push(yAxis[0].name);
                tableHeaders.push(itemName);
                const dataObj = seriesData.reduce((acc: any, cur: any) => {
                  const [date, value] = cur;
                  timeSteps.add(date);
                  acc[date] = value;
                  return acc;
                }, {});
                all[itemName] = dataObj;
                return all;
              }, {});
            const timestepsArray = Array.from(timeSteps).sort();
            let table =
              '<textarea style="width:calc(100% - 10px);height:calc(100% - 10px);overflow-x:auto;">';
            table += tableHeaders.join(excision);
            table += '&#10;';
            table += units.join(excision);
            table += '&#10;';
            table += '-------------------------------------- &#10;';
            for (let i = 0; i < timestepsArray.length; i += 1) {
              const time = timestepsArray[i];
              table += `${time}${excision}`;
              table += tableHeaders
                .map((header) => list[header][time])
                .join(excision);
              table += '&#10;';
            }
            table += '</textarea>';
            return table;
          },
        },
        restore: {},
        saveAsImage: {},
      },
    },
  };

  return { series, option };
};

export default getPlot;
