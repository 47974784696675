import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogWorkspacesMembersShareAction } from '../../../../../actions/updateDialogWorkspacesMembersShareAction';
import { createWorkspacesMembersShareWithThunk } from '../../../../../api/workspacesMembersThunk';
import Loader from 'react-loader-spinner';
import WorkspacesShareListItem from './WorkspacesShareListItem';

class WorkspacesMembersShareModal extends Component {
    constructor(props) {
        super(props);
        this.shareMembers = this.shareMembers.bind(this);
    }

    static propTypes = {
        dialog: PropTypes.object.isRequired,
        workspace: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        sorting: PropTypes.object.isRequired,
        dispatchDialogWorkspacesMembersShare: PropTypes.func.isRequired
    };

    setDefaultDialogState() {
        let workspaces_members_share = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            show_dialog: false
        };

        this.props.dispatchDialogWorkspacesMembersShare(workspaces_members_share);
    }

    closeModal() {
        this.setDefaultDialogState();
    }

    shareMembers() {
        const { workspacesMembersShare, workspacesShareList } = this.props;
        this.props.createWorkspacesMembersShare(workspacesMembersShare, workspacesShareList);
        //this.setDefaultDialogState();
    }

    render() {
        const { dialog, loading, workspacesShareList } = this.props;

        return (
            <div>
                {
                    loading &&
                    <div className="loader-available-workspace-list">
                        <Loader
                            type="BallTriangle"
                            color="#00BFFF"
                            height="70"
                            width="70"
                        />
                    </div>
                }
                <div className={"popupContainer h-100 align-items-center justify-content-center" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                    <div className={"popupPanel popupPanel-small p-4" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                        <p className="pupupName">Workspace List</p>
                        <div className="popupSearch searchInput float-left mr-2 mt-0">
                            <span className="icon-wrd-search"></span>
                            <input type="text" placeholder="Search..." />
                        </div>
                        <button className="btn resetBtn"><span
                            className="icon-wrd-reset"></span></button>
                        <div className="popupList mt-4 scroll-Share-Workspaces-List">
                            <ul>
                                {(workspacesShareList !== undefined && workspacesShareList.length > 0) ?
                                    workspacesShareList.map((item, index) => (
                                        <WorkspacesShareListItem key={item.id} item={item} />
                                    ))
                                    :
                                    'No match'
                                }
                            </ul>
                        </div>
                        <div className="mt-5">
                            <div className="row">
                                <div className="col text-left"><button
                                    className="btn greyBtn icoTextBtn"
                                    onClick={() => this.closeModal()}><span
                                        className="icon-wrd-close"></span>Cancel</button></div>
                                <div className="col text-right"><button
                                    className="btn greenBtn icoTextBtn"
                                    onClick={() => this.shareMembers()}><span
                                        className="icon-wrd-share"></span>
                                    Share</button></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ dialog, pagination, detail, sorting, workspacesShareList, loading, workspacesMembersShare }) => ({
    dialog: dialog.workspaces_members_share,
    workspace: detail.workspace,
    pagination: pagination.member,
    sorting: sorting.member,
    workspacesShareList,
    loading: loading.workspaces_share_list.loading,
    workspacesMembersShare
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogWorkspacesMembersShare: (dialog) => dispatch(updateDialogWorkspacesMembersShareAction(dialog)),
    createWorkspacesMembersShare: (members, workspaces) => dispatch(createWorkspacesMembersShareWithThunk(members, workspaces))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesMembersShareModal));
