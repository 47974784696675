import { WindowProps } from '@wrd/types/index';

const { API_BASE_URL, API_BASE_URL_GATEWAY, API_BASE_URL_PY } = window as any;

export const getBaseUrls = () => {
  const api_base_url =
    API_BASE_URL === 'DefaultBaseURL'
      ? 'https://waterdssapi-wrd-test.eu.mike-cloud-test.com'
      : API_BASE_URL;
  const api_base_url_py =
    API_BASE_URL_PY === 'DefaultBaseURLPy'
      ? 'https://wrdmainservices-wrd-test.eu.mike-cloud-test.com'
      : API_BASE_URL_PY;

  return {
    API_BASE_URL: api_base_url,
    API_BASE_URL_GATEWAY,
    API_BASE_URL_PY: api_base_url_py,
  } as WindowProps;
};

export const fetchBaseURLAPI = async () => {
  try {
    let data;

    if (window.location.hostname === 'localhost') {
      data = {
        baseURLPortal: process.env['NX_DEFAULT_BASE_URL'],
        baseURLPortalOld: process.env['NX_DEFAULT_BASE_URL_GATEWAY'],
        clientId: 'Water Tools',
        authority: 'https://login.mike-cloud-dev.com',
        baseURLPython: process.env['NX_DEFAULT_BASE_URL_PY'],
      };
    } else {
      const baseURL =
        window.location.hostname === 'localhost'
          ? process.env['NX_DEFAULT_CLIENT_BASE_URL']
          : '';
      const response = await fetch(`${baseURL}/api/baseurls`, {
        headers: {
          'Cache-Control': 'no-cache, no-store',
          Expires: '0',
          Pragma: 'no-cache',
          'X-Content-Type-Options': 'nosniff',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      data = await response.json();
    }

    if (
      data.baseURLPortal &&
      data.baseURLPortalOld &&
      data.clientId &&
      data.authority &&
      data.baseURLPython
    ) {
      sessionStorage.setItem('baseURL', data.baseURLPortal);
      sessionStorage.setItem('baseURLPython', data.baseURLPython);
      sessionStorage.setItem('oldBaseURL', data.baseURLPortalOld);
      sessionStorage.setItem('clientId', data.clientId);
      sessionStorage.setItem('authority', data.authority);
      sessionStorage.setItem('useOpenId', data.useOpenId);

      return { success: true, data };
    } else {
      throw new Error('No baseURLPortal found');
    }
  } catch (error) {
    return { success: false, data: error };
  }
};
