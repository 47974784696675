import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogUsersAction } from '../../../actions/updateDialogUsersAction';
import { updateStickyUserStatusWithThunk } from '../../../api/usersThunk';
import { removeStickyUserWithThunk } from '../../../api/usersThunk';
import { removeUserWithThunk } from '../../../api/usersThunk';
import { updateUserStatusWithThunk, updateUserAccessWorkspaceWithThunk, removeUserAccessWorkspaceWithThunk } from '../../../api/usersThunk';
import { resetPasswordAdminWithThunk } from '../../../api/resetPasswordAdminThunk';
import { routeCodes } from '../../../configurations/routes';
import { overrideWorkspaceId } from '../../../utils/workspaces';
import { getSessionStorage } from '../../../utils/sessionStorage';

class UsersModal extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        dialog: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        sorting: PropTypes.object.isRequired,
        dispatchDialogUsers: PropTypes.func.isRequired,
        updateUserStatus: PropTypes.func.isRequired,
        updateUserDetailStatus: PropTypes.func.isRequired,
        removeUser: PropTypes.func.isRequired,
        removeStickyUser: PropTypes.func.isRequired
    };

    setDefaultDialogState() {
        let users = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            show_dialog: false
        };

        this.props.dispatchDialogUsers(users);
    }

    async updateUser() {
        const { dialog, type } = this.props;

        this.setDefaultDialogState();

        if (dialog.dialog_type === 'trash') {
            let user = {
                id: dialog.id
            }

            if (type === 'list') {
                this.props.removeStickyUser(user);
            }
            else {
                this.props.removeUser(user);
                this.props.history.push(routeCodes.USERS_LIST);
            }
        }
        else if (dialog.dialog_type === 'reset') {            
            let user = {
                uid: dialog.uid,
                username: dialog.name,
                email: dialog.id
            }
            let workspaceId = await overrideWorkspaceId(dialog.status);
            this.props.resetUserPasswordAdmin(user, workspaceId);            
        }
        else {
            if (type === 'list') {
                let user = {
                    uid: dialog.id,
                    user_status: (dialog.status === 'Enabled') ? 'Disabled' : 'Enabled'
                }
                this.props.updateUserStatus(user);
            }
            else {
                if (dialog.status === 'Enabled' || dialog.status === 'Disabled') {
                    let user = {
                        ...this.props.user,
                        uid: dialog.id,
                        user_status: (this.props.user.user_status === 'Enabled') ? 'Disabled' : 'Enabled'
                    };
                    this.props.updateUserDetailStatus(user);
                }
                else if (dialog.status === 'Active' || dialog.status === 'Suspended') {
                    let targetId = dialog.id;
                    let width = Math.ceil((window.innerWidth - 560) * (3 / 12));
                    let height = width;
                    if (targetId === "") {
                        const sessionRole = getSessionStorage("role");
                        if (sessionRole !== "") {
                            const role = JSON.parse(sessionRole);
                            targetId = role.user_id;
                        }
                    }
                    this.props.updateUserAccessWorkspace(dialog.workspace_id, targetId, (dialog.status === 'Active') ? 'Suspend' : 'Active', width, height)
                }
                else if (dialog.status === 'RemoveAccess') {
                    let targetId = dialog.id;
                    let width = Math.ceil((window.innerWidth - 560) * (3 / 12));
                    let height = width;
                    if (targetId === "") {
                        const sessionRole = getSessionStorage("role");
                        if (sessionRole !== "") {
                            const role = JSON.parse(sessionRole);
                            targetId = role.user_id;
                        }
                    }
                    this.props.removeUserAccessWorkspace(dialog.workspace_id, targetId, width, height)
                }
            }
        }
    }

    closeModal() {
        this.setDefaultDialogState();
    }

    render() {
        const { dialog } = this.props;
        const dialogCommand = (dialog.dialog_type === 'reset') ? 'RESET the PASSWORD for' :
            (dialog.dialog_type === 'trash') ? 'REMOVE' :
                (dialog.status === 'Enabled') ? 'DISABLE' :
                    (dialog.status === 'Disabled') ? 'ENABLE' :
                        (dialog.status === 'Active') ? 'Suspend' :
                            (dialog.status === 'RemoveAccess' ? 'Remove Access' : '');

        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{dialog.dialog_title}</h2>
                            <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody">
                        <div className="p-4">
                            <div className="summernote">
                                <p>
                                    Are you sure you want to <b className={(dialog.dialog_type === 'trash') ? 'actionRemove' : ''}>{dialogCommand}</b> user
                                    &nbsp;<b className="appName">{dialog.name}</b> {(dialog.status === 'Active' || dialog.status === 'RemoveAccess') ? 'From ' + dialog.dialog_type : ''} &nbsp;?
                                    <br />
                                    {(dialog.dialog_type === 'trash') ? 'All data related to this user including global and user preference will no longer be available.' : ''}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-2">
                            <div className="row">
                                <div className="col">
                                    <button className="btn greyBtn icoTextBtn closePopup" onClick={() => { this.closeModal(); }}>
                                        <span className="icon-wrd-close"></span>
                                        Cancel
                                    </button>
                                </div>
                                <div className="col text-right">
                                    <button className={((dialog.dialog_type === 'trash') ? 'redBtn' : (dialog.status === 'Enabled' || dialog.status === 'Active' || dialog.status === 'RemoveAccess') ? 'redBtn' : 'greenBtn') + ' btn icoTextBtn mr-3 closePopup'}
                                        onClick={() => { this.updateUser(); }}>
                                        <span className={(dialog.dialog_type === 'reset') ? 'icon-wrd-reset' : (dialog.dialog_type === 'trash') ? "icon-wrd-trash" : (dialog.status === 'Enabled' || dialog.status === 'Active') ? "icon-wrd-lock" : (dialog.status === 'Disabled') ? "icon-wrd-unlock" : (dialog.status === 'RemoveAccess') ? 'icon-wrd-revoke' : ''}></span>
                                        {(dialog.dialog_type === 'reset') ? 'Reset' : (dialog.dialog_type === 'trash') ? 'Remove' : (dialog.status === 'Enabled') ? 'Disable' : (dialog.status === 'Disabled') ? 'Enable' : (dialog.status === 'Active') ? 'Suspend' : (dialog.status === 'RemoveAccess') ? 'Remove Access' : ''}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ dialog, pagination, detail, sorting, search }) => ({
    dialog: dialog.users,
    user: detail.user,
    pagination: pagination.user,
    sorting: sorting.user,
    search: search.user
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogUsers: (dialog) => dispatch(updateDialogUsersAction(dialog)),
    updateUserStatus: (user) => dispatch(updateStickyUserStatusWithThunk(user)),
    updateUserDetailStatus: (user) => dispatch(updateUserStatusWithThunk(user)),
    removeStickyUser: (user) => dispatch(removeStickyUserWithThunk(user)),
    removeUser: (user) => dispatch(removeUserWithThunk(user)),
    resetUserPasswordAdmin: (user, workspaceId) => dispatch(resetPasswordAdminWithThunk(user, workspaceId)),
    updateUserAccessWorkspace: (workspaceId, userId, status, width, height) => dispatch(updateUserAccessWorkspaceWithThunk(workspaceId, userId, status, width, height)),
    removeUserAccessWorkspace: (workspaceId, userId, width, height) => dispatch(removeUserAccessWorkspaceWithThunk(workspaceId, userId, width, height))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersModal));
