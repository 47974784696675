import { UPDATE_CONTACT_ADMINISTRATORS, CLEAR_CONTACT_ADMINISTRATORS } from '../actions/index';

const initialContactAdministratorsState = {
    subject: '',
    emailContent: ''
}

export const contactAdministrators = (state = initialContactAdministratorsState, action) => {
    const { contact_administrators } = action

    switch (action.type) {
        case UPDATE_CONTACT_ADMINISTRATORS:
            return contact_administrators
        case CLEAR_CONTACT_ADMINISTRATORS:
            return initialContactAdministratorsState
        default:
            return state;
    }
};