import { ThemeProvider as Provider } from '@mui/material/styles';

import { ThemeProviderProps } from './types';
import { DHIThemeV5 } from './libs/shared-theme-styles-v5';
import { CssBaseline } from '@mui/material';

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return (
    <Provider theme={DHIThemeV5}>
      <CssBaseline />
      {children}
    </Provider>
  );
};

export default ThemeProvider;
