import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateUserAccessAction } from '../../../actions/updateUserAccessAction';
import { routeCodes } from '../../../configurations/routes';

class UserAccessApplicationsItem extends Component {
	constructor(props) {
		super(props);
		this.handleLockApplicationChange = this.handleLockApplicationChange.bind(this);
		this.handleApplicationSettings = this.handleApplicationSettings.bind(this);
		this.onClickApp = this.onClickApp.bind(this);
	}

	static propTypes = {
		application: PropTypes.object.isRequired,
		workspace: PropTypes.object.isRequired,
		role: PropTypes.object.isRequired
	};

	state = {
		flip: false
	}

	onMouseEnter() {
		this.setState({ flip: true });
	}

	onMouseLeave() {
		this.setState({ flip: false });
	}

	onClickApp() {
		const { application, workspace } = this.props;
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspace.name.toLowerCase().replaceAll(" ", "+") + "/applications/" + application.application_name.toLowerCase().replaceAll(" ", "+"));
	}

	handleLockApplicationChange() {
		const { application, workspace, userAccess } = this.props;

		let workspaces = userAccess.access;
		let wsIndex = workspaces.findIndex(item => item.id === workspace.id);
		workspaces[wsIndex].edited = true;

		let applications = workspaces[wsIndex].applications;
		let appIndex = applications.findIndex(item => item.id === application.id);

		workspaces[wsIndex].applications[appIndex].allow_access = !application.allow_access;
		let newUserAccess = {
			...userAccess,
			access: workspaces
		};

		this.props.dispatchUserAccess(newUserAccess);
	}

	handleApplicationSettings() {
		const { application, workspace, userAccess } = this.props;
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspace.name.toLowerCase().replaceAll(" ", "+") + '/applications/' + application.application_name.toLowerCase().replaceAll(" ", "+") + '/users/' + userAccess.user.id.toLowerCase() + '/settings');
	}

	render() {
		const { flip } = this.state;
		const { application } = this.props;
		return (
			<div className="col-6 col-sm-4">
				<div className="appPanel enable active" onMouseEnter={() => { this.onMouseEnter(); }} onMouseLeave={() => { this.onMouseLeave(); }}>
					<div className="appLogo"><img
						src={"data:image/jpeg;base64," + application.application_image} alt='' /></div>
					<div
						className="appName d-flex align-items-center justify-content-center">
						<span>{application.application_name}</span>
					</div>
					<div className={(application.allow_access ? "activeLabel" : "activeLabel status-disabled-important")}><span
						className={(application.allow_access) ? "icon-wrd-unlock" : "icon-wrd-lock"}></span></div>
					<span className="activeLabel inactive-lock-label">{(application.global_status === 'Disabled') ? 'Disabled' : (application.status === 'Disabled') ? 'Suspended' : null}</span>
					<div className={"flipInfo animated " + ((flip) ? "flipInY" : "d-none flipOutY")}>
						<div className="position-relative h-100 w-100">
							<div className="detailLink" onClick={this.handleApplicationSettings}>
								<div className="bodyTooltip">
									<h3>{application.application_name}</h3>
									<p>
										<b>Title</b>
										<span className="text">{application.application_title}</span>
									</p>
									<p>
										<b>Keywords</b>
										{(application.keywords !== undefined && application.keywords.length > 0) ?
											application.keywords.map((keyword, index) => (
												<span key={index} className="badge badge-pill">{keyword}</span>
											))
											: null
										}

									</p>
								</div>
							</div>
							<div className="actTooltip">
								<div className="container-fluid">
									<div className="row">
										<div className="col p-0 text-center" onClick={this.handleLockApplicationChange} title={(application.allow_access) ? 'Revoke' : 'Grant'}><button
											className={(application.allow_access) ? "btn iconBtn popupTrigger revokeApp" : "btn iconBtn popupTrigger grantApp"}><span
												className={(application.allow_access) ? "icon-wrd-lock" : "icon-wrd-unlock"}></span></button>
										</div>
										<div className="col p-0 text-center" title="Settings"><button onClick={this.handleApplicationSettings} className="btn iconBtn popupTrigger">
											<span className="icon-wrd-settings"></span></button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ userAccess, role }) => ({
	userAccess,
	role
});

const mapDispatchToProps = (dispatch) => ({
	dispatchUserAccess: (userAccess) => dispatch(updateUserAccessAction(userAccess))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserAccessApplicationsItem));
