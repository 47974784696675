import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateRoleAction } from '../../../../actions/updateRoleAction';
import GridContainer from '../Grid/GridContainer';
import { getUrlWorkspaceId } from '../../../../utils/url';
import { getWorkspaceMemberHeaders } from '../../../../utils/workspacesMembers';
import { routeCodes } from '../../../../configurations/routes';
import { routeBackCreateUserAction } from '../../../../actions/routeBackCreateUserAction';
import { routeBackGlobalUsersAction } from '../../../../actions/routeBackGlobalUsersAction';
import { clearWorkspacesMembersAction } from '../../../../actions/clearWorkspacesMembersAction';
import { getWorkspacesMembersSearchWithThunk } from '../../../../api/workspacesMembersThunk';
import { getWorkspacesMembersSearchAction } from '../../../../actions/getWorkspacesMembersSearchAction';
import { getWorkspacesMembersWithThunk } from '../../../../api/workspacesMembersThunk';
import { showSwitchButton, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize, defaultUsersPageSize } from '../../../../configurations/app';
import {  } from '../../../../configurations/app';
import { getUserAvailableWorkspacesWithThunk } from '../../../../api/userAvailableWorkspacesThunk';
import UserAvailableWorkspacesContainer from '../../UserAvailableWorkspaces/UserAvailableWorkspacesContainer';
import { updateAvailableWorkspacesOpenedStatus } from '../../../../utils/workspaces';

class WorkspacesMembersBody extends Component {
    constructor(props) {
        super(props);
        this.switchUserMode = this.switchUserMode.bind(this);
        this.createUser = this.createUser.bind(this);
        this.addExistingUser = this.addExistingUser.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.hideList = this.hideList.bind(this);
        this.state = {
            wsListOpened: false,
            firstOpen: true
        };
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
        workspacesMembers: PropTypes.array.isRequired,
        dispatchRole: PropTypes.func.isRequired,
        routeBackCreateUser: PropTypes.func.isRequired,
        routeBackGlobalUsers: PropTypes.func.isRequired,
        dispatchWorkspacesMembersSearch: PropTypes.func.isRequired,
        getWorkspacesMembers: PropTypes.func.isRequired,
        updateMode: PropTypes.func.isRequired,
        pagination: PropTypes.func.isRequired
    };

    calculatePageSize() {
        let size = defaultUsersPageSize;

        this.setState({ page: 1, size });
        return size;
    }

    switchUserMode(e) {
        const userRole = (this.props.role.user_role === 'Administrator') ? 'Owner' : 'Administrator';
        const role = {
            ...this.props.role,
            user_role: userRole
        }

        this.props.dispatchRole(role);
    }

    createUser() {
        const { workspace } = this.props;
        const url = routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/members";
        const source_page = "workspace_member";

        this.props.routeBackCreateUser({ url, source_page })
        this.props.history.push(url + "/create");
    }

    addExistingUser() {
        const { workspace } = this.props;
        const url = routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/members";
        const source_page = "workspace_member";

        this.props.routeBackGlobalUsers({ url, source_page })
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/users");
    }

    shareUser() {
        const { workspace } = this.props;
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/members/share");
    }

    getCurrentWorkspaceId = () => {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    handleOnChange(e) {
        let search = {
            ...this.props.member,
            search_text: e.target.value
        };
        this.props.dispatchWorkspacesMembersSearch(search);
    }

    handleSearch(e) {
        const { search } = this.props;
        if (e.key === 'Enter' || e.target.name !== 'searchText') {
            const width = 80;
            const height = width;
            const size = this.calculatePageSize();
            this.props.clearWorkspacesMembers();
            if (search.search_text.trim() !== "") {
                this.props.getWorkspacesMembersSearch(this.getCurrentWorkspaceId(), 1, size, search.search_text);
                this.props.updateMode(1, 'search');
            }
            else {
                this.props.getWorkspacesMembers(this.getCurrentWorkspaceId(), 1, size, width, height);
            }
        }
    }

    availableWorkspacesSwitch() {
        this.setState({ wsListOpened: !this.state.wsListOpened });
        this.setState({ firstOpen: false });
        if (this.state.wsListOpened === false) {
            const { role } = this.props;
            this.props.getUserAvailableWorkspaces(role.user_id, role.user_role, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize);
        }
        else {
            updateAvailableWorkspacesOpenedStatus(false);
        }
    }

    hideList() {
        this.setState({ wsListOpened: false });
    }

    componentWillUnmount() {
        let search = {
            ...this.props.member,
            search_text: ''
        };
        this.props.dispatchWorkspacesMembersSearch(search);
    }

    render() {
        const { role, workspacesMembers, search } = this.props;
        const headers = getWorkspaceMemberHeaders();

        return (
            <div className="mainBody">
                <div className="row mt-4">
                    <div className=" col-xl-12">
                        <div className="userSection">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-9">
                                    <button className="btn icoTextBtn btn-wrd_default mr-3" onClick={() => this.createUser()}>
                                        <span className="icon-wrd-add"></span>
                                        Create New User
                                    </button>
                                    {(role.user_role === 'Administrator') &&
                                        <button className="btn icoTextBtn btn-wrd_default mr-3 popupTrigger" onClick={() => this.addExistingUser()}>
                                            <span className="icon-wrd-user-detail"></span>
                                            Add Existing User(s)
                                        </button>
                                    }
                                    <button className="btn icoTextBtn btn-wrd_default popupTrigger" onClick={() => this.shareUser()}>
                                        <span className="icon-wrd-share"></span>
                                        Share Member(s)
                                    </button>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-3 text-right">
                                    <div className="searchInput">
                                        <span style={{ cursor: 'pointer' }} className="icon-wrd-search" onClick={this.handleSearch}></span>
                                        <input type="text" onKeyPress={this.handleSearch} name="searchText" value={search.search_text} placeholder="Search..." onChange={this.handleOnChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid">
                                <GridContainer headers={headers} items={workspacesMembers} pagination={this.props.pagination} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={(this.state.firstOpen) ? "workspaceList d-none" : (this.state.wsListOpened) ? "workspaceList open animated fadeInRight" : "workspaceList open animated fadeOutRight"}>
                    <UserAvailableWorkspacesContainer hideList={this.hideList} />
                </div>
                <button className="btn iconBtn wsListBtn" onClick={() => this.availableWorkspacesSwitch()}>
                    <span className={(this.state.wsListOpened) ? "icon-wrd-close" : "icon-wrd-ws-list"}></span>
                </button>
                {showSwitchButton &&
                    <button className="btn customBtn" onClick={() => this.switchUserMode()}>{(role.user_role === 'Administrator') ? 'Owner' : 'Administrator'} Mode</button>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role, workspacesMembers, search }) => ({
    workspace: detail.workspace,
    role,
    workspacesMembers,
    search: search.member
});

const mapDispatchToProps = (dispatch) => ({
    dispatchRole: (role) => dispatch(updateRoleAction(role)),
    routeBackCreateUser: (url) => dispatch(routeBackCreateUserAction(url)),
    routeBackGlobalUsers: (url) => dispatch(routeBackGlobalUsersAction(url)),
    clearWorkspacesMembers: () => dispatch(clearWorkspacesMembersAction([])),
    dispatchWorkspacesMembersSearch: (data) => dispatch(getWorkspacesMembersSearchAction(data)),
    getWorkspacesMembersSearch: (id, pageIndex, pageSize, searchKeyword) => dispatch(getWorkspacesMembersSearchWithThunk(id, pageIndex, pageSize, searchKeyword)),
    getWorkspacesMembers: (id, pageIndex, pageSize) => dispatch(getWorkspacesMembersWithThunk(id, pageIndex, pageSize)),
    getUserAvailableWorkspaces: (userId, userRole, pageIndex, pageSize) => dispatch(getUserAvailableWorkspacesWithThunk(userId, userRole, pageIndex, pageSize))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesMembersBody));
