import { axios, queryBaseUrlV2 } from './getCookies';

async function getCategoryById(categoryId) {
    const results = await axios.get(queryBaseUrlV2 + "application/categories/" + categoryId);

    return results;
}

async function getCategoryIdByName(categoryName) {
    const results = await axios.get(queryBaseUrlV2 + "application/categories/id/" + categoryName);

    return results;
}

async function getCategoryList() {
   const results = await axios.get(queryBaseUrlV2 + "application/categories/list?pageIndex=1&pageSize=10000");

    return results;
}

async function createCategory(categories) {
    const results = await axios.post(queryBaseUrlV2 + "application/categories", categories)
        .catch(function (error) { return error.response });
    return results;
}

async function updateCategory(categories) {
    const results = await axios.put(queryBaseUrlV2 + "application/categories", categories)
        .catch(function (error) { return error.response });
    return results;
}

async function deleteCategory(categoryId) {
    const results = await axios.delete(queryBaseUrlV2 + "application/categories/" + categoryId)
        .catch(function (error) { return error.response });
    return results;
}

export {
    getCategoryById,
    getCategoryIdByName,
    getCategoryList,
    updateCategory,
    createCategory,
    deleteCategory
}
