import {
    getPortalSettingBannerApi,
    addPortalSettingBannerApi,
    updatePortalSettingBannerApi,
    removePortalSettingBannerApi,
    getPortalSettingFeatureApi,
    addPortalSettingFeatureApi,
    updatePortalSettingFeatureApi,
    removePortalSettingFeatureApi,
    getPortalSettingNewsApi,
    addPortalSettingNewsApi,
    updatePortalSettingNewsApi,
    removePortalSettingNewsApi,
    getPortalSettingLoginApi,
    updatePortalSettingLoginApi,
    getPortalSettingEmailApi,
    updatePortalSettingEmailApi,
    getPortalSettingThemeApi,
    addPortalSettingThemeApi,
    updatePortalSettingThemeApi,
    removePortalSettingThemeApi
} from '../backend/domain/portal';

import { getPortalSettingBannerAction } from '../actions/getPortalSettingBannerAction';
import { loadingPortalSettingBannerAction } from '../actions/loadingPortalSettingBannerAction';
import { updatePortalSettingBannerAction } from '../actions/updatePortalSettingBannerAction';
import { getPortalSettingFeatureAction } from '../actions/getPortalSettingFeatureAction';
import { loadingPortalSettingFeatureAction } from '../actions/loadingPortalSettingFeatureAction';
import { updatePortalSettingFeatureAction } from '../actions/updatePortalSettingFeatureAction';
import { getPortalSettingNewsAction } from '../actions/getPortalSettingNewsAction';
import { loadingPortalSettingNewsAction } from '../actions/loadingPortalSettingNewsAction';
import { updatePortalSettingNewsAction } from '../actions/updatePortalSettingNewsAction';
import { getPortalSettingLoginAction } from '../actions/getPortalSettingLoginAction';
import { loadingPortalSettingLoginAction } from '../actions/loadingPortalSettingLoginAction';
import { updatePortalSettingLoginAction } from '../actions/updatePortalSettingLoginAction';
import { getPortalSettingEmailAction } from '../actions/getPortalSettingEmailAction';
import { loadingPortalSettingEmailAction } from '../actions/loadingPortalSettingEmailAction';
import { updatePortalSettingEmailAction } from '../actions/updatePortalSettingEmailAction';
import { getPortalSettingThemeAction } from '../actions/getPortalSettingThemeAction';
import { loadingPortalSettingThemeAction } from '../actions/loadingPortalSettingThemeAction';
import { updatePortalSettingThemeAction } from '../actions/updatePortalSettingThemeAction';
import { handlePortalSettingsUpdateError } from '../utils/errorMessages';

export const getPortalSettingBannerWithThunk = () =>
    (dispatch) => {
        dispatch(loadingPortalSettingBannerAction(true));
        getPortalSettingBannerApi()
            .then((response) => {
                if (!response.error) {
                    dispatch(getPortalSettingBannerAction(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingBannerAction(false));
                return response;
            });
    };

export const addPortalSettingBannerWithThunk = (bannerSetting, index) =>
    (dispatch) => {
        dispatch(loadingPortalSettingBannerAction(true));
        addPortalSettingBannerApi(bannerSetting.banners[index])
            .then((response) => {
                handlePortalSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                if (response.status === 200) {
                    let settingBanner = bannerSetting;
                    settingBanner.banners[index].id = response.id;
                    settingBanner.banners[index].status = 'Enabled';
                    dispatch(updatePortalSettingBannerAction(settingBanner));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingBannerAction(false));
                return response;
            });
    };

export const updatePortalSettingBannerWithThunk = (bannerSetting, index) =>
    (dispatch) => {
        dispatch(loadingPortalSettingBannerAction(true));
        updatePortalSettingBannerApi(bannerSetting.banners[index])
            .then((response) => {
                handlePortalSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updatePortalSettingBannerAction(bannerSetting));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingBannerAction(false));
                return response;
            });
    };

export const removePortalSettingBannerWithThunk = (bannerSetting, id) =>
    (dispatch) => {
        dispatch(loadingPortalSettingBannerAction(true));
        removePortalSettingBannerApi(id)
            .then((response) => {
                handlePortalSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updatePortalSettingBannerAction(bannerSetting));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingBannerAction(false));
                return response;
            });
    };

export const getPortalSettingFeatureWithThunk = () =>
    (dispatch) => {
        dispatch(loadingPortalSettingFeatureAction(true));
        getPortalSettingFeatureApi()
            .then((response) => {
                if (!response.error) {
                    dispatch(getPortalSettingFeatureAction(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingFeatureAction(false));
                return response;
            });
    };

export const addPortalSettingFeatureWithThunk = (featureSetting, index) =>
    (dispatch) => {
        dispatch(loadingPortalSettingFeatureAction(true));
        addPortalSettingFeatureApi(featureSetting.features[index])
            .then((response) => {
                handlePortalSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                if (response.status === 200) {
                    let settingFeature = featureSetting;
                    settingFeature.features[index].id = response.id;
                    settingFeature.features[index].status = 'Enabled';
                    dispatch(updatePortalSettingFeatureAction(settingFeature));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingFeatureAction(false));
                return response;
            });
    };

export const updatePortalSettingFeatureWithThunk = (featureSetting, index) =>
    (dispatch) => {
        dispatch(loadingPortalSettingFeatureAction(true));
        updatePortalSettingFeatureApi(featureSetting.features[index])
            .then((response) => {
                handlePortalSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updatePortalSettingFeatureAction(featureSetting));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingFeatureAction(false));
                return response;
            });
    };

export const removePortalSettingFeatureWithThunk = (featureSetting, id) =>
    (dispatch) => {
        dispatch(loadingPortalSettingFeatureAction(true));
        removePortalSettingFeatureApi(id)
            .then((response) => {
                handlePortalSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updatePortalSettingFeatureAction(featureSetting));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingFeatureAction(false));
                return response;
            });
    };


export const getPortalSettingNewsWithThunk = () =>
    (dispatch) => {
        dispatch(loadingPortalSettingNewsAction(true));
        getPortalSettingNewsApi()
            .then((response) => {
                if (!response.error) {
                    dispatch(getPortalSettingNewsAction(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingNewsAction(false));
                return response;
            });
    };

export const addPortalSettingNewsWithThunk = (newsSetting, index) =>
    (dispatch) => {
        dispatch(loadingPortalSettingNewsAction(true));
        addPortalSettingNewsApi(newsSetting.news[index])
            .then((response) => {
                handlePortalSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                if (response.status === 200) {
                    let settingNews = newsSetting;
                    settingNews.news[index].id = response.id;
                    settingNews.news[index].status = 'Enabled';
                    dispatch(updatePortalSettingNewsAction(settingNews));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingNewsAction(false));
                return response;
            });
    };

export const updatePortalSettingNewsWithThunk = (newsSetting, index) =>
    (dispatch) => {
        dispatch(loadingPortalSettingNewsAction(true));
        updatePortalSettingNewsApi(newsSetting.news[index])
            .then((response) => {
                handlePortalSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updatePortalSettingNewsAction(newsSetting));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingNewsAction(false));
                return response;
            });
    };

export const removePortalSettingNewsWithThunk = (newsSetting, id) =>
    (dispatch) => {
        dispatch(loadingPortalSettingNewsAction(true));
        removePortalSettingNewsApi(id)
            .then((response) => {
                handlePortalSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updatePortalSettingNewsAction(newsSetting));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingNewsAction(false));
                return response;
            });
    };

export const getPortalSettingLoginWithThunk = () =>
    (dispatch) => {
        dispatch(loadingPortalSettingLoginAction(true));
        getPortalSettingLoginApi()
            .then((response) => {
                if (!response.error) {
                    dispatch(getPortalSettingLoginAction(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingLoginAction(false));
                return response;
            });
    };

export const updatePortalSettingLoginWithThunk = (loginSetting) =>
    (dispatch) => {
        dispatch(loadingPortalSettingLoginAction(true));
        updatePortalSettingLoginApi(loginSetting)
            .then((response) => {
                handlePortalSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                dispatch(updatePortalSettingLoginAction(loginSetting));
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingLoginAction(false));
                return response;
            });
    };

export const getPortalSettingEmailWithThunk = () =>
    (dispatch) => {
        dispatch(loadingPortalSettingEmailAction(true));
        getPortalSettingEmailApi()
            .then((response) => {
                if (!response.error) {
                    dispatch(getPortalSettingEmailAction(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingEmailAction(false));
                return response;
            });
    };

export const updatePortalSettingEmailWithThunk = (emailSetting) =>
    (dispatch) => {
        dispatch(loadingPortalSettingEmailAction(true));
        updatePortalSettingEmailApi(emailSetting)
            .then((response) => {
                handlePortalSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                dispatch(updatePortalSettingEmailAction(emailSetting));
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingEmailAction(false));
                return response;
            });
    };

export const getPortalSettingThemeWithThunk = () =>
    (dispatch) => {
        dispatch(loadingPortalSettingThemeAction(true));
        getPortalSettingThemeApi()
            .then((response) => {
                if (!response.error) {
                    dispatch(getPortalSettingThemeAction(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingThemeAction(false));
                return response;
            });
    };

export const addPortalSettingThemeWithThunk = (themeSetting, index) =>
    (dispatch) => {
        dispatch(loadingPortalSettingThemeAction(true));
        addPortalSettingThemeApi(themeSetting.themes[index])
            .then((response) => {
                handlePortalSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                if (response.status === 200) {
                    let settingTheme = themeSetting;
                    settingTheme.themes[index].id = response.id;
                    settingTheme.themes[index].status = 'Enabled';
                    dispatch(updatePortalSettingThemeAction(settingTheme));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingThemeAction(false));
                return response;
            });
    };

export const updatePortalSettingThemeWithThunk = (themeSetting, index) =>
    (dispatch) => {
        dispatch(loadingPortalSettingThemeAction(true));
        updatePortalSettingThemeApi(themeSetting.themes[index])
            .then((response) => {
                handlePortalSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then(async (response) => {
                if (response.status === 200) {
                    dispatch(updatePortalSettingThemeAction(themeSetting));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingThemeAction(false));
                return response;
            });
    };

export const removePortalSettingThemeWithThunk = (themeSetting, id) =>
    (dispatch) => {
        dispatch(loadingPortalSettingThemeAction(true));
        removePortalSettingThemeApi(id)
            .then((response) => {
                handlePortalSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updatePortalSettingThemeAction(themeSetting));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingPortalSettingThemeAction(false));
                return response;
            });
    };