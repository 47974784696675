import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearWorkspaceAction } from '../../../actions/clearWorkspaceAction';
import { getUserWorkspaceLandingPropertiesByIdApiWithThunk } from '../../../api/workspacesThunk';
import { getWorkspaceLandingSettingWithThunk } from '../../../api/workspacesSettingsThunk';
import { getAccountWithThunk } from '../../../api/accountThunk';
import { getUrlWorkspaceId } from '../../../utils/url';
import Loader from 'react-loader-spinner';
import WorkspacesLandingHeader from '../../Workspaces/Landing/WorkspacesLandingHeader';
import WorkspacesLandingBody from '../../Workspaces/Landing/WorkspacesLandingBody';
import { userLogout } from '../../../api/logoutThunk';
import { getClientIpWithThunk } from '../../../api/ipAddressThunk';
import WorkspacesLandingApplicationModal from '../Modal/WorkspacesLandingApplicationModal';
import { anonymousUserId } from '../../../configurations/app';
import { getSessionStorage } from '../../../utils/sessionStorage';
import { SharedHeaderApp } from '@wrd/shared/header-app';
import { css } from '@emotion/css';
import { StyledRow } from '@wrd/utils/style';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@wrd/utils/style';
import UserAvailableWorkspacesContainer from '../UserAvailableWorkspaces/UserAvailableWorkspacesContainer';
import { routeCodes } from '../../../configurations/routes';
import {
  defaultAvailableWorkspacesPageIndex,
  defaultAvailableWorkspacesPageSize,
} from '../../../configurations/app';
import { getUserAvailableWorkspacesWithThunk } from '../../../api/userAvailableWorkspacesThunk';
import { updateDialogWorkspacesLandingApplicationAction } from '../../../actions/updateDialogWorkspacesLandingApplicationAction';
import MainFeaturedPost from './MainFeaturedPost';
import { changeRouteAction } from '../../../actions/changeRouteAction';
import { getCookies } from '../../../backend/api/getCookies';

import { getApplicationUrls } from '@wrd/helpers';

class WorkspacesLandingContainer extends Component {
  static propTypes = {
    workspace: PropTypes.object.isRequired,
    getUserWorkspaceLandingPropertiesById: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    role: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
  };

  state = {
    firstOpen: false,
    wsListOpened: false,
  };
  getCurrentWorkspaceId = () => {
    let id = getUrlWorkspaceId(this.props.location.pathname);
    return id;
  };

  loadWorkspaceLandingProperty = async () => {
    const { workspace, role, loading, getUserWorkspaceLandingPropertiesById } =
      this.props;
    const workspaceId = this.getCurrentWorkspaceId();

    let userId = '';
    let isAdmin = false;
    if (role.user_id === '') {
      const sessionRole = getSessionStorage('role');

      if (sessionRole !== '') {
        const role = JSON.parse(sessionRole);
        userId = role.user_id;
        isAdmin = role.user_role === 'Administrator' || 'User' ? true : false;
      }
    } else {
      userId = role.user_id;
      isAdmin = role.user_role === 'Administrator' || 'User' ? true : false;
    }

    if (userId === '') {
      userId = anonymousUserId;
    }

    if (
      workspace.id === '' ||
      userId !== '' ||
      (!loading && workspace.id !== '')
    ) {
      await getUserWorkspaceLandingPropertiesById(
        workspaceId,
        userId,
        isAdmin,
        339,
        156,
        this.props.history
      );
    }
  };

  async componentDidMount() {
    await this.loadWorkspaceLandingProperty();
  }

  componentDidUpdate(prevProps) {
    const { role, loading, account } = this.props;
    const { getWorkspaceSettings, getClientIp } = this.props;
    const workspaceId = this.getCurrentWorkspaceId();

    if (prevProps.loading !== loading && !loading) {
      getWorkspaceSettings(
        workspaceId,
        'ShowPortalLogo;GoogleAnalytic;ShowWorkspaceLogo'
      );
      getClientIp();
      if (account.id === '') {
        this.props.getAccount(role.user_id, false, 0, 0);
      }
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.loadWorkspaceLandingProperty();
    }
  }

  componentWillUnmount() {
    this.props.clearWorkspace();
  }

  profilePage = () => {
    this.props.history.push(routeCodes.PROFILE);
  };

  userGuidePage = () => {
    window.open("https://doc.mikepoweredbydhi.help/webhelp/mike-cloud/MIKEMine/index.htm", "_blank");
  }

  logout = () => {
    this.props.history.push(routeCodes.USERS_LOGOUT);
  };

  privacyPolicy = () => {
    window.open("https://www.dhigroup.com/legal-and-compliance/privacy-policy", "_blank");
  }

  contactSupport = () => {
    let workspaceName = this.getCurrentWorkspaceId();
    console.log(workspaceName)
    this.props.history.push(routeCodes.WORKSPACES_LIST  + "/" + workspaceName+ "/contactOwners");
  }

  handleWorkspacesClick = () => {
    const { workspace, role } = this.props;
    let url =
      role.user_role === 'Administrator'
        ? routeCodes.WORKSPACES_LIST +
          '/' +
          workspace.workspace_name.toLowerCase().replaceAll(' ', '+') +
          '/properties'
        : routeCodes.WORKSPACES_LIST;
    this.props.history.push(url);
  };

  handleEditWorkspaces = () => {
    const { workspace, role } = this.props;
    let url =
      role.user_role === 'Administrator' || workspace.owner
        ? routeCodes.WORKSPACES_LIST +
          '/' +
          workspace.workspace_name.toLowerCase().replaceAll(' ', '+') +
          '/properties'
        : routeCodes.WORKSPACES_LIST;
    this.props.history.push(url);
  };

  handleOpenWorkspaces = () => {
    const { getUserAvailableWorkspaces, role, availableWorkspaces } =
      this.props;
    if (!availableWorkspaces?.workspaces) {
      getUserAvailableWorkspaces(
        role.user_id,
        role.user_role,
        defaultAvailableWorkspacesPageIndex,
        defaultAvailableWorkspacesPageSize
      );
    }
    this.setState({ wsListOpened: !this.state.wsListOpened });
  };

  hideList = () => {
    this.setState({ wsListOpened: false });
  };

  loginPage = () => {
    const { workspace, logout } = this.props;
    logout();
    this.props.history.push(
      routeCodes.USERS_LOGIN +
        '/' +
        workspace.workspace_name.toLowerCase().replaceAll(' ', '+')
    );
  };

  enterBetaWorkspace() {
    const { workspaces } = getApplicationUrls();

    const returnUrl = window.location.href;

    const url = `${workspaces}/${this.props.workspace.id}?returnUrl=${returnUrl}`;

    window.location.href = url;
  }

  render() {
    const {
      history,
      workspace,
      categories,
      userCategories,
      loading,
      loadingTheme,
      workspaceSetting,
      role,
      workspace_theme,
      availableApplications,
      dispatchDialogWorkspacesLandingApplication,
      dispatchRoute,
      clientIp,
    } = this.props;
    return (
      <>
        {(loading ||
          loadingTheme ||
          workspace.id === '' ||
          categories === undefined ||
          userCategories === undefined) && (
          <div className="loader-container">
            <Loader
              type="BallTriangle"
              color="#00BFFF"
              height="100"
              width="100"
            />
          </div>
        )}
        {!loading &&
          !loadingTheme &&
          workspace.id !== '' &&
          categories !== undefined &&
          userCategories !== undefined && (
            <>
              <SharedHeaderApp
                url={process.env.PUBLIC_URL}
                showPortalLogo={workspaceSetting.showPortalLogo}
                userImage={workspace.user_picture}
                userInitial={workspace.user_initial}
                login={role.is_logged_in}
                workspaceEdit={
                  role.user_role === 'Administrator' ||
                  // 'User' ||
                  (workspace.owner && !workspace.suspendedUser)
                }
                handleEditWorkspaces={this.handleEditWorkspaces}
                // workspaceName={workspace.workspace_name}
                handleWorkspacesClick={this.handleWorkspacesClick}
                handleOpenWorkspaces={this.handleOpenWorkspaces}
                handleOpenLoginPage={this.loginPage}
                listNav={[
                  {
                    label: 'Profile',
                    handleClickNavItem: this.profilePage,
                  },
                  {
                    label: 'Help & User Guide',
                    handleClickNavItem: this.userGuidePage,
                  },
                  {
                    label: 'Privacy policy',
                    handleClickNavItem: this.privacyPolicy,
                  },
                  {
                    label: 'Contact & Support',
                    handleClickNavItem: () => this.contactSupport(),
                  },
                  {
                    label: 'Logout',
                    handleClickNavItem: this.logout,
                  },
                ]}
              />

              {this.state.wsListOpened && (
                <>
                  <div
                    className={
                      this.state.wsListOpened
                        ? 'workspaceList open animated fadeInRight'
                        : 'workspaceList open animated fadeOutRight'
                    }
                  >
                    <UserAvailableWorkspacesContainer
                      hideList={this.hideList}
                    />
                  </div>
                  <button
                    className="btn iconBtn wsListBtn"
                    onClick={() => this.handleOpenWorkspaces()}
                  >
                    <CloseIcon />
                  </button>
                </>
              )}
              <main
                className={css`
                  margin: 0px;
                  margin-top: 3px;
                  position: relative !important;
                `}
              >
                <MainFeaturedPost
                  history={history}
                  workspace={workspace}
                  categories={categories}
                  userCategories={userCategories}
                  workspaceSetting={workspaceSetting}
                  workspaceTheme={workspace_theme}
                  availableApplications={availableApplications}
                  role={role}
                  dispatchDialogWorkspacesLandingApplication={
                    dispatchDialogWorkspacesLandingApplication
                  }
                  dispatchRoute={dispatchRoute}
                  clientIp={clientIp}
                />
                <WorkspacesLandingApplicationModal />
                {/* {role.user_role === 'User' &&
                     !getCookies.isMikeMine && (
                    <div
                      style={{
                        position: 'fixed',
                        bottom: '1rem',
                        right: '1rem',
                        zIndex: '9999',
                      }}
                    >
                      <button
                        className="btn textBtn orangeBtn"
                        onClick={() => this.enterBetaWorkspace()}
                      >
                        Enter beta workspace
                      </button>
                    </div>
                  )} */}
              </main>
              {/* <main className="cancel-main-margin">
                        <div className="container-fluid h-100">
                            <div id="full-body" className="mcp">
                                <WorkspacesLandingHeader />
                                <WorkspacesLandingBody />
                                <WorkspacesLandingApplicationModal />
                                {role.user_role === 'User' && (
                  <div
                    style={{
                      position: 'fixed',
                      bottom: '1rem',
                      right: '1rem',
                      zIndex: '9999',
                    }}
                  >
                    <button
                      className="btn textBtn orangeBtn"
                      onClick={() => this.enterBetaWorkspace()}
                    >
                      Enter beta workspace
                    </button>
                  </div>
                )}
                            </div>
                        </div>
                    </main> */}
            </>
          )}
      </>
    );
  }
}

const mapStateToProps = ({
  detail,
  loading,
  role,
  availableWorkspaces,
  availableApplications,
  clientIp,
}) => ({
  workspace: detail.workspace,
  workspace_theme: detail.workspace_theme_setting,
  categories: detail.workspace.categories,
  userCategories: detail.workspace.user_categories,
  account: detail.account,
  loading: loading.workspace.loading,
  loadingTheme: loading.theme.loading,
  role,
  workspaceSetting: detail.workspace_setting,
  availableWorkspaces,
  availableApplications,
  clientIp,
});

const mapDispatchToProps = (dispatch) => ({
  getWorkspaceSettings: (workspaceId, includeOnly) =>
    dispatch(getWorkspaceLandingSettingWithThunk(workspaceId, includeOnly)),
  getUserWorkspaceLandingPropertiesById: (
    workspaceId,
    userId,
    isAdmin,
    snapshotWidth,
    snapshotHeight,
    historyProps
  ) =>
    dispatch(
      getUserWorkspaceLandingPropertiesByIdApiWithThunk(
        workspaceId,
        userId,
        isAdmin,
        snapshotWidth,
        snapshotHeight,
        historyProps
      )
    ),
  logout: (role, history) => dispatch(userLogout(role, history)),
  getClientIp: () => dispatch(getClientIpWithThunk()),
  getAccount: (userId, withPcture, width, height) =>
    dispatch(getAccountWithThunk(userId, withPcture, width, height)),
  clearWorkspace: () => dispatch(clearWorkspaceAction([])),
  getUserAvailableWorkspaces: (userId, userRole, pageIndex, pageSize) =>
    dispatch(
      getUserAvailableWorkspacesWithThunk(userId, userRole, pageIndex, pageSize)
    ),
  dispatchDialogWorkspacesLandingApplication: (dialog) =>
    dispatch(updateDialogWorkspacesLandingApplicationAction(dialog)),
  dispatchRoute: (navigation) => dispatch(changeRouteAction(navigation)),
});

const StyledIconButton = styled(IconButton)`
  margin-left: auto;
  box-shadow: 0.7px 0.7px 2px #0006;
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 9999;
`;
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkspacesLandingContainer)
);
