import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearCategoryAction } from '../../../actions/clearCategoryAction';
import { getCategoryByIdWithThunk } from '../../../api/categoriesThunk';
import { getUrlCategoryId } from '../../../utils/url';
import Loader from 'react-loader-spinner'
import SidebarContainer from '../../Sidebar/SidebarContainer';
import CategoriesDetailBody from './CategoriesDetailBody';
import { changeNavigationAction } from '../../../actions/changeNavigationAction';
import CategoriesCreationErrorModal from '../Modal/CategoriesCreationErrorModal';

class CategoriesDetailContainer extends Component {
    static propTypes = {
        category: PropTypes.object.isRequired,
        getCategoryById: PropTypes.func.isRequired,
        clearCategory: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        dispatchNavigation: PropTypes.func.isRequired
    };

    getCurrentCategoryId = () => {
        let id = getUrlCategoryId(this.props.location.pathname);
        return id;
    }

    changeNavigation(name) {
        let navigation = {
            name
        };
        this.props.dispatchNavigation(navigation);
    }

    componentDidMount() {
        const categoryId = this.getCurrentCategoryId();
        this.props.getCategoryById(categoryId);

        this.changeNavigation('Applications');
    }

    componentWillUnmount() {
        this.props.clearCategory();
    }

    render() {
        const { category, loading, loadingTheme, role } = this.props;

        if (category.category_id === undefined || category.category_id === '')
            return (
                <div>
                    {(loading || loadingTheme) &&
                        <div className="loader-container">
                            <Loader
                                type="BallTriangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            );

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="BallTriangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <CategoriesDetailBody role={role} />
                                </div>
                            </div>
                        </main>
                        <CategoriesCreationErrorModal type="error" />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, loading, role, navigation }) => ({
    category: detail.category,
    loading: loading.category.loading,
    loadingTheme: loading.theme.loading,
    role,
    navigation
});

const mapDispatchToProps = (dispatch) => ({
    getCategoryById: (categoryId) => dispatch(getCategoryByIdWithThunk(categoryId)),
    clearCategory: () => dispatch(clearCategoryAction([])),
    dispatchNavigation: (navigation) => dispatch(changeNavigationAction(navigation))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoriesDetailContainer));