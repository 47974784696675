import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UserRegisterForm from './UserRegisterForm';

class UserRegisterBody extends Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        scrollTop: PropTypes.func.isRequired
    };

    render() {
        return (
            <div className="mainBody">
                <UserRegisterForm scrollTop={this.props.scrollTop} />
            </div>
        );
    }
}

const mapStateToProps = ({ setup }) => ({
    user: setup.user
});

export default withRouter(connect(mapStateToProps)(UserRegisterBody));
