import { axios, queryBaseUrlV2 } from './getCookies';

async function getWorkspaceCategoryById(workspaceId, categoryId) {
  const results = await axios.get(
    queryBaseUrlV2 +
      'workspace/' +
      workspaceId +
      '/application/categories/' +
      categoryId
  );

  return results;
}

async function getWorkspaceCategoryIdByName(workspaceId, categoryName) {
  const results = await axios.get(
    queryBaseUrlV2 +
      'workspace/' +
      workspaceId +
      '/application/categories/id/' +
      categoryName
  );

  return results;
}

async function getWorkspaceApplicationCategoryList(workspaceId) {
  const results = await axios.get(
    queryBaseUrlV2 +
      'workspace/' +
      workspaceId +
      '/application/categories/list?pageIndex=1&pageSize=10000'
  );

  return results;
}

async function createWorkspaceCategory(workspaceId, categories) {
  const results = await axios
    .post(
      queryBaseUrlV2 + 'workspace/' + workspaceId + '/application/categories',
      categories
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function createWorkspaceCategoryImage(image) {
  let data = new FormData();
  data.append('image', image, image.name);

  const imageId = await axios
    .post(
      queryBaseUrlV2 + 'workspace/application/categories/image',
      data,
      {
        headers: {
          'Content-Type': `multipart/form-data`,
        },
      }
    )
    .catch((error) => {
      return error.response;
    });
  return imageId;
}

async function updateWorkspaceCategory(workspaceId, categories) {
  const results = await axios
    .put(
      queryBaseUrlV2 + 'workspace/' + workspaceId + '/application/categories',
      categories
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function updateOrderWorkspacesCategories(workspaceId, categoryOrders) {
  const results = await axios
    .post(
      queryBaseUrlV2 +
        'workspace/' +
        workspaceId +
        '/application/categories/reorder',
      categoryOrders
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function deleteWorkspaceCategory(workspaceId, categoryId) {
  const results = await axios
    .delete(
      queryBaseUrlV2 +
        'workspace/' +
        workspaceId +
        '/application/categories/' +
        categoryId
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

export {
  getWorkspaceCategoryById,
  getWorkspaceCategoryIdByName,
  getWorkspaceApplicationCategoryList,
  updateWorkspaceCategory,
  updateOrderWorkspacesCategories,
  createWorkspaceCategory,
  createWorkspaceCategoryImage,
  deleteWorkspaceCategory,
};
