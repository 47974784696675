import { userResendConfirmationApi } from '../backend/domain/users';
import { loadingWorkspacesMembersAction } from '../actions/loadingWorkspacesMembersAction';
import { handleRegistrationReminderError } from '../utils/errorMessages';
import { overrideWorkspaceId } from '../utils/workspaces';

export const registrationReminderWithThunk = (uid, workspaceId, username) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);
        dispatch(loadingWorkspacesMembersAction(true));
        userResendConfirmationApi(uid, wId)
            .then((response) => {
                handleRegistrationReminderError(dispatch, response.status, response.error_messages, username);
                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspacesMembersAction(false));
                return response;
            });
    };