import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import SidebarContainer from '../../../Sidebar/SidebarContainer';
import WorkspacesFocusAreasShapeHeader from './WorkspacesFocusAreasShapeHeader';
import WorkspacesFocusAreasShapeBody from './WorkspacesFocusAreasShapeBody';
import WorkspacesFocusAreasShapeModal from '../Modal/WorkspacesFocusAreasShapeModal';
import { getUrlWorkspaceId } from '../../../../utils/url';
import { getSessionStorage } from '../../../../utils/sessionStorage';
import { getWorkspaceByIdWithThunk } from '../../../../api/workspacesThunk';
import { updateWorkspacesFocusAreasShapeHeaderAction } from '../../../../actions/updateWorkspacesFocusAreasShapeHeaderAction';
import { clearWorkspacesFocusAreasShapeAction } from '../../../../actions/clearWorkspacesFocusAreasShapeAction';
import WorkspacesFocusAreaShapeFileModal from '../Modal/WorkspacesFocusAreaShapeFileModal';
import { updateDialogFocusAreasShapeFileAction } from '../../../../actions/updateDialogFocusAreasShapeFileAction';

class WorkspacesFocusAreasShapeContainer extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired
    };

    getCurrentWorkspaceId = () => {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    componentDidMount() {
        const { workspace, role } = this.props;
        const workspaceId = this.getCurrentWorkspaceId();

        if (workspaceId !== workspace.workspace_name.toLowerCase().replaceAll(" ", "+")) {
            let userId = role.user_id;
            if (userId === "") {
                const sessionRole = getSessionStorage("role");

                if (sessionRole !== "") {
                    const role = JSON.parse(sessionRole);
                    userId = role.user_id;
                }
            }
            this.props.getWorkspaceById(workspaceId, userId);
        }

        let focusAreas = {
            show_dialog: true
        };

        this.props.dispatchDialogFocusAreaShapeFile(focusAreas);
    }

    componentWillUnmount() {
        this.props.dispatchWorkspacesFocusAreasShapeHeader([]);
        this.props.clearWorkspacesFocusAreasShape();
    }

    render() {
        const { workspace, loading, loadingTheme } = this.props;

        if (workspace.id === undefined || workspace.id === '')
            return (
                <div>
                    {(loading || loadingTheme) &&
                        <div className="loader-container">
                            <Loader
                                type="BallTriangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            );

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="BallTriangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <WorkspacesFocusAreasShapeHeader />
                                    <WorkspacesFocusAreasShapeBody />
                                </div>
                            </div>
                        </main>
                        <WorkspacesFocusAreaShapeFileModal />
                        <WorkspacesFocusAreasShapeModal />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role, loading }) => ({
    workspace: detail.workspace,
    role,
    loading: loading.workspace.loading,
    loadingTheme: loading.theme.loading
});

const mapDispatchToProps = (dispatch) => ({
    getWorkspaceById: (workspaceId, userId) => dispatch(getWorkspaceByIdWithThunk(workspaceId, userId)),
    dispatchWorkspacesFocusAreasShapeHeader: (featuresHeaders) => dispatch(updateWorkspacesFocusAreasShapeHeaderAction(featuresHeaders)),
    clearWorkspacesFocusAreasShape: () => dispatch(clearWorkspacesFocusAreasShapeAction([])),
    dispatchDialogFocusAreaShapeFile: (dialog) => dispatch(updateDialogFocusAreasShapeFileAction(dialog))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesFocusAreasShapeContainer));
