import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUrlWorkspaceId } from '../../../../utils/url';
import Loader from 'react-loader-spinner'
import SidebarContainer from '../../../Sidebar/SidebarContainer';
import { clearWorkspaceEmailSettingAction } from '../../../../actions/clearWorkspaceEmailSettingAction';
import { getWorkspaceEmailSettingByIdWithThunk, getWorkspaceEmailSettingWithThunk } from '../../../../api/workspacesEmailSettingsThunk';
import { getSessionStorage } from '../../../../utils/sessionStorage';
import WorkspacesModal from '../../Modal/WorkspacesModal';
import WorkspacesSettingsHeader from '../../../Workspaces/Settings/Email/WorkspacesEmailSettingsHeader';
import WorkspacesSettingsBody from '../../../Workspaces/Settings/Email/WorkspacesEmailSettingsBody';
import WorkspaceSettingsErrorModal from '../Modal/WorkspaceSettingsErrorModal';
import { getPortalSettingEmailWithThunk } from '../../../../api/portalSettingsThunk';

class WorkspacesEmailSettingContainer extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        workspaceSetting: PropTypes.object.isRequired,
        getWorkspaceEmailById: PropTypes.func.isRequired,
        clearWorkspaceSetting: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        role: PropTypes.object.isRequired
    };

    getCurrentWorkspaceId = () => {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    componentDidMount() {
        const { workspace, role, loading } = this.props;
        const workspaceId = this.getCurrentWorkspaceId();

        if (loading === false) {
            if (workspaceId !== workspace.workspace_name.toLowerCase().replaceAll(" ", "+")) {
                let userId = role.user_id;
                let isAdmin = (role.user_role === "Administrator") ? true : false;
                if (userId === "") {
                    const sessionRole = getSessionStorage("role");

                    if (sessionRole !== "") {
                        const role = JSON.parse(sessionRole);
                        userId = role.user_id;
                        isAdmin = (role.user_role === "Administrator") ? true : false;
                    }
                }
                this.props.getWorkspaceEmailById(workspaceId, userId, isAdmin, this.props.history);
            }
            else {
                this.props.getWorkspaceEmail(workspaceId);
            }
        }
    }

    render() {
        const { workspaceSetting, loading, loadingTheme } = this.props;

        if (workspaceSetting.id === undefined || workspaceSetting.id === '')
            return (
                <div>
                    {(loading || loadingTheme) &&
                        <div className="loader-container">
                            <Loader
                                type="BallTriangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            );

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="BallTriangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <WorkspacesSettingsHeader />
                                    <WorkspacesSettingsBody />
                                </div>
                            </div>
                        </main>
                        <WorkspacesModal type="detail" />
                        <WorkspaceSettingsErrorModal type="error" />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, loading, role }) => ({
    workspace: detail.workspace,
    workspaceSetting: detail.workspace_email_setting,
    loading: loading.workspaces_settings.loading,
    loadingTheme: loading.theme.loading,
    role
});

const mapDispatchToProps = (dispatch) => ({
    getPortalSettingEmail: () => dispatch(getPortalSettingEmailWithThunk()),
    getWorkspaceEmailById: (workspaceId, userId, isAdmin, historyProps) => dispatch(getWorkspaceEmailSettingByIdWithThunk(workspaceId, userId, isAdmin, historyProps)),
    getWorkspaceEmail: (workspaceId) => dispatch(getWorkspaceEmailSettingWithThunk(workspaceId)),
    clearWorkspaceSetting: () => dispatch(clearWorkspaceEmailSettingAction([]))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesEmailSettingContainer));
