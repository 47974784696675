import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import { IMikeTheme } from './types';
import dhiTypography from './dhiTypography';
import dhiPalette from './dhiPallete';
import dhiOverrides from './dhiOverrides';

const defaultTheme = createTheme();

export const DHIThemeV5: IMikeTheme = createTheme(
  deepmerge(defaultTheme, {
    typography: dhiTypography,
    palette: dhiPalette,
    components: dhiOverrides,
  })
);
