import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateUserAccessAction } from '../../../actions/updateUserAccessAction';

class ProfileAccessAreasItem extends Component {
    constructor(props) {
        super(props);
        this.handleLockAreaChange = this.handleLockAreaChange.bind(this);
    }

    static propTypes = {
        focusArea: PropTypes.object.isRequired,
        workspace: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired
    };

    handleLockAreaChange() {
        const { focusArea, workspace, userAccess } = this.props;

        let workspaces = userAccess.access;
        let wsIndex = workspaces.findIndex(item => item.id === workspace.id);
        workspaces[wsIndex].edited = true;

        let focusAreas = workspaces[wsIndex].focusAreas;
        let faIndex = focusAreas.findIndex(item => item.id === focusArea.id);

        workspaces[wsIndex].focusAreas[faIndex].allow_access = !focusArea.allow_access;

        let newUserAccess = {
            ...userAccess,
            access: workspaces
        };

        this.props.dispatchUserAccess(newUserAccess);
    }

    render() {
        const { workspace, focusArea, role } = this.props;
        return (
            (role.user_role === 'Administrator' || (role.user_role !== 'Administrator' && workspace.owner)) ?
                <div className="col-12 col-sm-6 col-xl-4">
                    <label className="customAccessCheckbox">
                        <div>
                            <input type="checkbox" onChange={this.handleLockAreaChange} checked={focusArea.allow_access} />
                            <span className="icon icon-wrd-lock"></span>
                            <span className="icon icon-wrd-unlock"></span>
                        </div>
                        <span className="text">{focusArea.name}</span>
                    </label>
                </div>
                : (focusArea.allow_access) ?
                    <div className="col-12 col-sm-6 col-xl-4">
                        <label className="customAccessCheckbox">
                            <span className="icon icon-wrd-badge"></span>
                            <span className="text">{focusArea.name}</span>
                        </label>
                    </div>
                    : null
        );
    }
}

const mapStateToProps = ({ userAccess, role }) => ({
    userAccess,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchUserAccess: (userAccess) => dispatch(updateUserAccessAction(userAccess))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileAccessAreasItem));
