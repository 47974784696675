import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import WorkspacesApplicationsSettingsTextbox from './WorkspacesApplicationsUserSettingsTextbox';
import WorkspacesApplicationsSettingsSlider from './WorkspacesApplicationsUserSettingsSlider';
import WorkspacesApplicationsSettingsCheckbox from './WorkspacesApplicationsUserSettingsCheckbox';
import WorkspacesApplicationsSettingsMultiSelect from './WorkspacesApplicationsUserSettingsMultiSelect';

class WorkspacesApplicationsSettingsCardItem extends Component {
    static propTypes = {
        id: PropTypes.number.isRequired,
        workspace: PropTypes.object.isRequired,
        workspaceApplication: PropTypes.object.isRequired,
		workspacesApplicationsUsersSettings: PropTypes.array.isRequired,
        groupLocked: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired
    };

    render() {
		const { id, workspacesApplicationsSettings, groupLocked, role } = this.props;
        let item = workspacesApplicationsSettings.filter((setting) => setting.id === id)[0];

        if (role.user_role !== 'Administrator' && item.locked)
            return (null);

        if (item.component_type === 'Text') {
            return (
				<WorkspacesApplicationsSettingsTextbox id={item.id} groupLocked={groupLocked} workspacesApplicationsSettings={workspacesApplicationsSettings} role={role} />
            );
        }
        else if (item.component_type === 'Slider') {
            return (
				<WorkspacesApplicationsSettingsSlider id={item.id} groupLocked={groupLocked} workspacesApplicationsSettings={workspacesApplicationsSettings} role={role} />
            );
        }
        else if (item.component_type === 'CheckBox') {
            return (
				<WorkspacesApplicationsSettingsCheckbox id={item.id} groupLocked={groupLocked} workspacesApplicationsSettings={workspacesApplicationsSettings} role={role} />
            );
        }
        else if (item.component_type === 'MultiSelect') {
            return (
				<WorkspacesApplicationsSettingsMultiSelect id={item.id} groupLocked={groupLocked} workspacesApplicationsSettings={workspacesApplicationsSettings} role={role} />
            );
        }
        else {
            return (null);
        }
    }
}

const mapStateToProps = ({ detail, workspacesApplicationsUsersSettings, role,workspacesApplicationsSettings }) => ({
    workspace: detail.workspace,
    workspaceApplication: detail.workspace_application,
	workspacesApplicationsUsersSettings,
    role,
    workspacesApplicationsSettings
});

const mapDispatchToProps = (dispatch) => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsSettingsCardItem));
