import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearUserAction } from '../../../actions/clearUserAction';
import { getUserByIdWithThunk } from '../../../api/usersThunk';
import { getWorkspaceByIdWithThunk } from '../../../api/workspacesThunk';
import { getUrlWorkspaceId, getUrlUserId, getUrlWorkspaceMemberId } from '../../../utils/url';
import { getSessionStorage } from '../../../utils/sessionStorage';
import Loader from 'react-loader-spinner'
import SidebarContainer from '../../Sidebar/SidebarContainer';
import UsersDetailHeader from '../../Users/Detail/UsersDetailHeader';
import UsersDetailBody from '../../Users/Detail/UsersDetailBody';
import UsersModal from '../Modal/UsersModal';
import { changeNavigationAction } from '../../../actions/changeNavigationAction';
import UsersLoginErrorModal from '../Modal/UsersLoginErrorModal';
import UsersCreationErrorModal from '../Modal/UsersCreationErrorModal';
import UsersResetPasswordErrorModal from '../Modal/UsersResetPasswordErrorModal';
import UsersRegistrationReminderErrorModal from '../Modal/UsersRegistrationReminderErrorModal'
import { isWorkspaceRootPage } from '../../../utils/login';

class UsersDetailContainer extends Component {
    detailStart = React.createRef()
    static propTypes = {
        user: PropTypes.object.isRequired,
        getUserById: PropTypes.func.isRequired,
        clearUser: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired
    };

    getCurrentUserId = () => {
        let id = '';
        if (isWorkspaceRootPage(this.props.location.pathname)) {
            id = getUrlWorkspaceMemberId(this.props.location.pathname);
        }
        else {
            id = getUrlUserId(this.props.location.pathname);
        }

        return id;
    }

    changeNavigation(name) {
        let navigation = {
            name
        };
        this.props.dispatchNavigation(navigation);
    }

    scrollToTop = () => {
        this.detailStart.current.scrollIntoView({ behavior: 'smooth' })
    }

    getCurrentWorkspaceId() {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    componentDidMount() {
        const { role, workspace, loading } = this.props;
        const id = this.getCurrentWorkspaceId();
        if (loading === false) {
            const userId = this.getCurrentUserId();
            const width = Math.ceil((window.innerWidth - 560) * (3 / 12));
            const height = width;
            this.props.getUserById(userId, true, width, height);
            if (id !== workspace.workspace_name.toLowerCase().replaceAll(" ", "+") && id !== "") {
                let userId = role.user_id;
                if (userId === "") {
                    const sessionRole = getSessionStorage("role");

                    if (sessionRole !== "") {
                        const role = JSON.parse(sessionRole);
                        userId = role.user_id;
                    }
                }
                this.props.getWorkspaceById(id, userId);
            }
        }
        this.changeNavigation('Users');
    }

    componentWillUnmount() {
        this.props.clearUser();
    }

    render() {
        const { user, loading, loadingTheme } = this.props;

        if (user.id === undefined || user.id === '')
            return (
                <div>
                    {(loading || loadingTheme) &&
                        <div className="loader-container">
                            <Loader
                                type="BallTriangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            );

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="BallTriangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <div ref={this.detailStart} />
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <UsersDetailHeader />
                                    <UsersDetailBody scrollTop={this.scrollToTop} />
                                </div>
                            </div>
                        </main>
                        <UsersModal type="detail" />
                        <UsersLoginErrorModal type="error" />
                        <UsersCreationErrorModal type="error" />
                        <UsersResetPasswordErrorModal type="error" />
                        <UsersRegistrationReminderErrorModal type="error" />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ navigation, detail, role, loading }) => ({
    navigation,
    user: detail.user,
    workspace: detail.workspace,
    role,
    loading: loading.user.loading,
    loadingTheme: loading.theme.loading
});

const mapDispatchToProps = (dispatch) => ({
    dispatchNavigation: (navigation) => dispatch(changeNavigationAction(navigation)),
    getUserById: (userId, withPicture, width, height) => dispatch(getUserByIdWithThunk(userId, withPicture, width, height)),
    getWorkspaceById: (workspaceId, userId) => dispatch(getWorkspaceByIdWithThunk(workspaceId, userId)),
    clearUser: () => dispatch(clearUserAction([]))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersDetailContainer));
