import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import GridHeaderItem from './GridHeaderItem';

class GridHeader extends Component {
    static propTypes = {
        headers: PropTypes.array.isRequired
    };

    render() {
        const { headers } = this.props;

        return (
            <thead>
                <tr>
                    {headers.map((header) => (
                        <GridHeaderItem key={header.id} header={header} />
                    ))}
                    <th id="grid-header-properties" className="grid-no-sort" style={{ display: 'none' }}>Properties</th>
                    <th className="stickyAction grid-no-sort">Action</th>
                </tr>
            </thead>
        );
    }
}

export default withRouter(GridHeader);