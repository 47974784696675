import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RichTextEditor from '../../../Editor/RichTextEditor'
import { setupNewWorkspaceCategoryAction } from '../../../../actions/setupNewWorkspaceCategoryAction';
import { createWorkspaceCategoryWithThunk } from '../../../../api/workspacesCategoriesThunk';
import { getUrlWorkspaceId } from '../../../../utils/url';
import { routeCodes } from '../../../../configurations/routes';
import WorkspaceCategoriesBanner from '../Image/WorkspaceCategoriesBanner'
import WorkspaceCategoriesIcon from '../Image/WorkspaceCategoriesIcon';

class WorkspacesCategoriesCreateBody extends Component {
    constructor(props) {
        super(props);
        this.onTitleChange = this.onTitleChange.bind(this);
        this.onDescriptionChange = this.onDescriptionChange.bind(this);
        this.onMapUrlChange = this.onMapUrlChange.bind(this);
        this.openWorkspaceApplicationList = this.openWorkspaceApplicationList.bind(this);
        this.createNewWorkspceCategory = this.createNewWorkspceCategory.bind(this);
    }

    static propTypes = {
        workspace_category: PropTypes.object.isRequired
    };

    getCurrentWorkspaceId = () => {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    onTitleChange(e) {
        let newCategory = {
            ...this.props.workspace_category,
            category_title: e.target.value,
        };

        this.props.setupNewCategory(newCategory);
    }

    onDescriptionChange(e) {
        let newCategory = {
            ...this.props.workspace_category,
            category_description: e.target.value
        };
        this.props.setupNewCategory(newCategory);
    }

    onMapUrlChange(e) {
        let newCategory = {
            ...this.props.workspace_category,
            mapUrl: e.target.value
        };
        this.props.setupNewCategory(newCategory);
    }

    openWorkspaceApplicationList() {
        const workspaceName = this.getCurrentWorkspaceId();
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspaceName + "/applications");
    }

    createNewWorkspceCategory() {
        const { workspace_category } = this.props;
        const workspaceName = this.getCurrentWorkspaceId();
        
        this.props.createWorkspaceCategory(workspaceName, workspace_category);
    }

    disableCreationButton() {
        return !Boolean(this.props.workspace_category.category_title);
    }

    render() {
        return (
            <div>
                <div className="mainHead">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="float-left mt-2 mr-4">New Workspace Category</h2>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className="mainBody">
                    <div className="formWrap">
                        <div className="row mt-5">
                            <div className="col-7">
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onTitleChange} required />
                                    <label>Title</label>
                                </div>
                                <div className="inputForm">
                                    <input className="form-control" type="text" name="" onChange={this.onDescriptionChange} required />
                                    <label>Description</label>
                                </div>
                                <div className="inputForm mb-5">
                                    <input className="form-control" type="text" name="" onChange={this.onMapUrlChange} required />
                                    <label>Map url</label>
                                </div>
                                <div className="mb-3">
                                    <WorkspaceCategoriesIcon />
                                </div>
                                <div className="mb-3">
                                    <WorkspaceCategoriesBanner />
                                </div>
                                <div>
                                    <div className="inputForm buttonGroup mt-5">
                                        <button className="btn greyBtn icoTextBtn mx-2" onClick={this.openWorkspaceApplicationList}><span className="icon-wrd-close"></span>Cancel</button>
                                        <button className="btn greenBtn icoTextBtn mx-2" onClick={this.createNewWorkspceCategory} disabled={this.disableCreationButton()}><span className="icon-wrd-add"></span> Create Workspace Category</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ setup }) => ({
    workspace_category: setup.workspace_category
});

const mapDispatchToProps = (dispatch) => ({
    setupNewCategory: (newCategory) => dispatch(setupNewWorkspaceCategoryAction(newCategory)),
    createWorkspaceCategory: (workspaceId, category) => dispatch(createWorkspaceCategoryWithThunk(workspaceId, category))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesCategoriesCreateBody));
