import { GET_WORKSPACES_SORTING, GET_WORKSPACES_MEMBERS_SORTING, GET_WORKSPACES_FOCUS_AREAS_SORTING, GET_USERS_SORTING } from '../actions/index';

const initialSortingState = {
    workspace: {
        sort_by: 'Name',
        sort_type: 'Ascending'
    },
    member: {
        sort_by: 'UserName',
        sort_type: 'Ascending'
    },
    area: {
        sort_by: 'focusAreaName',
        sort_type: 'Ascending'
    },
    user: {
        sort_by: 'UserName',
        sort_type: 'Ascending'
    }
}

export const sorting = (state = initialSortingState, action) => {
    const { workspace, member, area, user} = action

    switch (action.type) {
        case GET_WORKSPACES_SORTING:
            return {
                ...state,
                workspace
            }
        case GET_WORKSPACES_MEMBERS_SORTING:
            return {
                ...state,
                member
            }
        case GET_WORKSPACES_FOCUS_AREAS_SORTING:
            return {
                ...state,
                area
            }
        case GET_USERS_SORTING:
            return {
                ...state,
                user
            }
        default:
            return state;
    }
};