import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { routeCodes } from '../../../../configurations/routes';
import { getCookies } from '../../../../backend/api/getCookies';

class WorkspacesApplicationsSettingsHeader extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        workspaceApplication: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
    };

    render() {
        const { workspace, workspaceApplication, query } = this.props;

        if ((workspace.id === undefined || workspace.id === '') &&
            (workspaceApplication.application_id === undefined || workspaceApplication.application_id === ''))
            return (null);

        return (
            <div className="mainHead">
                <div className="row">
                    <div className="col-12">
                        <h2 className="float-left mt-2 mr-4">Application</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="appInfo">
                            <div className="row">
                                <div className="appName col-xl-7 offset-xl-3">{workspaceApplication.application_name}</div>
                                <div className="workspaceName col-xl-7 offset-xl-3">
                                    <h3 className="mr-4">{workspace.workspace_name}</h3>
                                </div>
                            </div>
                            <nav className="col-lg-6 col-xl-5">
                                <div className="row text-center">
                                    <div className="col px-0">
                                        <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/applications/' + workspaceApplication.application_name.toLowerCase().replaceAll(" ", "+") + "/properties"} title="Workspace Application Detail" className="workspaceMenu py-3">Details</Link>
                                    </div>
                                    <div className="col px-0">
                                        <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/applications/' + workspaceApplication.application_name.toLowerCase().replaceAll(" ", "+") + '/settings'} title="Workspace Application Settings" className={`workspaceMenu py-3 ${query() === null && 'active'}`}>Settings</Link>
                                    </div>
                                    {!getCookies.isMikeMine && (
                                    <div className="col px-0" >
                                        <span className={`workspaceMenu cursor-pointer py-3 ${query() === 'yml' && 'active'}`} onClick={() => this.props.history.push('?tab=yml')}>YML Settings</span>
                                    </div>)}
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role }) => ({
    workspace: detail.workspace,
    workspaceApplication: detail.workspace_application,
    role
});

const mapDispatchToProps = (dispatch) => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsSettingsHeader));
