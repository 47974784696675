import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../configurations/routes';
import { updatePortalSettingTabAction } from '../../../actions/updatePortalSettingTabAction';

class PortalSettingsHeader extends Component {
    static propTypes = {
        activeTab: PropTypes.string.isRequired,
        dispatchActiveTab: PropTypes.func.isRequired
    };

    switchActiveTab(activeTab) {
        this.props.dispatchActiveTab(activeTab);
    }

    render() {
        const { path } = this.props.match;
        return (
            <div className="mainHead">
                <div className="row">
                    <div className="col-12">
                        <h2 className="float-left mt-2 mr-4">Portal Settings</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="workspaceInfo">
                            <nav className="col-lg-5 col-xl-4">
                                <div className="row text-center">
                                    <div className="col px-0">
                                        <Link to={routeCodes.PORTAL_SETTINGS} className={path === routeCodes.PORTAL_SETTINGS ? 'workspaceMenu py-3 active' : 'workspaceMenu py-3'}> Banners </Link>
                                    </div>
                                    <div className="col px-0">
                                        <Link to={routeCodes.PORTAL_FEATURES} className={path === routeCodes.PORTAL_FEATURES ? 'workspaceMenu py-3 active' : 'workspaceMenu py-3'}> Features </Link>
                                    </div>
                                    <div className="col px-0">
                                        <Link to={routeCodes.PORTAL_NEWS} className={path === routeCodes.PORTAL_NEWS ? 'workspaceMenu py-3 active' : 'workspaceMenu py-3'}> News </Link>
                                    </div>
                                    <div className="col px-0">
                                        <Link to={routeCodes.PORTAL_OPTIONS} 
                                        className={path === routeCodes.PORTAL_OPTIONS ||path === routeCodes.PORTAL_OPTIONS_EMAIL || path === routeCodes.PORTAL_OPTIONS_THEME  ? 'workspaceMenu py-3 active' : 'workspaceMenu py-3'}> Settings </Link>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ portalSettings }) => ({
    activeTab: portalSettings.active_tab
});

const mapDispatchToProps = (dispatch) => ({
    dispatchActiveTab: (active_tab) => dispatch(updatePortalSettingTabAction(active_tab))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalSettingsHeader));