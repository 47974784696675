import {
	checkUserAvailableApplicationApi
} from '../backend/domain/users';

import {
    getUserWorkspaceByIdApi
} from '../backend/domain/workspaces';

import {
    getWorkspaceApplicationPropertiesByIdApi,
    getWorkspaceApplicationConnectionUsageVerificatonApi,
    getWorkspaceApplicationByIdApi,
    getWorkspaceApplicationHeaderByIdApi,
    updateWorkspaceApplicationApi,
    updateWorkspaceApplicationStatusApi,
    updateOrderApplicationApi
} from '../backend/domain/workspacesApplications';

import { getWorkspaceAction } from '../actions/getWorkspaceAction';
import { getWorkspaceAvailableApplicationsAction } from '../actions/getWorkspaceAvailableApplicationsAction';
import { getWorkspaceApplicationAction } from '../actions/getWorkspaceApplicationAction';
import { clearWorkspaceApplicationAction } from '../actions/clearWorkspaceApplicationAction';
import { clearWorkspaceApplicationLandingAction } from '../actions/clearWorkspaceApplicationLandingAction';
import { getWorkspaceApplicationConnectionsAction } from '../actions/getWorkspaceApplicationConnetionsAction';
import { updateWorkspaceApplicationAction } from '../actions/updateWorkspaceApplicationAction';
import { loadingWorkspaceApplicationAction } from '../actions/loadingWorkspaceApplicationAction';
import { loadingWorkspacesApplicationsAction } from '../actions/loadingWorkspacesApplicationsAction';
import { updateDialogWorkspacesApplicationsConnectionAction } from '../actions/updateDialogWorkspacesApplicationsConnectionAction';
import { routeCodes } from '../configurations/routes';
import { anonymousUserId } from '../configurations/app';
import { handleWorkspaceApplicationUpdateError } from '../utils/errorMessages';
import { updateWorkspaceApplicationLanding, updateWorkspaceApplicationLandingState } from '../utils/workspacesApplicationsLanding';
import { overrideWorkspaceId } from '../utils/workspaces';
import { overrideWorkspaceApplicationId, getWorkspaceApplicationNameById } from '../utils/workspacesApplications';
import { updateUnavailableContextAction } from '../actions/updateUnavailableContextAction';
import { loadingPageRedirectionAction } from '../actions/loadingPageRedirectionAction';
import { getWorkspaceThemeSettingImplementationWithThunk } from '../api/workspacesThemeSettingsThunk';
import { store } from '../index';

export const getWorkspaceApplicationByIdWithThunk = (workspaceId, applicationId, userId) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);
        let appId = await overrideWorkspaceApplicationId(wId, applicationId);

        dispatch(clearWorkspaceApplicationAction([]));
		dispatch(loadingWorkspaceApplicationAction(true));
        getUserWorkspaceByIdApi(wId, userId)
			.then((response) => {
                if (!response.error) {
					dispatch(getWorkspaceAction(response));

					if (response.available_applications !== undefined) {
						let availableApplications = {};
						availableApplications.id = response.available_applications.id;
						availableApplications.applications = response.available_applications.applications;
						dispatch(getWorkspaceAvailableApplicationsAction(availableApplications));
					}
				}
				return response;
			})
			.then(() => {
                getWorkspaceApplicationByIdApi(wId, appId)
					.then(response => {
						dispatch(getWorkspaceApplicationAction(response));
						if (response.connections !== undefined) {
							let workspaceApplicationConnections = {};
							workspaceApplicationConnections.connections = response.connections;
							dispatch(getWorkspaceApplicationConnectionsAction(workspaceApplicationConnections));
						}
					})
					.then((response) => {
						dispatch(loadingWorkspaceApplicationAction(false));
						return response;
					});
			});
	};

export const getWorkspaceApplicationPropertiesWithThunk = (workspaceId, applicationId) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);
        let appId = await overrideWorkspaceApplicationId(wId, applicationId);

        dispatch(clearWorkspaceApplicationAction([]));
        dispatch(loadingWorkspaceApplicationAction(true));
        getWorkspaceApplicationByIdApi(wId, appId)
            .then(response => {
                dispatch(getWorkspaceApplicationAction(response));
                if (response.connections !== undefined) {
                    let workspaceApplicationConnections = {};
                    workspaceApplicationConnections.connections = response.connections;
                    dispatch(getWorkspaceApplicationConnectionsAction(workspaceApplicationConnections));
                }
            })
            .then((response) => {
                dispatch(loadingWorkspaceApplicationAction(false));
                return response;
            });
    };

export const getWorkspaceApplicationWithThunk = (workspaceId, applicationId, userId, getToken) =>
	(dispatch) => {
		dispatch(loadingWorkspaceApplicationAction(true));
        dispatch(clearWorkspaceApplicationLandingAction([]));

        getWorkspaceApplicationPropertiesByIdApi(workspaceId, applicationId)
            .then((response) => {
                if (!response.error) {
                    updateWorkspaceApplicationLanding(response.workspace_application_landing, userId, getToken);
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspaceApplicationAction(false));
                return response;
            });
	};

export const checkApplicationPublicAccessWithThunk = (workspaceId, applicationId, isLoggedIn, isMember, isAdmin, location, userId) =>
    new Promise(async (resolve, reject) => {
        let wId = await overrideWorkspaceId(workspaceId);
        let appId = await overrideWorkspaceApplicationId(wId, applicationId);

        store.dispatch(loadingPageRedirectionAction(true));
        getWorkspaceApplicationPropertiesByIdApi(wId, appId)
			.then(async (response) => {
                if (!response.error) {
                    if (userId === "") {
                        userId = anonymousUserId;
                    }
                    await updateWorkspaceApplicationLandingState(wId);
                    await updateWorkspaceApplicationLanding(response.workspace_application_landing, userId, true);

                    let result = '';
                    let application = response.workspace_application_landing.application;
					if (!application.allow_public_access) {
						if (isLoggedIn) {
							if (!isMember && !isAdmin) {
								result = routeCodes.PROFILE;
							}
							else {
                                if (application.global_status === 'Enabled') {
                                    if (application.status === 'Enabled') {
										if (isAdmin) {
											result = location;
										} else {
                                            await checkUserAvailableApplicationApi(userId, wId, appId).then((accessResponse) => {
												if (accessResponse) {
													result = location;
												}
												else {
                                                    store.dispatch(updateUnavailableContextAction('notAuthorizeApplication'));
													result = routeCodes.UNAVAILABLE_PAGE + '?page=' + location;
												}
											})
										}
									} else {
                                        store.dispatch(updateUnavailableContextAction('suspendedApplication'));
										result = routeCodes.UNAVAILABLE_PAGE + '?page=' + location;
									}
								} else {
                                    store.dispatch(updateUnavailableContextAction('disabledApplication'));
									result = routeCodes.UNAVAILABLE_PAGE + '?page=' + location;
								}
							}
                        }
                        else {
							result = routeCodes.USERS_LOGIN + '/' + workspaceId;
						}
                    }
                    else {
                        if (application.global_status === 'Enabled') {
                            if (application.status === 'Enabled') {
								result = location;
							} else {
                                store.dispatch(updateUnavailableContextAction('suspendedApplication'));
								result = routeCodes.UNAVAILABLE_PAGE + '?page=' + location;
							}
						} else {
                            store.dispatch(updateUnavailableContextAction('disabledApplication'));
							result = routeCodes.UNAVAILABLE_PAGE + '?page=' + location;
						}
                    }
                    if (userId === anonymousUserId && result !== routeCodes.USERS_LOGIN + '/' + workspaceId) {
                        await getWorkspaceThemeSettingImplementationWithThunk(workspaceId);
                    }
                    resolve(result);
                }
                else {
					reject('Disabled')
				}
			})
			.then(() => {
				store.dispatch(loadingPageRedirectionAction(false));
			});
	});


export const getWorkspaceApplicationHeaderByIdWithThunk = (workspaceId, applicationId, isOwner, isAdmin, location) =>
	new Promise(async (resolve, reject) => {
        store.dispatch(loadingPageRedirectionAction(true));

        let wId = await overrideWorkspaceId(workspaceId);
        let appId = await overrideWorkspaceApplicationId(wId, applicationId);
        getWorkspaceApplicationHeaderByIdApi(wId, appId)
			.then(async (response) => {
				if (!response.error) {
					let result = '';
					if (response.global_status === 'Enabled') {
						if (isOwner || isAdmin) {
							result = location;
						}
                        else {
                            result = routeCodes.WORKSPACES_LIST + '/' + workspaceId;
						}
					}
					else {
                        store.dispatch(updateUnavailableContextAction('disabledApplication'));
						result = routeCodes.UNAVAILABLE_PAGE + '?page=' + location;
					}
					resolve(result);
				}
				else {
					reject('Disabled');
				}
			})
			.then(() => {
				store.dispatch(loadingPageRedirectionAction(false));
			});;
	});

export const updateWorkspaceApplicationWithThunk = (workspaceApplication, bypassUrl, historyProps) =>
	(dispatch) => {
		dispatch(loadingWorkspaceApplicationAction(true));
        let originalWorkspaceApplicationName = workspaceApplication.application_name;
        workspaceApplication.application_name = (workspaceApplication.temp_application_name !== undefined) ? workspaceApplication.temp_application_name : originalWorkspaceApplicationName;
		updateWorkspaceApplicationApi(workspaceApplication, bypassUrl)
              .then(async (response) => {
                if (response.status === 200) {
                  dispatch(updateWorkspaceApplicationAction(workspaceApplication));
                  if (typeof response.id === 'string') {
                    let ids = response.id.split("/");
                    let wId = ids[0].split(":")[1];
                    let aId = ids[1].split(":")[1];
                    let w = await getWorkspaceApplicationNameById(wId, aId);
                    let a = w.workspace_application_landing.application;
                    let link = routeCodes.WORKSPACES_LIST + '/' + workspaceApplication.workspace_name.toLowerCase().replaceAll(" ", "+") + '/applications/' + a.application_name.toLowerCase().replaceAll(" ", "+") + "/properties";
                    historyProps.push(link);
                }
                else {
                    workspaceApplication.application_name = originalWorkspaceApplicationName;
                }
                handleWorkspaceApplicationUpdateError(dispatch, response.status, response.error_messages);
				return response;
			}})
			.then((response) => {
				dispatch(loadingWorkspaceApplicationAction(false));
				return response;
			});
	};

export const updateWorkspaceApplicationStatusWithThunk = (workspaceApplication) =>
	(dispatch) => {
		dispatch(loadingWorkspaceApplicationAction(true));
        updateWorkspaceApplicationStatusApi(workspaceApplication.workspace_id, workspaceApplication.application_id, workspaceApplication.status, false)
			.then((response) => {
				dispatch(updateWorkspaceApplicationAction(workspaceApplication));
				return response;
			})
			.then((response) => {
				dispatch(loadingWorkspaceApplicationAction(false));
				return response;
			});
	};

export const updateWpAppsOrderWithThunk = (workspaceId, apps) =>
	(dispatch) => {
		dispatch(loadingWorkspacesApplicationsAction(true));
        updateOrderApplicationApi(workspaceId, apps)
			.then((response) => {
				dispatch(loadingWorkspacesApplicationsAction(false));
			});
    };

export const updateWorkspaceApplicationConnectionWithThunk = (workspaceApplication, connections, role, historyProps) =>
    (dispatch) => {
        const workspaceId = workspaceApplication.workspace_id;
        const applicationId = workspaceApplication.application_id;

        let connectionIds = "";
        let connectionType = [];
        for (let i in workspaceApplication.connections) {
            let connection = workspaceApplication.connections[i];
            if (connection.connection_string !== connections[i].connection_string) {
                let connectionArr = connection.connection_string.split("&");
                connectionIds += (connectionIds !== "") ? (!connectionIds.includes(connectionArr[1])) ? "," + connectionArr[1] : "" : connectionArr[1];
                if (connectionArr[0].toLowerCase() === 'domainservices') {
                    connectionType.push({ is_domain_service: true });
                }
                else {
                    connectionType.push({ is_domain_service: false });
                }
            }
        }

        let domainServicesConnections = connectionType.filter((item) => item.is_domain_service);
        let hasDomainServicesConnections = (domainServicesConnections.length > 0) ? true : false;

        getWorkspaceApplicationConnectionUsageVerificatonApi(workspaceId, applicationId, connectionIds, hasDomainServicesConnections)
            .then((response) => {
                if (response.workspaceApplication && !response.error) {
                    let acrossApplications = response.workspaceApplication.usage_across_applications;
                    let acrossWorkspaces = response.workspaceApplication.usage_across_workspaces;
                    let userRole = role.user_role;

                    if (!acrossApplications && !acrossWorkspaces) {
                        dispatch(loadingWorkspaceApplicationAction(true));

                        let originalWorkspaceApplicationName = workspaceApplication.application_name;
                        workspaceApplication.application_name = (workspaceApplication.temp_application_name !== undefined) ? workspaceApplication.temp_application_name : originalWorkspaceApplicationName;
                        updateWorkspaceApplicationApi(workspaceApplication, false)
                            .then(async (response) => {
                                if (response.status === 200) {
                                    dispatch(updateWorkspaceApplicationAction(workspaceApplication));
                                    let ids = response.id.split("/");
                                    let wId = ids[0].split(":")[1];
                                    let aId = ids[1].split(":")[1];
                                    let w = await getWorkspaceApplicationNameById(wId, aId);
                                    let a = w.workspace_application_landing.application;
                                    let link = routeCodes.WORKSPACES_LIST + '/' + workspaceApplication.workspace_name.toLowerCase().replaceAll(" ", "+") + '/applications/' + a.application_name.toLowerCase().replaceAll(" ", "+") + "/properties";
                                    historyProps.push(link);
                                }
                                else {
                                    workspaceApplication.application_name = originalWorkspaceApplicationName;
                                }
                                handleWorkspaceApplicationUpdateError(dispatch, response.status, response.error_messages);
                                return response;
                            })
                            .then((response) => {
                                dispatch(loadingWorkspaceApplicationAction(false));
                                return response;
                            })                    }
                    else {
                        showConnectionDialog(workspaceApplication, acrossApplications, acrossWorkspaces, userRole, dispatch);
                    }
                }
                return response;
            });
    };

const showConnectionDialog = (workspaceApplication, acrossApplications, acrossWorkspaces, userRole, dispatch) => {
    if (acrossApplications) {
        let workspacesApplications = {
            id: workspaceApplication.application_id,
            name: workspaceApplication.application_name,
            status: workspaceApplication.status,
            dialog_title: 'Warning',
            dialog_type: 'connection_1',
            show_dialog: true
        };
        dispatch(updateDialogWorkspacesApplicationsConnectionAction(workspacesApplications));
    }
    else {
        if (acrossWorkspaces) {
            if (userRole === "Administrator") {
                let workspacesApplications = {
                    id: workspaceApplication.application_id,
                    name: workspaceApplication.application_name,
                    status: workspaceApplication.status,
                    dialog_title: 'Warning',
                    dialog_type: 'connection_3',
                    show_dialog: true
                };
                dispatch(updateDialogWorkspacesApplicationsConnectionAction(workspacesApplications));

            }
            else if (userRole === "Owner") {
                let workspacesApplications = {
                    id: workspaceApplication.application_id,
                    name: workspaceApplication.application_name,
                    status: workspaceApplication.status,
                    dialog_title: 'Warning',
                    dialog_type: 'connection_2',
                    show_dialog: true
                };
                dispatch(updateDialogWorkspacesApplicationsConnectionAction(workspacesApplications));
            }
        }
    }
}
