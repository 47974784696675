import {
    deleteFocusAreasSetGeoApi,
    postFocusAreasSetGeoApi,
    getFocusAreasSetGeoApi,
    getWorkspacesFocusAreasApi,
    getWorkspaceFocusAreaConnectionsApi,
    createWorkspacesFocusAreasApi,
    updateWorkspacesFocusAreasApi,
    removeWorkspacesFocusAreasApi,
    updateWorkspaceFocusAreaConnectionsApi,
    addWorkspaceFocusAreaShapeApi
} from '../backend/domain/workspacesFocusAreas';

import {
    getUserWorkspaceByIdApi
} from '../backend/domain/workspaces';

import { getSpatialReferenceStringApi } from './gisApi';
import { updateMapGeometryAction } from '../actions/updateMapGeometryAction';
import { updateDialogWorkspacesSetGeoAction } from '../actions/updateDialogWorkspacesSetGeoAction';
import { updateDialogFocusAreasSetGeoErrorAction } from '../actions/updateDialogFocusAreasSetGeoErrorAction';
import { updateToasterAction } from './../actions/updateToasterAction';
import { getWorkspaceAction } from '../actions/getWorkspaceAction';
import { getWorkspaceAvailableApplicationsAction } from '../actions/getWorkspaceAvailableApplicationsAction';
import { getWorkspacesFocusAreasAction } from '../actions/getWorkspacesFocusAreasAction';
import { clearWorkspacesFocusAreasAction } from '../actions/clearWorkspacesFocusAreasAction';
import { clearFocusAreaMapAction } from '../actions/clearFocusAreaMapAction';
import { loadingWorkspacesFocusAreasAction } from '../actions/loadingWorkspacesFocusAreasAction';
import { loadingWorkspaceFocusAreaConnectionsAction } from '../actions/loadingWorkspaceFocusAreaConnectionsAction';
import { loadingWorkspaceAction } from '../actions/loadingWorkspaceAction';
import { getWorkspaceFocusAreaConnectionsAction } from '../actions/getWorkspaceFocusAreaConnectionsAction';
import { clearWorkspaceFocusAreaConnectionsAction } from '../actions/clearWorkspaceFocusAreaConnectionsAction';
import { updateDialogWorkspacesConnectionsAction } from '../actions/updateDialogWorkspacesConnectionsAction';
import { setInitialSelectedFocusArea, setSelectedFocusArea, getSelectedFocusArea, drawMap, setFeaturesCoordinates } from '../utils/map';
import { handleFocusAreasShapeError } from '../utils/errorMessages';
import { overrideWorkspaceId } from '../utils/workspaces';

export const deleteSetGeometryWithThunk = (id, data) =>
    (dispatch) => {
        dispatch(loadingWorkspacesFocusAreasAction(true));
        deleteFocusAreasSetGeoApi(data.workspaceId, data.focusAreaId)
            .then((response) => {
                if (response.status >= 400) {
                    const message = {
                        status: response.status,
                        message: '',
                        error: 'Geometry Is Not Set ',
                        show_dialog: true,
                        redirect: false,
                    }
                    dispatch(updateToasterAction(message));
                } else {
                    getWorkspacesFocusAreasApi(id)
                        .then((response) => {
                            dispatch(clearWorkspacesFocusAreasAction([]));
                            dispatch(clearFocusAreaMapAction([]));
                            return response;
                        })
                        .then(async (response) => {
                            const focusAreas = await setSelectedFocusArea(data.focusAreaId, response.areas)
                            if (focusAreas.length > 0) {
                                dispatch(getWorkspacesFocusAreasAction(focusAreas));
                                getSelectedFocusArea();
                            }
                            return response;
                        })
                        .then(response => {
                            dispatch(loadingWorkspacesFocusAreasAction(false));
                            return response;
                        })
                        .then((response) => {
                            const message = {
                                status: response.status,
                                message: 'Refresh Geometry success !!',
                                error: '',
                                show_dialog: true,
                                redirect: false,
                            }
                            dispatch(updateToasterAction(message));
                        });
                }
                return response;
            })
    };

export const postSetGeometryWithThunk = (id, data) =>
    (dispatch) => {
        dispatch(loadingWorkspacesFocusAreasAction(true));
        postFocusAreasSetGeoApi(data.workspaceId, data.focusAreaId, data)
            .then((response) => {
                if (response.status >= 400) {
                    const setGeo = {
                        id: '',
                        name: '',
                        status: '',
                        format: 'GeoJSON',
                        geometry: '',
                        enabled: '',
                        dialog_title: '',
                        dialog_type: '',
                        show_dialog: false
                    }
                    dispatch(updateDialogWorkspacesSetGeoAction(setGeo));

                    let error_messages = ""
                    if (response.status === 500) {
                        error_messages = 'Internal server error, Please contact administrator.';
                    }
                    else {
                        let i = 0;
                        for (const value of Object.entries(response.data)) {
                            if (i === 0) {
                                error_messages = value[1];
                            }
                            i += 1;
                        }
                    }

                    const message = {
                        id: data.focusAreaId,
                        name: data.focusAreaName,
                        dialog_title: 'Invalid Focus Area Geometry',
                        dialog_type: error_messages,
                        show_dialog: true
                    }
                    dispatch(updateDialogFocusAreasSetGeoErrorAction(message));

                    return true;
                } else {
                    const setGeo = {
                        id: '',
                        name: '',
                        status: '',
                        format: 'GeoJSON',
                        geometry: '',
                        enabled: '',
                        dialog_title: '',
                        dialog_type: '',
                        show_dialog: false
                    }
                    dispatch(updateDialogWorkspacesSetGeoAction(setGeo));

                    const message = {
                        status: response.status,
                        message: 'Focus Area Geometry has been saved succesfully',
                        error: '',
                        show_dialog: true,
                        redirect: false,
                    }
                    dispatch(updateToasterAction(message));
                }

                return response;
            })
            .then(() => {
                getWorkspacesFocusAreasApi(id)
                    .then((response) => {
                        dispatch(clearWorkspacesFocusAreasAction([]));
                        dispatch(clearFocusAreaMapAction([]));
                        return response;
                    })
                    .then(async (response) => {
                        const focusAreas = await setSelectedFocusArea(data.focusAreaId, response.areas)
                        if (focusAreas.length > 0) {
                            dispatch(getWorkspacesFocusAreasAction(focusAreas));
                            getSelectedFocusArea();
                        }
                        return response;
                    })
                    .then(response => {
                        dispatch(loadingWorkspacesFocusAreasAction(false));
                        return response;
                    })
            })
    };

export const getGeometryList = (data) => {
    getFocusAreasSetGeoApi(data.workspaceId, data.focusAreaId)
        .then((response) => {
            return response;
        })
};

export const getGeometrybyIdWithThunk = (data) =>
    (dispatch) => {
        getFocusAreasSetGeoApi(data.workspaceId, data.focusAreaId)
            .then(async (response) => {
                if (response.status === 200) {
                    let geoJson = JSON.parse(response.data.geometry);
                    if (geoJson && geoJson.features !== undefined) {
                        if (geoJson.features[0].properties !== undefined) {
                            const srId = geoJson.features[0].properties.sr_id;
                            let projection = "";
                            if (geoJson.features[0].properties.sr_id !== undefined) {
                                projection = await getSpatialReferenceStringApi(srId);
                            }
                            if (projection !== "") {
                                let features = [];
                                for (let i in geoJson.features) {
                                    let feature = geoJson.features[i];
                                    feature = await setFeaturesCoordinates(feature, projection);
                                    features.push(feature);
                                }
                            }
                        }
                    }
                    else if (geoJson && geoJson.features === undefined) {
                        if (response.data.simple_geometry !== undefined) {
                            geoJson = JSON.parse(response.data.simple_geometry);
                        }
                    }
                    dispatch(updateMapGeometryAction(geoJson.features));
                    drawMap();
                }
                else {
                    dispatch(updateMapGeometryAction([]));
                }
                return response;
            })
    };

export const getSetGeometryWithThunk = (data) =>
    (dispatch) => {
        dispatch(loadingWorkspacesFocusAreasAction(true));
        getFocusAreasSetGeoApi(data.workspaceId, data.focusAreaId)
            .then((response) => {
                if (response.status >= 400 && response.status !== 404) {
                    const setGeo = {
                        id: data.focusAreaId,
                        name: data.focusAreaName,
                        dialog_title: 'Set Geometry',
                        dialog_type: 'set_geometry',
                        geometry: response.data.geometry,
                        format: response.data.format,
                        show_dialog: true,
                    }

                    dispatch(updateDialogWorkspacesSetGeoAction(setGeo));
                }
                else {
                    const setGeo = {
                        id: data.focusAreaId,
                        name: data.focusAreaName,
                        dialog_title: 'Set Geometry',
                        dialog_type: 'set_geometry',
                        geometry: response.data.geometry,
                        format: response.data.format,
                        show_dialog: true,
                    }
                    dispatch(updateDialogWorkspacesSetGeoAction(setGeo));
                }

                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspacesFocusAreasAction(false));
                return response;
            })
    };

export const getWorkspacesFocusAreasWithThunk = (workspaceId) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);
        dispatch(loadingWorkspacesFocusAreasAction(true));
        getWorkspacesFocusAreasApi(wId)
            .then((response) => {
                dispatch(clearWorkspacesFocusAreasAction([]));
                return response;
            })
            .then(async (response) => {
                const focusAreas = await setInitialSelectedFocusArea(response.areas)
                if (focusAreas.length > 0) {
                    dispatch(getWorkspacesFocusAreasAction(focusAreas));
                    getSelectedFocusArea();
                }

                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspacesFocusAreasAction(false));
                return response;
            })
    };

export const getWorkspacesFocusAreasConnectionsWithThunk = (workspaceId) =>
    (dispatch) => {
        dispatch(clearWorkspaceFocusAreaConnectionsAction([]));
        dispatch(loadingWorkspaceFocusAreaConnectionsAction(true));
        getWorkspaceFocusAreaConnectionsApi(workspaceId)
            .then((response) => {
                let workspaceConnections = {};
                workspaceConnections.connections = response.connections;
                dispatch(getWorkspaceFocusAreaConnectionsAction(workspaceConnections));
                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspaceFocusAreaConnectionsAction(false));
                return response;
            })
    };

export const getWorkspacesFocusAreasByIdWithThunk = (workspaceId, userId) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);
        dispatch(loadingWorkspacesFocusAreasAction(true));
        getUserWorkspaceByIdApi(wId, userId)
            .then((response) => {
                if (!response.error) {
                    dispatch(getWorkspaceAction(response));

                    let availableApplications = {};

                    availableApplications.id = response.available_applications.id;
                    availableApplications.applications = response.available_applications.applications;
                    dispatch(getWorkspaceAvailableApplicationsAction(availableApplications));
                }
                return response;
            })
            .then(() => {
                getWorkspacesFocusAreasApi(wId)
                    .then((response) => {
                        dispatch(clearWorkspacesFocusAreasAction([]));
                        return response;
                    })
                    .then(async (response) => {
                        const focusAreas = await setInitialSelectedFocusArea(response.areas)
                        if (focusAreas.length > 0) {
                            dispatch(getWorkspacesFocusAreasAction(focusAreas));
                            getSelectedFocusArea();
                        }
                        return response;
                    })
                    .then((response) => {
                        dispatch(loadingWorkspacesFocusAreasAction(false));
                        return response;
                    });
            });
    };

export const createWorkspacesFocusAreasWithThunk = (workspaceId, focusArea) =>
    (dispatch) => {
        dispatch(loadingWorkspacesFocusAreasAction(true));
        let data = {
            focus_area_name: focusArea.focus_area_name,
        };
        createWorkspacesFocusAreasApi(workspaceId, data)
            .then(() => {
                getWorkspacesFocusAreasApi(workspaceId)
                    .then((response) => {
                        dispatch(clearWorkspacesFocusAreasAction([]));
                        return response;
                    })
                    .then(response => {
                        const focusAreas = setInitialSelectedFocusArea(response.areas)
                        if (focusAreas.length > 0) {
                            dispatch(getWorkspacesFocusAreasAction(focusAreas));
                            getSelectedFocusArea();
                        }
                    })
                    .then((response) => {
                        dispatch(loadingWorkspacesFocusAreasAction(false));
                        return response;
                    });
            });
    };

export const updateWorkspacesFocusAreasWithThunk = (workspaceId, focusAreas) =>
    (dispatch) => {
        dispatch(loadingWorkspacesFocusAreasAction(true));
        updateWorkspacesFocusAreasApi(workspaceId, focusAreas)
            .then(() => {
                getWorkspacesFocusAreasApi(workspaceId)
                    .then((response) => {
                        dispatch(clearWorkspacesFocusAreasAction([]));
                        return response;
                    })
                    .then(response => {
                        const focusAreas = setInitialSelectedFocusArea(response.areas)
                        if (focusAreas.length > 0) {
                            dispatch(getWorkspacesFocusAreasAction(focusAreas));
                            getSelectedFocusArea();
                        }
                        return response;
                    })
                    .then((response) => {
                        dispatch(loadingWorkspacesFocusAreasAction(false));
                        return response;
                    });
            });
    };

export const removeWorkspacesFocusAreasWithThunk = (workspaceId, focusAreaId) =>
    (dispatch) => {
        dispatch(loadingWorkspacesFocusAreasAction(true));
        removeWorkspacesFocusAreasApi(workspaceId, focusAreaId)
            .then(() => {
                getWorkspacesFocusAreasApi(workspaceId)
                    .then((response) => {
                        dispatch(clearWorkspacesFocusAreasAction([]));
                        return response;
                    })
                    .then(response => {
                        const focusAreas = setInitialSelectedFocusArea(response.areas)
                        if (focusAreas.length > 0) {
                            dispatch(getWorkspacesFocusAreasAction(focusAreas));
                            getSelectedFocusArea();
                        }
                    })
                    .then((response) => {
                        dispatch(loadingWorkspacesFocusAreasAction(false));
                        return response;
                    });
            });
    };

export const updateWorkspaceFocusAreaConnectionWithThunk = (workspaceId, connections) =>
    (dispatch) => {
        dispatch(loadingWorkspaceFocusAreaConnectionsAction(true));
        let data = {
            connections: connections,
        };
        updateWorkspaceFocusAreaConnectionsApi(workspaceId, data)
            .then((response) => {
                if (response.status === 200) {
                    let workspaces_connections = {
                        id: workspaceId,
                        name: '',
                        status: '',
                        dialog_title: 'Connection Strings Updated',
                        dialog_type: 'updated',
                        show_dialog: true
                    };

                    dispatch(updateDialogWorkspacesConnectionsAction(workspaces_connections));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspaceFocusAreaConnectionsAction(false));
                return response;
            });
    };

export const addWorkspaceFocusAreaShapeWithThunk = (workspaceId, workspacesFocusAreasShapeHeader, workspacesFocusAreasShape) =>
    (dispatch) => {
        dispatch(loadingWorkspaceAction(true));
        addWorkspaceFocusAreaShapeApi(workspaceId, workspacesFocusAreasShapeHeader, workspacesFocusAreasShape)
            .then((response) => {
                handleFocusAreasShapeError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspaceAction(false));
                return response;
            });
    };