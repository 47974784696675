import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../configurations/routes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { changeNavigationAction } from '../../actions/changeNavigationAction';
import { getPortalLogoThunk } from '../../api/portalLogoThunk';
import { getUrlWorkspaceId } from '../../utils/url';
import {
    isWorkspaceRootPage,
    isApplicationLandingPage,
    isWorkspaceLandingPage,
    isWorkspacePropertiesPage,
    isWorkspaceMemberDetailPage,
    isUnavailablePage
} from '../../utils/login';

class SidebarHeader extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            linkTo: '',
            pointerClass: '',
            logoTooltip: ''
        }
        this.setLogoPosition = this.setLogoPosition.bind(this);
        this.showHideMenu = this.showHideMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);
        this.getMenu = this.getMenu.bind(this);
        this.getSidebarHeaderResize = this.getSidebarHeaderResize.bind(this);
        this.changeNavigation = this.changeNavigation.bind(this);
    }

    state = {
        showMenu: false,
        workspaceRoot: false,
        workspaceId: ''
    }

    static propTypes = {
        portalLogo: PropTypes.object.isRequired,
        dispatchNavigation: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired
    };

    showHideMenu() {
        this.setState({ showMenu: !this.state.showMenu });
    }

    hideMenu() {
        this.setState({ showMenu: false });
    }

    getMenu(url) {
        this.props.history.push(url);
    }

    getSidebarHeaderResize() {
        this.setState({ showMenu: false });
    }

    changeNavigation(name) {
        let navigation = {
            name
        };
        this.props.dispatchNavigation(navigation);
    }

    getCurrentWorkspaceId() {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    getAspectRatioClass(image_aspect_ratio) {
        let aspectRatioClass = 'portal-logo43';
        if (image_aspect_ratio >= 1 && image_aspect_ratio < 1.2) {
            aspectRatioClass = 'portal-logo11';
        }
        else if (image_aspect_ratio >= 1.2 && image_aspect_ratio < 1.4) {
            aspectRatioClass = 'portal-logo43';
        }
        else if (image_aspect_ratio >= 1.4 && image_aspect_ratio < 1.6) {
            aspectRatioClass = 'portal-logo32';
        }
        else if (image_aspect_ratio >= 1.6 && image_aspect_ratio < 2.3) {
            aspectRatioClass = 'portal-logo169';
        }
        else if (image_aspect_ratio >= 2.3) {
            aspectRatioClass = 'portal-logo219';
        }

        return aspectRatioClass;
    }

    setLogoPosition() {
        let logoContainer = document.getElementById("logo-container");
        let logoImage = document.getElementById("logo-image");

        if (logoImage !== null) {
            let imageHeight = logoImage.offsetHeight;
            let top = (130 / 2 - imageHeight / 2);
            logoContainer.style.paddingTop = top + 'px';
        }
    }

    componentDidMount() {
        this._isMounted = true;

        const location = this.props.location.pathname;
        const workspaceId = this.getCurrentWorkspaceId();

        if (this._isMounted && isWorkspaceRootPage(location)) {
            let name = workspaceId;

            if (this._isMounted && isWorkspaceLandingPage(location)) {
                this.setState({ linkTo: routeCodes.WORKSPACES_LIST + '/' + name.toLowerCase().replaceAll(" ", "+") });
                this.setState({ pointerClass: 'defaultCursorSidebarLogo' });
            }
            else if (this._isMounted && isApplicationLandingPage(location)) {
                this.setState({ linkTo: routeCodes.WORKSPACES_LIST + '/' + name.toLowerCase().replaceAll(" ", "+") });
                this.setState({ logoTooltip: 'Go back to workspace' });
            }
            else if (this._isMounted && isWorkspacePropertiesPage(location)) {
                this.setState({ linkTo: routeCodes.WORKSPACES_LIST + '/' + name.toLowerCase().replaceAll(" ", "+") + '/properties' });
                this.setState({ pointerClass: 'defaultCursorSidebarLogo' });
            }
            else if (this._isMounted && isWorkspaceMemberDetailPage(location)) {
                this.setState({ linkTo: routeCodes.WORKSPACES_LIST + '/' + name.toLowerCase().replaceAll(" ", "+") + '/properties' });
                this.setState({ logoTooltip: 'Go back to workspace' });
            }
            else {
                if (this._isMounted) {
                    this.setState({ linkTo: routeCodes.WORKSPACES_LIST + '/' + name.toLowerCase().replaceAll(" ", "+") + '/properties' });
                    this.setState({ logoTooltip: 'Go back to workspace' });
                }
            }
        }
        else if (this._isMounted && isUnavailablePage(location)) {
            let paramsString = window.location.search;
            let params = new URLSearchParams(paramsString);
            let unavailLocation = params.get('page');
            let workspaceName = getUrlWorkspaceId(unavailLocation);
            this.setState({ linkTo: routeCodes.WORKSPACES_LIST + '/' + workspaceName });
            this.setState({ logoTooltip: 'Go back to workspace' });
        }
        else {
            if (this._isMounted) {
                this.setState({ linkTo: location });
                this.setState({ pointerClass: 'defaultCursorSidebarLogo' });
            }
        }
        window.addEventListener('resize', this.getSidebarHeaderResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.getSidebarHeaderResize);
        this._isMounted = false;
    }

    render() {
        const { portalLogo, role, loading } = this.props;

        return (
            <div>
                {loading &&
                    <div className="loader-container-sidebar-logo">
                        <Loader
                            type="ThreeDots"
                            color="#00BFFF"
                            height="50"
                            width="50"
                        />
                    </div>
                }
                {!loading &&
                    <header id="logo-container" className="headerBg">
                        <button onClick={this.showHideMenu} onBlur={() => this.hideMenu()}><span className="icon-wrd-mobile-menu"></span></button>
                        <Link to={this.state.linkTo} onClick={this.onLogoClick} title={this.state.logoTooltip} className={'logo ' + this.state.pointerClass}>
                            {
                                (portalLogo.image === "NoLogo") ?
                                    null
                                    : (portalLogo.image === "Default") ?
                                    <img id="logo-image" src={process.env.PUBLIC_URL + "/images/DHI_Logo.png"} className={(portalLogo.isLoaded) ? "sidebar-header-logo" : "sidebar-header-logo-none"} alt="" />
                                        : (portalLogo.image === "") ? null
                                            : <img id="logo-image" src={((portalLogo.image !== undefined) ? "data:image/png;base64," : "") + portalLogo.image} className={this.getAspectRatioClass(portalLogo.image_aspect_ratio)} alt="" />
                            }
                        </Link>
                        <div className="sticky-action-sidebar">
                            <ul className={(this.state.showMenu) ? "action show-action" : "action hide-action"}>
                                {(role.user_role === 'Administrator' && role.token.userStatus === 'Active') &&
                                    <div>
                                        <li>
                                            <Link to={routeCodes.WORKSPACES_LIST} onClick={() => this.changeNavigation('Workspaces')} onMouseDown={() => this.getMenu(routeCodes.WORKSPACES_LIST)} title="Go to workspace" className="remove">
                                                <span className="icon-wrd-workspace" style={{ paddingRight: '30px' }}></span> Workspaces
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={routeCodes.APPLICATIONS_LIST} onClick={() => this.changeNavigation('Applications')} onMouseDown={() => this.getMenu(routeCodes.APPLICATIONS_LIST)} title="Go to applications" className="remove">
                                                <span className="icon-wrd-applications" style={{ paddingRight: '30px' }}></span> Applications
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={routeCodes.USERS_LIST} onClick={() => this.changeNavigation('Users')} onMouseDown={() => this.getMenu(routeCodes.USERS_LIST)} title="Go to users" className="remove">
                                                <span className="icon-wrd-users" style={{ paddingRight: '30px' }}></span> Users
                                            </Link>
                                        </li>
                                        {/* <li>
                                            <Link to={routeCodes.PORTAL_SETTINGS} onClick={() => this.changeNavigation('PortalSettings')} onMouseDown={() => this.getMenu(routeCodes.PORTAL_SETTINGS)} title="Go to portal settings" className="remove">
                                                <span className="icon-wrd-portal" style={{ paddingRight: '30px' }}></span> Portal Settings
                                            </Link>
                                        </li> */}
                                    </div>
                                }
                                {role.is_logged_in &&
                                    <li>
                                        <Link className="sticky-action-line" to={routeCodes.PROFILE} onClick={() => this.changeNavigation('Profile')} onMouseDown={() => this.getMenu(routeCodes.PROFILE)} title="Go to profile">
                                            <span className="icon-wrd-profile" style={{ paddingRight: '30px'}}></span>Profile
                                        </Link>
                                    </li>
                                }
                                {(role.is_logged_in) &&
                                    <li>
                                        <Link to={routeCodes.USERS_LOGOUT} onClick={() => this.changeNavigation('Logout')} onMouseDown={() => this.getMenu(routeCodes.USERS_LOGOUT)} title="Logout">
                                            <span className="icon-wrd-power" style={{ paddingRight: '30px' }}></span>Logout
                                        </Link>
                                    </li>
                                }
                                {!role.is_logged_in &&
                                    <li>
                                        <Link className="sticky-action-line" to={routeCodes.USERS_LOGIN} onClick={() => this.changeNavigation('Login')} onMouseDown={() => this.getMenu(routeCodes.USERS_LOGIN)} title="Login">
                                            <span className="icon-wrd-log-in" style={{ paddingRight: '30px' }}></span>Login
                                        </Link>
                                    </li>
                                }
                            </ul>
                        </div>
                    </header>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ portalLogo, detail, role, navigation, loading }) => ({
    portalLogo,
    workspace: detail.workspace,
    role,
    navigation,
    loading: loading.portal_logo.loading
});

const mapDispatchToProps = (dispatch) => ({
    getPortalLogoById: (workspaceId) => dispatch(getPortalLogoThunk(workspaceId)),
    dispatchNavigation: (navigation) => dispatch(changeNavigationAction(navigation))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarHeader));
