import {
    LOADING_WORKSPACES_GET_GEOMETRY,
    LOADING_WORKSPACES,
    LOADING_WORKSPACE_DETAIL,
    LOADING_CREATE_WORKSPACE,
    LOADING_APPLICATIONS,
    LOADING_APPLICATIONS_SETTINGS,
    LOADING_APPLICATION,
    LOADING_CREATE_APPLICATION,
    LOADING_CATEGORY,
    LOADING_CREATE_CATEGORY,
    LOADING_WORKSPACES_APPLICATIONS,
	LOADING_WORKSPACES_APPLICATIONS_SETTINGS,
	LOADING_WORKSPACES_APPLICATIONS_USERS_SETTINGS,
    LOADING_WORKSPACES_MEMBERS,
    LOADING_WORKSPACES_SHARE_LIST,
    LOADING_WORKSPACES_FOCUS_AREAS,
    LOADING_WORKSPACE_SETTING,
    LOADING_WORKSPACE_APPLICATION_DETAIL,
    LOADING_WORKSPACE_CATEGORY,
    LOADING_CREATE_WORKSPACE_CATEGORY,
    LOADING_USERS,
    LOADING_USER,
    LOADING_CREATE_USER,
    LOADING_ACCOUNT,
    LOADING_GLOBAL_USERS,
    LOADING_PORTAL_LOGO,
    LOADING_USER_AVAILABLE_WORKSPACES,
    LOADING_PORTAL_LANDING_BANNER,
    LOADING_PORTAL_LANDING_FEATURE,
    LOADING_PORTAL_LANDING_NEWS,
    LOADING_PORTAL_LOGIN,
    LOADING_LOGIN,
    LOADING_PORTAL_SETTING_BANNER,
    LOADING_PORTAL_SETTING_FEATURE,
    LOADING_PORTAL_SETTING_NEWS,
    LOADING_PORTAL_SETTING_LOGIN,
    LOADING_PORTAL_SETTING_EMAIL,
    LOADING_PORTAL_SETTING_THEME,
    LOADING_CHANGE_PASSWORD,
    LOADING_RESET_PASSWORD,
    LOADING_WORKSPACE_FOCUS_AREA_CONNECTIONS,
    LOADING_PAGE_REDIRECTION,
    LOADING_THEME,
    LOADING_CONTACTS_OWNERS,
    LOADING_CONTACT_ADMINISTRATORS,
    LOADING
} from '../actions/index';

const initialLoadingState = {
    defaultLoading: {
        loading: false
    },
    contactAdministrators: {
        loading: false
    },
    contactOwners: {
        loading: false
    },
    workspaces: {
        loading: false
    },
    workspace: {
        loading: false
    },
    create_workspace: {
        loading: false
    },
    applications: {
        loading: false
    },
    applications_settings: {
        loading: false
    },
    application: {
        loading: false
    },
    create_application: {
        loading: false
    },
    category: {
        loading: false
    },
    create_category: {
        loading: false
    },
    workspaces_applications: {
        loading: false
    },
    workspaces_applications_settings: {
        loading: false
	},
	workspaces_applications_users_settings: {
		loading: false
	},
    workspaces_members: {
        loading: false
    },
    workspaces_share_list: {
        loading: false
    },
    workspaces_focus_areas: {
        loading: false
    },
    workspaces_settings: {
        loading: false
    },
    workspace_application: {
        loading: false
    },
    workspace_category: {
        loading: false
    },
    create_workspace_category: {
        loading: false
    },
    users: {
        loading: false
    },
    user: {
        loading: false
    },
    create_user: {
        loading: false
    },
    account: {
        loading: false
    },
    global_users: {
        loading: false
    },
    portal_logo: {
        loading: false
    },
    user_available_workspaces: {
        loading: false
    },
    portal_landing_banner: {
        loading: false
    },
    portal_landing_feature: {
        loading: false
    },
    portal_landing_news: {
        loading: false
    },
    portal_setting_banner: {
        loading: false
    },
    portal_setting_feature: {
        loading: false
    },
    portal_setting_news: {
        loading: false
    },
    portal_login: {
        loading: false
    },
    portal_setting_login: {
        loading: false
    },
    portal_setting_email: {
        loading: false
    },
    portal_setting_theme: {
        loading: false
    },
    login: {
        loading: false
    },
    change_password: {
        loading: false
    },
    reset_password: {
        loading: false
    },
    workspace_focus_area_connections: {
        loading: false
    },
    page_redirection: {
        loading: false
    },
    theme: {
        loading: false
    },
    geometry: {
        loading: false
    }
}

export const loading = (state = initialLoadingState, action) => {
    const { loading } = action

    switch (action.type) {
        case LOADING:
            return {
                ...state,
                defaultLoading: {
                    loading
                }
            }
        case LOADING_WORKSPACES_GET_GEOMETRY:
            return {
                ...state,
                geometry: {
                    loading
                }
            }
        case LOADING_CONTACT_ADMINISTRATORS:
            return {
                ...state,
                contactAdministrators: {
                    loading
                }
            }
        case LOADING_CONTACTS_OWNERS:
            return {
                ...state,
                contactOwners: {
                    loading
                }
            }
        case LOADING_WORKSPACES:
            return {
                ...state,
                workspaces: {
                    loading
                }
            }
        case LOADING_WORKSPACE_DETAIL:
            return {
                ...state,
                workspace: {
                    loading
                }
            }
        case LOADING_CREATE_WORKSPACE:
            return {
                ...state,
                create_workspace: {
                    loading
                }
            }
        case LOADING_APPLICATIONS:
            return {
                ...state,
                applications: {
                    loading
                }
            }
        case LOADING_APPLICATIONS_SETTINGS:
            return {
                ...state,
                applications_settings: {
                    loading
                }
            }
        case LOADING_APPLICATION:
            return {
                ...state,
                application: {
                    loading
                }
            }
        case LOADING_CREATE_APPLICATION:
            return {
                ...state,
                create_application: {
                    loading
                }
            }
        case LOADING_CATEGORY:
            return {
                ...state,
                category: {
                    loading
                }
            }
        case LOADING_CREATE_CATEGORY:
            return {
                ...state,
                create_category: {
                    loading
                }
            }
        case LOADING_WORKSPACES_APPLICATIONS:
            return {
                ...state,
                workspaces_applications: {
                    loading
                }
            }
        case LOADING_WORKSPACES_APPLICATIONS_SETTINGS:
            return {
                ...state,
                workspaces_applications_settings: {
                    loading
                }
			}
		case LOADING_WORKSPACES_APPLICATIONS_USERS_SETTINGS:
			return {
				...state,
				workspaces_applications_users_settings: {
					loading
				}
			}
        case LOADING_WORKSPACES_MEMBERS:
            return {
                ...state,
                workspaces_members: {
                    loading
                }
            }
        case LOADING_WORKSPACES_SHARE_LIST:
            return {
                ...state,
                workspaces_share_list: {
                    loading
                }
            }
        case LOADING_WORKSPACES_FOCUS_AREAS:
            return {
                ...state,
                workspaces_focus_areas: {
                    loading
                }
            }
        case LOADING_WORKSPACE_SETTING:
            return {
                ...state,
                workspaces_settings: {
                    loading
                }
            }
        case LOADING_WORKSPACE_APPLICATION_DETAIL:
            return {
                ...state,
                workspace_application: {
                    loading
                }
            }
        case LOADING_WORKSPACE_CATEGORY:
            return {
                ...state,
                workspace_category: {
                    loading
                }
            }
        case LOADING_CREATE_WORKSPACE_CATEGORY:
            return {
                ...state,
                create_workspace_category: {
                    loading
                }
            }
        case LOADING_USERS:
            return {
                ...state,
                users: {
                    loading
                }
            }
        case LOADING_USER:
            return {
                ...state,
                user: {
                    loading
                }
            }
        case LOADING_CREATE_USER:
            return {
                ...state,
                create_user: {
                    loading
                }
            }
        case LOADING_ACCOUNT:
            return {
                ...state,
                account: {
                    loading
                }
            }
        case LOADING_GLOBAL_USERS:
            return {
                ...state,
                global_users: {
                    loading
                }
            }
        case LOADING_PORTAL_LOGO:
            return {
                ...state,
                portal_logo: {
                    loading
                }
            }
        case LOADING_USER_AVAILABLE_WORKSPACES:
            return {
                ...state,
                user_available_workspaces: {
                    loading
                }
            }
        case LOADING_PORTAL_LANDING_BANNER:
            return {
                ...state,
                portal_landing_banner: {
                    loading
                }
            }
        case LOADING_PORTAL_LANDING_FEATURE:
            return {
                ...state,
                portal_landing_feature: {
                    loading
                }
            }
        case LOADING_PORTAL_LANDING_NEWS:
            return {
                ...state,
                portal_landing_news: {
                    loading
                }
            }
        case LOADING_PORTAL_SETTING_BANNER:
            return {
                ...state,
                portal_setting_banner: {
                    loading
                }
            }
        case LOADING_PORTAL_SETTING_FEATURE:
            return {
                ...state,
                portal_setting_feature: {
                    loading
                }
            }
        case LOADING_PORTAL_SETTING_NEWS:
            return {
                ...state,
                portal_setting_news: {
                    loading
                }
            }
        case LOADING_PORTAL_LOGIN:
            return {
                ...state,
                portal_login: {
                    loading
                }
            }
        case LOADING_PORTAL_SETTING_LOGIN:
            return {
                ...state,
                portal_setting_login: {
                    loading
                }
            }
        case LOADING_PORTAL_SETTING_EMAIL:
            return {
                ...state,
                portal_setting_email: {
                    loading
                }
            }
        case LOADING_PORTAL_SETTING_THEME:
            return {
                ...state,
                portal_setting_theme: {
                    loading
                }
            }
        case LOADING_LOGIN:
            return {
                ...state,
                login: {
                    loading
                }
            }
        case LOADING_CHANGE_PASSWORD:
            return {
                ...state,
                change_password: {
                    loading
                }
            }
        case LOADING_RESET_PASSWORD:
            return {
                ...state,
                reset_password: {
                    loading
                }
            }
        case LOADING_WORKSPACE_FOCUS_AREA_CONNECTIONS:
            return {
                ...state,
                workspace_focus_area_connections: {
                    loading
                }
            }
        case LOADING_PAGE_REDIRECTION:
            return {
                ...state,
                page_redirection: {
                    loading
                }
            }
        case LOADING_THEME:
            return {
                ...state,
                theme: {
                    loading
                }
            }
        default:
            return state;
    }
};