import {
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Slide,
  Typography,
} from '@mui/material';
import React from 'react';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DehazeIcon from '@mui/icons-material/Dehaze';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { styled, StyledCol } from '@wrd/utils/style';
import { css } from '@emotion/css';

export interface ListAppItem {
  name: string;
  handleClickAppItem(e: any, d: any): void;
  logo?: React.ReactNode;
  enabled?: boolean;
}

export interface SharedDropdownListProps {
  applicationName?: string;
  listApps?: ListAppItem[];
  closeDropdown?: null | HTMLElement;
  setCloseDropdown: (HTMLElement: HTMLElement | null) => void;
}

export function SharedDropdownList({
  applicationName,
  listApps,
  closeDropdown,
  setCloseDropdown,
}: SharedDropdownListProps) {
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const containerRef = React.useRef(null);
  const open = Boolean(closeDropdown);
  const handleClick = (e: any) => {
    setCloseDropdown(closeDropdown ? null : e.target);
  };
  return (
    <div>
      {applicationName && (
        <StyledCol
          ref={containerRef}
          className={css`
            min-height: 60px;
          `}
        >
          <StyledButton
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            startIcon={ <DehazeIcon
                  className={css`
                   text-transform: none;
                   margin-bottom: -15px;
                 `}
                 fontSize="large"/> }
              // open ? (
              //   <ArrowDropDownIcon
              //     className={css`
              //       margin-left: auto;
              //       text-transform: none;
              //       margin-bottom: -15px;
              //     `}
              //   />
              // ) : (
              //   <ArrowDropUpIcon
              //     className={css`
              //       margin-left: auto;
              //       text-transform: none;
              //       margin-bottom: -15px;
              //     `}
              //   />
              // )

          >
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 700, margin: ' 0px 0px -13px 0px !important' }}
            >
              {applicationName}
            </Typography>
          </StyledButton>
          {listApps && (
            <Slide direction="up" in={open} container={containerRef.current}>
              <List
                sx={{
                  paddingLeft: '0px',
                  paddingBottom: '0px',
                  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 8px 8px',
                  zIndex: 9,
                  width: '100%',
                  maxWidth: 300,
                  bgcolor: 'rgb(248, 248, 248)',
                  position: 'absolute',
                  top: '55px',
                  color: '#09334B',
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <>
                    <ListSubheader
                      component="div"
                      sx={{
                        height: '60px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        Applications:
                      </Typography>
                    </ListSubheader>
                    <Divider />
                  </>
                }
              >
                {listApps &&
                  listApps.map((m) => (
                    <div key={`app-list` + Math.random()}>
                      {m?.enabled === true ? (
                        <ListItemButton
                          sx={{
                            '&:hover': {
                              backgroundColor: 'rgb(219, 228, 233)',
                            },
                          }}
                          onClick={(e) => {
                            m.handleClickAppItem(e, m ?? null);
                          }}
                        >
                          <ListItemIcon>
                            {m.logo && (
                              <img
                                className="logo-apps"
                                src={
                                  (m.logo !== undefined
                                    ? 'data:image/jpeg;base64,'
                                    : '') + m.logo
                                }
                                alt=""
                              />
                            )}
                          </ListItemIcon>
                          <ListItemText primary={m.name} />
                        </ListItemButton>
                      ) : null}
                      <Divider />
                    </div>
                  ))}
              </List>
            </Slide>
          )}
        </StyledCol>
      )}
    </div>
  );
}

export default SharedDropdownList;

const StyledButton = styled(Button)`
  // margin:0px 5px;
  padding: 0px 10px;
  height: 60px;
  min-width: 150px;
  // background-color:#DBE4E9;
  // background-color:#F2F5F7;
  text-transform: none;
  &,
  .MuiButton-endIcon {
    color: #09334b;
  }
  &:hover {
    // background-color:#F2F5F7;
  }
`;
