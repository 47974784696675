import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner'
import PortalSettingsFeatureItem from './PortalSettingsFeatureItem';
import { updatePortalSettingFeatureAction } from '../../../../actions/updatePortalSettingFeatureAction';
import {
    getPortalSettingFeatureWithThunk
} from '../../../../api/portalSettingsThunk';

class PortalSettingsFeature extends Component {
    constructor(props) {
        super(props);
        this.addPortalSettingFeature = this.addPortalSettingFeature.bind(this);
    }

    static propTypes = {
        settingFeature: PropTypes.object.isRequired,
        dispatchUpdateSettingFeature: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired
    };

    addPortalSettingFeature() {
        const { settingFeature } = this.props;
        let features = settingFeature.features;

        let featureSetting = {
            ...settingFeature,
            features
        };
        let tempLength = featureSetting.features.length + 1;
        var newObj = {
            id: "temp" + tempLength,
            feature_title: "",
            feature_description: "",
            image: "",
            status: ""
        }
        featureSetting.features.unshift(newObj);

        this.props.dispatchUpdateSettingFeature(featureSetting);
    }

    componentDidMount() {
        this.props.getPortalSettingFeature();
    }

    render() {
        const { settingFeature, loading } = this.props;

        return (
            <div className="row mt-5">
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="BallTriangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                <div className="col-md-12">
                    <div className="wsHomeSection">
                        <h2 className="float-left mt-2 mr-4">Features</h2>
                        <button className="btn icoTextBtn btn-wrd_default mb-4" onClick={() => this.addPortalSettingFeature()}><span className="icon-wrd-add"></span>
                            Add Feature</button>
                        {(settingFeature.features !== undefined && settingFeature.features.length > 0) ?
                            settingFeature.features.map((item) => (
                                <PortalSettingsFeatureItem key={item.id} item={item} />
                            ))
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ portalSettings, loading }) => ({
    settingFeature: portalSettings.setting_feature,
    loading: loading.portal_setting_feature.loading,
});

const mapDispatchToProps = (dispatch) => ({
    getPortalSettingFeature: () => dispatch(getPortalSettingFeatureWithThunk()),
    dispatchUpdateSettingFeature: (settingFeature) => dispatch(updatePortalSettingFeatureAction(settingFeature)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalSettingsFeature));