import cookies from 'js-cookie';

export const getUserFromCookie = () => {
  const waterdssCookie = JSON.parse(cookies.get('waterdss') || '{}');

  const isRole = (userRole: string) => {
      return waterdssCookie?.user_roles?.some((role:string) => role.toLowerCase().includes(userRole));
  };

  const isAdministrator: boolean = isRole('administrator');
  const isModerator: boolean = isRole('moderator');
  const isUser: boolean = isRole('user');
  const isAuthenticated = isAdministrator || isUser;

  const currentTime = Math.floor(Date.now() / 1000);
  const expireTime = waterdssCookie?.expireInSeconds;
  const isExpiredTokenTrue = currentTime % expireTime >= 86400;
  const isExistedAccessToken = Boolean(waterdssCookie?.accessToken)

  const handleCookieReset = () => {
    return new Promise<void>((resolve) => {
      cookies.remove('waterdss', {
        domain: window.location.hostname,
        path: '/',
      });

      setTimeout(() => {
        resolve();
      }, 1000);
    });
  };
  return {
    ...waterdssCookie,
    isAdministrator,
    isModerator,
    isUser,
    isAuthenticated,
    isExpiredTokenTrue,
    isExistedAccessToken,
    handleCookieReset,
  };
};
