import { GET_WORKSPACE_APPLICATION_CONNECTIONS, CLEAR_WORKSPACE_APPLICATION_CONNECTIONS, UPDATE_WORKSPACE_APPLICATION_CONNECTIONS } from '../actions/index';

const initialWorkspaceApplicationConnections = {}

export const workspaceApplicationConnections = (state = initialWorkspaceApplicationConnections, action) => {
    const { workspace_application_connections } = action

    switch (action.type) {
        case GET_WORKSPACE_APPLICATION_CONNECTIONS:
            return {                
                connections: workspace_application_connections.connections
            }
        case CLEAR_WORKSPACE_APPLICATION_CONNECTIONS:
            return initialWorkspaceApplicationConnections;
        case UPDATE_WORKSPACE_APPLICATION_CONNECTIONS:
            return {                
                connections: workspace_application_connections.map((el, index) => (
                    action.workspace_application_connections.id === index ? { ...el, workspace_application_connections: action.workspace_application_connections } : el
                ))
            }
        default:
            return state;
    }
};