import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Pagination from 'rc-pagination';
import { getUserAvailableWorkspacesWithThunk } from '../../../api/userAvailableWorkspacesThunk';
import { getUserAvailableWorkspacesPaginationAction } from '../../../actions/getUserAvailableWorkspacesPaginationAction';

class UsersInfoWorkspacesPagination extends Component {
    state = {
        totalCount: 1
    }

    static propTypes = {
        grid: PropTypes.object.isRequired,
        getUserAvailableWorkspaces: PropTypes.func.isRequired,
        getUserAvailableWorkspacesPagination: PropTypes.func.isRequired
    };

    dispatchPagination(page) {
        let data = {
            ...this.grid,
            current_page: page
        }
        this.props.getUserAvailableWorkspacesPagination(data);
    }

    onChange = (page) => {
        const { grid, role } = this.props;

        this.setState(() => ({ totalCount: this.props.grid.total_count }));
        this.dispatchPagination(page);
        this.props.getUserAvailableWorkspaces(role.user_id, role.user_role, page, grid.page_size);
    }

    render() {
        const { grid } = this.props;
        const pageSize = parseInt(grid.page_size, 10);
        const currentPage = parseInt(grid.current_page, 10);
        let totalCount = parseInt(grid.total_count, 10);

        if (isNaN(totalCount))
            totalCount = this.state.totalCount;

        if (pageSize === 0)
            return (null);

        return (
            <Pagination className="rc-pagination-wsList" showLessItems onChange={this.onChange} current={currentPage} defaultPageSize={pageSize} total={totalCount} />
        );
    }
}

const mapStateToProps = ({ pagination, role }) => ({
    grid: pagination.available_workspaces,
    role
});

const mapDispatchToProps = (dispatch) => ({
    getUserAvailableWorkspaces: (userId, userRole, pageIndex, pageSize) => dispatch(getUserAvailableWorkspacesWithThunk(userId, userRole, pageIndex, pageSize)),
    getUserAvailableWorkspacesPagination: (data) => dispatch(getUserAvailableWorkspacesPaginationAction(data))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersInfoWorkspacesPagination));