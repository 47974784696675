import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../configurations/routes';
import { getWorkspaceThemeSettingImplementationWithThunk } from '../../../api/workspacesThemeSettingsThunk';

class UserAvailableWorkspacesItem extends Component {
    constructor(props) {
        super(props);
        this.displayWorkspace = this.displayWorkspace.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        item: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
        hideList: PropTypes.func.isRequired
    };

    displayWorkspace() {
        const { item, role } = this.props;

        this.props.hideList();
        let name = item.workspace_name.toLowerCase().replaceAll(" ", "+");
        let url = (role.user_role === 'Administrator' || item.owner) ? routeCodes.WORKSPACES_LIST + "/" + name + "/properties" : routeCodes.WORKSPACES_LIST + "/" + name;

        getWorkspaceThemeSettingImplementationWithThunk(name, this.props.history, url);
    }

    render() {
        const { item, workspace, role } = this.props;

        return (
            (role.user_role === 'Administrator' || (item.owner && item.enabled && !item.suspend) || (role.user_role === 'User' && item.enabled && item.status === 'Active' && !item.suspend)) ?
                <li>
                    <Link to="#" onClick={() => { this.displayWorkspace(); return false; }}>
                        <div className={(workspace.id === item.id) ? "wsItem active" : "wsItem"} >
                            <div className="container h-100">
                                <div className="row h-100">
                                    <div className="col-3 px-0 d-flex align-items-center">
                                        <img src={((item.url_image !== undefined) ? "data:image/jpeg;base64," : "") + item.url_image} className="logo" alt="" />
                                    </div>
                                    <div className="col-9">
                                        <p className="wsListInfo">
                                            <b>{item.workspace_name}</b>
                                            <i className="d-block wsListOwner"> {(item.owner) ? "Workspace Owner" : null}</i>
                                            <i className="d-block wsListSuspend"> {(!item.enabled) ? "Disabled" : (item.status === 'Suspend') ? "Suspended" : null}</i>
                                            <span>{item.workspace_title}</span>
                                        </p>
                                    </div>
                                    {(workspace.id === item.id) ?
                                        <div className="activeSign">
                                            <img src={process.env.PUBLIC_URL + "/images/activeWorkspace.png"} alt="" />
                                        </div> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </Link>
                </li>
                : null
        );
    }
}

const mapStateToProps = ({ detail, role }) => ({
    workspace: detail.workspace,
    role
});

export default withRouter(connect(mapStateToProps)(UserAvailableWorkspacesItem));
