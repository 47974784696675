import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogWorkspacesFocusAreasAction } from '../../../../actions/updateDialogWorkspacesFocusAreasAction';
import { setupNewWorkspaceFocusAreaAction } from '../../../../actions/setupNewWorkspaceFocusAreaAction';
import { createWorkspacesFocusAreasWithThunk } from '../../../../api/workspacesFocusAreasThunk';

class WorkspacesFocusAreasModal extends Component {
    constructor(props) {
        super(props);
        this.onFocusAreaNameChange = this.onFocusAreaNameChange.bind(this); 
        this.textInput = React.createRef();
        this.enterSubmit = this.enterSubmit.bind(this);
    }

    state = {
        area_name: this.props.focusArea.area_name,
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        type: PropTypes.string.isRequired,
        dialog: PropTypes.object.isRequired,        
        dispatchDialogWorkspacesFocusAreas: PropTypes.func.isRequired,
        setupNewWorkspaceFocusArea: PropTypes.func.isRequired,
        createWorkspaceFocusArea: PropTypes.func.isRequired
    };

    async enterSubmit(e) {
        if (e.key === 'Enter') {
            this.submitActionButton();
        }
    }

    setDefaultDialogState() {
        let focus_areas = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            show_dialog: false
        };

        this.props.dispatchDialogWorkspacesFocusAreas(focus_areas);
    }

    submitActionButton() {
        const { workspace, dialog, type, focusArea } = this.props;

        this.setDefaultDialogState();

        if (type === "list") {
            if (dialog.dialog_type === 'create_focus_area') {
                this.props.createWorkspaceFocusArea(workspace.id, { focus_area_name: focusArea.area_name });
                this.setDefaultDialogState();
                this.setupDefaultFocusArea();
            }           
        }
    }

    setupDefaultFocusArea() {
        let focusArea = {
            ...this.props.focusArea,
            area_name: ''
        };
        this.props.setupNewWorkspaceFocusArea(focusArea);
        this.setState({ area_name: '' });
    }

    onFocusAreaNameChange(e) {        
        this.setState({ area_name: e.target.value });
        let focusArea = {
            ...this.props.focusArea,
            area_name: e.target.value
        };
        this.props.setupNewWorkspaceFocusArea(focusArea);
    }

    componentDidUpdate() {
        if (this.props.dialog.show_dialog) {
            this.textInput.current.focus();
        }
    }

    closeModal() {
        this.setDefaultDialogState();
        this.setupDefaultFocusArea();
    }

    render() {
        const { dialog } = this.props;        

        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div onKeyPress={this.enterSubmit} className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="py-3 px-4">
                            <h2 className="popupTitle text-left">{dialog.dialog_title}</h2>
                            <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>                    
                    <div className="popupBody">
                        <div className="p-4">
                            <div className="formWrap">
                                <div className="inputForm">
                                    <input ref={this.textInput} className="form-control" type="text" name="" value={this.state.area_name} onChange={this.onFocusAreaNameChange} required />
                                    <label>Area Name</label>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-3">
                            <div className="row">
                                <div className="col-md-6">
                                    <button className="btn greyBtn icoTextBtn closePopup" onClick={() => { this.closeModal(); }}>
                                        <span className="icon-wrd-close"></span>
                                        Cancel
                                    </button>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button className="btn textBtn greenBtn" onClick={() => { this.submitActionButton(); }}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, dialog, setup }) => ({
    workspace: detail.workspace,
    dialog: dialog.workspaces_focus_areas,
    focusArea: setup.workspace_focus_area
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogWorkspacesFocusAreas: (dialog) => dispatch(updateDialogWorkspacesFocusAreasAction(dialog)),
    setupNewWorkspaceFocusArea: (item) => dispatch(setupNewWorkspaceFocusAreaAction(item)),
    createWorkspaceFocusArea: (id, focusArea) => dispatch(createWorkspacesFocusAreasWithThunk(id, focusArea))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesFocusAreasModal));