import {
  GET_WORKSPACE,
  CLEAR_WORKSPACE,
  UPDATE_WORKSPACE,
  GET_APPLICATION,
  CLEAR_APPLICATION,
  UPDATE_APPLICATION,
  GET_CATEGORY,
  UPDATE_CATEGORY,
  CLEAR_CATEGORY,
  GET_WORKSPACE_APPLICATION,
  CLEAR_WORKSPACE_APPLICATION,
  UPDATE_WORKSPACE_APPLICATION,
  GET_WORKSPACE_SETTING,
  UPDATE_WORKSPACE_SETTING,
  CLEAR_WORKSPACE_SETTING,
  GET_WORKSPACE_LOGIN_SETTING,
  UPDATE_WORKSPACE_LOGIN_SETTING,
  CLEAR_WORKSPACE_LOGIN_SETTING,
  GET_WORKSPACE_EMAIL_SETTING,
  UPDATE_WORKSPACE_EMAIL_SETTING,
  CLEAR_WORKSPACE_EMAIL_SETTING,
  GET_WORKSPACE_THEME_SETTING,
  UPDATE_WORKSPACE_THEME_SETTING,
  CLEAR_WORKSPACE_THEME_SETTING,
  GET_WORKSPACE_CATEGORY,
  UPDATE_WORKSPACE_CATEGORY,
  CLEAR_WORKSPACE_CATEGORY,
  GET_USER,
  CLEAR_USER,
  UPDATE_USER,
  GET_ACCOUNT,
  CLEAR_ACCOUNT,
  UPDATE_ACCOUNT,
  GET_LOGIN,
  CLEAR_LOGIN,
  UPDATE_LOGIN,
  GET_CHANGE_PASSWORD,
  CLEAR_CHANGE_PASSWORD,
  UPDATE_CHANGE_PASSWORD,
  GET_RESET_PASSWORD,
  CLEAR_RESET_PASSWORD,
  UPDATE_RESET_PASSWORD,
} from '../actions/index';

const initialDetailState = {
  workspace: {
    id: '',
    url_image: '',
    image_width: 0,
    image_height: 0,
    image_aspect_ratio: 0,
    workspace_name: '',
    temp_workspace_name: '',
    workspace_title: '',
    created_date: '',
    due_date: '',
    status: '',
    owner_count: 0,
    member_count: 0,
    workspace_desc: '',
    domain_url: '',
    video_url: '',
    workspace_url: '',
    workspace_name_locked: false,
    workspace_desc_locked: false,
    workspace_title_locked: false,
    domain_url_locked: false,
    video_url_locked: false,
    workspace_url_locked: false,
    public_access_locked: false,
    users_to_register_locked: false,
    focus_area_access_locked: false,
    application_access_locked: false,
    logo_locked: false,
    owner: false,
    allow_public_access: false,
    allow_users_to_register: false,
    all_focus_area_access: false,
    all_application_access: false,
    showPortalLogo: false,
    showWorkspaceName: false,
    user_picture: '',
    user_initial: '',
    categories: [],
    user_categories: [],
    available_applications: [],
    user_available_applications: [],
  },
  application: {
    id: '',
    url_image: '',
    image_width: 0,
    image_height: 0,
    image_aspect_ratio: 0,
    application_name: '',
    temp_application_name: '',
    application_title: '',
    application_desc: '',
    application_url: '',
    snapshot_type: 0,
    snapshot_url_image: '',
    snapshot_image_aspect_ratio: 0,
    keywords: [],
    status: '',
    is_new: false,
    is_deleted: false,
    is_logo_changed: false,
    is_snapshot_changed: false,
  },
  category: {
    category_id: '',
    category_title: '',
    category_description: '',
  },
  workspace_application: {
    application_desc: '',
    url: '',
    url_settings: '',
    application_name: '',
    temp_application_name: '',
    application_title: '',
    application_token: '',
    enabled: false,
    url_image: '',
    image_aspect_ratio: '',
    snapshot_type: 0,
    snapshot_default_url_image: '',
    snapshot_custom_url_image: '',
    snapshot_custom_image_aspect_ratio: 0,
    keywords: [],
    workspace_id: '',
    application_id: '',
    status: '',
    available: false,
    allow_public_access: false,
    application_desc_locked: false,
    url_locked: false,
    application_name_locked: false,
    application_title_locked: false,
    logo_locked: false,
    snapshot_locked: false,
    keywords_locked: false,
    allow_public_access_locked: false,
    is_logo_changed: false,
    is_snapshot_changed: false,
    yml_configuration: '',
  },
  user: {
    uid: '',
    id: '',
    full_name: '',
    company: '',
    role: '',
    registration_status: '',
    user_status: '',
    email_address: '',
    use_personal: false,
    phone: '',
    organization: '',
    picture: '',
    image_width: 0,
    image_height: 0,
    image_aspect_ratio: 0,
    personal: {
      address_1: '',
      address_2: '',
      zip_code: '',
      country: '',
      city: '',
      state: '',
      phone: '',
      mobile_phone: '',
      email_address: '',
    },
    public: {
      organization: '',
      att: '',
      address_1: '',
      address_2: '',
      zip_code: '',
      country: '',
      city: '',
      state: '',
      phone: '',
      mobile_phone: '',
      email_address: '',
    },
    is_picture_changed: false,
  },
  account: {
    uid: '',
    id: '',
    full_name: '',
    company: '',
    role: '',
    registration_status: '',
    user_status: '',
    email_address: '',
    use_personal: false,
    phone: '',
    organization: '',
    picture: '',
    image_width: 0,
    image_height: 0,
    image_aspect_ratio: 0,
    personal: {
      address_1: '',
      address_2: '',
      zip_code: '',
      country: '',
      city: '',
      state: '',
      phone: '',
      mobile_phone: '',
      email_address: '',
    },
    public: {
      organization: '',
      att: '',
      address_1: '',
      address_2: '',
      zip_code: '',
      country: '',
      city: '',
      state: '',
      phone: '',
      mobile_phone: '',
      email_address: '',
    },
  },
  workspace_setting: {
    id: '',
    workspace_name: '',
    workspace_url_image: '',
    workspace_image_aspect_ratio: 0,
    new_url_image: '',
    new_image_aspect_ratio: 0,
    custom_url_banner: '',
    custom_banner_aspect_ratio: 0,
    chosen_themes: '',
    chosen_banner: '',
    portal_logo_locked: false,
    themes_locked: false,
    banner_locked: false,
    showPortalLogo: false,
    showWorkspaceName: false,
    showWorkspaceLogo: false,
    useGoogleAnalytics: false,
    useMatomoAnalytics: false,
    googleAnalyticsTrackingId: '',
    matomoAnalyticsTrackingId: '',
    matomoAnalyticsURL: '',
    themeSelection: '',
  },
  workspace_login_setting: {
    id: '',
    welcome_text_selected: '',
    welcome_text: '',
    welcome_background_selected: '',
    welcome_background_color: '',
    welcome_background_custom_image: '',
    welcome_background_custom_image_aspect_ratio: '',
    login_background_selected: '',
    login_background_color: '',
    login_background_custom_image: '',
    login_background_custom_image_aspect_ratio: '',
    welcome_text_locked: false,
    welcome_background_locked: false,
    login_background_locked: false,
  },
  workspace_email_setting: {
    id: '',
    emails: [
      {
        id: '',
        email_selected: '',
        email_type: '',
        sender_from: '',
        email_subject: '',
        email_content: '',
        is_locked: false,
      },
    ],
  },
  workspace_theme_setting: {},
  workspace_category: {
    category_id: '',
    category_title: '',
    category_description: '',
    mapUrl: '',
    imageId: '',
    iconId: '',
  },
  login: {
    user_name: '',
    password: '',
    new_password: '',
    change_new_password: '',
  },
  changePassword: {
    current_password: '',
    new_password: '',
    confirmation_new_password: '',
  },
  resetPassword: {
    username: '',
    email: '',
  },
};

export const detail = (state = initialDetailState, action) => {
  const {
    workspace,
    application,
    category,
    workspace_application,
    workspace_setting,
    workspace_login_setting,
    workspace_email_setting,
    workspace_theme_setting,
    workspace_category,
    user,
    account,
    login,
    changePassword,
    resetPassword,
  } = action;
  switch (action.type) {
    case GET_WORKSPACE:
      return {
        ...state,
        workspace,
      };
    case UPDATE_WORKSPACE:
      return {
        ...state,
        workspace,
      };
    case CLEAR_WORKSPACE:
      return {
        ...state,
        workspace: initialDetailState.workspace,
      };
    case GET_APPLICATION:
      return {
        ...state,
        application,
      };
    case UPDATE_APPLICATION:
      return {
        ...state,
        application,
      };
    case CLEAR_APPLICATION:
      return {
        ...state,
        application: initialDetailState.application,
      };
    case GET_CATEGORY:
      return {
        ...state,
        category,
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        category,
      };
    case CLEAR_CATEGORY:
      return {
        ...state,
        category: initialDetailState.category,
      };
    case GET_WORKSPACE_APPLICATION:
      return {
        ...state,
        workspace_application,
      };
    case UPDATE_WORKSPACE_APPLICATION:
      return {
        ...state,
        workspace_application,
      };
    case CLEAR_WORKSPACE_APPLICATION:
      return {
        ...state,
        workspace_application: initialDetailState.workspace_application,
      };
    case GET_WORKSPACE_SETTING:
      return {
        ...state,
        workspace_setting,
      };
    case UPDATE_WORKSPACE_SETTING:
      return {
        ...state,
        workspace_setting,
      };
    case CLEAR_WORKSPACE_SETTING:
      return {
        ...state,
        workspace_setting: initialDetailState.workspace_setting,
      };
    case GET_WORKSPACE_LOGIN_SETTING:
      return {
        ...state,
        workspace_login_setting,
      };
    case UPDATE_WORKSPACE_LOGIN_SETTING:
      return {
        ...state,
        workspace_login_setting,
      };
    case CLEAR_WORKSPACE_LOGIN_SETTING:
      return {
        ...state,
        workspace_login_setting: initialDetailState.workspace_login_setting,
      };
    case GET_WORKSPACE_EMAIL_SETTING:
      return {
        ...state,
        workspace_email_setting,
      };
    case UPDATE_WORKSPACE_EMAIL_SETTING:
      return {
        ...state,
        workspace_email_setting,
      };
    case CLEAR_WORKSPACE_EMAIL_SETTING:
      return {
        ...state,
        workspace_email_setting: initialDetailState.workspace_email_setting,
      };
    case GET_WORKSPACE_THEME_SETTING:
      return {
        ...state,
        workspace_theme_setting,
      };
    case UPDATE_WORKSPACE_THEME_SETTING:
      return {
        ...state,
        workspace_theme_setting,
      };
    case CLEAR_WORKSPACE_THEME_SETTING:
      return {
        ...state,
        workspace_theme_setting: initialDetailState.workspace_theme_setting,
      };
    case GET_WORKSPACE_CATEGORY:
      return {
        ...state,
        workspace_category,
      };
    case UPDATE_WORKSPACE_CATEGORY:
      return {
        ...state,
        workspace_category,
      };
    case CLEAR_WORKSPACE_CATEGORY:
      return {
        ...state,
        workspace_category: initialDetailState.workspace_category,
      };
    case GET_USER:
      return {
        ...state,
        user,
      };
    case UPDATE_USER:
      return {
        ...state,
        user,
      };
    case CLEAR_USER:
      return {
        ...state,
        user: initialDetailState.user,
      };
    case GET_ACCOUNT:
      return {
        ...state,
        account,
      };
    case CLEAR_ACCOUNT:
      return {
        ...state,
        account: initialDetailState.account,
      };
    case UPDATE_ACCOUNT:
      return {
        ...state,
        account,
      };
    case GET_LOGIN:
      return {
        ...state,
        login,
      };
    case CLEAR_LOGIN:
      return {
        ...state,
        login: initialDetailState.login,
      };
    case UPDATE_LOGIN:
      return {
        ...state,
        login,
      };
    case GET_CHANGE_PASSWORD:
      return {
        ...state,
        changePassword,
      };
    case CLEAR_CHANGE_PASSWORD:
      return {
        ...state,
        changePassword: initialDetailState.changePassword,
      };
    case UPDATE_CHANGE_PASSWORD:
      return {
        ...state,
        changePassword,
      };
    case GET_RESET_PASSWORD:
      return {
        ...state,
        resetPassword,
      };
    case CLEAR_RESET_PASSWORD:
      return {
        ...state,
        resetPassword: initialDetailState.resetPassword,
      };
    case UPDATE_RESET_PASSWORD:
      return {
        ...state,
        resetPassword,
      };
    default:
      return state;
  }
};
