import ls from 'local-storage'

export const saveLocalStorage = (key, value) => {
    ls.set(key, value);
}

export const getLocalStorage = (key) => {
    const result = ls.get(key);

    return (result !== null) ? result : ""; 
}

export const removeLocalStorage = (key) => {
    ls.remove(key);
}