import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { updateApplicationAction } from '../../../actions/updateApplicationAction';

class ApplicationsDetailHeader extends Component {
    constructor(props) {
        super(props);
        this.onApplicationNameChange = this.onApplicationNameChange.bind(this);
    }

    static propTypes = {
        application: PropTypes.object.isRequired,
        dispatchApplication: PropTypes.func.isRequired,
    };

    onApplicationNameChange(e) {
        let application = {
            ...this.props.application,
            temp_application_name: e.target.value
        };

        this.props.dispatchApplication(application);
    }

    render() {
        const { application } = this.props;

        return (
            <div className="mainHead">
                <div className="row">
                    <div className="col-12">
                        <h2 className="float-left mt-2 mr-4">Application</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="workspaceInfo">
                            <div className="row">
                                <div className="workspaceName col-xl-7 offset-xl-3">
                                    <div className="float-left">
                                        <div className="formWrap">
                                            <div className="inputForm wsNameEdit">
                                                <input className="form-control appNameInput appNameEditWider" type="text" onChange={this.onApplicationNameChange} required defaultValue={(application.temp_application_name !== undefined || application.tem_application_name !== undefined) ? application.temp_application_name : application.application_name} />
                                                <label>Name</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="userRole col-xl-7 offset-xl-3">Administrator</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail }) => ({
    application: detail.application
});

const mapDispatchToProps = (dispatch) => ({
    dispatchApplication: (application) => dispatch(updateApplicationAction(application))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ApplicationsDetailHeader));