import { updateImage } from '../../utils/snapshot';
import {
  axios,
  queryBaseUrlV1,
  queryBaseUrlV2,
  getCookies,
} from './getCookies';
import environment from 'environment';
import { getApplicationUrls } from '@wrd/helpers';

const config = require('../../environments/' + environment.settings);
const CircularJSON = require('circular-json');

const { auth } = getApplicationUrls();
const confirmationUrl = `${auth}/${config.emailConfirmationPage}`;

async function getUserById(userId) {
  const results = await axios
    .get(queryBaseUrlV2 + 'account/' + userId)
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getUserByName(userName) {
  const results = await axios
    .get(queryBaseUrlV2 + 'account/id/' + userName)
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getUserNameById(userId) {
  // const results = await axios.get(queryBaseUrlV2 + "account/" + userId + "/username")
  const results = await axios
    .get(queryBaseUrlV2 + 'account/' + getCookies.user_id + '/username')
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getUserHeaderList(pageIndex, pageSize, sortingBy, sortingType) {
  const results = await axios.get(queryBaseUrlV2 + 'account/list', {
    params: {
      page: pageIndex,
      pageSize: pageSize,
      sort: sortingBy,
      order: sortingType,
    },
  });

  return results;
}

async function getUserSearchList(
  pageIndex,
  pageSize,
  searchKeyword,
  sortingBy,
  sortingType
) {
  const results = await axios.get(queryBaseUrlV2 + 'account/search', {
    params: {
      page: pageIndex,
      pageSize: pageSize,
      keyword: searchKeyword,
      sort: sortingBy,
      order: sortingType,
      photo: true,
    },
  });
  return results;
}

async function getUserPhoto(userId, width, height) {
  let url;
  // if (userId) {
  //   url =
  //     queryBaseUrlV2 + 'account/' + userId + '/picture/' + width + '/' + height;
  // } else {
    url =
      queryBaseUrlV2 +
      'account/' +
      getCookies.user_id +
      '/picture/' +
      width +
      '/' +
      height;
  // }

  let results;
  try {
    const response = await axios.get(url);
    results = response.data;
  } catch (err) {
    if (err.response !== undefined) {
      if (err.response.status === 404) {
        return results;
      }
    }
  }
  return results;
}

async function getUserAccessById(userId) {
  const results = await axios
    .get(queryBaseUrlV2 + 'account/' + userId + '/access')
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getUserAvailabeWorkspaces(userId) {
  const results = await axios
    .get(queryBaseUrlV1 + 'workspace/member/' + userId)
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getUserAvailabeWorkspacesDetail(
  wsIds,
  pageIndex,
  pageSize,
  keyword
) {
  let workspaceIds = '';
  for (let i in wsIds) {
    workspaceIds += 'ids=' + wsIds[i].workspaceId + '&';
  }

  let keywords = keyword !== undefined ? '&keyword=' + keyword : '';

  const results = await axios
    .get(
      queryBaseUrlV1 +
        'workspace/search/available-workspaces?' +
        workspaceIds +
        'pageIndex=' +
        pageIndex +
        '&pageSize=' +
        pageSize +
        keywords
    )
    .catch(function (error) {
      if (error.response) {
        return error.response;
      }
    });

  return results;
}

async function getUserAccess(userId) {
  const results = await axios
    .get(queryBaseUrlV1 + 'workspace/member/' + userId + '/focus-area/access')
    .catch(function (error) {
      return error.response;
    });
  return results;
}

// async function getUserAccessApplications(userId) {
//   const results = await axios
//     .get(
//       queryBaseUrlV1 +
//         'workspace/member/' +
//         userId +
//         '/application/access?all=true'
//     )
//     .catch(function (error) {
//       return error.response;
//     });
//   return results;
// }

// async function getUserAccessWorkspaceApplications(workspaceId, userId) {
//   const results = await axios
//     .get(
//       queryBaseUrlV1 +
//         'workspace/' +
//         workspaceId +
//         '/member/' +
//         getCookies.user_id +
//         '/application/access?all=false'
//     )
//     .catch(function (error) {
//       return error.response;
//     });
//   return results;
// }

async function getWorkspaceRole(userId) {
  const results = await axios
    .get(queryBaseUrlV1 + 'workspace/member/' + userId)
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function createUser(user, sendEmail) {
  const results = await axios
    .post(
      queryBaseUrlV1 +
        'management/account?confirmationUrl=' +
        confirmationUrl +
        '&sendingEmail=' +
        sendEmail,
      user
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function getUserIdByEmailName(data) {
  const results = await axios
    .get(
      queryBaseUrlV1 +
        'management/account/id?userName=' +
        data.userName +
        '&email=' +
        data.email
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function updateUser(user) {
  const results = await axios
  .put(
    queryBaseUrlV1 + 'management/account/' + user.id + '/role/' + user.role
  )
  .catch(function (error) {
    return error.response;
  });
  return results;

}

async function updateUserAccess(userId, userAccess) {
  const results = await axios
    .post(
      queryBaseUrlV1 + 'workspace/member/' + userId + '/focus-area',
      userAccess
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function updateUserApplicationAccess(userId, userAccess) {
  const results = await axios
    .post(
      queryBaseUrlV1 + 'workspace/member/' + userId + '/applications',
      userAccess
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function updateUserAccessStatus(userId, workspaceId, status) {
  const results = await axios
    .put(
      queryBaseUrlV1 +
        'workspace/' +
        workspaceId +
        '/member/' +
        userId +
        '/status/' +
        status
    )
    .then((response) => {
      let json = CircularJSON.stringify(response);
      return JSON.parse(json);
    })
    .catch((error) => {
      return error.response;
    });
  return results;
}

async function updateUserChangePassword(userId, passwords) {
  const results = await axios
    .put(queryBaseUrlV1 + 'account/password/' + userId, passwords)
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function removeUserAccess(userId, workspaceId) {
  const results = await axios
    .delete(queryBaseUrlV1 + 'workspace/' + workspaceId + '/member/' + userId)
    .then((response) => {
      let json = CircularJSON.stringify(response);
      return JSON.parse(json);
    })
    .catch((error) => {
      return error.response;
    });
  return results;
}

async function updateUserStatus(id, status) {
  const results = await axios.put(
    queryBaseUrlV1 + 'management/account/' + id + '/status/' + status
  );
  return results;
}

async function updateUserRole(id, role) {
  const results = await axios.put(
    queryBaseUrlV1 + 'management/account/' + id + '/role/' + role
  );
  return results;
}

async function removeUser(id) {
  const results = await axios.delete(
    queryBaseUrlV1 + 'management/account/' + id,
    null
  );
  return results;
}

async function updatePhoto(userId, content) {
  const url = queryBaseUrlV2 + 'account/' + userId + '/picture';

  return await updateImage(content, url);
}

async function login(userPayload) {
  const results = await axios
    .post(queryBaseUrlV1 + 'authentication/login', userPayload)
    .then((res) => {
      return res;
    })
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function loginConfirmation(userId, token, userPayload) {
  const results = await axios
    .post(
      queryBaseUrlV1 +
        'authentication/confirmation/' +
        userId +
        '?token=' +
        token,
      userPayload
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function resetConfirmation(userId, token, userPayload) {
  const results = await axios
    .post(
      queryBaseUrlV1 + 'authentication/reset/' + userId + '?token=' + token,
      userPayload
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function resetPasswordPortal(reset) {
  const results = await axios
    .post(queryBaseUrlV1 + 'authentication/forgot/', reset)
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function resetPasswordPortalAdmin(uid, reset) {
  const results = await axios
    .post(
      queryBaseUrlV1 +
        'management/account/' +
        uid +
        '/reset-password?resetUrl=' +
        reset.resetUrl
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function resetPasswordWorkspace(userId, workspaceId, reset) {
  const results = await axios
    .post(
      queryBaseUrlV1 +
        'workspace/' +
        workspaceId +
        '/member/' +
        userId +
        '/forgot?resetUrl=' +
        reset.resetUrl
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function resetPasswordWorkspaceAdmin(uid, workspaceId, reset) {
  const results = await axios
    .post(
      queryBaseUrlV1 +
        'workspace/' +
        workspaceId +
        '/member/' +
        uid +
        '/reset-password?resetUrl=' +
        reset.resetUrl
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function resendConfirmation(userId, urlParam) {
  const results = await axios
    .post(
      queryBaseUrlV1 + 'management/account/' + userId + '/confirmation-resend/',
      urlParam
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function resendConfirmationWorkspace(userId, workspaceId, urlParam) {
  const results = await axios
    .post(
      queryBaseUrlV1 +
        'workspace/' +
        workspaceId +
        '/member/' +
        userId +
        '/confirmation-resend?confirmationUrl=' +
        urlParam.confirmationUrl
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

async function sendContactAdministrators(uid, contactAdministrators) {
  const results = await axios
    .post(
      queryBaseUrlV1 + 'account/' + uid + '/email/admin',
      contactAdministrators
    )
    .catch(function (error) {
      return error.response;
    });
  return results;
}

export {
  getUserById,
  getUserByName,
  getUserNameById,
  getUserHeaderList,
  getUserSearchList,
  getUserPhoto,
  getUserAccessById,
  getUserAvailabeWorkspaces,
  getUserAvailabeWorkspacesDetail,
  getUserAccess,
  // getUserAccessApplications,
  getWorkspaceRole,
  // getUserAccessWorkspaceApplications,
  getUserIdByEmailName,
  createUser,
  updateUser,
  updateUserAccess,
  updateUserApplicationAccess,
  updateUserAccessStatus,
  updateUserChangePassword,
  removeUserAccess,
  updateUserStatus,
  updateUserRole,
  removeUser,
  updatePhoto,
  login,
  loginConfirmation,
  resetConfirmation,
  resetPasswordPortal,
  resetPasswordPortalAdmin,
  resetPasswordWorkspace,
  resetPasswordWorkspaceAdmin,
  resendConfirmation,
  resendConfirmationWorkspace,
  sendContactAdministrators,
};
