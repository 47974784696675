import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ApplicationsCreateInput from './ApplicationsCreateInput';
import ApplicationsCreateLogo from './ApplicationsCreateLogo';
import ApplicationsCreateAction from './ApplicationsCreateAction';

class ApplicationsCreateForm extends Component {
    static propTypes = {
        application: PropTypes.object.isRequired
    };

    render() {
        return (
            <div className="formWrap">
                <div className="row">
                    <div className="col-md-12 mb-2">
                        <p className="text prologue mt-5">Please fill all the fields</p>
                    </div>
                    <ApplicationsCreateLogo />
                    <ApplicationsCreateInput />
                    <ApplicationsCreateAction />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ setup }) => ({
    application: setup.application
});

export default withRouter(connect(mapStateToProps)(ApplicationsCreateForm));