import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import SidebarContainer from '../../Sidebar/SidebarContainer';
import WorkspacesCreateHeader from './WorkspacesCreateHeader';
import WorkspacesCreateBody from './WorkspacesCreateBody';
import { setupNewWorkspaceAction } from '../../../actions/setupNewWorkspaceAction';
import { clearSetupNewWorkspaceAction } from '../../../actions/clearSetupNewWorkspaceAction';
import { getDefaultImage } from '../../../utils/cropImage';
import moment from 'moment';
import WorkspacesCreationErrorModal from '../Modal/WorkspacesCreationErrorModal';

class WorkspacesCreateContainer extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired
    };

    getCurrentDate() {
        let today = moment().format("MM-DD-YYYY");
        return today;
    }

    getUserName() {
        let username = "John Doe";
        return username;
    }

    getUrlImage() {
        let urlImage = getDefaultImage();
        return urlImage;
    }

    getStatus() {
        let status = "Active";
        return status;
    }

    dispatchNewWorkspace(currentDate, username, urlImage, status) {
        let newWorkspace = {
            ...this.props.workspace,
            default_url_image: urlImage,
            url_image: '',
            image_aspect_ratio: 4 / 3,
            created_date: currentDate,
            username: username,
            status: status
        };
        this.props.setupNewWorkspace(newWorkspace);
    }

    setDefaultData() {
        const today = this.getCurrentDate();
        const username = this.getUserName();
        const urlImage = this.getUrlImage();
        const status = this.getStatus();

        this.dispatchNewWorkspace(today, username, urlImage, status);
    }

    componentWillMount() {
        this.setDefaultData();
    }

    componentWillUnmount() {
        this.props.clearSetupNewWorkspace();
    }

    render() {
        const { loading, loadingTheme } = this.props;

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="BallTriangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <WorkspacesCreateHeader />
                                    <WorkspacesCreateBody />
                                </div>
                            </div>
                        </main>
                        <WorkspacesCreationErrorModal type="error" />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ setup, loading }) => ({
    workspace: setup.workspace,
    loading: loading.create_workspace.loading,
    loadingTheme: loading.theme.loading
});

const mapDispatchToProps = (dispatch) => ({
    setupNewWorkspace: (workspace) => dispatch(setupNewWorkspaceAction(workspace)),
    clearSetupNewWorkspace: () => dispatch(clearSetupNewWorkspaceAction([]))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesCreateContainer));
