import {
    getWorkspaceLoginSettingByIdApi,
    updateWorkspaceLoginSettingApi
} from '../backend/domain/workspacesSettings';

import {
    getUserWorkspacePropertiesByIdApi
} from '../backend/domain/workspaces';

import { getWorkspaceAction } from '../actions/getWorkspaceAction';
import { clearWorkspaceAction } from '../actions/clearWorkspaceAction';
import { getWorkspaceLoginSettingAction } from '../actions/getWorkspaceLoginSettingAction';
import { clearWorkspaceLoginSettingAction } from '../actions/clearWorkspaceLoginSettingAction';
import { updateWorkspaceLoginSettingAction } from '../actions/updateWorkspaceLoginSettingAction';
import { loadingWorkspaceSettingAction } from '../actions/loadingWorkspaceSettingAction';
import { handleWorkspaceSettingsUpdateError } from '../utils/errorMessages';
import { overrideWorkspaceId } from '../utils/workspaces';

export const getWorkspaceLoginSettingByIdWithThunk = (id, userId, isAdmin, historyProps) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(id);
        dispatch(loadingWorkspaceSettingAction(true));
        dispatch(clearWorkspaceAction([]));
        dispatch(clearWorkspaceLoginSettingAction([]));
        getUserWorkspacePropertiesByIdApi(wId, userId, isAdmin, historyProps, "login")
            .then((response) => {
                if (!response.error) {
                    dispatch(getWorkspaceAction(response));
                }
                return response;
            })
            .then(() => {
                getWorkspaceLoginSettingByIdApi(wId)
                    .then(response => {
                        if (!response.error) {
                            dispatch(getWorkspaceLoginSettingAction(response));
                        }
                        return response;
                    })
                    .then((response) => {
                        dispatch(loadingWorkspaceSettingAction(false));
                        return response;
                    });
            });
    };
	
export const getWorkspaceLoginSettingWithThunk = (id) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(id);
        dispatch(loadingWorkspaceSettingAction(true));        
        getWorkspaceLoginSettingByIdApi(wId)
			.then(response => {
				if (!response.error) {
					dispatch(getWorkspaceLoginSettingAction(response));
				}
				return response;
			})
			.then((response) => {
				dispatch(loadingWorkspaceSettingAction(false));
				return response;
			});
    };	

export const getImplWorkspaceLoginSettingByIdWithThunk = (id) =>
    (dispatch) => {
        dispatch(loadingWorkspaceSettingAction(true));
        getWorkspaceLoginSettingByIdApi(id)
            .then(response => {
                if (!response.error) {
                    dispatch(getWorkspaceLoginSettingAction(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspaceSettingAction(false));
                return response;
            });
    };

export const updateWorkspaceLoginSettingWithThunk = (workspaceSetting) =>
    (dispatch) => {
        dispatch(loadingWorkspaceSettingAction(true));
        updateWorkspaceLoginSettingApi(workspaceSetting)
            .then((response) => {
                dispatch(updateWorkspaceLoginSettingAction(workspaceSetting));
                return response;
            })
            .then((response) => {
                handleWorkspaceSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspaceSettingAction(false));
                return response;
            });
    };