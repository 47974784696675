// import axios from 'axios';

export const getApplicationUrls = () => {
  const base = `${window.location.protocol}//${window.location.host}`;
  const isLocal = window.location.hostname === 'localhost';
  const portal = isLocal ? 'http://localhost:3000' : `${base}`;
  const auth = isLocal ? `http://localhost:1337` : `${base}/auth`;
  const workspaces = isLocal ? `http://localhost:1339` : `${base}/workspaces`;

  const subscriptions = isLocal
    ? `http://localhost:1338`
    : `${base}/subscriptions`;

  return {
    isLocal,
    portal,
    auth,
    subscriptions,
    workspaces,
  };
};
