import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getWorkspaceApplicationByIdWithThunk, getWorkspaceApplicationPropertiesWithThunk } from '../../../../api/workspacesApplicationsThunk';
import { getWorkspacesApplicationsSettingsWithThunk, getWorkspacesApplicationsSettingsByIdWithThunk } from '../../../../api/workspacesApplicationsSettingsThunk';
import Loader from 'react-loader-spinner';
import { getUrlWorkspaceId, getUrlWorkspaceApplicationId } from '../../../../utils/url';
import { getSessionStorage } from '../../../../utils/sessionStorage';
import SidebarContainer from '../../../Sidebar/SidebarContainer';
import WorkspacesApplicationsDetailModal from '../Modal/WorkspacesApplicationsDetailModal';
import { clearWorkspaceApplicationAction } from '../../../../actions/clearWorkspaceApplicationAction';
import { clearWorkspaceApplicationConnectionsAction } from '../../../../actions/clearWorkspaceApplicationConnectionsAction';
import WorkspacesApplicationsUpdateErrorModal from '../Modal/WorkspacesApplicationsUpdateErrorModal';
import WorkspacesApplicationsSettingsHeader from './WorkspacesApplicationsSettingsHeader';
import WorkspacesApplicationsSettingsBody from './WorkspacesApplicationsSettingsBody';
import WorkspacesApplicationsSettingsYml from './WorkspacesApplicationsSettingsYml';
import ErrorSuccessModal from '../../../Shared/ErrorSuccessModal';
import { clearWorkspacesApplicationsSettingsAction } from '../../../../actions/clearWorkspacesApplicationsSettingsAction';


class WorkspacesApplicationsSettingsContainer extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        workspaceApplication: PropTypes.object.isRequired,
        getWorkspaceApplicationById: PropTypes.func.isRequired,
        getWorkspaceApplicationProperties: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        clearWorkspaceApplication: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired,
        getWorkspacesApplicationsSettings: PropTypes.func.isRequired,
        getWorkspacesApplicationsSettingsById: PropTypes.func.isRequired,
        clearWorkspaceApplicationsSettings: PropTypes.func.isRequired,
    };

    queryUrlTab = () => {
        const query = new URLSearchParams(this.props.location.search);
        return query.get('tab');
    }

    getCurrentWorkspaceId = () => {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    getCurrentApplicationId = () => {
        let id = getUrlWorkspaceApplicationId(this.props.location.pathname);
        return id;
    }

    componentDidMount() {
        const { workspace, role, loading } = this.props;

        if (!loading) {
            const workspaceId = this.getCurrentWorkspaceId();
            const applicationId = this.getCurrentApplicationId();

            if (workspaceId !== workspace.workspace_name.toLowerCase().replaceAll(" ", "+")) {
                let userId = role.user_id;
                if (userId === "") {
                    const sessionRole = getSessionStorage("role");

                    if (sessionRole !== "") {
                        const role = JSON.parse(sessionRole);
                        userId = role.user_id;
                    }
                }
                this.props.getWorkspaceApplicationById(workspaceId, applicationId, userId);
                this.props.getWorkspacesApplicationsSettingsById(workspaceId, applicationId, userId);
              }
            else {
                this.props.getWorkspaceApplicationProperties(workspaceId, applicationId);
                this.props.getWorkspacesApplicationsSettings(workspaceId, applicationId);
              }
        }
    }

    render() {
        const { workspaceApplication, loading, loadingTheme } = this.props;
        
        if (workspaceApplication.workspace_id === undefined || workspaceApplication.workspace_id === '' ||
            (workspaceApplication.application_id === undefined || workspaceApplication.application_id === '') ||
            workspaceApplication.connections === undefined)
            return (
                <div>
                    {(loading || loadingTheme) &&
                        <div className="loader-container">
                            <Loader
                                type="BallTriangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            );

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="BallTriangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <WorkspacesApplicationsSettingsHeader query={this.queryUrlTab} />
                                    { 
                                        this.queryUrlTab() === 'yml' ? 
                                        <WorkspacesApplicationsSettingsYml /> :
                                        <WorkspacesApplicationsSettingsBody />
                                    }
                                </div>
                            </div>
                        </main>
                        <ErrorSuccessModal type="error" />
                        </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, loading, role }) => ({
    workspace: detail.workspace,
    workspaceApplication: detail.workspace_application,
    loading: loading.workspace_application.loading,
    loadingTheme: loading.theme.loading,
    role
});

const mapDispatchToProps = (dispatch) => ({
    getWorkspaceApplicationById: (workspaceId, applicationId, userId) => dispatch(getWorkspaceApplicationByIdWithThunk(workspaceId, applicationId, userId)),
    getWorkspaceApplicationProperties: (workspaceId, applicationId) => dispatch(getWorkspaceApplicationPropertiesWithThunk(workspaceId, applicationId)),
    clearWorkspaceApplication: () => dispatch(clearWorkspaceApplicationAction([])),
    clearWorkspaceApplicationConnections: () => dispatch(clearWorkspaceApplicationConnectionsAction([])),
    getWorkspacesApplicationsSettings: (workspaceId, applicationId) => dispatch(getWorkspacesApplicationsSettingsWithThunk(workspaceId, applicationId)),
    getWorkspacesApplicationsSettingsById: (workspaceId, applicationId, userId) => dispatch(getWorkspacesApplicationsSettingsByIdWithThunk(workspaceId, applicationId, userId)),
    clearWorkspaceApplicationsSettings: () => dispatch(clearWorkspacesApplicationsSettingsAction([]))
  });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsSettingsContainer));
