import {
    getWorkspaceApplicationListApi,
    updateWorkspaceApplicationCategoryApi,
    updateWorkspaceApplicationStatusApi
} from '../backend/domain/workspacesApplications';

import {
    getWorkspaceApplicationCategoryListApi,
    createWorkspaceCategoryApi,
    updateOrderWorkspacesCategoriesApi
} from '../backend/domain/workspacesCategories';

import {
    getCategoryByIdApi
} from '../backend/domain/categories';

import {
    getUserWorkspacePropertiesByIdApi
} from '../backend/domain/workspaces';

import { getWorkspaceAction } from '../actions/getWorkspaceAction';
import { clearWorkspaceAction } from '../actions/clearWorkspaceAction';
import { getWorkspaceAvailableApplicationsAction } from '../actions/getWorkspaceAvailableApplicationsAction';
import { getWorkspacesApplicationsAction } from '../actions/getWorkspacesApplicationsAction';
import { clearWorkspacesApplicationsAction } from '../actions/clearWorkspacesApplicationsAction';
import { loadingWorkspacesApplicationsAction } from '../actions/loadingWorkspacesApplicationsAction';
import { clearWorkspacesCategoriesAction } from '../actions/clearWorkspacesCategoriesAction';
import { getWorkspacesCategoriesAction } from '../actions/getWorkspacesCategoriesAction';
import { overrideWorkspaceId, getWorkspaceNameById } from '../utils/workspaces';
import { mergeWorkspacesApplicationsCategories, updateWorkspacesApplicationsCategoriesStatus } from '../utils/workspacesCategories';
import { sortWorkspacesApplications } from '../utils/workspacesApplications';

export const getWorkspacesApplicationsWithThunk = (workspaceId, isAdmin) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);

        dispatch(loadingWorkspacesApplicationsAction(true));
        getWorkspaceApplicationListApi(wId, isAdmin)
            .then((response) => {
                dispatch(clearWorkspacesApplicationsAction([]));
                dispatch(clearWorkspacesCategoriesAction([]));
                return response;
            })
            .then(async (response) => {
                let workspaceCategories = await getWorkspaceApplicationCategoryListApi(wId);

                let w = await getWorkspaceNameById(wId);
                let applications = getWorkspacesApplications(w.workspace.name, response.applications);
                dispatch(getWorkspacesApplicationsAction(sortWorkspacesApplications(applications)));

                let mergeWorkspaceCategories = await mergeWorkspacesApplicationsCategories(workspaceCategories, applications);
                dispatch(getWorkspacesCategoriesAction(mergeWorkspaceCategories));
            })
            .then((response) => {
                dispatch(loadingWorkspacesApplicationsAction(false));
                return response;
            })
    };

export const getWorkspacesApplicationsByIdWithThunk = (workspaceId, userId, isAdmin, historyProps) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);

        dispatch(loadingWorkspacesApplicationsAction(true));
        dispatch(clearWorkspaceAction([]));
        dispatch(clearWorkspacesApplicationsAction([]));
        dispatch(clearWorkspacesCategoriesAction([]));

        getUserWorkspacePropertiesByIdApi(wId, userId, isAdmin, historyProps, "applications")
            .then((response) => {
                if (!response.error) {
                    dispatch(getWorkspaceAction(response));

                    let availableApplications = {};

                    availableApplications.id = response.available_applications.id;
                    availableApplications.applications = response.available_applications.applications;
                    dispatch(getWorkspaceAvailableApplicationsAction(availableApplications));
                }
                return response;
            })
            .then(() => {
                getWorkspaceApplicationListApi(wId, isAdmin)
                    .then(async response => {
                        let workspaceCategories = await getWorkspaceApplicationCategoryListApi(wId);

                        let w = await getWorkspaceNameById(wId);
                        let applications = getWorkspacesApplications(w.workspace.name, response.applications);
                        dispatch(getWorkspacesApplicationsAction(sortWorkspacesApplications(applications)));

                        let mergeWorkspaceCategories = await mergeWorkspacesApplicationsCategories(workspaceCategories, applications);
                        dispatch(getWorkspacesCategoriesAction(mergeWorkspaceCategories));
                    })
                    .then((response) => {
                        dispatch(loadingWorkspacesApplicationsAction(false));
                        return response;
                    });
            });
    };

export const updateWorkspaceApplicationCategoryWithThunk = (workspaceId, applicationId, categoryId, isWorkspaceCategory, isAdmin) =>
    async (dispatch) => {
        dispatch(loadingWorkspacesApplicationsAction(true));
        if (!isWorkspaceCategory) {
            let category = await getCategoryByIdApi(categoryId);
            let result = await createWorkspaceCategoryApi(workspaceId, category);
            categoryId = result.id;
        }
        updateWorkspaceApplicationCategoryApi(workspaceId, applicationId, categoryId)
            .then(() => {
                getWorkspaceApplicationListApi(workspaceId, isAdmin)
                    .then(async response => {
                        let workspaceCategories = await getWorkspaceApplicationCategoryListApi(workspaceId);

                        let w = await getWorkspaceNameById(workspaceId);
                        let applications = getWorkspacesApplications(w.workspace.name, response.applications);
                        dispatch(clearWorkspacesApplicationsAction(sortWorkspacesApplications(applications)))

                        let mergeWorkspaceCategories = await mergeWorkspacesApplicationsCategories(workspaceCategories, applications);
                        dispatch(clearWorkspacesCategoriesAction(mergeWorkspaceCategories));
                    })
                    .then((response) => {
                        dispatch(loadingWorkspacesApplicationsAction(false));
                        return response;
                    });
            });
    };


export const updateWorkspaceApplicationLinkStatusWithThunk = (workspaceId, applicationId, status, isFromList, isAdmin) =>
    (dispatch) => {
        dispatch(loadingWorkspacesApplicationsAction(true));
        updateWorkspaceApplicationStatusApi(workspaceId, applicationId, status, isFromList)
            .then(async (response) => {
                dispatch(getWorkspaceAvailableApplicationsAction(response));
                if (status === "link" || status === "unlink") {
                    await getWorkspaceApplicationListApi(workspaceId, isAdmin)
                        .then(async response => {
                            let workspaceCategories = await getWorkspaceApplicationCategoryListApi(workspaceId);

                            let w = await getWorkspaceNameById(workspaceId);
                            let applications = getWorkspacesApplications(w.workspace.name, response.applications);
                            dispatch(clearWorkspacesApplicationsAction(sortWorkspacesApplications(applications)))

                            let mergeWorkspaceCategories = await mergeWorkspacesApplicationsCategories(workspaceCategories, applications);
                            dispatch(clearWorkspacesCategoriesAction(mergeWorkspaceCategories));
                        })
                        .then((response) => {
                            return response;
                        });
                }
                else {
                    await updateWorkspacesApplicationsCategoriesStatus(workspaceId, applicationId, status);
                }
            })
            .then((response) => {
                dispatch(loadingWorkspacesApplicationsAction(false));
                return response;
            });
    };

export const updateWorkspacesCategoriesOrderWithThunk = (workspaceId, categoryOrders) =>
    (dispatch) => {
        dispatch(loadingWorkspacesApplicationsAction(true));
        updateOrderWorkspacesCategoriesApi(workspaceId, categoryOrders)
            .then(() => {
                dispatch(loadingWorkspacesApplicationsAction(false));
            });
    };

const getWorkspacesApplications = (workspaceName, workspacesApplications) => {
    let applications = [];

    for (let i in workspacesApplications) {
        let application = workspacesApplications[i];
        application.workspace_name = workspaceName;
        applications.push(application);
    }
    return applications;
}