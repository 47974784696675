import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { updateUserAction } from '../../../actions/updateUserAction';
import { routeCodes } from '../../../configurations/routes';
import { getUrlWorkspaceId, getUrlUserId, getUrlWorkspaceMemberId } from '../../../utils/url';
import { isWorkspaceRootPage } from '../../../utils/login';

class UserDetailHeader extends Component {
    constructor(props) {
        super(props);
        this.onUserNameChange = this.onUserNameChange.bind(this);
        this.state = {
            linkTo: ''
        }
    }

    static propTypes = {
        user: PropTypes.object.isRequired,
        dispatchUser: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired
    };

    onUserNameChange(e) {
        let user = {
            ...this.props.user,
            full_name: e.target.value
        };

        this.props.dispatchUser(user);
    }

    componentDidMount() {
        const location = this.props.location.pathname;

        if (isWorkspaceRootPage(location)) {
            let workspaceId = getUrlWorkspaceId(this.props.location.pathname);
            let userId = getUrlWorkspaceMemberId(this.props.location.pathname);
            this.setState({ linkTo: routeCodes.WORKSPACES_LIST + '/' + workspaceId + '/members/' + userId + '/access'});            
        }
        else {
            let id = getUrlUserId(this.props.location.pathname);
            this.setState({ linkTo: routeCodes.USERS_LIST + '/' + id + '/access' });
        }
    }

    render() {
        const { user, role } = this.props;
        return (
            <div className="mainHead">
                <div className="row">
                    <div className="col-12">
                        <h2 className="float-left mt-2 mr-4">User Profile</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="workspaceInfo">
                            <div className="row">
                                <div className="workspaceName col-xl-7 offset-xl-3">
                                    <div className="float-left">
                                        <div className="formWrap">
                                            <div className="inputForm wsNameEdit">
                                                <input className="form-control appNameInput wsNameEditWider" type="text" name=""
                                                    required defaultValue={user.full_name} onChange={this.onUserNameChange} disabled={role.user_role === 'Owner'} />
                                                <label>Full Name</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="userRole col-xl-7 offset-xl-3 mt-2">
                                    {user.id} <span className={(user.registration_status === 'Pending') ? 'confirmStat ml-2' : 'hide-registration-status'}>{user.registration_status}</span>
                                </div>
                            </div>
                            <nav className="col-lg-5 col-xl-4">
                                <div className="row text-center">
                                    <div className="col px-0">
                                        <Link to={'#'}
                                            className="workspaceMenu py-3 active">Detail
                                        </Link>
                                    </div>
                                    <div className="col px-0">
                                        <Link to={this.state.linkTo}
                                            className="workspaceMenu py-3">Access
                                        </Link>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role }) => ({
    user: detail.user,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchUser: (user) => dispatch(updateUserAction(user))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetailHeader));