import { createImage, updateImage } from '../../utils/snapshot';
import { axios, queryBaseUrlV1, queryBaseUrlV2  } from './getCookies';

const configApp = require('../../configurations/app');

async function getApplicationById(applicationId) {
    const results = await axios.get(queryBaseUrlV2 + "application/" + applicationId);

    return results;
}

async function getApplicationByName(applicationName) {
    const results = await axios.get(queryBaseUrlV2 + "application/id/" + applicationName);

    return results;
}

async function getApplicationAvailability(applicationId) {
   const results = await axios.get(queryBaseUrlV2 + "application/" + applicationId + "/ping")
        .catch(function (error) { return error.response });

    return results;
}

async function createApplication(application) {
    const results = await axios.post(queryBaseUrlV1 + "application", application)
        .catch(function (error) { return error.response });
    return results;
}

async function updateApplication(application) {
   const results = await axios.put(queryBaseUrlV1 + "application", application)
        .catch(function (error) { return error.response });
    return results;
}

async function updateApplicationStatus(applicationId, status) {
   const results = await axios.put(queryBaseUrlV1 + "application/" + applicationId + "/Enable?status=" + ((status === 'Enabled') ? 'true' : 'false'));
    return results;
}

async function updateApplicationCategory(applicationId, categoryId) {
   const results = (categoryId !== "") ? await axios.put(queryBaseUrlV2 + "application/" + applicationId + "/category/" + categoryId)
        : await axios.put(queryBaseUrlV2 + "application/" + applicationId + "/category/reset");
    return results;
}

async function deleteApplication(applicationId) {
    const results = await axios.delete(queryBaseUrlV1 + "application/" + applicationId);
    return results;
}

async function createLogo(applicationId, content) {
   const url = queryBaseUrlV2 + "application/logo/" + applicationId;

    return await createImage(content, url);
}

async function updateLogo(applicationId, content) {
   const url = queryBaseUrlV2 + "application/logo/" + applicationId;

    return await updateImage(content, url);;
}

async function updateSnapshot(applicationId, content) {
   const url = queryBaseUrlV2 + "application/snapshot/" + applicationId;

    return await updateImage(content, url);;
}

async function getApplicationLogo(id, width, height) {
   const url = queryBaseUrlV2 + "application/logo/" + id + "/" + width + "/" + height;

    let results;
    try {
        const response = await axios.get(url, { responseType: 'arraybuffer' });
        results = Buffer.from(response.data, 'binary').toString('base64');
    }
    catch (err) {
        if (err.response !== undefined) {
            if (err.response.status === 404) {
                return results;
            }
        }
    }
    return results;
}

async function getApplicationSnapshot(id, width, height) {
   const url = queryBaseUrlV2 + "application/snapshot/" + id + "/" + width + "/" + height;

    let results;
    try {
        const response = await axios.get(url, { responseType: 'arraybuffer' });
        results = Buffer.from(response.data, 'binary').toString('base64');
    }
    catch (err) {
        if (err.response !== undefined) {
            if (err.response.status === 404) {
                return results;
            }
        }
    }
    return results;
}

async function getApplicationSearchList(keyword, pageIndex, pageSize) {
    const results = await axios.get(queryBaseUrlV2 + "application/search", {
        params: {
            keyword: keyword,
            pageIndex: pageIndex,
            pageSize: pageSize,
            logo: true
        }
    });

    return results;
}

async function getApplicationList(pageIndex, pageSize) {
   const results = await axios.get(queryBaseUrlV2 + "application/list", {
        params: {
            pageIndex: pageIndex,
            pageSize: pageSize,
            logo: true
        }
    });

    return results;
}

async function getAvailableApplicationList(withLogo) {
   const results = await axios.get(queryBaseUrlV2 + "application/list", {
        params: {
            pageIndex: 1,
            pageSize: configApp.maximumApplications,
            logo: withLogo
        }
    });

    return results;
}

export {
    getApplicationById,
    getApplicationByName,
    getApplicationAvailability,
    createApplication,
    updateApplication,
    updateApplicationStatus,
    updateApplicationCategory,
    deleteApplication,
    createLogo,
    updateLogo,
    updateSnapshot,
    getApplicationLogo,
    getApplicationSnapshot,
    getApplicationSearchList,
    getApplicationList,
    getAvailableApplicationList
}
