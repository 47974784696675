exports.settings = "local";
exports.server = "wrddev";
exports.domain = "wrddev";
exports.port = 3100;
exports.portalServer = "wrddev";
exports.portalPort = 3000;
exports.protocol = "http://";
exports.useContainer = false;
exports.anonymous = "00000000-0000-0000-0000-000000000000";
exports.emptyConnectionString = "_$$_";
exports.spatialServer = "epsg.io";
exports.protocol = "http://";
exports.gatewayServerQuery = "wrddev/gateway-api";
exports.gatewayPortQuery = "80";
exports.gatewayAPiVersionQuery = "v1";
exports.gatewayAPiVersionQueryV2 = "v2";
exports.gatewayServerCommand = "wrddev/gateway-api";
exports.gatewayPortCommand = "80";
exports.gatewayAPiVersionCommand = "v1";
exports.gatewayAPiVersionCommandV2 = "v2";
exports.frontendServer = "wrddev";
exports.emailConfirmationPage = "watertools/login/users/confirmation";
exports.resetPasswordLoginPage = "watertools/login/users";
exports.portalServerAppFolder = "portal-server";

// exports.server="localhost";
// exports.domain="localhost";
// exports.port=3100;
// exports.portalServer="wrddev";
// exports.portalPort=3000;
// exports.protocol="http://";
// exports.useContainer=false;
// exports.anonymous="00000000-0000-0000-0000-000000000000";
// exports.emptyConnectionString="_$$_";
// exports.spatialServer="epsg.io";
// exports.protocol="http://";
// exports.gatewayServerQuery="wrddev/gateway-api";
// exports.gatewayPortQuery="80";
// exports.gatewayAPiVersionQuery="v1";
// exports.gatewayAPiVersionQueryV2="v2";
// exports.gatewayServerCommand="wrddev/gateway-api";
// exports.gatewayPortCommand="80";
// exports.gatewayAPiVersionCommand="v1";
// exports.gatewayAPiVersionCommandV2="v2";
// exports.frontendServer="wrddev";
// exports.emailConfirmationPage="watertools/login/users/confirmation";
// exports.resetPasswordLoginPage="watertools/login/users";
// exports.portalServerAppFolder="";