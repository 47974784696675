import * as React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import ReactPlayer from 'react-player/lazy';
import { StyledCol, StyledRow, styled } from '@wrd/utils/style';
import { css } from '@emotion/css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { getDHILogoTransparentImage } from '../../../utils/image';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
// import { autoPlay } from 'react-swipeable-views-utils';
import MobileStepper from '@mui/material/MobileStepper';
import { routeCodes } from '../../../configurations/routes';
import dompurify from 'dompurify';
import ReactGA from 'react-ga';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import useMeasure from 'react-use-measure';
import { useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const sanitizer = dompurify.sanitize;

function MainFeaturedPost(props) {
    const {
        dispatchRoute,
        history,
        workspace,
        categories,
        userCategories,
        role,
        workspace_theme,
        workspaceSetting,
        availableApplications,
        dispatchDialogWorkspacesLandingApplication,
        clientIp,
        availableApplicationsUser
    } = props;
    const template = { __html: workspace.workspace_desc };
    const dhiImage = getDHILogoTransparentImage();
    const showOnPx = 100;
    const [showButtonScroll, setShowButtonScroll] = useState(false);
    const scrollContainer = () => {
        return document.documentElement || document.body;
    };

    const [ref, bounds] = useMeasure();
    const [height, setHeight] = useState(100);
    useEffect(() => {

        // GA needs
        let loc = history.location;
        if (workspaceSetting.useGoogleAnalytics) {
            const trackingId = workspaceSetting.googleAnalyticsTrackingId;
            ReactGA.initialize(trackingId);

            loc = '/workspaces' + loc.pathname.split('/workspaces')[1];
            const workspaceName = workspace.workspace_name;
            const userName = role.user_name;
            const ipAddress = clientIp.ipAddress

            let datetime = new Date();
            datetime = datetime.toUTCString();

            ReactGA.set({ page: loc });
            ReactGA.pageview(`Landing page of ${workspaceName}`);

            // for event
            ReactGA.event({
                category: `Workspace ${workspaceName} landing page`,
                action: 'Display workspace landing page',
                label: `${workspaceName} - ${ipAddress} - ${userName} - ${datetime}`
            });
        }

        document.addEventListener("scroll", () => {
            if (scrollContainer().scrollTop > showOnPx) {
                setShowButtonScroll(true);
            } else {
                setShowButtonScroll(false);
            }
        });
    }, [])

    const changeRoute = (name) => {
        let navigation = {
            name
        };
        dispatchRoute(navigation);
    }

    // const contactOwners = () => {
    //     changeRoute(routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+"));
    //     history.push(routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/contactOwners");
    // }

    const moveUp = () => {
        document.body.scrollIntoView({
            behavior: "smooth"
        });
    }

    const openUrl = (url) => {
        if (url.trim() === "") {
            return;
        }

        if (!url.includes("http")) {
            url = "https://" + url;
        }

        const win = window.open(url, "_blank");
        if (win != null) {
            win.focus();
        }
    }

    if (categories === undefined || userCategories === undefined)
        return (null);

    let noCategoryApplications = [];
    let categoryApplications = [];
    let categs = [];

const categoryId= new Set(categories.map(item => item.category_id));
const filteredItems = availableApplications.applications.filter(item => categoryId.has(item.category_id) && item.status === 'Enabled');
function getUniqueListBy(filteredItems, key) {
  return [...new Map(filteredItems.map(item => [item[key], item])).values()]
}
const applicationEnabled = getUniqueListBy(filteredItems, 'category_title')

let apps = [];
    if (role.is_logged_in) {
        if (role.user_role === 'Administrator' || role.user_role === 'User' || (workspace.owner && !workspace.suspendedUser)) {
            apps = (availableApplications.applications !== undefined) ? availableApplications.applications : [];
            categs = applicationEnabled;
        }
        else {
            apps = (availableApplicationsUser !== undefined) ? availableApplicationsUser : [];
            categs = applicationEnabled;
        }
    }
    else {
        apps = (availableApplications.applications !== undefined) ? availableApplications.applications.filter((item) => item.allow_public_access) : [];
        categs = applicationEnabled;
    }

    if (apps.length !== 0) {
        noCategoryApplications = apps.filter((item) => item.category_title === "");
        categoryApplications = apps.filter((item) => item.category_title !== "");
    }

    useEffect(() => {
        setHeight(bounds.height)
    }, [bounds]);

    const handleClickScroll = (id) => {
        const element = document.getElementById(id);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <>
            {showButtonScroll &&
                <Fab color="primary" variant="extended" sx={{
                    borderRadius: '5px',
                    position: 'fixed',
                    bottom: '120px',
                    right: '20px',
                }}
                    onClick={moveUp}

                >
                    <NavigationIcon />
                </Fab>
            }
            <div className={css`
                          position: relative;
                          height:auto;
                          background:#0B4566;
                          color:#fff;
                          background-size:100% 100%;
                          background-repeat:no-repeat;
                          background-position:center;
                          background-image:url(data:image/png;base64,${workspace.workspace_landing_banner});
                        `}>
                {/* <img id="header-image" className={css`
                            position: absolute;
                            width: 100%;
                            height: ${height}px;
                            top: 0;
                            left: 0;
                        `} src={"data:image/png;base64," + workspace.workspace_landing_banner} alt="Workspace Logo" />
             */}
                <div className={css`
                          position: absolute;
                          width: 100%;
                          height: ${height}px;
                          top: 0;
                          left: 0;
                        `}>

                    <Grid container ref={ref}>
                        <Grid item md={8}>
                            <Box
                                sx={{
                                    position: 'relative',
                                    p: { xs: 4, md: 8 },
                                    pr: { md: 0 },
                                }}
                            >
                                <Typography component="h4" variant="h4" color="inherit" gutterBottom>
                                    {workspace.workspace_name}
                                </Typography>
                                <Typography component="h5" variant="h5" color="inherit" gutterBottom>
                                    {workspace.workspace_title}
                                    <img className="crop-logo_landing" onClick={() => openUrl(workspace.workspace_url)} src={((workspace.url_image !== undefined) ? "data:image/png;base64," : "") + workspace.url_image} style={{ cursor: (workspace.workspace_url !== '') ? 'pointer' : 'default' }} title={workspace.workspace_url} alt="" />
                                </Typography>
                                <p></p>
                                {/* {categs.length > 0 &&
                                    <Typography dangerouslySetInnerHTML={template}></Typography>
                                } */}
                                 {(categs.length > 0) &&
                                <div className="col-9" style={{ height: 100 + 'px' }}>
                                    <Scrollbars style={{ height: 120 + 'px' }}>
                                        <div>
                                            <div className="wsHomeSection">
                                                <div className='desc py-2 pr-4 font-style-wsdeescription'>
                                                  <span dangerouslySetInnerHTML={template}></span>
                                                </div>
                                            </div>
                                        </div>
                                    </Scrollbars>
                                </div>
                            }
                            </Box>
                        </Grid>

                        {
                            (workspace.video_url !== "") &&
                            <Grid item md={4}>
                                <Box
                                    sx={{
                                        position: 'relative',
                                        p: { xs: 4, md: 4 },
                                        pr: { md: 0 },
                                        height: '80%',
                                        width: '80%',
                                        minHeight: '225px',
                                        maxHeight: '300px',
                                        m: '15px',
                                        mt: '25px',
                                    }}
                                >
                                    <ReactPlayer id="header-react-player" controls={true} url={(workspace.video_url.includes("http")) ? workspace.video_url : "https://" + workspace.video_url} width='100%' height='100%' />
                                </Box>
                            </Grid>
                        }

                    </Grid >
                </div>
                <div className={css`
                          width: 100%;
                          height: ${height}px;
                          top: 0;
                          left: 0;
                        `}>
                    <svg width="100%" height="100%" viewBox={"0 90 " + "100%" + "" + "100%"} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.95" d="M621.964 660.81C1320.93 645.328 1553.23 212.221 1582 0H-10V1178C-3.02426 924.402 291.337 668.134 621.964 660.81Z" fill="url(#paint0_linear_137_896)" />
                        <defs>
                            <linearGradient id="paint0_linear_137_896" x1="394.581" y1="142.5" x2="1584.79" y2="606.778" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#0B4566" />
                                <stop offset="1" stopColor="#0B4566" stopOpacity="0" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>
            <StyledRow
                className={css`
                    flex-wrap: wrap;
                    padding:0px 25px;
                    color:#fff;
                    background-image: radial-gradient(at top left, transparent 67%, #F2F5F7 67.5%),
                        linear-gradient(135deg, #0B4566 0%, #a5b1c2 100%);
                    background-size: 110% 100%,cover;
                    background-repeat: no-repeat;
                        `}>

                {categs.filter(f => f.category_title !== '').map(d => (
                    <Typography
                        component={Link}
                        key={Math.random()}
                        to={`/${d.category_id}`}
                        onClick={() => handleClickScroll(d.category_id)}
                        sx={{
                            margin: '17px 25px',
                            fontWeight: 700,
                            fontSize: '32px',
                            borderRight: '2px dotted #c7ecee',
                            padding: 2.5,
                            cursor: 'pointer',
                            "&:hover": {
                                transform: 'scale(0.9)',
                            },
                        }} variant="h4" color="inherit" gutterBottom>
                        {d.category_title}
                    </Typography>
                ))}
            </StyledRow>
            {noCategoryApplications.length > 0 &&
                <>
                    <br />
                    <StyledRow className={css`
                    flex-wrap: wrap;
                    margin:5px;
                    padding:10px 50px;
                    `}>
                        {
                            noCategoryApplications.map((item) => (
                                <MediaCard key={Math.random()} item={item} history={history} workspace={workspace} dispatchDialogWorkspacesLandingApplication={dispatchDialogWorkspacesLandingApplication} />
                            ))
                        }
                    </StyledRow>
                </>
            }


            {
                categs.length > 0 &&
                <>
                    <Grid container>
                        {categs.map((ct, index) => {
                            return (
                                <StyledCategoriesContent white={index % 2 !== 0} className={css`
                                    padding:10px 50px;
                                    width:100%;
                                    `}
                                    id={ct.category_id}
                                    key={Math.random()}
                                >
                                    <StyledCategoriesGroup>
                                        <StyledCategories white={index % 2 !== 0}>

                                            <Typography sx={{
                                                fontWeight: 700,
                                                fontSize: '32px',
                                            }} component="h4" variant="h4" color="inherit" gutterBottom>
                                                {ct.category_title}
                                            </Typography>
                                            <Typography sx={{
                                                fontWeight: 500,
                                                fontSize: '18px',
                                            }} dangerouslySetInnerHTML={{ __html: sanitizer(ct.category_description) }}></Typography>

                                            <br />
                                        </StyledCategories>

                                        <StyledRow className={css`
                                     flex-wrap: wrap;
                                    `}>
                                            {
                                                categoryApplications.filter((item) => item.category_id === ct.category_id).map((item) => (
                                                    <MediaCard key={Math.random()} item={item} history={history} workspace={workspace} dispatchDialogWorkspacesLandingApplication={dispatchDialogWorkspacesLandingApplication} />
                                                ))
                                            }

                                        </StyledRow>
                                    </StyledCategoriesGroup>
                                </StyledCategoriesContent>
                            )
                        })}
                    </Grid>

                    <StyledRow className={css`
                                    position:relative;
                                    min-height:56px;
                                    padding:0px 15px;
                                    background:#0B4566;
                                    `}>
                        <img className={css`
                                        cursor: pointer;
                                        padding-right: 10px;
                                        width: 100px;
                                        min-width: 83.47px;
                                        display: block;
                                    `} style={{ cursor: 'pointer' }} onClick={() => window.open("http://www.dhigroup.com", "_blank")} src={"data:image/png;base64," + dhiImage} title="www.dhigroup.com" alt="" />
                        <img className={css`
                                        width: 70px;
                                        height: 50px;
                                        margin: 0px 20px;
                                    `} onClick={() => openUrl(workspace.workspace_url)} src={((workspace.url_image !== undefined) ? "data:image/png;base64," : "") + workspace.url_image} style={{ cursor: (workspace.workspace_url !== '') ? 'pointer' : 'default' }} title={workspace.workspace_url} alt="" />
                        <StyledCol className={css`
                                        margin-left:auto;
                                        margin-right:20px;
                                    `}>
                            <StyledButtonFooter variant="text"
                                onClick={moveUp}
                            >
                                <Typography sx={{
                                    color: '#fff',
                                }} component="subtitle1" variant="subtitle1" color="inherit" gutterBottom>
                                    HOME
                                </Typography>
                            </StyledButtonFooter>
                            {/* <StyledButtonFooter variant="text" sx={{
                                textTransform: 'none',
                            }}
                                onClick={contactOwners}
                            >
                                <Typography sx={{
                                    color: '#fff',
                                }} component="subtitle1" variant="subtitle1" color="inherit" gutterBottom>
                                    Contact
                                </Typography>
                            </StyledButtonFooter> */}

                        </StyledCol>
                    </StyledRow>
                </>
            }

            {
                categs.length === 0 &&
                <>
                    <StyledRow className={css`
                                    position:relative;
                                    margin:5px;
                                    padding:10px 50px;
                                    `}>
                        <Typography sx={{
                            marginTop: '15px',
                            fontWeight: 500,
                        }} component="h5" variant="h5" color="inherit" gutterBottom>
                            About {workspace.workspace_name}
                        </Typography>
                    </StyledRow>
                    {/* <StyledRow className={css`
                                    position:relative;
                                    margin:5px;
                                    padding:10px 50px;
                                    `}>
                        <Typography dangerouslySetInnerHTML={template}></Typography>
                    </StyledRow>
                    <StyledRow className={css`
                                position:relative;
                                margin:5px;
                                padding:10px 50px;
                                text-align:center;
                                justify-content: center;
                            `}>
                        <StyledButton variant="text"
                            onClick={contactOwners}
                        >
                            <Typography sx={{
                                marginTop: '15px',
                                textDecoration: 'underline'
                            }} component="h5" variant="h5" color="inherit" gutterBottom>
                                Contact & Support
                            </Typography>
                        </StyledButton>
                    </StyledRow> */}
                </>
            }
        </>
    );
}



export function MediaCard(props) {

    const { item, workspace, dispatchDialogWorkspacesLandingApplication, history, availableApplications } = props;
    const [activeStep, setActiveStep] = React.useState(1);
    const list = [
        {
            label: item.category_title,
            imgPath: item.application_description,
        },
        {
            label: item.category_title,
            imgPath: item.snapshot_url_image,
        },
    ]
    const maxSteps = list.length;
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };


    const routeToApp = (e) => {
        const url = window.location.href;
        let isPortalHttps = (url.includes('https')) ? true : false;
        let isApplicationHttps = (item.application_url.includes('https')) ? true : false;

        let isWarning = (isPortalHttps && !isApplicationHttps);

        const link = routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/applications/" + item.application_name.toLowerCase().replaceAll(" ", "+");
        if (!e.ctrlKey) {
            if (!isWarning) {
                history.push(link);
            }
            else {
                showDialog(item.application_name, link, false);
            }
        }
        else {
            if (!isWarning) {
                const win = window.open(link, "_blank");
                if (win != null) {
                    win.focus();
                }
            }
            else {
                showDialog(item.application_name, link, true);
            }
        }
    }

    const showDialog = (appName, link, controlKey) => {
        let workspaces_landing_application = {
            id: '',
            name: '',
            status: '',
            dialog_title: appName,
            dialog_type: '',
            link,
            control_key: controlKey,
            show_dialog: true
        };

        dispatchDialogWorkspacesLandingApplication(workspaces_landing_application);
    }

    return (
      <>
      {item.status==="Enabled"?(
        <StyledCard sx={{ minWidth: 300, maxWidth: 400 }}>
            <CardContent sx={{ minHeight: 255 }}>

                <Typography gutterBottom variant="h6" component="div"
                    sx={{
                        height: 'auto',
                        minHeight: 50
                    }}
                >
                    {item.application_name}
                </Typography>

                {!item.snapshot_url_image &&
                    <Typography variant="subtitle1" color="text.secondary" dangerouslySetInnerHTML={{ __html: sanitizer(item.application_description) }}>

                    </Typography>
                }
                {item.snapshot_url_image ?
                    <>
                        <SwipeableViews
                            // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >
                            {list.map((step, index) => {
                                if (index === 0) {
                                    return (
                                        <Typography key={step.label + '0'} variant="subtitle1" color="text.secondary" dangerouslySetInnerHTML={{ __html: sanitizer(item.application_description) }}>
                                        </Typography>
                                    )
                                } else {
                                    return (
                                        <Box
                                            key={step.label + '1'}
                                            component="img"
                                            sx={{
                                                height: 255,
                                                display: 'block',
                                                maxWidth: 400,
                                                overflow: 'hidden',
                                                width: '100%',
                                            }}
                                            src={"data:image/png;base64," + step.imgPath}
                                            alt={step.label}
                                        />
                                    )
                                }

                            })}
                        </SwipeableViews>
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                                <Button
                                    size="small"
                                    onClick={handleNext}
                                    disabled={activeStep === maxSteps - 1}
                                >
                                    <KeyboardArrowRight />
                                </Button>
                            }
                            backButton={
                                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    <KeyboardArrowLeft />
                                </Button>
                            }
                        />
                    </>
                    :
                    <Box
                        key={'blank'}
                        component="div"
                        sx={{
                            height: 300,
                            display: 'block',
                            maxWidth: 400,
                            overflow: 'hidden',
                            width: '100%',
                        }}
                        alt={'blank'}
                    />
                }
            </CardContent>
            <CardActions>
                <Button sx={{ width: '100%' }} variant="outlined" size="large" onClick={routeToApp} >Open</Button>
            </CardActions>
        </StyledCard>
        ):null}
        </>
    );
}

export const StyledCategoriesGroup = styled.div`
display: flex;
align-items: center;
flex-direction: row;
flex-grow: 1;
box-sizing: border-box;
@media (max-width: 768px) {
    flex: 1 100%;
    flex-direction: column;
    max-width:fit-content;
}
`
const StyledCategoriesContent = styled(StyledCol)`
    ${({ white }) => white && `
    // background-image:
    //     linear-gradient(
    //       to bottom right,
    //       hsl(208.29, 42.68%, 32.16%) 0%,
    //       hsl(208.85, 38.4%, 33.91%) 16.3%,
    //       hsl(209.86, 30.32%, 38.19%) 29.7%,
    //       hsl(210.64, 23.18%, 43.73%) 40.6%,
    //       hsl(211.06, 17.93%, 49.8%) 49.4%,
    //       hsl(211.18, 18.11%, 55.99%) 56.4%,
    //       hsl(211.09, 19.03%, 62.08%) 61.8%,
    //       hsl(210.86, 20.64%, 67.89%) 66.2%,
    //       hsl(210.56, 23.1%, 73.32%) 69.7%,
    //       hsl(210.21, 26.69%, 78.28%) 72.7%,
    //       hsl(209.86, 31.83%, 82.7%) 75.6%,
    //       hsl(209.53, 39.11%, 86.49%) 78.7%,
    //       hsl(209.23, 49.15%, 89.59%) 82.4%,
    //       hsl(209, 61.89%, 91.92%) 86.9%,
    //       hsl(208.85, 74.67%, 93.4%) 92.7%,
    //       hsl(208.8, 80.65%, 93.92%) 100%
    //     );
        // background-image: linear-gradient(to right bottom, #0b4566, #586e8b, #929ab0, #cacbd7, #ffffff);
        background: rgb(11,69,102);
        background: linear-gradient(140deg, rgba(11,69,102,1) 0%, rgba(255,255,255,1) 100%);
    background-size: 110% 100%,cover;
    background-repeat: no-repeat;
    `}
`

const StyledCategories = styled.div`
    ${({ white }) => white && `
    color:#fff;
    `}
    align-self: baseline;
    align-items: baseline;
    min-width:300px;
    max-width:400px;
    min-height:200px;
    height:auto;
    width: 100%;
    display: flex;
    padding: 5px;
    flex-direction: column;
    border-radius:5px !important;
    margin:0.5rem;
    padding: 10px;
    flex: 50%;
    // justify-content: center;
    @media (max-width: 945px) {
        // flex: 1 100%;
        // max-width:fit-content;
        // min-width:200px;
    }
    @media (max-width: 768px) {
        flex: 1 100%;
        align-self:baseline;
        // max-width:fit-content;
        // min-width:200px;
    }
`

const StyledCard = styled(Card)`
    background: #fff;
    display: flex;
    padding: 5px;
    flex-direction: column;
    border-radius:5px !important;
    margin:0.5rem;
    height:auto;
    width: 100%;
    padding: 10px;
    flex: 40%;
    // justify-content: center;
    @media (max-width: 945px) {
        flex: 1 100%;
    }
    @media (max-width: 768px) {
        flex: 1 100%;
    }
`

const StyledButtonFooter = styled(Button)`
  margin:0px;
  padding:0px;
`

const StyledButton = styled(Button)`
  margin:0px;
  padding:0px;
  &:hover {
    background-color:#F2F5F7;
  }
`

// MainFeaturedPost.propTypes = {
//     post: PropTypes.shape({
//         description: PropTypes.string.isRequired,
//         image: PropTypes.string.isRequired,
//         imageText: PropTypes.string.isRequired,
//         linkText: PropTypes.string.isRequired,
//         title: PropTypes.string.isRequired,
//     }).isRequired,
// };

export default MainFeaturedPost;
