import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { updateChangePasswordAction } from '../../../actions/updateChangePasswordAction';
import { updateUserChangePasswordWithThunk } from '../../../api/changePasswordThunk';
import { routeCodes } from '../../../configurations/routes';
import { updateDialogUsersLoginErrorAction } from '../../../actions/updateDialogUsersLoginErrorAction';
import { encryptPassword, checkPassword } from '../../../utils/password';

class UsersChangePasswordBody extends Component {
    constructor(props) {
        super(props);        
        this.onCurrentPasswordChange = this.onCurrentPasswordChange.bind(this);
        this.onNewPasswordChange = this.onNewPasswordChange.bind(this);
        this.onConfirmationNewPasswordChange = this.onConfirmationNewPasswordChange.bind(this);
        this.submitChangePassword = this.submitChangePassword.bind(this);
        this.userProfilePage = this.userProfilePage.bind(this);
        this.state = {
            current_password_validation: '',
            new_password_validation: '',
            confirm_password_validation: ''
        };
    }    

    static propTypes = {
        account: PropTypes.object.isRequired,       
        dispatchChangePassword: PropTypes.func.isRequired,
        dispatchDialogUsersLoginError: PropTypes.func.isRequired,
        updateChangePassword: PropTypes.func.isRequired
    };    

    onCurrentPasswordChange(e) {
        const changePassword = {
            ...this.props.changePassword,
            current_password: e.target.value
        }

        let passwordCheck = checkPassword(changePassword.current_password);
        if (passwordCheck === 'OK') {
            this.setState({ current_password_validation: '' })
        } else {
            this.setState({ current_password_validation: passwordCheck })
        }

        changePassword.current_password = encryptPassword(changePassword.current_password);
        this.props.dispatchChangePassword(changePassword);
    }

    onNewPasswordChange(e) {
        const changePassword = {
            ...this.props.changePassword,
            new_password: e.target.value
        }

        let passwordCheck = checkPassword(changePassword.new_password);
        if (passwordCheck === 'OK') {
            this.setState({ new_password_validation: '' })
        } else {
            this.setState({ new_password_validation: passwordCheck })
        }       

        changePassword.new_password = encryptPassword(changePassword.new_password);
        this.props.dispatchChangePassword(changePassword);
    }

    onConfirmationNewPasswordChange(e) {
        const changePassword = {
            ...this.props.changePassword,
            confirmation_new_password: e.target.value
        }

        let passwordCheck = checkPassword(changePassword.confirmation_new_password);
        if (passwordCheck === 'OK') {
            this.setState({ confirm_password_validation: '' })
        } else {
            this.setState({ confirm_password_validation: passwordCheck })
        }

        changePassword.confirmation_new_password = encryptPassword(changePassword.confirmation_new_password);
        this.props.dispatchChangePassword(changePassword);
    }       

    userProfilePage() {
        this.props.history.push(routeCodes.PROFILE);
    }

    submitChangePassword() {
        const { changePassword, role } = this.props;
        if (changePassword.new_password !== changePassword.confirmation_new_password) {
            let login_error = {
                id: role.user_id,
                name: '',
                status: '',
                dialog_title: 'New password and new password confirmation do not match',
                dialog_type: 'New password mismatch',
                show_dialog: true
            };
            this.props.dispatchDialogUsersLoginError(login_error);
        }
        else {

            this.props.updateChangePassword(changePassword, role.user_id);
        }
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
        const { account } = this.props;

        return (
            <div className="mainBody">
                <div className="row mt-5">
                    <div className="col-md-12 order-md-1 col-lg-8">
                        <div className="wsHomeSection">
                            <div className="desc">
                                <div className="formWrap">
                                    <p className="subLabel px-3 py-4">Change Password</p>
                                    <div className="inputForm">
                                        <input className="form-control text-password" type="text" name="" onChange={this.onCurrentPasswordChange} required />
                                        <label>Current Password</label>
                                        <span className="password-validation">{this.state.current_password_validation}</span>
                                    </div>
                                    <div className="inputForm">
                                        <input className="form-control text-password" type="text" name="" onChange={this.onNewPasswordChange} required />
                                        <label>New Password</label>
                                        <span className="password-validation">{this.state.new_password_validation}</span>
                                    </div>
                                    <div className="inputForm">
                                        <input className="form-control text-password" type="text" name="" onChange={this.onConfirmationNewPasswordChange} required />
                                        <label>Confirmation of New Password</label>
                                        <span className="password-validation">{this.state.confirm_password_validation}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 order-md-1">
                        <div className="inputForm buttonGroup mt-2 text-center">
                            <button className="btn greyBtn textBtn mx-2" onClick={this.userProfilePage}>Cancel</button>
                            <button className="btn greenBtn textBtn mx-2" onClick={this.submitChangePassword}>Submit</button>
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-4 col-xl-3 d-none d-lg-block">
                        <div className="wsPhoto text-center">
                            <div className="photoContainer userPhoto mtMin text-center mb-4">
                                <img src={process.env.PUBLIC_URL + "/images/transCircle.png"} className="insideCircle" alt="" />
                                <img src={((account.picture !== undefined) ? "data:image/jpeg;base64," : "") + account.picture} className="photo" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ role, detail }) => ({
    role,
    account: detail.account,
    changePassword: detail.changePassword
});

const mapDispatchToProps = (dispatch) => ({
    dispatchChangePassword: (changePassword) => dispatch(updateChangePasswordAction(changePassword)),
    updateChangePassword: (account, uid) => dispatch(updateUserChangePasswordWithThunk(account, uid)),
    dispatchDialogUsersLoginError: (dialog) => dispatch(updateDialogUsersLoginErrorAction(dialog))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersChangePasswordBody));