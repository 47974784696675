import { getUserAvailableWorkspacesApi } from '../backend/domain/users';
import { maximumGlobalUsers } from '../configurations/users';
import { isValidGuid } from '../utils/index';
import {
  isLoginPage,
  isConfirmationPage,
  isPortalLandingPage,
  isWorkspaceRootPage,
  isApplicationLandingPage,
  isSessionExpired,
  checkIfUserisOwner,
  checkIfUserisMember,
  isPasswordResetPage,
  isPortalLogoutPage,
  isPortalDomainPage,
  isWorkspaceLoginPage,
  isProfilePage,
  checkIfUserisActiveMember,
  isUnavailablePage,
  isWorkspacePropertiesPage,
  isWorkspaceApplicationRootPage,
  isWorkspaceLandingPage,
  isChangePasswordPage,
  isContactOwnersPage,
  isContactAdministratorsPage,
  isWorkspaceApplicationListPage,
  isWorkspaceMemberListPage,
  isWorkspaceSettingsPage,
  isWorkspaceMemberRegisterPage,
} from '../utils/login';
import {
  getLocalStorage,
  saveLocalStorage,
  removeLocalStorage,
} from '../utils/localStorage';
import {
  getSessionStorage,
  removeSessionStorage,
  saveSessionStorage,
} from '../utils/sessionStorage';
import {
  validateWorkspaceId,
  updateAvailableWorkspacesOpenedStatus,
} from '../utils/workspaces';
import { validateWorkspaceApplcationId } from '../utils/workspacesApplications';
import { generatePaths } from '../utils/url';
import { decodeToken } from '../utils/token';
import { updateRoleProperties } from '../utils/role';
import { appFolder } from '../configurations/app';
import { routeCodes } from '../configurations/routes';
import {
  checkApplicationPublicAccessWithThunk,
  getWorkspaceApplicationHeaderByIdWithThunk,
} from '../api/workspacesApplicationsThunk';
import { getWorkspaceThemeSettingImplementationWithThunk } from '../api/workspacesThemeSettingsThunk';
import { getWorkspaceHeaderByIdWithThunk } from '../api/workspacesThunk';
import { userLogout } from '../api/logoutThunk';
import { updateRedirectUrlAction } from '../actions/updateRedirectUrlAction';
import { clearRedirectUrlAction } from '../actions/clearRedirectUrlAction';
import { updateUnavailableContextAction } from '../actions/updateUnavailableContextAction';
import { updateRoleAction } from '../actions/updateRoleAction';
import { clearWorkspaceAction } from '../actions/clearWorkspaceAction';
import { store } from '../index';

const virtualFolder = '/' + appFolder + '/';

export const redirectToPage = async (
  location,
  redirectUrlProps,
  roleObject
) => {
  if (
    !isPortalLandingPage(location) &&
    !isLoginPage(location) &&
    !isConfirmationPage(location) &&
    !isPasswordResetPage(location) &&
    !isPortalLogoutPage(location) &&
    !isPortalDomainPage(location) &&
    !isWorkspaceLoginPage(location) &&
    !isChangePasswordPage(location) &&
    !isContactOwnersPage(location) &&
    !isContactAdministratorsPage(location)
  ) {
    if (!isSessionExpired()) {
      if (isWorkspaceRootPage(location)) {
        if (roleObject.user_id === '' || roleObject.user_id === undefined) {
          roleObject = JSON.parse(getSessionStorage('role'));
        }
        if (roleObject.user_role !== 'Administrator') {
          let url = location.split(virtualFolder);
          if (url.length > 1) {
            let paths = generatePaths(url);
            saveLocalStorage(
              roleObject.user_id + '-last_used_workspace',
              routeCodes.WORKSPACES_LIST + '/' + paths[2]
            );
          }
        }
      }
    } else {
      if (isUnavailablePage(location)) {
        let paramsString = window.location.search;
        let params = new URLSearchParams(paramsString);
        location = params.get('page');
      }

      const url = {
        ...redirectUrlProps,
        url_redirect: location,
      };
      saveLocalStorage('workspace-direct_access', location);
      store.dispatch(updateRedirectUrlAction(url));
    }
  }
};

export const checkBeforeAccess = async (
  prevLocation,
  location,
  encodedRole,
  historyProps,
  roleObject
) => {
  if (isSessionExpired()) {
    let url = location.split(virtualFolder);
    if (url.length > 0) {
      let paths = url.length === 2 ? generatePaths(url) : [];
      store.dispatch(userLogout());
      if (
        !isPortalLandingPage(location) &&
        !isConfirmationPage(location) &&
        !isPasswordResetPage(location) &&
        !isWorkspaceLoginPage(location) &&
        !isUnavailablePage(location)
      ) {
        if (isPortalDomainPage(location)) {
          historyProps.push(routeCodes.HOME);
        } else {
          if (isWorkspaceRootPage(location)) {
            if (isApplicationLandingPage(location)) {
              checkApplicationPublicAccessWithThunk(
                paths[2],
                paths[4],
                false,
                false,
                false,
                location,
                ''
              ).then((pageToGo) => {
                historyProps.push(pageToGo);
              });
            } else {
              if (
                isWorkspaceLandingPage(location) ||
                isContactOwnersPage(location) ||
                isWorkspaceMemberRegisterPage(location)
              ) {
                getWorkspaceHeaderByIdWithThunk(paths[2]).then(
                  async (response) => {
                    if (response.allow_public_access) {
                      await getWorkspaceThemeSettingImplementationWithThunk(
                        paths[2]
                      );
                      if (response.enabled && response.status === 'Active') {
                        if (isWorkspaceLandingPage(location)) {
                          historyProps.push(
                            routeCodes.WORKSPACES_LIST + '/' + paths[2]
                          );
                        } else if (isContactOwnersPage(location)) {
                          historyProps.push(
                            routeCodes.WORKSPACES_LIST +
                              '/' +
                              paths[2] +
                              '/contactOwners'
                          );
                        } else if (isWorkspaceMemberRegisterPage(location)) {
                          historyProps.push(
                            routeCodes.WORKSPACES_LIST +
                              '/' +
                              paths[2] +
                              '/members/register'
                          );
                        }
                      } else {
                        if (!response.enabled) {
                          store.dispatch(
                            updateUnavailableContextAction('disabledWorkspace')
                          );
                        } else if (response.status !== 'Active') {
                          store.dispatch(
                            updateUnavailableContextAction('suspendedWorkspace')
                          );
                        }
                        historyProps.push(
                          routeCodes.UNAVAILABLE_PAGE + '?page=' + location
                        );
                      }
                    } else {
                      historyProps.push(
                        routeCodes.USERS_LOGIN + '/' + paths[2]
                      );
                    }
                  }
                );
              } else {
                let decodedRole = decodeToken(encodedRole);
                if (
                  decodedRole !== undefined &&
                  decodedRole !== null &&
                  decodedRole.user_id !== ''
                ) {
                  const role = JSON.stringify(decodedRole);

                  await getWorkspaceThemeSettingImplementationWithThunk(
                    paths[2]
                  );
                  await saveSessionStorage('login_date', new Date(Date.now()));
                  await saveSessionStorage('role', role);
                  await store.dispatch(updateRoleAction(decodedRole));

                  historyProps.push(location);
                } else {
                  historyProps.push(routeCodes.USERS_LOGIN + '/' + paths[2]);
                }
              }
            }
          } else {
            document.getElementById('PortalTheme').href =
              process.env.PUBLIC_URL + '/portal/css/white-theme.css';
            // historyProps.push(routeCodes.USERS_LOGIN);
            let url = location.split('.');
            if (url.length > 2) {
              if (url[0] === 'www') {
                window.location.href = routeCodes.USERS_LOGIN;
              } else {
                historyProps.push(routeCodes.USERS_LOGIN);
              }
            } else {
              window.location.href = routeCodes.USERS_LOGIN;
            }
          }
        }
      } else {
        document.getElementById('PortalTheme').href =
          process.env.PUBLIC_URL + '/portal/css/white-theme.css';
        if (isPortalLandingPage(location)) {
          historyProps.push(routeCodes.USERS_LOGIN);
        } else {
          historyProps.push(location);
        }
      }
    }
  } else {
    if (
      (roleObject !== undefined && roleObject.user_id === '') ||
      (roleObject !== undefined && roleObject.user_id === undefined) ||
      roleObject === undefined
    ) {
      roleObject = JSON.parse(getSessionStorage('role'));
      store.dispatch(updateRoleAction(roleObject));
    }

    let availableWorkspaces = getSessionStorage('allAvailableWorkspaces');
    let availableWorkspacesObject =
      availableWorkspaces !== ''
        ? JSON.parse(getSessionStorage('allAvailableWorkspaces'))
        : [];

    if (
      !isPortalLandingPage(location) &&
      !isLoginPage(location) &&
      !isConfirmationPage(location) &&
      !isPasswordResetPage(location) &&
      !isUnavailablePage(location)
    ) {
      rulesChecker(
        roleObject,
        historyProps,
        prevLocation,
        location,
        availableWorkspacesObject,
        false
      );
    }
  }
};

export const checkAfterLoggedIn = async (historyProps, roleObject) => {
  let availableWorkspacesSession = getSessionStorage('allAvailableWorkspaces');
  let availableWorkspacesObject = {};
  if (availableWorkspacesSession !== '') {
    availableWorkspacesObject = JSON.parse(availableWorkspacesSession);
  } else {
    await getUserAvailableWorkspacesApi(
      roleObject.user_id,
      roleObject.user_role,
      1,
      maximumGlobalUsers
    );
    availableWorkspacesSession = getSessionStorage('allAvailableWorkspaces');
    if (availableWorkspacesSession !== '') {
      availableWorkspacesObject = JSON.parse(availableWorkspacesSession);
    }
  }

  const directAnonymousAccess = getSessionStorage('anonymous_direct_access');
  const directAccess = getLocalStorage(roleObject.user_id + '-direct_access');
  const lastUsedWorkspace = getLocalStorage(
    roleObject.user_id + '-last_used_workspace'
  );

  store.dispatch(clearRedirectUrlAction());

  let location =
    directAnonymousAccess !== ''
      ? directAnonymousAccess
      : directAccess !== ''
      ? directAccess
      : lastUsedWorkspace;

  const unregisteredPage = getLocalStorage('workspace-direct_access');
  ///workspace app direct access must more specific than direct access
  const compareLong =
    unregisteredPage.split('/').length > location.split('/').length;
  if (compareLong && unregisteredPage !== '') location = unregisteredPage;
  removeLocalStorage('workspace-direct_access');

  let isValid = await isLocationValid(location);
  if (directAnonymousAccess !== '') {
    removeSessionStorage('anonymous_direct_access');
  }

  location = isValid ? location : '';
  rulesChecker(
    roleObject,
    historyProps,
    null,
    location,
    availableWorkspacesObject,
    true
  );
};

async function isLocationValid(location) {
  let isValid = true;

  let params = location.split('/');
  if (params.length > 1) {
    if (params[1] !== appFolder) {
      isValid = false;
      return isValid;
    }
  } else {
    isValid = false;
    return isValid;
  }

  if (isWorkspaceRootPage(location)) {
    let url = location.split(virtualFolder);
    if (url.length > 1) {
      let paths = generatePaths(url);
      if (paths.length > 2 && paths.length < 5) {
        if (isValidGuid(paths[2])) {
          isValid = false;
        } else {
          let wId = await validateWorkspaceId(paths[2]);
          if (wId === null) {
            isValid = false;
          }
        }
      } else if (paths.length >= 5) {
        if (isValidGuid(paths[2])) {
          isValid = false;
        } else {
          let wId = await validateWorkspaceId(paths[2]);
          if (wId === null) {
            isValid = false;
          } else {
            if (isValidGuid(paths[4])) {
              isValid = false;
            } else {
              let aId = await validateWorkspaceApplcationId(wId, paths[4]);
              if (aId === null) {
                isValid = false;
              }
            }
          }
        }
        if (
          !isValid &&
          paths[3] === 'settings' &&
          paths[4] === 'general' &&
          paths.length === 5
        ) {
          isValid = true;
        }
      }
    }
  }

  return isValid;
}

async function rulesChecker(
  roleObject,
  historyProps,
  prevLocation,
  location,
  availableWorkspacesObject,
  fromLogin
) {
  if (roleObject.token.userStatus === 'Active') {
    if (location !== '') {
      let url = location.split(virtualFolder);
      if (url.length > 0) {
        let paths = url.length === 2 ? generatePaths(url) : [];
        if (isWorkspaceRootPage(location)) {
          let prevPaths = [];
          if (prevLocation !== undefined && prevLocation !== null) {
            let prevUrl = prevLocation.split(virtualFolder);
            if (prevUrl.length > 0) {
              prevPaths = prevUrl.length === 2 ? generatePaths(prevUrl) : [];
            }
          }
          let isFromWorkspaceList = false;
          if (prevPaths.length === 2 && prevPaths[1] === 'workspaces') {
            isFromWorkspaceList = true;
          }

          let isFromUserAvailableWorkspaceList = false;
          const availableWorkspaces = store.getState().availableWorkspaces;
          if (availableWorkspaces.is_opened) {
            isFromUserAvailableWorkspaceList = true;
            updateAvailableWorkspacesOpenedStatus(false);
          }

          if (
            !isFromWorkspaceList &&
            !fromLogin &&
            !isFromUserAvailableWorkspaceList
          ) {
            await getWorkspaceThemeSettingImplementationWithThunk(paths[2]);
          }
          if (isWorkspaceApplicationRootPage(location)) {
            if (isApplicationLandingPage(location)) {
              let isMember = checkIfUserisMember(
                paths[2],
                availableWorkspacesObject
              );
              checkApplicationPublicAccessWithThunk(
                paths[2],
                paths[4],
                true,
                isMember,
                roleObject.user_role === 'Administrator',
                location,
                roleObject.user_id
              ).then((pageToGo) => {
                historyProps.push(pageToGo);
              });
              await updateRoleProperties(false);
            } else {
              if (fromLogin) {
                await updateRoleProperties(false);
                getWorkspaceApplicationHeaderByIdWithThunk(
                  paths[2],
                  paths[4],
                  checkIfUserisOwner(paths[2], availableWorkspacesObject),
                  roleObject.user_role === 'Administrator',
                  location
                ).then((pageToGo) => {
                  historyProps.push(pageToGo);
                });
              } else {
                await updateRoleProperties(false);
                return;
              }
            }
          } else {
            if (roleObject.user_role !== 'Administrator') {
              if (
                !isWorkspaceLandingPage(location) &&
                !isWorkspacePropertiesPage(location) &&
                !isWorkspaceApplicationListPage(location) &&
                !isWorkspaceMemberListPage(location) &&
                !isWorkspaceSettingsPage(location)
              ) {
                await updateRoleProperties(false);
                historyProps.push(location);
              } else {
                let isMember = await checkIfUserisMember(
                  paths[2],
                  availableWorkspacesObject
                );
                let isOwner = await checkIfUserisOwner(
                  paths[2],
                  availableWorkspacesObject
                );
                let isActiveMember = await checkIfUserisActiveMember(
                  paths[2],
                  availableWorkspacesObject
                );

                getWorkspaceHeaderByIdWithThunk(paths[2]).then(
                  async (response) => {
                    if (response.enabled && response.status === 'Active') {
                      if (isContactOwnersPage(location)) {
                        await updateRoleProperties(false);
                        historyProps.push(
                          routeCodes.WORKSPACES_LIST +
                            '/' +
                            paths[2] +
                            '/contactOwners'
                        );
                      } else {
                        if (isMember) {
                          if (!isOwner || (isOwner && !isActiveMember)) {
                            if (fromLogin) {
                              await store.dispatch(clearWorkspaceAction([]));
                              await updateRoleProperties(false);
                              historyProps.push(
                                routeCodes.WORKSPACES_LIST + '/' + paths[2]
                              );
                            } else {
                              if (
                                response.allow_public_access ||
                                isActiveMember
                              ) {
                                await store.dispatch(clearWorkspaceAction([]));
                                await updateRoleProperties(false);
                                historyProps.push(
                                  routeCodes.WORKSPACES_LIST + '/' + paths[2]
                                );
                              } else {
                                await updateRoleProperties(false);
                                historyProps.push(routeCodes.PROFILE);
                              }
                            }
                          } else {
                            await updateRoleProperties(false);
                            historyProps.push(location);
                          }
                        } else {
                          if (response.allow_public_access) {
                            await store.dispatch(clearWorkspaceAction([]));
                            historyProps.push(
                              routeCodes.WORKSPACES_LIST + '/' + paths[2]
                            );
                          } else {
                            await updateRoleProperties(false);
                            historyProps.push(routeCodes.PROFILE);
                          }
                        }
                      }
                    } else {
                      if (!response.enabled) {
                        await store.dispatch(
                          updateUnavailableContextAction('disabledWorkspace')
                        );
                        await updateRoleProperties(false);
                        historyProps.push(
                          routeCodes.UNAVAILABLE_PAGE + '?page=' + location
                        );
                      } else if (response.status !== 'Active') {
                        if (isOwner) {
                          if (fromLogin) {
                            await updateRoleProperties(false);
                            historyProps.push(location);
                          } else {
                            await updateRoleProperties(false);
                            return;
                          }
                        } else {
                          await store.dispatch(
                            updateUnavailableContextAction('suspendedWorkspace')
                          );
                          await updateRoleProperties(false);
                          historyProps.push(
                            routeCodes.UNAVAILABLE_PAGE + '?page=' + location
                          );
                        }
                      }
                    }
                  }
                );
              }
            } else {
              if (fromLogin) {
                await updateRoleProperties(false);
                historyProps.push(location);
              } else {
                await updateRoleProperties(false);
                return;
              }
            }
          }
        } else {
          document.getElementById('PortalTheme').href =
            process.env.PUBLIC_URL + '/portal/css/white-theme.css';
          if (
            !isPortalLogoutPage(location) &&
            !isProfilePage(location) &&
            !isChangePasswordPage(location) &&
            !isContactAdministratorsPage(location) &&
            roleObject.user_role !== 'Administrator'
          ) {
            await updateRoleProperties(false);
            historyProps.push(routeCodes.PROFILE);
          } else {
            if (fromLogin) {
              await updateRoleProperties(false);
              historyProps.push(location);
            } else {
              await updateRoleProperties(false);
              return;
            }
          }
        }
      }
    } else {
      if (roleObject.user_role === 'Administrator') {
        await updateRoleProperties(false);
        historyProps.push(routeCodes.PORTAL_SETTINGS);
      } else {
        await updateRoleProperties(false);
        if (
          availableWorkspacesObject.workspaces === undefined ||
          availableWorkspacesObject.workspaces.length <= 0
        ) {
          await updateRoleProperties(false);
          historyProps.push(routeCodes.PROFILE);
        }
      }
    }
  } else {
    if (isApplicationLandingPage(location)) {
      let url = location.split(appFolder);
      if (url.length > 1) {
        let paths = generatePaths(url);
        checkApplicationPublicAccessWithThunk(
          paths[2],
          paths[4],
          true,
          false,
          false,
          location,
          roleObject.user_id
        ).then(async (pageToGo) => {
          await updateRoleProperties(false);
          historyProps.push(pageToGo);
        });
      }
    } else {
      await updateRoleProperties(false);
      historyProps.push(routeCodes.PROFILE);
    }
  }
}
