import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUrlWorkspaceId } from '../../../../utils/url';
import Loader from 'react-loader-spinner'
import SidebarContainer from '../../../Sidebar/SidebarContainer';
import { getWorkspacesMembersShareWithThunk, getWorkspacesMembersShareByIdWithThunk, getWorkspacesMembersShareSearchWithThunk } from '../../../../api/workspacesMembersThunk';
import { clearWorkspacesMembersShareAction } from '../../../../actions/clearWorkspacesMembersShareAction';
import { getSessionStorage } from '../../../../utils/sessionStorage';
import { ghead, itemHeight, tHead, defaultUsersPageSize } from '../../../../configurations/app';
import WorkspacesMembersShareHeader from './WorkspacesMembersShareHeader';
import WorkspacesMembersShareBody from './WorkspacesMembersShareBody';
import WorkspacesMembersShareModal from '../Share/Workspaces/WorkspacesMembersShareModal';
import WorkspacesMembersSharingModal from '../Modal/WorkspacesMembersSharingModal';

class WorkspacesMembersShareContainer extends Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.handleScroll = this.handleScroll.bind(this);
        this.getUserList = this.getUserList.bind(this);
        this.calculatePageSize = this.calculatePageSize.bind(this);
        this.updateMode = this.updateMode.bind(this);
        this.getPagination = this.getPagination.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
        getWorkspacesMembersShareById: PropTypes.func.isRequired,
        getWorkspacesMembersShare: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired
    };

    state = {
        page: 1,
        size: 0,
        mode: 'list'
    }

    calculatePageSize() {
        let size = defaultUsersPageSize;

        this.setState({ page: 1, size });
        return size;
    }

    getUserList(currentPage) {
        const { page, size, mode } = this.state;
        const { workspace, role, search } = this.props;
        const id = this.getCurrentWorkspaceId();

        if (page < currentPage) {
            if (mode === 'list') {
                if (id !== workspace.workspace_name.toLowerCase().replaceAll(" ", "+")) {
                    let userId = role.user_id;
                    let isAdmin = (role.user_role === "Administrator") ? true : false;
                    if (userId === "") {
                        const sessionRole = getSessionStorage("role");

                        if (sessionRole !== "") {
                            const role = JSON.parse(sessionRole);
                            userId = role.user_id;
                            isAdmin = (role.user_role === "Administrator") ? true : false;
                        }
                    }

                    this.props.getWorkspacesMembersShareById(id, userId, isAdmin, currentPage, size, this.props.history);
                }
                else {
                    this.props.getWorkspacesMembersShare(id, role.user_id, currentPage, size);
                }
            }
            else {
                this.props.getWorkspacesMembersShareSearch(workspace.id, role.user_id, currentPage, size, search.search_text);
            }
            this.setState({ page: currentPage });
        }
    }

    handleScroll() {
        let { size } = this.state;
        if (size === 0)
            return;

        let top = document.documentElement.scrollTop;
        let height = document.documentElement.scrollHeight;
        let page = (height - ghead - tHead - 71) / (size * itemHeight);
        page = (Math.abs(page - Math.floor(page)) > 0.2) ? Math.ceil(page) : Math.floor(page);

        if (Math.ceil(top + window.innerHeight) >= height) {
            page = page + 1;
            this._isMounted && this.getUserList(page);
        }
    }

    updateMode(page, mode) {
        this.setState({ page, mode });
    }

    getCurrentWorkspaceId = () => {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    getPagination() {
        const { page, size } = this.state;
        return {
            page,
            size
        }
    }

    getCurrentWorkspaceId = () => {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    componentDidMount() {
        this._isMounted = true;
        const { workspace, role, loading } = this.props;
        const workspaceId = this.getCurrentWorkspaceId();
        const size = this.calculatePageSize();

        if (loading === false) {
            if (workspaceId !== workspace.workspace_name.toLowerCase().replaceAll(" ", "+")) {
                let userId = role.user_id;
                let isAdmin = (role.user_role === "Administrator") ? true : false;
                if (userId === "") {
                    const sessionRole = getSessionStorage("role");

                    if (sessionRole !== "") {
                        const role = JSON.parse(sessionRole);
                        userId = role.user_id;
                        isAdmin = (role.user_role === "Administrator") ? true : false;
                    }
                }
                this.props.getWorkspacesMembersShareById(workspaceId, userId, isAdmin, 1, size, this.props.history);
            }
            else {
                this.props.getWorkspacesMembersShare(workspaceId, role.user_id, 1, size);
            }
        }
        window.addEventListener("scroll", this.handleScroll);
        this.setState({ page: 1, size, mode: 'list' });
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.clearWorkspacesMembersShare();
        this.setState({ page: 1, size: 0, mode: 'list' });
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        const { workspacesMembersShare, loading, loadingTheme } = this.props;

        if (workspacesMembersShare.length < 1)
            return (
                <div>
                    {(loading || loadingTheme) &&
                        <div className="loader-container">
                            <Loader
                                type="BallTriangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            );

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="BallTriangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <WorkspacesMembersShareHeader />
                                    <WorkspacesMembersShareBody />
                                </div>
                            </div>
                        </main>
                        <WorkspacesMembersShareModal />
                        <WorkspacesMembersSharingModal type="error" />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role, loading, workspacesMembersShare }) => ({
    workspace: detail.workspace,
    role,
    loading: loading.workspaces_members.loading,
    loadingTheme: loading.theme.loading,
    workspacesMembersShare
});

const mapDispatchToProps = (dispatch) => ({
    getWorkspacesMembersShare: (id, userId, pageIndex, pageSize) => dispatch(getWorkspacesMembersShareWithThunk(id, userId, pageIndex, pageSize)),
    getWorkspacesMembersShareById: (id, userId, isAdmin, pageIndex, pageSize, historyProps) => dispatch(getWorkspacesMembersShareByIdWithThunk(id, userId, isAdmin, pageIndex, pageSize, historyProps)),
    getWorkspacesMembersShareSearch: (id, userId, pageIndex, pageSize, searchKeyword) => dispatch(getWorkspacesMembersShareSearchWithThunk(id, userId, pageIndex, pageSize, searchKeyword)),
    clearWorkspacesMembersShare: () => dispatch(clearWorkspacesMembersShareAction([]))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesMembersShareContainer));
