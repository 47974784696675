import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SidebarHeader from './SidebarHeader';
import SidebarBody from './SidebarBody';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getUrlWorkspaceId } from '../../utils/url';
import { clearPortalLogoAction } from '../../actions/clearPortalLogoAction';
import { getPortalLogoThunk } from '../../api/portalLogoThunk';
import { updatePortalLogoAction } from '../../actions/updatePortalLogoAction';

class SidebarContainer extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired,
        portalLogo: PropTypes.object.isRequired,
        getPortalLogoById: PropTypes.func.isRequired
    };

    getCurrentWorkspaceId() {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    componentDidMount() {
        const { loading } = this.props;
        const workspaceId = this.getCurrentWorkspaceId();

        if (!loading) {
            if (workspaceId !== '' && workspaceId !== undefined) {
                this.props.getPortalLogoById(workspaceId);
            }
            else {
                let portalLogo = {
                    ...this.props.portalLogo,
                    id: "",
                    name: "",
                    image: "Default",
                    isLoaded: true
                };
                this.props.dispatchPortalLogo(portalLogo);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            const workspaceId = this.getCurrentWorkspaceId();

            if (workspaceId !== '' && workspaceId !== undefined) {
                this.props.getPortalLogoById(workspaceId);
            }
            else {
                let portalLogo = {
                    ...this.props.portalLogo,
                    id: "",
                    name: "",
                    image: "Default",
                    isLoaded: true
                };
                this.props.dispatchPortalLogo(portalLogo);
            }
        }
    }

    render() {
        return (
            <div id="sidebar">
                <SidebarHeader />
                <SidebarBody />
            </div>
        );
    }
}

const mapStateToProps = ({ portalLogo, detail, loading }) => ({
    portalLogo,
    workspace: detail.workspace,
    loading: loading.portal_logo.loading
});

const mapDispatchToProps = (dispatch) => ({
    getPortalLogoById: (workspaceId) => dispatch(getPortalLogoThunk(workspaceId)),
    clearPortalLogo: () => dispatch(clearPortalLogoAction([])),
    dispatchPortalLogo: (portalLogo) => dispatch(updatePortalLogoAction(portalLogo)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SidebarContainer));
