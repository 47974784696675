import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  getWorkspacesSearchWithThunk,
  getWorkspacesWithThunk,
} from '../../../api/workspacesThunk';
import { clearWorkspacesAction } from '../../../actions/clearWorkspacesAction';
import { routeCodes } from '../../../configurations/routes';
import { getWorkspacesSearchAction } from '../../../actions/getWorkspacesSearchAction';
import { defaultWorkspacesPageSize } from '../../../configurations/app';
import { getUserFromCookie } from '@wrd/helpers';

class WorkpacesListHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  static propTypes = {
    dispatchWorkspacesSearch: PropTypes.func.isRequired,
    getWorkspaces: PropTypes.func.isRequired,
    getWorkspacesSearch: PropTypes.func.isRequired,
    updateMode: PropTypes.func.isRequired,
    clearWorkspaces: PropTypes.func.isRequired,
  };

  calculatePageSize() {
    let size = defaultWorkspacesPageSize;
    return size;
  }

  handleSearch(e) {
    const { sort, search } = this.props;
    let currentSortBy = sort.sort_by;
    let currentSortType = sort.sort_type;

    if (e.key === 'Enter' || e.target.name !== 'searchText') {
      const size = this.calculatePageSize();
      this.props.clearWorkspaces();
      if (search.search_text.trim() !== '') {
        this.props.getWorkspacesSearch(
          search.search_text,
          1,
          size,
          currentSortBy,
          currentSortType
        );
        this.props.updateMode(1, 'search');
      } else {
        this.props.getWorkspaces(1, size, currentSortBy, currentSortType);
        this.props.updateMode(1, 'list');
      }
    }
  }

  handleOnChange(e) {
    let search = {
      ...this.props.workspaces,
      search_text: e.target.value,
    };
    this.props.dispatchWorkspacesSearch(search);
  }

  createWorkspace() {
    this.props.history.push(routeCodes.WORKSPACE_CREATE);
  }

  componentWillUnmount() {
    let search = {
      ...this.props.workspaces,
      search_text: '',
    };
    this.props.dispatchWorkspacesSearch(search);
  }

  render() {
    const { search_text } = this.props.search;
    const userFromCookie = getUserFromCookie();

    return (
      <div className="mainHead">
        <div className="row align-items-center">
          <div className="col-md-8 col-lg-9">
            <h3 className="float-left mt-2 mr-4" style={{ fontWeight: 'normal' }}>Workspaces</h3>
            {userFromCookie.isAdministrator && (
              <button
                className="btn icoTextBtn btn-wrd_default"
                onClick={() => this.createWorkspace()}
              >
                <span className="icon-wrd-add"></span>
                Create
              </button>
            )}
          </div>
          <div className="col-md-4 col-lg-3 text-right">
            <div className="searchInput mt-0">
              <span
                style={{ cursor: 'pointer' }}
                name="searchBtn"
                className="icon-wrd-search"
                onClick={this.handleSearch}
              ></span>
              <input
                type="text"
                onKeyPress={this.handleSearch}
                name="searchText"
                value={search_text}
                placeholder="Search..."
                onChange={this.handleOnChange}
              />
            </div>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}

const mapStateToProps = ({ sorting, pagination, search }) => ({
  pagination: pagination.workspace,
  sort: sorting.workspace,
  search: search.workspaces,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchWorkspacesSearch: (data) => dispatch(getWorkspacesSearchAction(data)),
  getWorkspaces: (pageIndex, pageSize, sortingBy, sortingType) =>
    dispatch(
      getWorkspacesWithThunk(pageIndex, pageSize, sortingBy, sortingType)
    ),
  getWorkspacesSearch: (keyword, pageIndex, pageSize, sortingBy, sortingType) =>
    dispatch(
      getWorkspacesSearchWithThunk(
        keyword,
        pageIndex,
        pageSize,
        sortingBy,
        sortingType
      )
    ),
  clearWorkspaces: () => dispatch(clearWorkspacesAction([])),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkpacesListHeader)
);
