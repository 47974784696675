import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UsersInfoWorkspacesItem from './UsersInfoWorkspacesItem';
import UsersInfoWorkspacesPagination from './UsersInfoWorkspacesPagination';
import Loader from 'react-loader-spinner';

class UsersInfoWorkspacesBody extends Component {
    static propTypes = {
        availableWorkspaces: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
        redirectUrl: PropTypes.object.isRequired,
        portalLogin: PropTypes.object.isRequired
    };

    render() {
        const { portalLogin, availableWorkspaces, loading } = this.props;

        if (loading) {
            return (
                <div className="loader-container">
                    <Loader
                        type="BallTriangle"
                        color="#00BFFF"
                        height="100"
                        width="100"
                    />
                </div>);
        }
        return (
            <div>
                <div className="workspaceList open h-100 width-50 d-flex justify-content-center">
                    {
                        (portalLogin.login_background_selected === 'NoBackground') ?
                            <div style={{ backgroundColor: portalLogin.login_background_color }} className="crop-welcome1820"> </div>
                            : <img src={"data:image/png;base64," + portalLogin.login_background_image}
                                className="crop-welcome1820" alt="" />

                    }
                    <div className="width-50 p-4 text-center">
                        <p className="titleText mt-3">Available Workspaces</p>
                        <ul className="wsGroup mt-2">
                            {availableWorkspaces.workspaces !== undefined && availableWorkspaces.workspaces.length > 0 &&
                                availableWorkspaces.workspaces.map((item) => (
                                    <UsersInfoWorkspacesItem key={item.id} item={item} />
                                ))
                            }
                        </ul>
                        {availableWorkspaces.workspaces !== undefined && availableWorkspaces.workspaces.length > 0 &&
                            <div className="available-workspaces-pagination">
                                <UsersInfoWorkspacesPagination />
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ availableWorkspaces, role, redirectUrl, portalLogin, loading }) => ({
    availableWorkspaces,
    role,
    redirectUrl,
    portalLogin: portalLogin.portal_login,
    loading: loading.page_redirection.loading
});

export default withRouter(connect(mapStateToProps)(UsersInfoWorkspacesBody));