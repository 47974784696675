import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import WorkspacesApplicationsSettingsCardItem from './WorkspacesApplicationsSettingsCardItem';
import { updateWorkspacesApplicationsSettingsAction } from '../../../../actions/updateWorkspacesApplicationsSettingsAction';

class WorkspacesApplicationsSettingsCardContainer extends Component {
    constructor(props) {
        super(props);
        this.handleGroupLockedChange = this.handleGroupLockedChange.bind(this);
        this.setGroupLocked = this.setGroupLocked.bind(this);
    }

    static propTypes = {
        header: PropTypes.string.isRequired,
        workspace: PropTypes.object.isRequired,
        workspaceApplication: PropTypes.object.isRequired,
        workspacesApplicationsSettings: PropTypes.array.isRequired,
        dispatchWorkspacesApplicationsSettings: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired
    };

    state = {
        isGroupLocked: null
    }

    componentDidMount() {
        const { header, workspacesApplicationsSettings } = this.props;
        const items = workspacesApplicationsSettings.filter((item) => item.group_name === header);
        const itemsLocked = workspacesApplicationsSettings.filter((item) => item.group_name === header && item.locked);
        const isGroupLocked = (items.length === itemsLocked.length);

        this.setState({ isGroupLocked });
    }

    handleGroupLockedChange() {
        const { header, workspacesApplicationsSettings } = this.props;
        const isGroupLocked = !this.state.isGroupLocked
        this.setState({ isGroupLocked });

        const items = workspacesApplicationsSettings.filter((item) => item.group_name === header);
        const changes = items.map((item) => {
            item.locked = isGroupLocked;
            return item;
        })

        this.props.dispatchWorkspacesApplicationsSettings(changes);
    }

    setGroupLocked() {
        const { header, workspacesApplicationsSettings } = this.props;
        const items = workspacesApplicationsSettings.filter((item) => item.group_name === header);
        const itemsLocked = workspacesApplicationsSettings.filter((item) => item.group_name === header && item.locked);

        this.setState({ isGroupLocked: (items.length === itemsLocked.length) });
    }

    render() {
        const { header, workspacesApplicationsSettings, role } = this.props;
        const { isGroupLocked } = this.state;
        const items = workspacesApplicationsSettings.filter((item) => item.group_name === header);

        if (isGroupLocked === null)
            return (null);

        if (role.user_role !== 'Administrator' && isGroupLocked)
            return (null);

        return (
            <div className="card">
                <div className="cardWrap">
                    <div className={(role.user_role === 'Administrator') ? 'title' : 'title settings-title'}>
                        {(role.user_role === 'Administrator') &&
                            <label className="btn iconBtn customLockCheckbox">
                                <input type="checkbox" className="lockInput" checked={!isGroupLocked} readOnly />
                                <span className="icon-wrd-unlock cursor-pointer" onClick={this.handleGroupLockedChange}></span>
                                <span className="icon-wrd-lock cursor-pointer" onClick={this.handleGroupLockedChange}></span>
                            </label>
                        }
                        {header}
                    </div>
                    <div className="formWrap">
                        {items.map((item) => (
                            <WorkspacesApplicationsSettingsCardItem key={item.id} id={item.id} groupLocked={this.setGroupLocked} />
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, workspacesApplicationsSettings, role }) => ({
    workspace: detail.workspace,
    workspaceApplication: detail.workspace_application,
    workspacesApplicationsSettings,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchWorkspacesApplicationsSettings: (settings) => dispatch(updateWorkspacesApplicationsSettingsAction(settings))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsSettingsCardContainer));
