import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import ReactPlayer from 'react-player/lazy';
import { routeCodes } from '../../../configurations/routes';
import { changeRouteAction } from '../../../actions/changeRouteAction';
import { defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize } from '../../../configurations/app';
import { getUserAvailableWorkspacesWithThunk } from '../../../api/userAvailableWorkspacesThunk';
import UserAvailableWorkspacesContainer from '../UserAvailableWorkspaces/UserAvailableWorkspacesContainer';
import { updateAvailableWorkspacesOpenedStatus } from '../../../utils/workspaces';
import { Scrollbars } from 'react-custom-scrollbars';

class WorkspacesLandingHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUserRole: props.role.user_role,
            wsListOpened: false,
            firstOpen: true,
            fullBodyHeight: 0,
            zoomLevel: 0,
            scaleSettings: 1,
            scrollHeight: 0
        };
        this.contactOwners = this.contactOwners.bind(this);
        this.getCategoryLinkStyle = this.getCategoryLinkStyle.bind(this);
        this.getCategoryLinkResize = this.getCategoryLinkResize.bind(this);
        this.openUrl = this.openUrl.bind(this);
        this.loadWorkspaceLandingHeaderLayout = this.loadWorkspaceLandingHeaderLayout.bind(this);
        this.hideList = this.hideList.bind(this);
        this.getZoomLevel = this.getZoomLevel.bind(this);
        this.setZoomLevel = this.setZoomLevel.bind(this);

        this.divHeadContainer = React.createRef();
        this.divCategoryLinkContainer = React.createRef();
        this.divWorkspaceCategoryLinkContainer = React.createRef();
        this.spanCategoryLinkContainer = React.createRef();
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired,
        workspace_theme: PropTypes.object.isRequired
    };

    changeRoute(name) {
        let navigation = {
            name
        };
        this.props.dispatchRoute(navigation);
    }

    contactOwners() {
        const { workspace } = this.props;
        this.changeRoute(routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+"));
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/contactOwners");
    }

    getSwitchAvailableWorkspaces() {
        const { workspace, role } = this.props;
        this.props.getWorkspaceById(workspace.id, role.user_id);
    }

    availableWorkspacesSwitch() {
        this.setState({ wsListOpened: !this.state.wsListOpened });
        this.setState({ firstOpen: false });
        if (this.state.wsListOpened === false) {
            const { role } = this.props;
            this.props.getUserAvailableWorkspaces(role.user_id, role.user_role, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize);
        }
        else {
            updateAvailableWorkspacesOpenedStatus(false);
        }
    }

    workspaceEditPage() {
        const { workspace } = this.props;
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/properties");
    }

    workspaceMembersCreatePage() {
        const { workspace } = this.props;
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + "/members/register");
    }

    profilePage() {
        this.props.history.push(routeCodes.PROFILE);
    }

    loginPage() {
        const { workspace } = this.props;
        this.props.history.push(routeCodes.USERS_LOGIN + "/" + workspace.workspace_name.toLowerCase().replaceAll(" ", "+"));
    }

    logout() {
        this.props.history.push(routeCodes.USERS_LOGOUT);
    }

    getInitials(string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    getCategoryLinkStyle() {
        if (this.divHeadContainer.current !== null && this.divCategoryLinkContainer.current !== null && this.divWorkspaceCategoryLinkContainer.current !== null && this.spanCategoryLinkContainer.current !== null) {
            let containerHeight = this.divHeadContainer.current.offsetHeight;
            let linkTop = this.divCategoryLinkContainer.current.offsetTop;
            let linkHeight = containerHeight - linkTop - 25;

            this.divCategoryLinkContainer.current.style.height = linkHeight + 'px';
            this.divWorkspaceCategoryLinkContainer.current.style.display = 'block';
        }
    }

    getCategoryLinkResize() {
        if (this.divHeadContainer.current !== null && this.divCategoryLinkContainer.current !== null && this.divWorkspaceCategoryLinkContainer.current !== null && this.spanCategoryLinkContainer.current !== null) {
            setTimeout(() => {
                let containerHeight = this.divHeadContainer.current.offsetHeight;
                let linkTop = this.divCategoryLinkContainer.current.offsetTop;
                let linkHeight = containerHeight - linkTop - 25;

                this.divCategoryLinkContainer.current.style.height = linkHeight + 'px';
                this.setZoomLevel();
            }, 200);
        }
    }

    getZoomLevel() {
        let zoomLevel = null;
        let screenCssPixelRatio = Math.ceil((window.outerWidth - 8) / window.innerWidth);

        if (screenCssPixelRatio >= .46 && screenCssPixelRatio < .54) {
            zoomLevel = -4;
        }
        else if (screenCssPixelRatio < .64) {
            zoomLevel = -3;
        }
        else if (screenCssPixelRatio < .76) {
            zoomLevel = -2;
        }
        else if (screenCssPixelRatio < .92) {
            zoomLevel = -1;
        }
        else if (screenCssPixelRatio < 1.10) {
            zoomLevel = 0;
        }
        else if (screenCssPixelRatio < 1.32) {
            zoomLevel = 1;
        }
        else if (screenCssPixelRatio < 1.58) {
            zoomLevel = 2;
        }
        else if (screenCssPixelRatio < 1.90) {
            zoomLevel = 3;
        }
        else if (screenCssPixelRatio <= 2.28) {
            zoomLevel = 4;
        }
        else if (screenCssPixelRatio <= 2.70) {
            zoomLevel = 5;
        }

        return zoomLevel;
    }

    setZoomLevel() {
        let zoomLevel = this.getZoomLevel();
        let scaleSettings = window.devicePixelRatio;
        this.setState({ zoomLevel, scaleSettings });
    }

    openUrl(url) {
        if (url.trim() === "") {
            return;
        }

        if (!url.includes("http")) {
            url = "https://" + url;
        }

        const win = window.open(url, "_blank");
        if (win != null) {
            win.focus();
        }
    }

    hideList() {
        this.setState({ wsListOpened: false });
    }

    loadWorkspaceLandingHeaderLayout() {
        setTimeout(() => {
            if (this.divWorkspaceCategoryLinkContainer.current !== null) {
                const { workspace, categories, userCategories, role } = this.props;
                let container = this.divWorkspaceCategoryLinkContainer.current.getBoundingClientRect();
                let totalWidth = container.width;

                let categs = [];
                if (role.is_logged_in) {
                    if (role.user_role === 'Administrator' || ( role.user_role === 'User' && !workspace.owner && !workspace.suspendedUser)) {
                        categs = categories;
                    }
                    else {
                        categs = userCategories;
                    }
                }
                else {
                    categs = userCategories;
                }

                let width = 0;
                for (let i in categs) {
                    let categ = categs[i];
                    let categoryLinks = document.getElementById("categ-links-" + categ.category_id);

                    let links = categoryLinks.getBoundingClientRect();

                    width = width + links.width + 54;
                }

                if (totalWidth < width) {
                    for (let i in categs) {
                        let categ = categs[i];
                        let categoryLinks = document.getElementById("categ-links-" + categ.category_id);
                        categoryLinks.style.fontSize = "24px";
                    }
                }
            }
        }, 500);
    }

    getSvgMargin() {
        let margin = 0;
        if (window.devicePixelRatio === 1) {
            margin = 0;
        }
        else if (window.devicePixelRatio === 1.25) {
            margin = 2;
        }
        else if (window.devicePixelRatio === 1.5) {
            margin = 4;
        }
        else if (window.devicePixelRatio === 1.75) {
            margin = 6;
        }
        return margin;
    }

    getScrollbarHeight() {
        let height = 200;

        if (window.devicePixelRatio === 1) {
            height = 200;
        }
        else if (window.devicePixelRatio === 1.25) {
            height = 140;
        }
        else if (window.devicePixelRatio === 1.5) {
            height = 130;
        }
        else if (window.devicePixelRatio === 1.75) {
            height = 120;
        }

        const { zoomLevel } = this.state;
        if (zoomLevel !== 0) {
            let heaaderWorkspaceNameLanding = document.getElementById("header-workspace-name-landing");
            let heaaderWorkspaceTitleLanding = document.getElementById("span-workspace-title-container");

            if (heaaderWorkspaceNameLanding !== null && heaaderWorkspaceTitleLanding !== null) {
                height = 421 - (80 + heaaderWorkspaceNameLanding.offsetHeight + heaaderWorkspaceTitleLanding.offsetHeight);
            }
        }

        return height;
    }

    getFullBodyHeight() {
        let fullBody = document.getElementById("full-body");
        let height = 0;

        if (fullBody !== null) {
            height = fullBody.offsetHeight - 48;
        }
        return height;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.zoomLevel !== this.state.zoomLevel) {
            setTimeout(() => {
                let scrollHeight = this.getScrollbarHeight();
                this.setState({ scrollHeight });
            }, 1000);
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.getCategoryLinkResize);
        window.addEventListener("wheel", this.setZoomLevel);
        this.loadWorkspaceLandingHeaderLayout();
        this.getCategoryLinkStyle();

        setTimeout(() => {
            let zoomLevel = this.getZoomLevel();
            let scaleSettings = window.devicePixelRatio;
            let height = this.getFullBodyHeight();
            let scrollHeight = this.getScrollbarHeight();
            this.setState({ fullBodyHeight: height, zoomLevel, scaleSettings, scrollHeight });
        }, 500);
    }

    render() {
        const { workspace, categories, userCategories, role, workspace_theme, workspaceSetting } = this.props;
        const { fullBodyHeight, zoomLevel, scrollHeight } = this.state;

        if (categories === undefined || userCategories === undefined)
            return (null);

        let categs = [];
        if (role.is_logged_in) {
            if (role.user_role === 'Administrator' || (role.user_role === 'User' && !workspace.owner && !workspace.suspendedUser)) {
                categs = categories;
            }
            else {
                categs = userCategories;
            }
        }
        else {
            categs = userCategories;
        }

        const template = { __html: workspace.workspace_desc };
        const canRegister = (!role.is_logged_in && workspace.allow_users_to_register);

        let width = 0.99 * window.screen.width - this.getSvgMargin();
        let height = 1.456 * window.screen.height;
        let svgWidth = 1439;
        let svgHeight = 1178;

        if (height > fullBodyHeight) {
            height = fullBodyHeight;
            svgHeight = ((height / width) * svgWidth) - 3;
            svgHeight = (svgHeight > 0) ? svgHeight : 0;
        }

        return (
            <div id="full-header-workspace-landing" ref={this.divHeadContainer} className="mainHead mainhead-margin-top">
                <div>
                    <div className="row">
                        {workspaceSetting.showPortalLogo ? (workspace_theme.theme_selected === "Default") ?
                            <img className="float-left mr-2 insideCircle left-logo-width" style={{ cursor: 'pointer' }} onClick={() => this.openUrl("http://www.dhigroup.com")} src={process.env.PUBLIC_URL + "/images/DHI_Logo_small.png"} title="www.dhigroup.com" alt="" />
                            :
                            <img className="float-left mr-2 insideCircle left-logo-width" style={{ cursor: 'pointer' }} onClick={() => this.openUrl("http://www.dhigroup.com")} src={process.env.PUBLIC_URL + "/images/DHI_Logo_small.png"} title="www.dhigroup.com" alt="" />
                            : null}

                        {(role.is_logged_in) ?
                            <button className="btn iconBtn wsListBtn ws-list-pos" onClick={() => this.availableWorkspacesSwitch()} title="Available workspaces"><span className={(this.state.wsListOpened) ? "icon-wrd-close close-x-list" : "icon-wrd-ws-list icon-ws-list-landing"}></span></button>
                            : null
                        }
                        {(role.user_role === 'Administrator' || (role.user_role === 'User' && !workspace.owner && !workspace.suspendedUser)) ?
                            <button className={(zoomLevel === 0) ? "btn customBtn workspaceHome ws-edit-pos font-style-wsedit" : "btn customBtn workspaceHome ws-edit-pos font-style-wsedit-zoom"} onClick={() => this.workspaceEditPage()} title="Edit page"> Edit </button>
                            : null
                        }
                        {(canRegister) &&
                            <button className={(zoomLevel === 0) ? "btn customBtn workspaceHome ws-register-pos font-style-wsedit" : "btn customBtn workspaceHome ws-register-pos font-style-wsedit-zoom"} onClick={() => this.workspaceMembersCreatePage()} title="Register User"> Register </button>
                        }
                        {(role.is_logged_in) ?
                            <>
                                <button className="btn customBtn wsListBtn ws-logout-pos button-login" onClick={() => this.logout()} title="Logout">  <span className="icon-wrd-power icon-ws-login-landing"></span> </button>
                                {(workspace.user_initial !== "") ?
                                    <button className="btn customBtn profileHome ws-profile-pos button-profile" onClick={() => this.profilePage()} title="My Profile">  <span className={(workspace_theme.theme_selected === "Default") ? "userProfile-default" : "userProfile-white"}>{workspace.user_initial}</span> </button>
                                    :
                                    (workspace.user_picture !== "") ?
                                        <img className="profileHome ws-profile-image-pos" style={{ cursor: 'pointer', borderRadius: '50%' }} src={"data:image/png;base64," + workspace.user_picture} onClick={() => this.profilePage()} alt="My Profile" />
                                        :
                                        <button className="btn customBtn profileHome ws-profile-pos" onClick={() => this.profilePage()} title="My Profile">  <span className="icon-wrd-profile icon-ws-profile-landing"></span> </button>
                                }
                            </>
                            :
                            <button className="btn customBtn wsListBtn ws-login-pos button-login" onClick={() => this.loginPage()} title="Login">  <span className="icon-wrd-power icon-ws-login-landing"></span> </button>
                        }
                    </div>
                    <div>
                        {(workspace.workspace_landing_banner !== undefined && workspace.workspace_landing_banner !== '') ?
                            <img id="header-image" className="workspaceBackgroundNoImage" src={"data:image/png;base64," + workspace.workspace_landing_banner} alt="Workspace Logo" />
                            :
                            <div id="header-image" className="workspaceBackgroundNoImage"></div>
                        }
                        <div className="border-mask"></div>
                        <div id="header-mask" className={(categs.length > 0) ? "category-mask" : "mask"}>
                            {(categs.length > 0) &&
                                <svg width={width} height={height} viewBox={ "0 90 " + svgWidth + " " + svgHeight } fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path opacity="0.95" d="M621.964 660.81C1320.93 645.328 1553.23 212.221 1582 0H-10V1178C-3.02426 924.402 291.337 668.134 621.964 660.81Z" fill="url(#paint0_linear_137_896)" />
                                    <defs>
                                        <linearGradient id="paint0_linear_137_896" x1="394.581" y1="142.5" x2="1584.79" y2="606.778" gradientUnits="userSpaceOnUse">
                                            <stop stopColor="#0B4566" />
                                            <stop offset="1" stopColor="#0B4566" stopOpacity="0" />
                                        </linearGradient>
                                    </defs>
                                </svg>
                            }
                        </div>
                    </div>
                    <div id="header-workspace-landing" className="row header-workspace-landing">
                        <div id="header-workspace-desc-landing" className="col-9">
                            <div id="header-workspace-name-landing" className="col-12">
                                <p className={(zoomLevel === 0) ? "font-style-wsname" : "font-style-wsname-zoom" }>{workspace.workspace_name}</p>
                            </div>
                            <div id="span-workspace-title-container" className="col-11">
                                <p className={(zoomLevel === 0) ? "font-style-wstitle" : "font-style-wstitle-zoom"}>
                                    <span>{workspace.workspace_title}</span>
                                    <span id="header-workspace-logo" style={{ visibility:'hidden' }}>
                                        <img className="crop-logo_landing" onClick={() => this.openUrl(workspace.workspace_url)} src={((workspace.url_image !== undefined) ? "data:image/png;base64," : "") + workspace.url_image} style={{ cursor: (workspace.workspace_url !== '') ? 'pointer' : 'default' }} title={workspace.workspace_url} alt="" />
                                    </span>
                                </p>
                            </div>
                            {(categs.length > 0) &&
                                <div className="col-9" style={{ height: scrollHeight + 'px' }}>
                                    <Scrollbars style={{ height: scrollHeight + 'px' }}>
                                        <div>
                                            <div className="wsHomeSection">
                                                <div className={(zoomLevel === 0) ? "desc py-2 pr-4 font-style-wsdeescription" : "desc py-2 pr-4 font-style-wsdeescription-zoom"}>
                                                    <span dangerouslySetInnerHTML={template}></span>
                                                </div>
                                            </div>
                                        </div>
                                    </Scrollbars>
                                </div>
                            }
                        </div>
                        <div id="header-player-container" className="col-3">
                            {(workspace.video_url !== "") &&
                                <div id="header-player">
                                    <ReactPlayer id="header-react-player" controls={true} url={(workspace.video_url.includes("http")) ? workspace.video_url : "https://" + workspace.video_url} width='100%' height='100%' />
                                </div>
                            }
                        </div>
                    </div>
                    {(categs.length > 0) &&
                        <div className="row" onLoad={this.getCategoryLinkStyle()}>
                        <div ref={this.divCategoryLinkContainer} id="header-workspace-landing-separator" className="col-12 workspaceAppLandingCategoryLinkSeparator" style={{ marginLeft: '15px', width: '95%'}}>
                                <div ref={this.divWorkspaceCategoryLinkContainer} className="workspaceAppLandingCategoryLinkContainer" style={{ marginLeft: '2.24%', width: '95%' }}>
                                        {categs.map((categ) => (
                                            <Link to={"#categ-" + categ.category_id} key={categ.category_id} className={(zoomLevel === 0) ? "font-style-wslink" : "font-style-wslink-zoom"}>
                                                <span id={'categ-links-' + categ.category_id} ref={this.spanCategoryLinkContainer}>
                                                    {categ.category_title}
                                                </span>
                                            </Link>
                                        ))}
                                </div>
                            </div>
                        </div>
                    }
                    <div className={(this.state.firstOpen) ? "workspaceList d-none" : (this.state.wsListOpened) ? "workspaceList open animated fadeInRight" : "workspaceList open animated fadeOutRight"}>
                        <UserAvailableWorkspacesContainer hideList={this.hideList} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role, portalRouter, contactOwners }) => ({
    workspace: detail.workspace,
    categories: detail.workspace.categories,
    userCategories: detail.workspace.user_categories,
    workspaceSetting: detail.workspace_setting,
    workspace_theme: detail.workspace_theme_setting,
    role,
    portalRouter,
    contactOwners: contactOwners
});

const mapDispatchToProps = (dispatch) => ({
    dispatchRoute: (navigation) => dispatch(changeRouteAction(navigation)),
    getUserAvailableWorkspaces: (userId, userRole, pageIndex, pageSize) => dispatch(getUserAvailableWorkspacesWithThunk(userId, userRole, pageIndex, pageSize))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesLandingHeader));
