import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogWorkspacesApplicationsConnectionAction } from '../../../../actions/updateDialogWorkspacesApplicationsConnectionAction';
import { updateWorkspaceApplicationWithThunk } from '../../../../api/workspacesApplicationsThunk';

class WorkspacesApplicationsDetailConnectionModal extends Component {
    constructor(props) {
        super(props);
        this.setDefaultDialogState = this.setDefaultDialogState.bind(this);
        this.updateWorkspaceApplication = this.updateWorkspaceApplication.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    static propTypes = {
        dialog: PropTypes.object.isRequired,
        workspaceApplication: PropTypes.object.isRequired,
        dispatchDialogWorkspacesApplications: PropTypes.func.isRequired,
        updateWorkspaceApplication: PropTypes.func.isRequired,
        updateConnection: PropTypes.func.isRequired
    };

    setDefaultDialogState() {
        let workspacesApplications = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            route_back_to: '',
            show_dialog: false
        };

        this.props.dispatchDialogWorkspacesApplications(workspacesApplications);
    }

    updateWorkspaceApplication() {
        const { workspaceApplication } = this.props;

        this.setDefaultDialogState();
        this.props.updateConnection();
        this.props.updateWorkspaceApplication(workspaceApplication, false, this.props.history);
    }

    closeModal() {
        this.setDefaultDialogState();
    }

    render() {
        const { dialog } = this.props;

        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{dialog.dialog_title}</h2>
                            <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody">
                        <div className="p-4">
                            <div className="summernote">
                                {dialog.dialog_type === "connection_1" &&
                                    <p>
                                        The connection string you are trying to update is used in other applications. Updating the connection string without changing the id of the connection string will affect the other applications. Are you sure you want to update the connection string for all applications where it is being used? 
                                    </p>
                                }
                                {dialog.dialog_type === "connection_2" &&
                                    <p>
                                        The connection string you are trying to update is used in other Workspaces. Please provide a different connection string ID.
                                    </p>
                                }
                                {dialog.dialog_type === "connection_3" &&
                                    <p>
                                        The connection string you are trying to update is used in applications in other workspaces. Updating the connection string without changing the id of the connection string will affect the applications in other workspaces. Are you sure you want to update the connection string for all applications where it is being used? 
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-2">
                            <div className="row">
                                {dialog.dialog_type !== "connection_2" &&
                                    <div className="col">
                                        <button className="btn greyBtn icoTextBtn closePopup" onClick={() => { this.closeModal(); }}>
                                            <span className="icon-wrd-close"></span>
                                            Cancel
                                            </button>
                                    </div>
                                }
                                {dialog.dialog_type !== "connection_2" &&
                                    <div className="col text-right">
                                        <button ref={this.textInput} className="greenBtn btn icoTextBtn mr-3 closePopup width-100px" onClick={() => { this.updateWorkspaceApplication(); }}>
                                            Yes
                                            </button>
                                    </div>
                                }
                                {dialog.dialog_type === "connection_2" &&
                                    <div className="col text-right">
                                        <button className="btn greyBtn icoTextBtn closePopup" onClick={() => { this.closeModal(); }}>
                                            <span className="icon-wrd-close"></span>
                                            Close
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ dialog, detail }) => ({
    dialog: dialog.workspaces_applications_connection,
    workspaceApplication: detail.workspace_application,
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogWorkspacesApplications: (dialog) => dispatch(updateDialogWorkspacesApplicationsConnectionAction(dialog)),
    updateWorkspaceApplication: (workspaceApplication, bypassUrl, historyProps) => dispatch(updateWorkspaceApplicationWithThunk(workspaceApplication, bypassUrl, historyProps))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsDetailConnectionModal));
