import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setupNewApplicationAction } from '../../../actions/setupNewApplicationAction';
import RichTextEditor from '../../Editor/RichTextEditor';
import { createApplicationWithThunk } from '../../../api/applicationsThunk';
import { getDefaultImage } from '../../../utils/cropImage';

class ApplicationsCreateInput extends Component {
  constructor(props) {
    super(props);
    this.enterSubmit = this.enterSubmit.bind(this);
    this.onApplicationNameChange = this.onApplicationNameChange.bind(this);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onKeywordsChange = this.onKeywordsChange.bind(this);
    this.onURLChange = this.onURLChange.bind(this);
    this.onToggleIsAllowPublicAccess =
      this.onToggleIsAllowPublicAccess.bind(this);
    this.handleNoSnapshotChange = this.handleNoSnapshotChange.bind(this);
    this.handleSnapshotChange = this.handleSnapshotChange.bind(this);
    this.onSelectSnapshot = this.onSelectSnapshot.bind(this);
    this.getSnapshotImageStyle = this.getSnapshotImageStyle.bind(this);
    this.imgSnapshot = React.createRef();
    this.imgNoSnapshot = React.createRef();
    this.imgSnapshotRadioButton = React.createRef();
    this.imgNoSnapshotRadioButton = React.createRef();
  }

  static propTypes = {
    application: PropTypes.object.isRequired,
    setupNewApplication: PropTypes.func.isRequired,
  };

  state = {
    src: null,
  };

  setDefaultImage() {
    const { url_image } = this.props.application;

    if (url_image === undefined || url_image === '') {
      const application = {
        ...this.props.application,
        snapshot_type: 0,
        url_image: getDefaultImage().replace('data:image/png;base64,', ''),
        image_aspect_ratio: 4 / 3,
      };

      this.props.setupNewApplication(application);
    }
  }

  onApplicationNameChange(e) {
    let application = {
      ...this.props.application,
      application_name: e.target.value,
    };
    this.props.setupNewApplication(application);
  }

  onTitleChange(e) {
    let application = {
      ...this.props.application,
      application_title: e.target.value,
    };
    this.props.setupNewApplication(application);
  }

  onDescriptionChange(e) {
    let application = {
      ...this.props.application,
      application_desc: e.target.value,
    };
    this.props.setupNewApplication(application);
  }

  onURLChange(e) {
    let application = {
      ...this.props.application,
      url: e.target.value,
    };
    this.props.setupNewApplication(application);
  }

  onToggleIsAllowPublicAccess(e) {
    const application = {
      ...this.props.application,
      allow_public_access: e.target.checked,
    };

    this.props.setupNewApplication(application);
  }

  onKeywordsChange(e) {
    let application = {
      ...this.props.application,
      keywords: e.target.value.split(',').map(function (item) {
        return item.trim();
      }),
    };
    this.props.setupNewApplication(application);
  }

  async enterSubmit(e) {
    if (e.key === 'Enter') {
      const { application } = this.props;
      this.props.createApplication(application, false);
    }
  }

  handleNoSnapshotChange() {
    let application = {
      ...this.props.application,
      snapshot_type: 0,
    };
    this.props.setupNewApplication(application);
  }

  handleSnapshotChange() {
    let application = {
      ...this.props.application,
      snapshot_type: 1,
    };
    this.props.setupNewApplication(application);
  }

  onSelectSnapshot = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        let imgs = reader.result.split('base64,');
        if (imgs.length > 0) {
          let application = {
            ...this.props.application,
            snapshot_type: 1,
            snapshot_url_image: imgs[1],
          };
          this.imgSnapshotRadioButton.current.checked = true;
          this.props.setupNewApplication(application);
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  getSnapshotImageStyle() {
    if (
      this.imgSnapshot.current !== null &&
      this.imgNoSnapshot.current !== null
    ) {
      this.imgSnapshot.current.style.height =
        this.imgSnapshot.current.offsetWidth * (175 / 381) + 'px';
      this.imgNoSnapshot.current.style.height =
        this.imgNoSnapshot.current.offsetWidth * (175 / 381) + 'px';
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.imgNoSnapshotRadioButton.current.checked = true;
      this.setDefaultImage();
    }, 500);
  }

  render() {
    const { application } = this.props;

    return (
      <div className="col-lg-6 col-xl-7 mb-5" onKeyPress={this.enterSubmit}>
        <div className="inputForm">
          <input
            className="form-control"
            type="text"
            name=""
            onChange={this.onApplicationNameChange}
            required
          />
          <label>Name</label>
        </div>
        <div className="inputForm">
          <input
            className="form-control"
            type="text"
            name=""
            onChange={this.onTitleChange}
            required
          />
          <label>Title</label>
        </div>
        {/* <label>Description</label>
        <RichTextEditor onChange={this.onDescriptionChange} /> */}
        <div className="inputForm">
          <input
            className="form-control"
            type="text"
            name=""
            onChange={this.onDescriptionChange}
            required
          />
          <label>Description</label>
        </div>
        <div className="inputForm">
          <input
            className="form-control"
            type="text"
            name=""
            onChange={this.onURLChange}
            required
          />
          <label>URL</label>
        </div>
        <div className="groupAppForm mt-5">
          <label className="sectionTitle">Application Snapshot</label>
          <p>Choose snapshot</p>
          <div className="row">
            <div className="col-sm-4 col-md-4 col-xl-5">
              <label className="custom-checkbox custom-pick-image reveal">
                <input
                  ref={this.imgNoSnapshotRadioButton}
                  type="radio"
                  name="lpwb"
                  className="custom-control-input"
                />
                <div
                  className="imgContainer"
                  onClick={this.handleNoSnapshotChange}
                >
                  <img
                    ref={this.imgNoSnapshot}
                    src={process.env.PUBLIC_URL + '/images/no-image-wide.png'}
                    style={this.getSnapshotImageStyle()}
                    className="themeAppImg"
                    alt=""
                  />
                </div>
                <p>
                  <span className="icon-wrd-checkmark"></span> No snapshot
                </p>
              </label>
            </div>
            <div
              ref={this.imgSnapshotContainer}
              className="col-sm-4 col-md-4 col-xl-5"
            >
              <label className="custom-checkbox custom-pick-image">
                <input
                  ref={this.imgSnapshotRadioButton}
                  type="radio"
                  name="lpwb"
                  className="custom-control-input"
                />
                <div
                  className="imgContainer"
                  onClick={this.handleSnapshotChange}
                >
                  {application.snapshot_url_image !== undefined &&
                    application.snapshot_url_image !== '' && (
                      <img
                        ref={this.imgSnapshot}
                        className="themeAppImg"
                        style={this.getSnapshotImageStyle()}
                        src={
                          'data:image/png;base64,' +
                          application.snapshot_url_image
                        }
                        alt=""
                      />
                    )}
                  {(application.snapshot_url_image === undefined ||
                    (application.snapshot_url_image !== undefined &&
                      application.snapshot_url_image === '')) && (
                    <img
                      ref={this.imgSnapshot}
                      className="themeAppImg"
                      style={this.getSnapshotImageStyle()}
                      alt=""
                    />
                  )}
                </div>
                <p>Click browse if you want to upload new snapshot</p>
                <button className="btn btn-wrd_default icoTextBtn browseLogo">
                  <label className="custom-file-upload">
                    <input type="file" onChange={this.onSelectSnapshot} />
                    <span className="icon-wrd-folder"></span>
                    Browse
                  </label>
                </button>
              </label>
            </div>
          </div>
        </div>
        <div className="d-none">
          <div className="custom-controls-stacked">
            <label className="custom-control ios-switch">
              <input
                type="checkbox"
                onClick={this.onToggleIsAllowPublicAccess}
                className="ios-switch-control-input"
              />
              <span className="ios-switch-control-indicator"></span>
              <span className="text">Allow Public Access</span>
            </label>
          </div>
        </div>
        <div className="inputForm">
          <input
            className="form-control"
            type="text"
            name=""
            onChange={this.onKeywordsChange}
            required
          />
          <label>Keyword(s)</label>
          <p className="mt-2">
            <span className="icon-wrd-alert-triangle"></span> Use coma ( , ) if
            using more than one. eg: rainfall, price, etc
          </p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ setup }) => ({
  application: setup.application,
});

const mapDispatchToProps = (dispatch) => ({
  setupNewApplication: (item) => dispatch(setupNewApplicationAction(item)),
  createApplication: (application, bypassUrl) =>
    dispatch(createApplicationWithThunk(application, bypassUrl)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ApplicationsCreateInput)
);
