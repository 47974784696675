import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../configurations/routes';
import { getUserInfoWorkspaceThemeSettingImplementationWithThunk } from '../../../api/workspacesThemeSettingsThunk';

class UsersInfoWorkspacesItem extends Component {
    constructor(props) {
        super(props);
        this.displayWorkspace = this.displayWorkspace.bind(this);
    }

    static propTypes = {
        item: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired
    };

    displayWorkspace() {
        const { item, role } = this.props;

        let name = item.workspace_name.toLowerCase().replaceAll(" ", "+");
        let url = (role.user_role === 'Administrator' || item.owner) ? routeCodes.WORKSPACES_LIST + "/" + name + "/properties" : routeCodes.WORKSPACES_LIST + "/" + name;

        getUserInfoWorkspaceThemeSettingImplementationWithThunk(name, this.props.history, url, this.props.location.pathname, this.props.redirectUrl);
    }

    render() {
        const { item, role } = this.props;

        return (
            (role.user_role === 'Administrator' || (item.owner && item.enabled) || (role.user_role === 'User' && item.enabled && item.status === 'Active')) ?
                <li>
                    <Link to="#" onClick={() => { this.displayWorkspace(); return false; }}>
                        <div className="wsItem">
                            <div className="container h-100">
                                <div className="row h-100">
                                    <div className="col-3 px-0 d-flex align-items-center">
                                        <img src={((item.url_image !== undefined) ? "data:image/jpeg;base64," : "") + item.url_image} className="logo" alt="" />
                                    </div>
                                    <div className="col-9">
                                        <p className="wsListInfo">
                                            <b>{item.workspace_name}</b>
                                            <i className="d-block wsListOwner"> {(item.owner) ? "Workspace Owner" : null}</i>
                                            <i className="d-block wsListSuspend"> {(item.status === 'Suspend') ? "Suspended" : (!item.enabled) ? "Disabled" : null}</i>
                                            <span>{item.workspace_title}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </li>
                : null
        );
    }
}

const mapStateToProps = ({ role, redirectUrl }) => ({
    role,
    redirectUrl
});

export default withRouter(connect(mapStateToProps)(UsersInfoWorkspacesItem));
