import { GET_WORKSPACES_FOCUS_AREAS, UPDATE_WORKSPACES_FOCUS_AREAS, UPDATE_WORKSPACES_FOCUS_AREAS_ALL, CLEAR_WORKSPACES_FOCUS_AREAS } from '../actions/index';

const getWorkspacesFocusAreas = (focusAreas = [], action) => {
    let results = action.areas;
    results.sort(function (a, b) {
        var nameA = a.focus_area_name.toUpperCase();
        var nameB = b.focus_area_name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    })
    return results
}

const updateWorkspacesFocusAreas = (focusAreas = [], action) => {
    const results = focusAreas
        .filter((focusArea) => (focusArea.id !== action.areas.id))
        .concat(action.areas);
    results.sort(function (a, b) {
        var nameA = a.focus_area_name.toUpperCase();
        var nameB = b.focus_area_name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    })
    return results
}

const updateWorkspacesFocusAreasAll = (action) => {
    const results = action.areas;

    results.sort(function (a, b) {
        var nameA = a.focus_area_name.toUpperCase();
        var nameB = b.focus_area_name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    })
    return results
}

const clearWorkspacesFocusAreas = (action) => {
    return action.areas;
}

export const focusAreas = (focusAreas = [], action) => {
    switch (action.type) {
        case GET_WORKSPACES_FOCUS_AREAS: return getWorkspacesFocusAreas(focusAreas, action);
        case UPDATE_WORKSPACES_FOCUS_AREAS: return updateWorkspacesFocusAreas(focusAreas, action);
        case UPDATE_WORKSPACES_FOCUS_AREAS_ALL: return updateWorkspacesFocusAreasAll(action);
        case CLEAR_WORKSPACES_FOCUS_AREAS: return clearWorkspacesFocusAreas(action);
        default: return focusAreas;
    }
};