import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { updateDialogPortalsAction } from '../../../actions/updateDialogPortalsAction';
import {
    updatePortalSettingBannerWithThunk, removePortalSettingBannerWithThunk,
    updatePortalSettingFeatureWithThunk, removePortalSettingFeatureWithThunk,
    updatePortalSettingNewsWithThunk, removePortalSettingNewsWithThunk,
    updatePortalSettingThemeWithThunk, removePortalSettingThemeWithThunk,
} from '../../../api/portalSettingsThunk';

class PortalsModal extends Component {
    static propTypes = {
        type: PropTypes.string.isRequired,
        dialog: PropTypes.object.isRequired,        
        dispatchDialogPortals: PropTypes.func.isRequired,
        settingBanner: PropTypes.object.isRequired,
        settingFeature: PropTypes.object.isRequired,
        settingNews: PropTypes.object.isRequired,
        settingTheme: PropTypes.object.isRequired
    };

    setDefaultDialogState() {
        let portals = {
            id: '',
            name: '',
            status: '',
            dialog_title: '',
            dialog_type: '',
            dialog_context: '',
            show_dialog: false
        };

        this.props.dispatchDialogPortals(portals);
    }

    updatePortal() {
        const { dialog } = this.props;
        this.setDefaultDialogState();
        if (dialog.dialog_context === 'banner') {
            if (dialog.dialog_type === 'disable') {
                const { settingBanner } = this.props;
                let banners = settingBanner.banners; 
                let index = banners.findIndex(banner => banner.id === dialog.id);
                banners[index].status = 'Disabled';                

                let bannerSetting = {
                    ...settingBanner,
                    banners
                };  

                this.props.updateSettingBanner(bannerSetting, index);
            }
            else if (dialog.dialog_type === "trash") {
                const { settingBanner } = this.props;
                let banners = settingBanner.banners; 
                let index = banners.findIndex(banner => banner.id === dialog.id);
                banners.splice(index, 1);

                let bannerSetting = {
                    ...settingBanner,
                    banners
                };                

                this.props.removePortalSettingBanner(bannerSetting, dialog.id);
            }
        } else if (dialog.dialog_context === 'feature') {
            if (dialog.dialog_type === 'disable') {
                const { settingFeature } = this.props;
                let features = settingFeature.features;
                let index = features.findIndex(feature => feature.id === dialog.id);
                features[index].status = 'Disabled';

                let featureSetting = {
                    ...settingFeature,
                    features
                };                
                this.props.updateSettingFeature(featureSetting, index);
            }
            else if (dialog.dialog_type === "trash") {
                const { settingFeature } = this.props;
                let features = settingFeature.features;
                let index = features.findIndex(feature => feature.id === dialog.id);
                features.splice(index, 1);

                let featureSetting = {
                    ...settingFeature,
                    features
                };    
                this.props.removePortalSettingFeature(featureSetting, dialog.id);
            }
        } else if (dialog.dialog_context === 'news') {
            if (dialog.dialog_type === 'disable') {
                const { settingNews } = this.props;
                let news = settingNews.news;
                let index = news.findIndex(news => news.id === dialog.id);
                news[index].status = 'Disabled';

                let newsSetting = {
                    ...settingNews,
                    news
                };
                this.props.updateSettingNews(newsSetting, index);
            }
            else if (dialog.dialog_type === "trash") {
                const { settingNews } = this.props;
                let news = settingNews.news;
                let index = news.findIndex(news => news.id === dialog.id);
                news.splice(index, 1);

                let newsSetting = {
                    ...settingNews,
                    news
                };
                this.props.removePortalSettingNews(newsSetting, dialog.id);
            }
        } else if (dialog.dialog_context === 'theme') {
            if (dialog.dialog_type === 'disable') {
                const { settingTheme} = this.props;
                let themes = settingTheme.themes;
                let index = themes.findIndex(theme => theme.id === dialog.id);
                themes[index].status = 'Disabled';

                let themeSetting = {
                    ...settingTheme,
                    themes
                };

                this.props.updateSettingTheme(themeSetting, index);
            }
            else if (dialog.dialog_type === "trash") {
                const { settingTheme } = this.props;
                let themes = settingTheme.themes;
                let index = themes.findIndex(theme => theme.id === dialog.id);
                themes.splice(index, 1);

                let themeSetting = {
                    ...settingTheme,
                    themes
                };

                this.props.removePortalSettingTheme(themeSetting, dialog.id);
            }
        }
    }

    closeModal() {
        this.setDefaultDialogState();
    }

    render() {
        const { dialog } = this.props;
        const dialogCommand = (dialog.dialog_type === 'trash') ? 'REMOVE' : (dialog.status === 'Enabled') ? 'DISABLE' : 'ENABLE';

        return (
            <div className={"popupContainer h-100 align-items-center justify-content-center enable" + ((dialog.show_dialog) ? " animated fadeIn d-flex" : " d-none")}>
                <div className={"popupPanel popupPanel-small deleteWarning" + ((dialog.show_dialog) ? " animated bounceInDown" : "")}>
                    <div className="popupHeader">
                        <div className="p-4">
                            <h2 className="popupTitle">{dialog.dialog_title}</h2>
                            <button className="btn iconBtn" onClick={() => { this.closeModal(); }}>
                                <span className="icon-wrd-close"></span>
                            </button>
                        </div>
                    </div>
                    <div className="popupBody">
                        <div className="p-4">
                            <div className="summernote">
                                <p>
                                    Are you sure you want to <b className={(dialog.dialog_type === 'trash') ? 'actionRemove' : ''}>{dialogCommand}</b>
                                    &nbsp;<b className="appName">{dialog.name}</b>&nbsp;?
                                    <br />
                                    {(dialog.dialog_type === 'trash') ? 'This operation cannot be undone' : ''}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="popupFooter">
                        <div className="px-4 pt-4 pb-2">
                            <div className="row">
                                <div className="col">
                                    <button className="btn greyBtn icoTextBtn closePopup" onClick={() => { this.closeModal(); }}>
                                        <span className="icon-wrd-close"></span>
                                        Cancel
                                    </button>
                                </div>
                                <div className="col text-right">
                                    <button className={((dialog.dialog_type === 'trash') ? 'redBtn' : (dialog.status === 'Enabled') ? 'redBtn' : 'greenBtn') + ' btn icoTextBtn mr-3 closePopup'}
                                        onClick={() => { this.updatePortal(); }}>
                                        <span className={(dialog.dialog_type === 'trash') ? "icon-wrd-trash" : ((dialog.status === 'Enabled') ? "icon-wrd-lock" : "icon-wrd-unlock")}></span>
                                        {(dialog.dialog_type === 'trash') ? 'Remove' : (dialog.status === 'Enabled') ? 'Disable' : 'Enable'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ dialog, portalSettings }) => ({
    dialog: dialog.portals,
    settingBanner: portalSettings.setting_banner,
    settingFeature: portalSettings.setting_feature,
    settingNews: portalSettings.setting_news,
    settingTheme: portalSettings.setting_theme
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogPortals: (dialog) => dispatch(updateDialogPortalsAction(dialog)),
    updateSettingBanner: (settingBanner, index) => dispatch(updatePortalSettingBannerWithThunk(settingBanner, index)),
    removePortalSettingBanner: (settingBanner, id) => dispatch(removePortalSettingBannerWithThunk(settingBanner, id)),
    updateSettingFeature: (settingFeature, index) => dispatch(updatePortalSettingFeatureWithThunk(settingFeature, index)),
    removePortalSettingFeature: (settingFeature, id) => dispatch(removePortalSettingFeatureWithThunk(settingFeature, id)),
    updateSettingNews: (settingNews, index) => dispatch(updatePortalSettingNewsWithThunk(settingNews, index)),
    removePortalSettingNews: (settingNews, id) => dispatch(removePortalSettingNewsWithThunk(settingNews, id)),
    updateSettingTheme: (settingTheme, index) => dispatch(updatePortalSettingThemeWithThunk(settingTheme, index)),
    removePortalSettingTheme: (settingTheme, id) => dispatch(removePortalSettingThemeWithThunk(settingTheme, id))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalsModal));
