import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../configurations/routes';

class PortalLandingHeader extends Component {
    render() {
        return (
            <div className="topSection w-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <Link to={routeCodes.HOME} className="portalLogo">
                                <img src={process.env.PUBLIC_URL + "/images/DHI_Logo.png"} className="logo" alt="" />
                            </Link>
                        </div>
                        <div className="col-md-6">
                            <Link to={routeCodes.USERS_LOGIN} title="Login" className="landingLogin">
                                Login
                                </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default (PortalLandingHeader);