import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { Element } from 'react-scroll';
import dompurify from 'dompurify';
import { getPortalLandingNewsWithThunk } from '../../../api/portalLandingThunk';
import { routeCodes } from '../../../configurations/routes';
const sanitizer = dompurify.sanitize;

class PortalLandingNews extends Component {
    constructor(props) {
        super(props);
        this.viewMore = this.viewMore.bind(this);
    }
    static propTypes = {
        landingNews: PropTypes.array,
        loading: PropTypes.bool.isRequired
    };

    viewMore(item) {
        this.props.history.push(routeCodes.HOME + item.id + '/blog/news');
    }
    componentDidMount() {
        this.props.getPortalLandingNews();
    }

    render() {
        const { landingNews, loading } = this.props;
        return (
            <Element name="news">
                <div className="newsSection w-100">
                    {
                        loading &&
                        <div className="loader-portal-news">
                            <Loader
                                type="BallTriangle"
                                color="#00BFFF"
                                height="70"
                                width="70"
                            />
                        </div>
                    }
                    <div className="container-fluid">
                        {(landingNews !== undefined && landingNews.length > 0) ?
                            <div className="row">
                                {(landingNews[0] !== undefined) ?
                                    <div className="col-xl-6 col-lg-6 p-0">
                                        <div className="newsBanner no-background">
                                            <img width="100%" height="100%" src={((landingNews[0].image !== undefined) ? "data:image/jpeg;base64," : "") + landingNews[0].image} alt="">
                                            </img>
                                            <div className="textContainer p-5">
                                                <h2>{landingNews[0].news_title}</h2>
                                                <div dangerouslySetInnerHTML={{ __html: sanitizer(landingNews[0].news_description).substr(0, 500) + "..." }} />
                                                <ul>
                                                    <li>
                                                        <button><span className="icon-wrd-share"></span>Share</button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => this.viewMore(landingNews[0])}><span className="icon-wrd-eye" ></span>View</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                                {(landingNews[1] !== undefined) ?
                                    <div className="col-xl-3 col-lg-6 p-0">
                                        <div className="newsBanner no-background">
                                            <img width="100%" height="100%" src={((landingNews[1].image !== undefined) ? "data:image/jpeg;base64," : "") + landingNews[1].image} alt="" />
                                            <div className="textContainer p-5">
                                                <h2>{landingNews[1].news_title}</h2>
                                                <div dangerouslySetInnerHTML={{ __html: sanitizer(landingNews[1].news_description).substr(0, 200) + "..." }} />
                                                <ul>
                                                    <li>
                                                        <button><span className="icon-wrd-share"></span>Share</button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => this.viewMore(landingNews[1])}><span className="icon-wrd-eye"></span>View</button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                                <div className="col-xl-3 col-lg-12 p-0">
                                    <div className="container-fluid news-height">
                                        <div className="row news-height">
                                            {(landingNews[2] !== undefined) ?
                                                <div className="col-xl-12 col-lg-6 p-0">
                                                    <div className="newsBanner no-background">
                                                        <img width="100%" height="100%" src={((landingNews[2].image !== undefined) ? "data:image/jpeg;base64," : "") + landingNews[2].image} alt="" />
                                                        <div className="textContainer p-5">
                                                            <h2>{landingNews[2].news_title}</h2>
                                                            <div dangerouslySetInnerHTML={{ __html: sanitizer(landingNews[2].news_description).substr(0, 200) + "..." }} />
                                                            <ul>
                                                                <li>
                                                                    <button><span className="icon-wrd-share"></span>Share</button>
                                                                </li>
                                                                <li>
                                                                    <button onClick={() => this.viewMore(landingNews[2])}><span className="icon-wrd-eye"></span>View</button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                            {(landingNews[3] !== undefined) ?
                                                <div className="col-xl-12 col-lg-6 p-0">
                                                    <div className="newsBanner no-background">
                                                        <img width="100%" height="100%" src={((landingNews[3].image !== undefined) ? "data:image/jpeg;base64," : "") + landingNews[3].image} alt="" />
                                                        <div className="textContainer p-5">
                                                            <h2>{landingNews[3].news_title}</h2>
                                                            <div dangerouslySetInnerHTML={{ __html: sanitizer(landingNews[3].news_description).substr(0, 200) + "..." }} />
                                                            <ul>
                                                                <li>
                                                                    <button><span className="icon-wrd-share"></span>Share</button>
                                                                </li>
                                                                <li>
                                                                    <button onClick={() => this.viewMore(landingNews[3])}><span className="icon-wrd-eye"></span>View</button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </Element >
        );
    }
}

const mapStateToProps = ({ portalLanding, loading }) => ({
    landingNews: portalLanding.portal_news.news,
    loading: loading.portal_landing_news.loading
});


const mapDispatchToProps = (dispatch) => ({
    getPortalLandingNews: () => dispatch(getPortalLandingNewsWithThunk())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalLandingNews));