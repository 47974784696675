import {
    getUserWorkspacePropertiesByIdApi
} from '../backend/domain/workspaces';

import {
    getWorkspaceEmailSettingByIdApi,
    updateWorkspaceEmailSettingApi
} from '../backend/domain/workspacesSettings';


import { getWorkspaceAction } from '../actions/getWorkspaceAction';
import { clearWorkspaceAction } from '../actions/clearWorkspaceAction';
import { getWorkspaceEmailSettingAction } from '../actions/getWorkspaceEmailSettingAction';
import { clearWorkspaceEmailSettingAction } from '../actions/clearWorkspaceEmailSettingAction';
import { updateWorkspaceEmailSettingAction } from '../actions/updateWorkspaceEmailSettingAction';
import { loadingWorkspaceSettingAction } from '../actions/loadingWorkspaceSettingAction';
import { handleWorkspaceSettingsUpdateError } from '../utils/errorMessages';
import { overrideWorkspaceId } from '../utils/workspaces';

export const getWorkspaceEmailSettingByIdWithThunk = (id, userId, isAdmin, historyProps) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(id);
        dispatch(loadingWorkspaceSettingAction(true));
        dispatch(clearWorkspaceAction([]));
        dispatch(clearWorkspaceEmailSettingAction([]));
        getUserWorkspacePropertiesByIdApi(wId, userId, isAdmin, historyProps, "email")
            .then((response) => {
                if (!response.error) {
                    dispatch(getWorkspaceAction(response));
                }
                return response;
            })
            .then(() => {
                getWorkspaceEmailSettingByIdApi(wId)
                    .then(response => {
                        if (!response.error) {                            
                            dispatch(getWorkspaceEmailSettingAction(response));
                        }
                        return response;
                    })
                    .then((response) => {
                        dispatch(loadingWorkspaceSettingAction(false));
                        return response;
                    });
            });
    };

export const getWorkspaceEmailSettingWithThunk = (id) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(id);
        dispatch(loadingWorkspaceSettingAction(true));        
        
        getWorkspaceEmailSettingByIdApi(wId)
			.then(response => {
				if (!response.error) {                            
					dispatch(getWorkspaceEmailSettingAction(response));
				}
				return response;
			})
			.then((response) => {
				dispatch(loadingWorkspaceSettingAction(false));
				return response;
			});
    };

export const updateWorkspaceEmailSettingWithThunk = (workspaceSetting) =>
    (dispatch) => {        
        dispatch(loadingWorkspaceSettingAction(true));
        updateWorkspaceEmailSettingApi(workspaceSetting)
            .then((response) => {
                dispatch(updateWorkspaceEmailSettingAction(workspaceSetting));
                return response;
            })
            .then((response) => {
                handleWorkspaceSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspaceSettingAction(false));
                return response;
            });
    };