import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RichTextEditor from '../../../Editor/RichTextEditor';
import { getWorkspaceCategoryAction } from '../../../../actions/getWorkspaceCategoryAction';
import {
  updateWorkspaceCategoryWithThunk,
  deleteWorkspaceCategoryWithThunk,
} from '../../../../api/workspacesCategoriesThunk';
import { getUrlWorkspaceId } from '../../../../utils/url';
import { routeCodes } from '../../../../configurations/routes';
import WorkspaceCategoriesBanner from '../Image/WorkspaceCategoriesBanner'
import WorkspaceCategoriesIcon from '../Image/WorkspaceCategoriesIcon';

class WorkspacesCategoriesDetailBody extends Component {
  constructor(props) {
    super(props);
    this.onTitleChange = this.onTitleChange.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
    this.onMapUrlChange = this.onMapUrlChange.bind(this);
    this.openWorkspaceApplicationList =
      this.openWorkspaceApplicationList.bind(this);
    this.updateWorkspaceCategory = this.updateWorkspaceCategory.bind(this);
    this.removeWorkspaceCategory = this.removeWorkspaceCategory.bind(this);
  }

  static propTypes = {
    role: PropTypes.object.isRequired,
    workspaceCategory: PropTypes.object.isRequired,
  };

  getCurrentWorkspaceId = () => {
    let id = getUrlWorkspaceId(this.props.location.pathname);
    return id;
  };

  onTitleChange(e) {
    let category = {
      ...this.props.workspaceCategory,
      category_title: e.target.value,
    };

    this.props.dispatchCategory(category);
  }

  onDescriptionChange(e) {
    let category = {
      ...this.props.workspaceCategory,
      category_description: e.target.value,
    };
    this.props.dispatchCategory(category);
  }

  onMapUrlChange(e) {
    let category = {
        ...this.props.workspaceCategory,
        mapUrl: e.target.value
    };
    this.props.dispatchCategory(category);
  }

  openWorkspaceApplicationList() {
    const workspaceName = this.getCurrentWorkspaceId();
    this.props.history.push(
      routeCodes.WORKSPACES_LIST +
        '/' +
        workspaceName.toLowerCase().replaceAll(' ', '+') +
        '/applications'
    );
  }

  updateWorkspaceCategory() {
    const { workspaceCategory } = this.props;
    const workspaceName = this.getCurrentWorkspaceId();

    this.props.updateWorkspaceCategory(workspaceName, workspaceCategory);
  }

  removeWorkspaceCategory() {
    const { workspaceCategory } = this.props;
    const workspaceName = this.getCurrentWorkspaceId();
    const routeBackTo =
      routeCodes.WORKSPACES_LIST +
      '/' +
      workspaceName.toLowerCase().replaceAll(' ', '+') +
      '/applications';
    this.props.deleteWorkspaceCategory(
      workspaceName,
      workspaceCategory.category_id,
      routeBackTo,
      this.props.history,
      true
    );
  }

  disableUpdateButton() {
    console.log(this.props, 'props')
    return !Boolean(this.props.workspaceCategory.category_title);
  }

  render() {
    const { workspaceCategory, role } = this.props;

    return (
      <div>
        <div className="mainHead">
          <div className="row">
            <div className="col-12">
              <h2 className="float-left mt-2 mr-4">Workspace Category</h2>
            </div>
          </div>
          <hr />
        </div>
        <div className="mainBody">
          <div className="formWrap">
            <div className="row">
              <div className="col-7">
                <label>Title</label>
                <div className="inputForm">
                  <input
                    className="form-control"
                    type="text"
                    name=""
                    defaultValue={workspaceCategory.category_title}
                    onChange={this.onTitleChange}
                    required
                  />
                </div>
                <label>Description</label>
                <div className="inputForm">
                  <input
                    className="form-control"
                    type="text"
                    value={workspaceCategory.category_description}
                    onChange={this.onDescriptionChange}
                    required
                  />
                </div>
                <label>Map url</label>
                <div className="inputForm">
                  <input
                    className="form-control"
                    type="text"
                    name=""
                    defaultValue={workspaceCategory.mapUrl}
                    onChange={this.onMapUrlChange}
                    required
                  />
                </div>
                <div>
                  <WorkspaceCategoriesIcon editForm />
                </div>
                <div className="mb-3">
                  <WorkspaceCategoriesBanner editForm />
                </div>
                <div className="col-md-12 mt-5">
                  <div className="inputForm buttonGroup mt-2 text-center">
                    <button
                      className="btn greyBtn icoTextBtn mx-2"
                      onClick={this.openWorkspaceApplicationList}
                    >
                      <span className="icon-wrd-close"></span>Cancel
                    </button>
                    <button
                      className="btn greenBtn icoTextBtn mx-2"
                      onClick={this.updateWorkspaceCategory}
                      disabled={this.disableUpdateButton()}
                    >
                      <span className="icon-wrd-add"></span> Update Workspace
                      Category
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul className="topNavBtn">
            {role.user_role === 'Administrator' && (
              <li>
                <button
                  className="btn redBtn"
                  onClick={this.removeWorkspaceCategory}
                >
                  <span className="icon-wrd-trash"></span>
                  Remove Workspace Category
                </button>
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ detail }) => ({
  workspaceCategory: detail.workspace_category,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchCategory: (category) =>
    dispatch(getWorkspaceCategoryAction(category)),
  updateWorkspaceCategory: (workspaceId, category) =>
    dispatch(updateWorkspaceCategoryWithThunk(workspaceId, category)),
  deleteWorkspaceCategory: (
    workspaceId,
    categoryId,
    routeBackTo,
    historyProps,
    isAdmin
  ) =>
    dispatch(
      deleteWorkspaceCategoryWithThunk(
        workspaceId,
        categoryId,
        routeBackTo,
        historyProps,
        isAdmin
      )
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkspacesCategoriesDetailBody)
);
