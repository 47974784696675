import { GET_GLOBAL_USERS, DELETE_GLOBAL_USER, CREATE_GLOBAL_USER, CLEAR_GLOBAL_USERS } from '../actions/index';

const getGlobalUsers = (globalUsers = [], action) => {
    let itemsKeys = [];
    let globalUsersKeys = action.globalUsers.reduce((keys, globalUser) => {
        globalUsersKeys.push(globalUser.uid);
        keys = itemsKeys;
        return keys;
    }, []);

    let result = globalUsers.filter((globalUser) => {
        return globalUsersKeys.indexOf(globalUser.uid) === -1;
    });

    return result.concat(action.globalUsers);
}

const deleteGlobalUser = (globalUsers = [], action) => {
    return globalUsers.filter((globalUser) => {
        return globalUser.uid !== action.uid;
    });
}

const createGlobalUser = (globalUsers = [], action) => {
    return globalUsers.concat(action.globalUser);
}

const clearGlobalUsers = (action) => {
    return action.globalUsers;
}

export const globalUsers = (globalUsers = [], action) => {
    switch (action.type) {
        case GET_GLOBAL_USERS: return getGlobalUsers(globalUsers, action);
        case DELETE_GLOBAL_USER: return deleteGlobalUser(globalUsers, action);
        case CREATE_GLOBAL_USER: return createGlobalUser(globalUsers, action);
        case CLEAR_GLOBAL_USERS: return clearGlobalUsers(action);
        default: return globalUsers;
    }
};