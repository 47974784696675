import * as React from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { createInstance } from '@datapunt/matomo-tracker-react';
import { getCookies } from '../backend/api/getCookies';

export const updateMatomoAnalytics = (trackingId, url) => {
  return createInstance({
    urlBase: url,
    siteId: trackingId,
    userId: getCookies.user_name,
    trackerUrl: url + 'matomo.php',
    srcUrl: url + 'matomo.js',
    disabled: false,
    heartBeat: {
      active: true,
      seconds: 10,
    },
    linkTracking: true,
    configurations: {
      disableCookies: true,
      setSecureCookie: true,
      setRequestMethod: 'POST',
    },
  });
};

export function MatomoComponent(props) {
  const {
    workspaceName,
    applicationName,
    userName,
    roleName,
    tenantName,
    visitorType,
    ipAddress,
    instance,
  } = props;

  const { trackPageView, trackEvent } = useMatomo();
  // let loc = '/workspaces' + location.pathname.split('/workspaces')[1];
  // let url = 'https://dhigroup.matomo.cloud/'

  React.useEffect(() => {
    let datetime = new Date();
    datetime = datetime.toUTCString();

    trackPageView({
      documentTitle: `WaterDss Portal - ${workspaceName} - ${applicationName} - ${roleName} - ${tenantName} - ${visitorType}`,
    });

    // trackEvent({
    //   category: `Workspace application ${workspaceName} - ${applicationName} landing page`,
    //   action: 'Display workspace application landing page',
    //   name: `${workspaceName} - ${applicationName} - ${ipAddress} - ${userName} - ${datetime}`,
    // });
  }, []);

  return <div></div>;
}
