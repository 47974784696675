import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import GridItem from './GridItem';

class GridBody extends Component {
    static propTypes = {
        items: PropTypes.array.isRequired,
        selected: PropTypes.func.isRequired
    };

    render() {
        const { items, selected } = this.props;

        return (
            <tbody>
                {items.map((item, index) => (
					<GridItem key={index} item={item} selected={selected} items={items}/>
                ))}
            </tbody>
        );
    }
}

export default withRouter(GridBody);
