import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { routeCodes } from '../../../configurations/routes';
import { Link as LinkScroll } from 'react-scroll';

class PortalLandingMenu extends Component {
    render() {
        const { id, blog } = this.props.match.params;
        let features = [];
        let news = [];
        const { landingFeature, landingNews } = this.props;
        if (landingFeature && landingFeature.length > 0) {
            features = landingFeature;
        }
        if (landingNews && landingNews.length > 0) {
            news = landingNews;
        }
        return (
            <div className="menuSection w-100">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            {!id ?
                                <ul>
                                    {features.length > 0 && news.length > 0 ? <li><Link className="active" to={routeCodes.HOME}> Home </Link></li> : ''}
                                    {features.length > 0 ? <li><LinkScroll to="features" spy={true} smooth={true} duration={700} className="cursor-pointer"> Features </LinkScroll></li> : ''}
                                    {news.length > 0 ? <li><LinkScroll to="news" spy={true} smooth={true} duration={700} className="cursor-pointer"> News </LinkScroll></li> : ''}
                                    {features.length > 0 && news.length > 0 ? <li><LinkScroll to="footer" spy={true} smooth={true} duration={700} className="cursor-pointer"> Contact </LinkScroll></li> : ''}
                                </ul>
                                : <ul>
                                    <li><Link to={routeCodes.HOME}> Home </Link></li>
                                    <li><Link className="active" to={this.props.match.url}> {blog.charAt(0).toUpperCase() + blog.slice(1)} </Link></li>
                                </ul>}
                        </div>
                        <div className="col-md-4 text-right">
                            {/* <div className="searchContainer">
                                <input type="text" name="" placeholder="search" className="searchMenuInput" />
                                <button className="searchMenuBtn"><span className="icon-wrd-search"></span></button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = ({ portalLanding }) => ({
    landingFeature: portalLanding.portal_feature.features,
    landingNews: portalLanding.portal_news.news,
});


export default connect(mapStateToProps)(PortalLandingMenu);