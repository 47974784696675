import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UsersAccessAreasItem from './UsersAccessAreasItem';
import UserAccessApplicationsItem from './UserAccessApplicationsItem';
import { Link } from 'react-router-dom';
import { updateDialogUsersAction } from '../../../actions/updateDialogUsersAction';
import { updateUserAccessWorkspaceWithThunk } from '../../../api/usersThunk';
import { routeCodes } from '../../../configurations/routes';
import { changeRouteAction } from '../../../actions/changeRouteAction';
import { getSessionStorage } from '../../../utils/sessionStorage';

class UsersAccessWorkspacesItem extends Component {
    constructor(props) {
        super(props);
        this.displayAction = this.displayAction.bind(this);
        this.updateUserStatus = this.updateUserStatus.bind(this);
        this.removeUserAccess = this.removeUserAccess.bind(this);
        this.state = {
            stickyActionIsOpen: false
        }
        this.contactOwners = this.contactOwners.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired
    };

    changeRoute(name) {
        let navigation = {
            name
        };
        this.props.dispatchRoute(navigation);
    }

    contactOwners() {
        const { workspace, user } = this.props;
        this.changeRoute(routeCodes.WORKSPACES_LIST + '/' + workspace.name.toLowerCase().replaceAll(" ", "+") + '/members/' + user.id.toLowerCase() + '/access');
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + workspace.name.toLowerCase().replaceAll(" ", "+") + "/contactOwners");
    }

    displayAction() {
        this.setState({ stickyActionIsOpen: !this.state.stickyActionIsOpen });
    }

    hideAction() {
        this.setState({ stickyActionIsOpen: false });
    }

    updateUserStatus() {
        const { role } = this.props;
        if (!this.props.workspace.suspend) {
            let user = {
                id: this.props.user.uid,
                name: this.props.user.full_name,
                status: (this.props.workspace.suspend) ? 'Suspended' : 'Active',
                dialog_title: 'Warning',
                dialog_type: this.props.workspace.name,
                workspace_id: this.props.workspace.id,
                observer_id: role.user_id,
                observer_role: role.user_role,
                show_dialog: true
            };

            this.props.dispatchDialogUsers(user);
        }
        else {
            let targetId = this.props.user.uid;
            let width = Math.ceil((window.innerWidth - 560) * (3 / 12));
            let height = width;
            if (targetId === "") {
                const sessionRole = getSessionStorage("role");
                if (sessionRole !== "") {
                    const role = JSON.parse(sessionRole);
                    targetId = role.user_id;
                }
            }
            this.props.updateUserAccessWorkspace(this.props.workspace.id, targetId, 'Active', width, height);
        }

        this.setState({ stickyActionIsOpen: !this.state.stickyActionIsOpen });
    }

    removeUserAccess() {
        const { role } = this.props;
        let user = {
            id: this.props.user.uid,
            name: this.props.user.full_name,
            status: 'RemoveAccess',
            dialog_title: 'Warning',
            dialog_type: this.props.workspace.name,
            workspace_id: this.props.workspace.id,
            observer_id: role.user_id,
            observer_role: role.user_role,
            show_dialog: true
        };

        this.props.dispatchDialogUsers(user);

        this.setState({ stickyActionIsOpen: !this.state.stickyActionIsOpen });
    }

    redirectToWorkspace(wName) {
        this.props.history.push(routeCodes.WORKSPACES_LIST + "/" + wName + "/properties");
    }

    render() {
        const { workspace } = this.props;
        const { stickyActionIsOpen } = this.state;

        return (
            <div>
                <div className="sectionTitle">
                    <div className="row cursor-pointer" onClick={() => this.redirectToWorkspace(workspace.name.toLowerCase().replaceAll(" ", "+"))}>
                        <div className="col-12 col-md-4 p-3">
                            <span className="titleLabel">Workspace</span>
                            <p>{workspace.name}</p>
                        </div>
                        <div className="col-4 col-md-1 p-3">
                            <span className="titleLabel">Role</span>
                            <p>{(workspace.owner) ? 'Owner' : 'Member'}</p>
                        </div>
                        <div className="col-4 col-md-2 p-3">
                            <span className="titleLabel">Status</span>
                            <p className={(workspace.enabled && workspace.status === 'Active') ? "greenText" : "redText"}>{(!workspace.enabled) ? 'Disabled' : (workspace.status !== 'Active') ? 'Suspended' : 'Enabled'}</p>
                        </div>
                        <div className=" col-4 col-md-1 p-3">
                            <span className="titleLabel">Access</span>
                            <p className={(workspace.suspend) ? "redText" : "greenText"}>{(workspace.suspend) ? 'Suspended' : 'Active'}</p>
                        </div>
                        <div className="col-7 col-md-3 p-3 text-right">
                            <button className="btn icoTextBtn greenBtn m-0" onClick={this.contactOwners} disabled={workspace.ownerCount === 0}>
                                <span className="icon-wrd-mail-closed"></span>
                                Contact Owner(s)
                            </button>
                        </div>
                    </div>
                    <button onClick={this.displayAction} onBlur={() => this.hideAction()} className="btn moreBtn"><span className="icon-wrd-more"></span></button>
                    <ul className={(stickyActionIsOpen) ? "action show-action" : "action"}>
                        <li><Link to={'#'} onMouseDown={() => this.updateUserStatus()}
                            className={(workspace.suspend) ? "greenLink" : "redLink"}>
                            <span className={(workspace.suspend) ? "icon-wrd-unlock" : "icon-wrd-lock"}></span>
                            {(workspace.suspend) ? 'Reinstate' : 'Suspend'}</Link></li>
                        <li><Link to="#" onMouseDown={this.removeUserAccess} className="redLink"><span
                            className="icon-wrd-revoke"></span>Remove Access</Link></li>
                    </ul>
                </div>
                <div className="sectionCheckbox mb-5">
                    {(workspace.focusAreas !== undefined && workspace.focusAreas.length > 0) ?
                        <div className="row">
                            <div className="col">
                                <p className="groupName">Area(s)</p>
                            </div>
                        </div>
                        : null
                    }
                    <div className="row">
                        {(workspace.focusAreas !== undefined && workspace.focusAreas.length > 0) ?
                            workspace.focusAreas.map((focusArea) => (
                                <UsersAccessAreasItem key={focusArea.id} focusArea={focusArea} workspace={workspace} />
                            ))
                            :
                            null
                        }
                    </div>
                    {(workspace.applications !== undefined && workspace.applications.length > 0) ?
                        <div className="row">
                            <div className="col">
                                <p className="groupName mt-4">Application(s)</p>
                            </div>
                        </div>
                        : null
                    }
                    <div className="row">
                        <div className=" col-xl-12">
                            <div className="wsAppsSection">
                                <div className="row">
                                    {(workspace.applications !== undefined && workspace.applications.length > 0) ?
                                        workspace.applications.map((application) => (
                                            <UserAccessApplicationsItem key={application.id} application={application} workspace={workspace} />
                                        ))
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = ({ userAccess, role, portalRouter }) => ({
    user: userAccess.user,
    role,
    portalRouter
});

const mapDispatchToProps = (dispatch) => ({
    dispatchRoute: (navigation) => dispatch(changeRouteAction(navigation)),
    dispatchDialogUsers: (dialog) => dispatch(updateDialogUsersAction(dialog)),
    updateUserAccessWorkspace: (workspaceId, userId, status, width, height) => dispatch(updateUserAccessWorkspaceWithThunk(workspaceId, userId, status, width, height))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersAccessWorkspacesItem));
