import { GET_WORKSPACE_AVAILABLE_APPLICATIONS, CLEAR_WORKSPACE_AVAILABLE_APPLICATIONS } from '../actions/index';

const initialAvailableApplicationstate = {}

export const availableApplications = (state = initialAvailableApplicationstate, action) => {
    const { available_applications } = action

    switch (action.type) {
        case GET_WORKSPACE_AVAILABLE_APPLICATIONS:
            return {
                workspace_id: available_applications.id,
                applications: available_applications.applications
            }
        case CLEAR_WORKSPACE_AVAILABLE_APPLICATIONS:
            return initialAvailableApplicationstate;
        default:
            return state;
    }
};