import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setupNewWorkspaceCategoryAction } from '../../../../actions/setupNewWorkspaceCategoryAction';
import { getWorkspaceCategoryAction } from '../../../../actions/getWorkspaceCategoryAction';
import { createWorkspaceCategoryImageApi } from 'apps/portal/src/backend/domain/workspacesCategories';
import WorkspacesCategoriesCreationErrorModal from '../Modal/WorkspacesCategoriesCreationErrorModal';
import { updateDialogWorkspacesCategoriesCreationErrorAction } from '../../../../actions/updateDialogWorkspacesCategoriesCreationErrorAction';
import { queryBaseUrlV2 } from '../../../../backend/api/getCookies';
import BannerModal from 'react-modal';
import BannerEditor from '../../../Editor/ImageEditor';
import { modalImageEditorStyle } from 'apps/portal/src/utils/modal';
class WorkspaceCategoriesBanner extends Component {
    constructor(props) {
        super(props);
        this.onSelectFile = this.onSelectFile.bind(this);
        this.onCloseBannerEditor = this.onCloseBannerEditor.bind(this);
    }

    state = {
        src: this.props.workspaceCategory.imageId ?? null,
        error: false,
        isUploading: false,
        bannerEditorIsOpen: false,
        selectedImage: null,
    };

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({ bannerEditorIsOpen: true, selectedImage: reader.result });
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    async onCloseBannerEditor(image, aspectRatio) {
        this.setState({ bannerEditorIsOpen: false, isUploading: true });

        const response = await createWorkspaceCategoryImageApi(image)
        if (response.status === 200) {
            this.setState({ src: response.data, error: false, isUploading: false });
            
            const workspace_category = {
                ...this.props.workspaceCategory,
                imageId: response.data,
            };
        
            this.props.editForm ? this.props.dispatchCategory(workspace_category) : this.props.setupCategory(workspace_category);
        } else {
            this.setState({ src: null, error: true, isUploading: false });
            this.props.errorUploadImage({
                id: 'upload',
                name: response?.status === 413 ? 'Image is too large' : 'Failed upload image',
                status: response?.status,
                dialog_title:'Upload image failed',
                dialog_type: response?.status === 413 ? 'Image is too larged' : 'Failed upload image',
                show_dialog: true
            })
        }
    }

    render() {
        const { src, error, isUploading, bannerEditorIsOpen, selectedImage } = this.state;

        return (
            <div>
                <div className="groupForm mt-3 pl-0">
                    <label>Banner image</label>
                    <div className="row">
                        <div className="col-sm-6 col-md-6 col-xl-6">
                            <label className="custom-checkbox custom-pick-image">
                                <input type="radio" className="custom-control-input" name="Banner"
                                    onChange={this.handleCustomBannerChange} checked={this.state.isCustomBannerChecked} />
                                <div className="imgContainer">
                                {
                                    src ? 
                                    <img src={`${queryBaseUrlV2}workspace/application/categories/image/${src}`} className="themeImg" alt="" /> :
                                    <img src={process.env.PUBLIC_URL + "/images/no-image-wide.png"} className="themeImg" alt="" />
                                }
                                </div>
                            </label>
                        </div>
                    </div>
                    { isUploading ? 
                        <p>is uploading ...</p> :
                        <button className="btn btn-wrd_default icoTextBtn browseLogo">
                            <label className="custom-file-upload d-flex align-items-center">
                                <input type="file" accept="image/png" onChange={this.onSelectFile} />
                                <span className="icon-wrd-folder icon-upload-folder mr-2"></span>
                                Browse
                            </label>
                        </button>
                    }
                </div>
                {
                    <BannerModal
                        isOpen={bannerEditorIsOpen}
                        style={modalImageEditorStyle}
                        contentLabel="Banner Editor">
                        <BannerEditor srcImage={selectedImage} onClose={this.onCloseBannerEditor} imgType={'banner'} blob />
                    </BannerModal>
                }
                {
                    error && <WorkspacesCategoriesCreationErrorModal type="error" />
                }      
            </div>
        );
    }
}

const mapStateToProps = ({ detail, setup }, ownProps) => ({
    workspaceCategory: ownProps.editForm ? detail.workspace_category : setup.workspace_category,
});

const mapDispatchToProps = (dispatch) => ({
    setupCategory: (category) => dispatch(setupNewWorkspaceCategoryAction(category)),
    dispatchCategory: (category) => dispatch(getWorkspaceCategoryAction(category)),
    errorUploadImage: (category) => dispatch(updateDialogWorkspacesCategoriesCreationErrorAction(category)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspaceCategoriesBanner));
