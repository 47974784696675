import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ProfileAccessWorkspacesItem from './ProfileAccessWorkspacesItem';
import { updateProfileAccessWithThunk } from '../../../api/usersThunk';
import { getUserAvailableWorkspacesWithThunk } from '../../../api/userAvailableWorkspacesThunk';
import { defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize } from '../../../configurations/app';
import UserAvailableWorkspacesContainer from '../../Workspaces/UserAvailableWorkspaces/UserAvailableWorkspacesContainer';
import { updateAvailableWorkspacesOpenedStatus } from '../../../utils/workspaces';

class ProfileAccessBody extends Component {
    constructor(props) {
        super(props);
        this.hideList = this.hideList.bind(this);
        this.state = {
            wsListOpened: false,
            firstOpen: true,
            isAnOwner: false
        };
    }

    static propTypes = {
        userAccess: PropTypes.object.isRequired,
        role: PropTypes.object.isRequired
    };

    updateWorkspaceSetting() {
        const { userAccess } = this.props;
        this.props.updateUserAccess(userAccess);
    }

    availableWorkspacesSwitch() {
        this.setState({ wsListOpened: !this.state.wsListOpened });
        this.setState({ firstOpen: false });
        if (this.state.wsListOpened === false) {
            const { role } = this.props;
            this.props.getUserAvailableWorkspaces(role.user_id, role.user_role, defaultAvailableWorkspacesPageIndex, defaultAvailableWorkspacesPageSize);
        }
        else {
            updateAvailableWorkspacesOpenedStatus(false);
        }
    }

    hideList() {
        this.setState({ wsListOpened: false });
    }

    componentDidMount() {
        const { userAccess } = this.props;
        if (userAccess.access.find(x => x.owner === true) !== undefined) {
            this.setState({ isAnOwner: true });
        }
    }

    render() {
        const { userAccess, role } = this.props;
        return (
            <div className="mainBody">
                <div className="row mt-5">
                    <div className="col-md-12 order-lg-1 col-lg-8 col-xl-9">
                        <div className="workspaceSectionAccess">
                            {(userAccess.access !== undefined && userAccess.access.length > 0) ?
                                userAccess.access.map((workspace) => (
                                    <ProfileAccessWorkspacesItem key={workspace.id} workspace={workspace} />
                                ))
                                :
                                null
                            }
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-4 col-xl-3">
                        <div className="wsPhoto text-center">
                            <div className="photoContainer userPhoto mtMin text-center mb-4">
                                <img src={process.env.PUBLIC_URL + "/images/transCircle.png"} className="insideCircle" alt="" />
                                <img src={((userAccess.user.picture !== undefined) ? "data:image/jpeg;base64," : "") + userAccess.user.picture} className="photo" alt="" />
                            </div>
                            {(userAccess.access !== undefined && userAccess.access.length > 0 && (role.user_role === 'Administrator' || (role.user_role !== 'Administrator' && this.state.isAnOwner )) ) ?
                                <button className="btn mb-5 textBtn greenBtn w-75" onClick={() => this.updateWorkspaceSetting()}>Update Changes</button>
                                : null
                            }
                        </div>
                    </div>
                </div>
                <div className={(this.state.firstOpen) ? "workspaceList d-none" : (this.state.wsListOpened) ? "workspaceList open animated fadeInRight" : "workspaceList open animated fadeOutRight"}>
                    <UserAvailableWorkspacesContainer hideList={this.hideList} />
                </div>
                <button className="btn iconBtn wsListBtn" onClick={() => this.availableWorkspacesSwitch()}>
                    <span className={(this.state.wsListOpened) ? "icon-wrd-close" : "icon-wrd-ws-list"}></span>
                </button>
            </div>
        );
    }
}

const mapStateToProps = ({ userAccess, role }) => ({
    userAccess,
    role
});

const mapDispatchToProps = (dispatch) => ({
    updateUserAccess: (userAccess) => dispatch(updateProfileAccessWithThunk(userAccess)),
    getUserAvailableWorkspaces: (userId, userRole, pageIndex, pageSize) => dispatch(getUserAvailableWorkspacesWithThunk(userId, userRole, pageIndex, pageSize))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfileAccessBody));
