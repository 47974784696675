import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import UserCreateInput from './UserCreateInput';
import UserCreatePicture from './UserCreatePicture';
import UserCreateAction from './UserCreateAction';

class UserCreateForm extends Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        scrollTop: PropTypes.func.isRequired
    };

    render() {
        return (
            <div className="formWrap">
                <div className="row">
                    <div className="col-md-12 mb-2">
                        <p className="text prologue mt-5">Please fill all the fields</p>
                    </div>
                    <UserCreatePicture />
                    <UserCreateInput scrollTop={this.props.scrollTop} />                                  
                    <UserCreateAction scrollTop={this.props.scrollTop} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ setup }) => ({
    user: setup.user
});

export default withRouter(connect(mapStateToProps)(UserCreateForm));