import { GET_APPLICATIONS, CLEAR_APPLICATIONS, CREATE_APPLICATION } from '../actions/index';

const getApplications = (applications = [], action) => {
    let itemsKeys = [];
    let applicationsKeys = action.applications.reduce((keys, application) => {
        itemsKeys.push(application.id);
        keys = itemsKeys;
        return keys;
    }, []);

    let result = applications.filter((application) => {
        return applicationsKeys.indexOf(application.id) === -1;
    });

    let results = result.concat(action.applications);

    results.sort(function (a, b) {
        return a.application_name.toLowerCase().localeCompare(b.application_name.toLowerCase());
    });

    return results;
}

const clearApplications = (action) => {
    return action.applications;
}

const createApplication = (applications = [], action) => {
    return applications.concat(action.application);
}

export const applications = (applications = [], action) => {
    switch (action.type) {
        case GET_APPLICATIONS: return getApplications(applications, action);
        case CLEAR_APPLICATIONS: return clearApplications(action);
        case CREATE_APPLICATION: return createApplication(applications, action);
        default: return applications;
    }
};