import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarContainer from '../Sidebar/SidebarContainer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import ContactAdministratorsBody from './ContactAdministratorsBody';
import ContactAdministratorsErrorModal from './Modal/ContactAdministratorsErrorModal';

class ContactAdministratorsContainer extends Component {
    static propTypes = {
        loading: PropTypes.bool.isRequired
    }

    componentDidMount() {

    }

    render() {
        const { loading, loadingTheme } = this.props;

        return (
            <>
                {(loading || loadingTheme) ?
                    <div className="loader-container">
                        <Loader
                            type="BallTriangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                    :
                    <div>
                        <SidebarContainer />
                        <main>
                            <ContactAdministratorsBody />
                        </main>
                        <ContactAdministratorsErrorModal />
                    </div>
                }
            </>
        );
    }
}

const mapStateToProps = ({ loading }) => ({
    loading: loading.contactOwners.loading,
    loadingTheme: loading.theme.loading
});

const mapDispatchToProps = (dispatch) => ({

});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContactAdministratorsContainer));
