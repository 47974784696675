import { getImageSize } from '../../utils/snapshot';
import { getDefaultImage, getThumbnailDefaultImage, getDefaultLogo } from '../../utils/image';
import { normalizeTextDescription } from '../../utils/text';
import sort from 'fast-sort';

const applicationsApi = require('../api/applications');
const workspacesApi = require('../api/workspaces');
const workspacesApplicationsApi = require('../api/workspacesApplications');
const workspacesSettingsApi = require('../api/workspacesSettings');

export async function getWorkspaceApplicationPropertiesByIdApi(workspaceId, applicationId) {
    const workspaceApplication = await workspacesApplicationsApi.getWorkspaceApplicationById(workspaceId, applicationId, false);
    const application = await applicationsApi.getApplicationById(applicationId);
    return mapToWorkspaceApplicationsFrontend(workspaceApplication.data, application.data);
}

export async function getWorkspaceApplicationIdByNameApi(workspaceId, workspaceApplicationName) {
    try {
        const workspaceApplication = await workspacesApplicationsApi.getWorkspaceApplicationIdByName(workspaceId, workspaceApplicationName);
        return mapWorkspaceApplicationIdFrontend(workspaceApplication.data, workspaceApplicationName);
    }
    catch (err) {
        if (err.response !== undefined) {
            return mapToEmptyWorkspaceApplicationFrontend(err.response.status.toString());
        }
        return mapToEmptyWorkspaceApplicationFrontend("500");
    }
}

export async function getWorkspaceApplicationByIdApi(workspaceId, applicationId) {
    let usedOriginal = true;
    let connections = [];
    const workspaceApps = await workspacesApplicationsApi.getWorkspaceApplicationPropertiesById(workspaceId, applicationId, true);
    const workspaceDetail = await workspacesApi.getWorkspaceById(workspaceId, false);
    const workspaceSettingLogo = await workspacesSettingsApi.getWorkspaceSettingLogoById(workspaceId);
    const workspaceSettingTheme = await workspacesSettingsApi.getWorkspaceThemeSettings(workspaceId);
    try {
        const resultConnections = await workspacesApplicationsApi.getWorkspaceApplicationConnections(workspaceId, applicationId, usedOriginal);
        const { data: focusAreas } = await workspacesApplicationsApi.getWorkspaceFocusAreas(workspaceId, applicationId);
        let shortConnString = []
        for (let i = 0; i < focusAreas.length; i++) {
            let { data: connection } = await workspacesApplicationsApi.getWorkspaceFocusAreaShortConnString(workspaceId, applicationId, focusAreas[i].id);
            if (connection) shortConnString.push(connection);
        }

        const completeConnString = resultConnections.data.map(dt => {
            const sConnection = shortConnString.find(sc => sc.id === dt.id)
            return {
                ...dt,
                shortConnectionString: sConnection ? sConnection.connectionstring : ''
            }
        })
        connections = await mapToWorkspacesApplicationConnectionsFrontend(completeConnString, workspaceApps.data);
    }
    catch (err) {
        connections = await mapToEmptyWorkspacesApplicationConnectionsFrontend();
    }

    let results = await mapToWorkspaceApplicationFrontend(workspaceApps.data, workspaceDetail.data, workspaceSettingLogo.data, workspaceSettingTheme.data);
    if (results !== undefined) {
        results.connections = connections;
    }

    return results;
}

export async function getWorkspaceApplicationConnectionUsageVerificatonApi(workspaceId, applicationId, connectionIds, hasDomainServicesConnections) {
    try {
        if (hasDomainServicesConnections) {
            const workspaceApplication = await workspacesApplicationsApi.getWorkspaceApplicationConnectionUsageVerificaton(workspaceId, applicationId, connectionIds);
            return mapWorkspaceApplicationConnectionUsageVerificatonFrontend(workspaceApplication.data);
        }
        else {
            let results = {};
            results.workspaceApplication = {
                usage_across_applications: false,
                usage_across_workspaces: false
            }
            return results;
        }
    }
    catch (err) {
        if (err.response !== undefined) {
            return mapToEmptyWorkspaceApplicationFrontend(err.response.status.toString());
        }
        return mapToEmptyWorkspaceApplicationFrontend("500");
    }
}

export async function getWorkspaceApplicationHeaderByIdApi(workspaceId, applicationId) {
    const workspaceApps = await workspacesApplicationsApi.getWorkspaceApplicationById(workspaceId, applicationId, false);
    const application = await applicationsApi.getApplicationById(applicationId);

    let results = mapToWorkspaceApplicationHeaderFrontend(workspaceApps.data, application.data);

    return results;
}

export async function getWorkspaceApplicationListApi(workspaceId, isAdmin) {
    if (isAdmin) {
        return await getWorkspaceApplicationListForAdmin(workspaceId);
    }
    else {
        return await getWorkspaceApplicationListForWorkspaceOwner(workspaceId);
    }
}

export async function getWorkspaceApplicationTokenApi(workspaceId, applicationId, userId) {
    let params = {
        workspaceId,
        applicationId,
        userId
    }
    let results = {
        appToken: ""
    };

    const token = await workspacesApplicationsApi.getWorkspaceApplicationToken(params);
    if (token !== undefined) {
        results.appToken = token.data;
    }

    return results;
}

async function getWorkspaceApplicationListForAdmin(workspaceId) {
    const appList = await applicationsApi.getAvailableApplicationList(true);
    const workspaceAppList = await workspacesApplicationsApi.getWorkspaceApplicationList(workspaceId, true);

    let apps = [];
    for (let i in workspaceAppList.data) {
        let a = workspaceAppList.data[i];
        let application = {
            name: a.name,
            title: a.title,
            logo: a.logo,
            keywords: (a.keywords === undefined) ? "" : a.keywords,
            category: a.category
        };
        let app = {
            application: application,
            applicationId: a.applicationId,
            index: a.index,
            workspaceApplicationEnabled: a.enabled,
            applicationEnabled: true,
            assigned: true
        };
        apps.push(app);
    }

    for (let i in appList.data.results) {
        let a = appList.data.results[i];
        let inApps = false;

        for (let j in apps) {
            let aps = apps[j];
            if (aps.applicationId === a.id) {
                inApps = true;
                break;
            }
        }

        if (!inApps) {
            let application = {
                logo: a.logo,
                name: a.name,
                title: a.title,
                keywords: (a.keywords === undefined) ? "" : a.keywords,
                category: a.category
            };
            let app = {
                application: application,
                applicationId: a.id,
                workspaceApplicationEnabled: false,
                applicationEnabled: a.enabled,
                assigned: false
            };
            apps.push(app);
        }
    }
    return mapToWorkspacesApplicationsFrontend(workspaceId, apps);
}

async function getWorkspaceApplicationListForWorkspaceOwner(workspaceId) {
    const workspaceAppList = await workspacesApplicationsApi.getWorkspaceApplicationList(workspaceId, true);

    let apps = [];
    for (let i in workspaceAppList.data) {
        let a = workspaceAppList.data[i];
        let application = {
            name: a.name,
            title: a.title,
            logo: a.logo,
            keywords: a.keywords,
            category: a.category
        };
        let app = {
            application: application,
            applicationId: a.applicationId,
            index: a.index,
            workspaceApplicationEnabled: a.enabled,
            applicationEnabled: true,
            assigned: true
        };
        apps.push(app);
    }

    return mapToWorkspacesApplicationsFrontend(workspaceId, apps);
}

export async function getWorkspaceAvailableApplicationsApi(workspaceId) {
    const results = await workspacesApplicationsApi.getWorkspaceApplicationList(workspaceId, true);
    return await mapToWorkspaceAvailableApplicationsFrontend(workspaceId, results.data);
}

export async function getWorkspaceLandingApplicationListApi(workspaceId, snapshotWidth, snapshotHeight, categories) {
    const results = await workspacesApplicationsApi.getWorkspaceLandingApplicationList(workspaceId, false, true, snapshotWidth, snapshotHeight);
    return await mapToWorkspaceLandingApplicationListFrontend(workspaceId, results.data, categories);
}

export async function getWorkspaceApplicationSnapshotApi(workspaceId, applicationId, width, height, snapshotType) {
    let snapshotImage = "";

    if (snapshotType === 1) {
        snapshotImage = await applicationsApi.getApplicationSnapshot(applicationId, width, height);
    }
    else if (snapshotType === 2) {
        snapshotImage = await workspacesApplicationsApi.getWorkspaceApplicationSnapshot(workspaceId, applicationId, width, height);
    }

    return snapshotImage
}

export async function getWorkspacesApplicationsSettingsApi(workspaceId, applicationId) {
    try {
        const results = await workspacesApplicationsApi.getWorkspacesApplicationsSettings(workspaceId, applicationId);
        return mapToWorkspacesApplicationsSettingsFrontend(results.data);
    }
    catch (err) {
        if (err.response.status === 404) {
            return mapToEmptyWorkspacesApplicationsSettingsFrontend();
        }
    }
}

export async function getWorkspacesApplicationsUsersSettingsApi(workspaceId, applicationId, userId) {
    try {
        const userSetting = await workspacesApplicationsApi.getWorkspacesApplicationsUsersSettings(workspaceId, applicationId, userId);
        return userSetting;
    }
    catch (err) {
        if (err.response.status === 404) {
            return mapToEmptyWorkspacesApplicationsSettingsFrontend();
        }
    }
}

export async function updateWorkspaceApplicationApi(workspaceApplication, bypassCheckUrl) {
    let data = {};
    let id = '';
    let error_messages = '';

    let mappingWorkspaceApplication = mapToWorkspaceApplicationBackendForUpdate(workspaceApplication);
    const results = await workspacesApplicationsApi.updateWorkspaceApplication(workspaceApplication.workspace_id, workspaceApplication.application_id, mappingWorkspaceApplication);

        if (results.status === 200) {
            if (results.data !== undefined) {
                let workspacesApplicationConnectionsMapping = mapToWorkspacesApplicationConnectionsBackendForUpdate(workspaceApplication.connections);

                await workspacesApplicationsApi.updateWorkspaceApplicationConnections(workspaceApplication.workspace_id, workspaceApplication.application_id, workspacesApplicationConnectionsMapping);
                if (workspaceApplication.is_logo_changed) {
                    await workspacesApplicationsApi.updateWorkspaceApplicationLogo(workspaceApplication.workspace_id, workspaceApplication.application_id, workspaceApplication.url_image);
                }
                if (workspaceApplication.is_snapshot_changed) {
                    await workspacesApplicationsApi.updateWorkspaceApplicationSnapshot(workspaceApplication.workspace_id, workspaceApplication.application_id, workspaceApplication.snapshot_custom_url_image);
                }
            }
            id = results.data
        }
        else if (results.status === 500) {
            error_messages = results.data;
        }
        else {
            let i = 0;
            for (const value of Object.entries(results.data)) {
                if (i === 0) {
                    error_messages = value[1];
                }
                i += 1;
            }
        }
        data = {
            id: id,
            status: results.status,
            error_messages: error_messages
        }

    return data;
}

export async function updateWorkspaceApplicationCategoryApi(workspaceId, applicationId, categoryId) {
    let results = await workspacesApplicationsApi.updateWorkspacesApplicationCategory(workspaceId, applicationId, categoryId);
    return results;
}

export async function updateWorkspaceApplicationStatusApi(workspaceId, applicationId, status, isFromList) {
    let results = {};
    let data = {};
    switch (status) {
        case 'Enabled':
            results = await workspacesApplicationsApi.updateWorkspacesApplicationsStatus(workspaceId, applicationId, true);
            break;
        case 'Disabled':
            results = await workspacesApplicationsApi.updateWorkspacesApplicationsStatus(workspaceId, applicationId, false);
            break;
        case 'enable':
            results = await workspacesApplicationsApi.updateWorkspacesApplicationsStatus(workspaceId, applicationId, true);
            break;
        case 'disable':
            results = await workspacesApplicationsApi.updateWorkspacesApplicationsStatus(workspaceId, applicationId, false);
            break;
        case 'link':
            results = await workspacesApplicationsApi.linkWorkspacesApplications(workspaceId, applicationId);
            break;
        case 'unlink':
            results = await workspacesApplicationsApi.removeLinkWorkspacesApplications(workspaceId, applicationId);
            break;
        default:
            break;
    }

    if (results !== undefined && !isFromList) {
        data = await workspacesApplicationsApi.getWorkspaceAvailableApplications(workspaceId);
    }

    return data;
}

export async function updateOrderApplicationApi(workspaceId, apps) {
    const res = await workspacesApplicationsApi.updateOrderWorkspacesApplications(workspaceId, apps);
    return res;
}

export async function updateWorkspacesApplicationsSettingsApi(workspaceId, applicationId, settings) {
    let data = {};
    const mappingSettings = mapToWorkspacesApplicationsSettingsBackendForUpdate(settings);
    const results = await workspacesApplicationsApi.updateWorkspacesApplicationsSettings(workspaceId, applicationId, mappingSettings);

    if (results.data !== undefined) {
        data = {
            workspace_id: workspaceId,
            application_id: applicationId,
            status: results.status,
            message: results.data
        };
    }

    return data;
}

export async function updateWorkspacesApplicationsUsersSettingsApi(workspaceId, applicationId, userId, settings) {
    let data = {};
    const mappingSettings = mapToWorkspacesApplicationsUsersSettingsBackendForUpdate(settings);
    const results = await workspacesApplicationsApi.updateWorkspacesApplicationsUsersSettings(workspaceId, applicationId, userId, mappingSettings);
    if (results.data !== undefined) {
        data = {
            workspace_id: workspaceId,
            application_id: applicationId,
            status: results.status,
            message: results.data
        };
    }

    return data;
}

function mapToWorkspaceApplicationsFrontend(workspaceApplicationData, applicationData) {
    let results = {};

    let w = workspaceApplicationData;
    let a = applicationData;

    let application = {
        application_id: w.applicationId,
        workspace_id: w.workspaceId,
        application_name: w.name,
        application_title: w.title,
        url: w.url,
        status: (w.enabled) ? "Enabled" : "Disabled",
        keywords: w.keywords,
        allow_public_access: w.publicAccess,
        global_status: (a.enabled) ? "Enabled" : "Disabled"
    };

    results.workspace_application_landing = {
        application
    }

    if (workspaceApplicationData === undefined) {
        results.error = "Workspace application is not found.";
    }

    return results;
}

function mapWorkspaceApplicationIdFrontend(workspaceApplicationData, workspaceApplicationName) {
    let results = {};

    let w = workspaceApplicationData;

    results.workspaceApplication = {
        id: w.applicationId,
        name: workspaceApplicationName
    }

    if (workspaceApplicationData === undefined) {
        results.error = "Workspace Application id is not found.";
    }

    return results;
}

function mapWorkspaceApplicationConnectionUsageVerificatonFrontend(workspaceApplicationData) {
    let results = {};

    let w = workspaceApplicationData;

    results.workspaceApplication = {
        usage_across_applications: w.internalUsage,
        usage_across_workspaces: w.externalUsage
    }

    return results;
}

function mapToEmptyWorkspaceApplicationFrontend(errorStatus) {
    let results = {};

    results.error = errorStatus;

    return results;
}

async function mapToWorkspaceApplicationFrontend(data, wsdata, wssetlogo, wssettheme) {
    let w = data;
    let workspaceApplication = {};

    if (w !== undefined) {
        w.logo = await workspacesApplicationsApi.getWorkspaceApplicationLogo(w.workspaceId, w.applicationId, 305, 237);
        let imageProps = (w.logo !== undefined && w.logo !== null) ? getImageSize(w.logo) : {
            'width': 1200,
            'height': 900
        };

        workspaceApplication = {
            application_id: w.applicationId,
            workspace_id: w.workspaceId,
            workspace_name: wsdata.name,
            workspace_logo: (wssetlogo.id === undefined) ? '' :
                (wssetlogo.id !== undefined && wssetlogo.image === undefined) ? '' :
                    (wssetlogo.id !== undefined && wssetlogo.image !== undefined) ? wssetlogo.image : getDefaultLogo(),
            workspace_theme: wssettheme.themeSelection,
            url_image: w.logo,
            image_width: imageProps.width,
            image_height: imageProps.height,
            image_aspect_ratio: imageProps.width / imageProps.height,
            application_name: w.name,
            application_title: w.title,
            application_desc: w.description,
            url: w.url,
            status: (w.enabled) ? "Enabled" : "Disabled",
            snapshot_type: (w.snapshotType.toLowerCase() === "nosnapshot") ? 0 : (w.snapshotType.toLowerCase() === "default") ? 1 : 2,
            keywords: w.keywords,
            is_new: true,
            allow_public_access: w.publicAccess,
            application_desc_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Application.Description').map(x => x.locked)[0] : true,
            url_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Application.Url').map(x => x.locked)[0] : true,
            application_name_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Application.Name').map(x => x.locked)[0] : true,
            application_title_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Application.Title').map(x => x.locked)[0] : true,
            logo_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Application.Logo').map(x => x.locked)[0] : true,
            snapshot_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Application.Snapshot').map(x => x.locked)[0] : true,
            keywords_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Application.Tags').map(x => x.locked)[0] : true,
            connections_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Application.Connections').map(x => x.locked)[0] : true,
            allow_public_access_locked: (w.lockProperties !== undefined) ? w.lockProperties.filter(x => x.propertyName === 'Application.AllowPublicAccess').map(x => x.locked)[0] : true,
            yml_configuration: w.ymlConfiguration,
        };
        workspaceApplication.snapshot_custom_url_image = await workspacesApplicationsApi.getWorkspaceApplicationSnapshot(w.workspaceId, w.applicationId, 281, 129);
        workspaceApplication.snapshot_default_url_image = await applicationsApi.getApplicationSnapshot(w.applicationId, 281, 129);
    }
    return workspaceApplication;
}

function mapToWorkspacesApplicationConnectionsFrontend(connectionData, workspaceData) {
    let connections = [];
    for (let i in connectionData) {
        let a = connectionData[i];
        let connectionStringLocked = workspaceData.lockProperties.filter((x) => x.propertyName === 'Application.Connections.' + a.id).map(x => x.locked)[0];

        let connection = {
            id: a.id,
            focus_area_name: a.focusAreaName,
            connection_string: (a.connectionstring !== undefined) ? a.connectionstring : "",
            shortConnectionString: a.shortConnectionString,
            locked: (connectionStringLocked !== undefined) ? connectionStringLocked : false,
            edit: false,
        }
        connections.push(connection);
    }

    return connections;
}

function mapToEmptyWorkspacesApplicationConnectionsFrontend() {
    let connections = [];
    return connections;
}

async function mapToWorkspaceApplicationHeaderFrontend(wsAppData, appData) {
    let w = wsAppData;
    let a = appData;
    let workspaceApplication = {};

    if (w !== undefined) {
        w.logo = await workspacesApplicationsApi.getWorkspaceApplicationLogo(w.workspaceId, w.applicationId, 40, 40);
        workspaceApplication = {
            application_id: w.applicationId,
            workspace_id: w.workspaceId,
            application_name: w.name,
            application_image: w.logo,
            application_title: w.title,
            url: w.url,
            status: (w.enabled) ? "Enabled" : "Disabled",
            keywords: w.keywords,
            is_new: true,
            allow_public_access: w.publicAccess,
            global_status: (a.enabled) ? "Enabled" : "Disabled"
        };
    }
    return workspaceApplication;
}

function mapToWorkspacesApplicationsFrontend(workspaceId, data) {
    let results = {};
    let applications = [];
    let applicationsLinked = [];
    let applicationsNotLinked = [];

    for (let i in data) {
        let app = data[i];
        let application = {
            id: app.applicationId,
            index: app.index,
            workspace_id: workspaceId,
            url_image: (app.application.logo === undefined) ? getDefaultImage() : app.application.logo,
            application_name: app.application.name,
            application_title: app.application.title,
            keywords: app.application.keywords,
            category_title: app.application.category,
            is_enabled: app.workspaceApplicationEnabled,
            is_enabled_global: app.applicationEnabled,
            is_linked: app.assigned
        };
        if (app.assigned) {
            applicationsLinked.push(application);
        } else {
            applicationsNotLinked.push(application);
        }
    }

    sort(applicationsLinked).asc(['index', 'application_name']);
    sort(applicationsNotLinked).asc(['application_name']);
    applications = applicationsLinked.concat(applicationsNotLinked);
    results.applications = applications;

    return results;
}

function mapToWorkspaceApplicationBackendForUpdate(data) {
    const setting = [{
        propertyName: 'Application.Name',
        locked: data.application_name_locked
    },
    {
        propertyName: 'Application.Description',
        locked: data.application_desc_locked
    },
    {
        propertyName: 'Application.Title',
        locked: data.application_title_locked
    },
    {
        propertyName: 'Application.Logo',
        locked: data.logo_locked
    },
    {
        propertyName: 'Application.Url',
        locked: data.url_locked
    },
    {
        propertyName: 'Application.Snapshot',
        locked: data.snapshot_locked
    },
    {
        propertyName: 'Application.Tags',
        locked: data.keywords_locked
    },
    {
        propertyName: 'Application.Connections',
        locked: data.connections_locked
    },
    {
        propertyName: 'Application.AllowPublicAccess',
        locked: data.allow_public_access_locked
    }];

    let connectionsData = data.connections;
    for (let i in connectionsData) {
        let a = connectionsData[i];
        let connection = {
            propertyName: 'Application.Connections.' + a.id,
            locked: a.locked
        }
        setting.push(connection);
    }

    let application = {};

    application.url = data.url;
    application.allowPublicAccess = data.allow_public_access;
    application.description = data.application_desc;
    application.name = data.application_name;
    application.title = data.application_title;
    application.snapshotType = (data.snapshot_type === 0) ? "NoSnapshot" : (data.snapshot_type === 1) ? "Default" : "UserDefined";
    application.keywords = data.keywords;
    application.lockProperties = setting;
    application.ymlConfiguration = data.yml_configuration;

    return application;
}

function mapToWorkspacesApplicationConnectionsBackendForUpdate(data) {
    let connections = [];

    for (let i in data) {
        let a = data[i];
        let connection = {
            focusAreaId: a.id,
            connectionString: a.connection_string
        };
        connections.push(connection);
    }
    return connections;
}

function mapToWorkspaceAvailableApplicationsFrontend(workspaceId, apps) {
    let availableApplications = {};
    let applications = [];

    for (let i in apps) {
        let app = apps[i];
        let imageProps = (app.logo !== undefined && app.logo !== null) ? getImageSize(app.logo) : {
            'width': 1200,
            'height': 900
        };

        let application = {
            id: app.applicationId,
            workspace_id: workspaceId,
            index: app.index,
            url_image: (app.logo === undefined) ? getThumbnailDefaultImage() : app.logo,
            image_width: imageProps.width,
            image_height: imageProps.height,
            image_aspect_ratio: imageProps.width / imageProps.height,
            application_name: app.name,
            application_title: app.title,
            application_description: normalizeTextDescription(app.description),
            application_url: app.url,
            status: (app.enabled) ? "Enabled" : "Disabled",
            snapshot_type: (app.snapshotType.toLowerCase() === "nosnapshot") ? 0 : (app.snapshotType.toLowerCase() === "default") ? 1 : 2,
            keywords: app.keywords,
            is_new: true,
            allow_public_access: app.publicAccess
        }
        application.snapshot_url_image = (app.snapshot !== undefined) ? app.snapshot : "";
        applications.push(application);
    }
    sort(applications).asc(['index', 'application_name']);

    availableApplications.id = workspaceId;
    availableApplications.applications = applications;

    return availableApplications;
}

function mapToWorkspaceLandingApplicationListFrontend(workspaceId, apps, categories) {
    let availableApplications = {};
    let applications = [];

    for (let i in apps) {
        let app = apps[i];
        let imageProps = (app.logo !== undefined && app.logo !== null) ? getImageSize(app.logo) : {
            'width': 1200,
            'height': 900
        };

        let category = {};
        let categ = categories.filter((item) => item.category_title === app.category);
        if (categ.length > 0) {
            category.category_id = categ[0].category_id;
            category.category_title = categ[0].category_title;
            category.category_description = categ[0].category_description;
        }
        else {
            category.category_id = "";
            category.category_title = "";
            category.category_description = "";
        }

        let application = {
            id: app.applicationId,
            workspace_id: workspaceId,
            category_id: category.category_id,
            category_title: category.category_title,
            category_description: normalizeTextDescription(category.category_description),
            index: app.index,
            url_image: (app.logo === undefined) ? getThumbnailDefaultImage() : app.logo,
            image_width: imageProps.width,
            image_height: imageProps.height,
            image_aspect_ratio: imageProps.width / imageProps.height,
            application_name: app.name,
            application_title: app.title,
            application_description: normalizeTextDescription(app.description),
            application_url: app.url,
            status: (app.enabled) ? "Enabled" : "Disabled",
            snapshot_type: (app.snapshotType.toLowerCase() === "nosnapshot") ? 0 : (app.snapshotType.toLowerCase() === "default") ? 1 : 2,
            keywords: app.keywords,
            is_new: true,
            allow_public_access: app.publicAccess
        }
        application.snapshot_url_image = (app.snapshot !== undefined) ? app.snapshot : "";
        applications.push(application);
    }
    sort(applications).asc(['index', 'application_name']);

    availableApplications.id = workspaceId;
    availableApplications.applications = applications;

    return availableApplications;
}

function mapToWorkspacesApplicationsSettingsFrontend(data) {
    let results = {};
    let settings = [];

    let index = 0;
    for (let i in data) {
        let s = data[i];
        let setting = {
            id: s.id,
            group_name: s.groupName,
            title: s.title,
            component_type: s.componentType,
            component_type_value: s.componentTypeValue,
            component_property: (s.componentProperty !== undefined) ? s.componentProperty : null,
            value: s.value,
            locked: s.locked,
            index
        }
        settings.push(setting);
        index += 1;
    }

    sort(settings).asc(['group_name', 'index']);
    results.settings = settings;

    return results;
}

function mapToEmptyWorkspacesApplicationsSettingsFrontend() {
    let results = {};
    results.settings = [];

    return results;
}

function mapToWorkspacesApplicationsSettingsBackendForUpdate(settings) {
    let results = [];

    for (let i in settings) {
        let s = settings[i];
        let setting = {
            id: s.id,
            value: s.value,
            locked: s.locked
        }
        results.push(setting);
    }

    return results;
}


function mapToWorkspacesApplicationsUsersSettingsBackendForUpdate(settings) {
    let results = [];

    for (let i in settings) {
        let s = settings[i];
        let setting = {
            settingId: s.id,
            settingValue: s.value
        }
        results.push(setting);
    }

    return results;
}