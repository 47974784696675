import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { routeCodes } from '../../../../configurations/routes';
import { updateWorkspaceApplicationAction } from '../../../../actions/updateWorkspaceApplicationAction';
import { getCookies } from '../../../../backend/api/getCookies';

class WorkspacesApplicationsDetailHeader extends Component {
    constructor(props) {
        super(props);
        this.onWorkspaceApplicationNameChange = this.onWorkspaceApplicationNameChange.bind(this);
        this.state = {
            isChecked: props.workspaceApplication.application_name_locked
        };
        this.handleChange = this.handleChange.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        workspaceApplication: PropTypes.object.isRequired,
        dispatchWorkspaceApplication: PropTypes.func.isRequired,
        role: PropTypes.object.isRequired,
    };

    onWorkspaceApplicationNameChange(e) {
        let workspaceApplication = {
            ...this.props.workspaceApplication,
            temp_application_name: e.target.value
        };

        this.props.dispatchWorkspaceApplication(workspaceApplication);
    }

    handleChange() {
        if (this.state.isChecked === true) {
            let workspaceApplication = {
                ...this.props.workspaceApplication,
                application_name_locked: false
            };
            this.props.dispatchWorkspaceApplication(workspaceApplication);
        }
        else {
            let workspaceApplication = {
                ...this.props.workspaceApplication,
                application_name_locked: true
            };
            this.props.dispatchWorkspaceApplication(workspaceApplication);
        }
        this.setState({ isChecked: !this.state.isChecked });
    }

    render() {
        const { workspace, workspaceApplication, role } = this.props;

        return (
            <div className="mainHead">
                <div className="row">
                    <div className="col-12">
                        <h2 className="float-left mt-2 mr-4">Application</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className={(role.user_role === 'Administrator') ? 'appInfo admin' : 'appInfo'} >
                            <div className="row">
                                <div className="appName col-xl-9 offset-xl-3">
                                    <div className="formWrap">
                                        <div className={(role.user_role === 'Administrator') ? 'groupForm m-0' : null}>
                                            {
                                                (role.user_role === 'Administrator') ?
                                                    <label className="btn iconBtn customLockCheckbox">
                                                        <input type="checkbox" className="lockInput" onChange={this.handleChange} checked={!this.state.isChecked} />
                                                        <span className="icon-wrd-unlock"></span>
                                                        <span className="icon-wrd-lock"></span>
                                                    </label>
                                                    : null
                                            }
                                            <div className="inputForm wsNameEdit">
                                                <input style={{ width: '100%' }} className="form-control appNameInput wsAppNameEditWider" type="text" name="" required defaultValue={(workspaceApplication.temp_application_name !== undefined || workspaceApplication.temp_application_name !== undefined) ? workspaceApplication.temp_application_name : workspaceApplication.application_name} onChange={this.onWorkspaceApplicationNameChange} disabled={role.user_role !== 'Administrator' && this.state.isChecked} />
                                                <label>Name</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="currentWsText col-xl-7 offset-xl-3">{workspace.workspace_name}</div>
                            </div>
                            <nav className="col-lg-6 col-xl-5">
                                <div className="row text-center">
                                    <div className="col px-0">
                                        <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/applications/' + workspaceApplication.application_name.toLowerCase().replaceAll(" ", "+") + "/properties"} title="Workspace Application Detail" className="workspaceMenu py-3 active">Details</Link>
                                    </div>
                                    <div className="col px-0">
                                        <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/applications/' + workspaceApplication.application_name.toLowerCase().replaceAll(" ", "+") + '/settings'} title="Workspace Application Settings" className="workspaceMenu py-3">Settings</Link>
                                    </div>
                                    {!getCookies.isMikeMine && (
                                    <div className="col px-0">
                                        <Link to={routeCodes.WORKSPACES_LIST + '/' + workspace.workspace_name.toLowerCase().replaceAll(" ", "+") + '/applications/' + workspaceApplication.application_name.toLowerCase().replaceAll(" ", "+") + '/settings?tab=yml'} title="Workspace Application YML Settings" className="workspaceMenu py-3">YML Settings</Link>
                                    </div>)}
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, role }) => ({
    workspace: detail.workspace,
    workspaceApplication: detail.workspace_application,
    role
});

const mapDispatchToProps = (dispatch) => ({
    dispatchWorkspaceApplication: (workspaceApplication) => dispatch(updateWorkspaceApplicationAction(workspaceApplication))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsDetailHeader));
