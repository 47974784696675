import {
    getUserWorkspacePropertiesByIdApi
} from '../backend/domain/workspaces';

import {
    getWorkspaceThemeSettingImplementationByIdApi,
    getWorkspaceThemeSettingByIdApi,
    updateWorkspaceThemeSettingApi
} from '../backend/domain/workspacesSettings';

import { getWorkspaceAction } from '../actions/getWorkspaceAction';
import { getWorkspaceThemeSettingAction } from '../actions/getWorkspaceThemeSettingAction';
import { loadingThemeAction } from '../actions/loadingThemeAction';
import { updateWorkspaceThemeSettingAction } from '../actions/updateWorkspaceThemeSettingAction';
import { loadingWorkspaceSettingAction } from '../actions/loadingWorkspaceSettingAction';
import { getApplicationsLandingThemeAction } from '../actions/getWorkspaceApplicationLandingAction';
import { handleWorkspaceSettingsUpdateError } from '../utils/errorMessages';
import { redirectToPage } from '../utils/pageRedirectionRules';
import { loadingPageRedirectionAction } from '../actions/loadingPageRedirectionAction';
import { overrideWorkspaceId } from '../utils/workspaces';
import { store } from '../index';

export const getWorkspaceThemeSettingByIdWithThunk = (id, userId, isAdmin, width, height, historyProps) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(id);
        dispatch(loadingWorkspaceSettingAction(true));
        getUserWorkspacePropertiesByIdApi(wId, userId, isAdmin, historyProps, "theme")
            .then((response) => {
                if (!response.error) {
                    dispatch(getWorkspaceAction(response));
                }
                return response;
            })
            .then(() => {
                getWorkspaceThemeSettingByIdApi(wId, width, height)
                    .then(response => {
                        if (!response.error) {
                            dispatch(getWorkspaceThemeSettingAction(response));
                        }
                        return response;
                    })
                    .then((response) => {
                        dispatch(loadingWorkspaceSettingAction(false));
                        return response;
                    });
            });
    };

export const getWorkspaceThemeSettingWithThunk = (id, width, height) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(id);
        dispatch(loadingWorkspaceSettingAction(true));
        getWorkspaceThemeSettingByIdApi(wId, width, height)
            .then(response => {
                if (!response.error) {
                    dispatch(getWorkspaceThemeSettingAction(response));
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspaceSettingAction(false));
                return response;
            });
    };

export const getWorkspaceThemeSettingImplementationWithThunk = async (id, history, url) => {
    if (history !== undefined) {
        store.dispatch(loadingPageRedirectionAction(true));
        store.dispatch(loadingThemeAction(true));
        let wId = await overrideWorkspaceId(id);
        getWorkspaceThemeSettingImplementationByIdApi(wId)
            .then((response) => {
                if (response.data !== null && response.data !== "") {
                    document.getElementById('PortalTheme').href = 'data:text/css;base64,' + response.data;
                }
                else {
                    document.getElementById('PortalTheme').href = process.env.PUBLIC_URL + "/portal/css/white-theme.css";
                }
            })
            .then(() => {
                store.dispatch(loadingPageRedirectionAction(false));
                store.dispatch(loadingThemeAction(false));
                if (history !== undefined) {
                    history.push(url);
                }
            });
    }
    else {
        store.dispatch(loadingThemeAction(true));
        store.dispatch(loadingThemeAction(true));
        let wId = await overrideWorkspaceId(id);
        getWorkspaceThemeSettingImplementationByIdApi(wId)
            .then((response) => {
                if (response.data !== null && response.data !== "") {
                    store.dispatch(getApplicationsLandingThemeAction('white'))
                    document.getElementById('PortalTheme').href = 'data:text/css;base64,' + response.data;
                }
                else {
                    store.dispatch(getApplicationsLandingThemeAction('default'))
                    document.getElementById('PortalTheme').href = process.env.PUBLIC_URL + "/portal/css/white-theme.css";
                }
            })
            .then(() => {
                store.dispatch(loadingThemeAction(false));
                store.dispatch(loadingThemeAction(false));
            });
    }
}

export const getUserInfoWorkspaceThemeSettingImplementationWithThunk = async (id, history, url, location, redirectUrl) => {
    store.dispatch(loadingPageRedirectionAction(true));
    store.dispatch(loadingThemeAction(true));
    let wId = await overrideWorkspaceId(id);
    getWorkspaceThemeSettingImplementationByIdApi(wId)
        .then((response) => {
            if (response.data !== null && response.data !== "") {
                document.getElementById('PortalTheme').href = 'data:text/css;base64,' + response.data;
            }
            else {
                document.getElementById('PortalTheme').href = process.env.PUBLIC_URL + "/portal/css/white-theme.css";
            }
        })
        .then(() => {
            store.dispatch(loadingPageRedirectionAction(false));
            store.dispatch(loadingThemeAction(false));
            if (history !== undefined) {
                history.push(url);
            }
            redirectToPage(location, redirectUrl);
        });
}

export const updateWorkspaceThemeSettingWithThunk = (workspaceSetting) =>
    (dispatch) => {
        dispatch(loadingWorkspaceSettingAction(true));
        updateWorkspaceThemeSettingApi(workspaceSetting)
            .then((response) => {
                handleWorkspaceSettingsUpdateError(dispatch, response.status, response.error_messages);
                return response;
            })
            .then((response) => {
                if (response.status === 200) {
                    dispatch(updateWorkspaceThemeSettingAction(workspaceSetting));
                    getWorkspaceThemeSettingImplementationByIdApi(workspaceSetting.id)
                        .then(response => {
                            if (response.data !== null && response.data !== "") {
                                document.getElementById('PortalTheme').href = 'data:text/css;base64,' + response.data;
                            }
                            else {
                                document.getElementById('PortalTheme').href = process.env.PUBLIC_URL + "/portal/css/white-theme.css";
                            }
                            return response;
                        })
                }
                return response;
            })
            .then((response) => {
                dispatch(loadingWorkspaceSettingAction(false));
                return response;
            });
    };
