import {
    GET_WORKSPACE_APPLICATION_LANDING,
    CLEAR_WORKSPACE_APPLICATION_LANDING,
    GET_APPLICATIONS_LANDING,
    GET_APPLICATIONS_LANDING_THEME,
} from '../actions/index';

const initialWorkspaceApplicationLandingState = {
    workspace_application_landing_data: {
        application: {}
    },
    applications: [],
    theme: 'default',
}

export const workspaceApplicationLanding = (state = initialWorkspaceApplicationLandingState, action) => {
    const { workspace_application_landing_data, applications, theme } = action
    switch (action.type) {
        case GET_WORKSPACE_APPLICATION_LANDING:
            return {
                ...state,
                workspace_application_landing_data
            }
        case CLEAR_WORKSPACE_APPLICATION_LANDING:
            const result = {
                ...state,
                workspace_application_landing_data: initialWorkspaceApplicationLandingState.workspace_application_landing_data
            }
            return result;
        case GET_APPLICATIONS_LANDING:
            return {
                ...state,
                applications
            }
        case GET_APPLICATIONS_LANDING_THEME:
            return {
                ...state,
                theme
            }
        default:
            return state;
    }
};