import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class UserCreateHeader extends Component {
    static propTypes = {
        user: PropTypes.object.isRequired
    };

    render() {
        return (
            <div className="mainHead">
                <div className="row">
                    <div className="col-12">
                        <h2 className="float-left mt-2 mr-4">User Creation</h2>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ setup }) => ({
    user: setup.user
});

export default withRouter(connect(mapStateToProps)(UserCreateHeader));
