import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { userLogout } from '../../../api/logoutThunk';
import { getCookies, handleCookieReset } from 'apps/portal/src/backend/api/getCookies';
import { stringify } from 'querystring';
import { getApplicationUrls } from '@wrd/helpers';
class UsersLogoutContainer extends Component {
    static propTypes = {
        role: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired,
    };

    async componentDidMount() {
      const { auth, portal } = getApplicationUrls();

      await handleCookieReset();
      const queryParameters = stringify({
        ...(!!getCookies?.userMikeCloud  && { method: 'signout' }),
        returnUrl: portal,
      });

      const href = `${auth}${!!!!getCookies?.userMikeCloud  ? '/openid' : ''}`;

      window.location.href = `${href}?${queryParameters}`;
  }

    render() {
        return (null);
    }
}

const mapStateToProps = ({ role }) => ({
    role
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(userLogout())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersLogoutContainer));
