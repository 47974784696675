import { getWorkspaceApplicationLandingAction } from '../actions/getWorkspaceApplicationLandingAction';
import { getWorkspaceSettingAction } from '../actions/getWorkspaceSettingAction';
import { getClientIpAction } from '../actions/getClientIpAction';
import { getWorkspaceApplicationTokenApi } from '../backend/domain/workspacesApplications';
import { getWorkspaceSettingIncludeByIdApi } from '../backend/domain/workspacesSettings';
import { getClientIp } from '../api/ipAddressApi';

import { store } from '../index';

export const updateWorkspaceApplicationLanding = async (response, userId, getToken) => {
    let appToken = "";
    if (getToken) {
        const workspaceId = response.application.workspace_id;
        const applicationId = response.application.application_id;

        let token = await getWorkspaceApplicationTokenApi(workspaceId, applicationId, userId);
        appToken = token.appToken;
    }

    let workspace_application_landing_data = {
        ...response,
        application: {
            ...response.application,
            application_token: appToken,
        }
    };

    store.dispatch(getWorkspaceApplicationLandingAction(workspace_application_landing_data));
};

export const updateWorkspaceApplicationLandingState = async (workspaceId) => {
    const workspaceSetting = store.getState().detail.workspace_setting;
    const ipAddress = store.getState().clientIp.ipAddress;

    if (workspaceSetting.id === "") {
        let ws = await getWorkspaceSettingIncludeByIdApi(workspaceId,"WorkspaceName;ShowPortalLogo;GoogleAnalytic;ShowWorkspaceName;ShowWorkspaceLogo;Theme;WorkspaceLogo");
        store.dispatch(getWorkspaceSettingAction(ws));
    }

    if (ipAddress === "") {
        let ip = await getClientIp();
        store.dispatch(getClientIpAction(ip));
    }
};
