import Toolbar from '@mui/material/Toolbar';
import { StyledRow } from '@wrd/utils/style';
import { css } from '@emotion/css';
import {
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { styled } from '@wrd/utils/style';
import { ListAppItem, SharedDropdownList } from '@wrd/shared/dropdown-list';
import React from 'react';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import HomeIcon from '@mui/icons-material/Home';
// import '@fontsource/roboto';
export interface ListNavItem {
  label: string;
  handleClickNavItem?(d: string): void;
}
export interface SharedHeaderAppProps {
  url: string;
  showPortalLogo?: boolean;
  workspaceName?: string;
  workspaceEdit?: boolean;
  showWorkspaceLogo?: boolean;
  workspaceImage?: string;
  handleWorkspacesClick?(e: any): void;
  applicationName?: string;
  login?: boolean;
  showAvilableWorkspaces?: boolean;
  handleOpenWorkspaces?(e: any): void;
  handleEditWorkspaces?(e: any): void;
  userImage?: string;
  userInitial?: string;
  listNav?: ListNavItem[];
  handleOpenLoginPage?(e: any): void;
  listApps?: ListAppItem[];
  closeDropdown?: null | HTMLElement;
  setCloseDropdown: (HTMLElement: HTMLElement | null) => void;
}

export function SharedHeaderApp({
  showPortalLogo = false,
  login = false,
  url,
  workspaceName,
  workspaceEdit,
  showWorkspaceLogo,
  workspaceImage,
  handleWorkspacesClick,
  applicationName,
  handleOpenWorkspaces,
  handleEditWorkspaces,
  showAvilableWorkspaces = true,
  userImage,
  userInitial,
  listNav,
  listApps,
  handleOpenLoginPage,
  closeDropdown,
  setCloseDropdown
}: SharedHeaderAppProps) {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  return (
    // <Toolbar
    //   className={css`
    //   margin:0px;
    //   padding:0px;
    //   background-color:#F2F5F7;
    //   `}
    // >
    <StyledRow
      className={css`
        margin: 0px;
        margin-bottom: 3px;
        padding: 0;
        background-color: #f2f5f7;
        min-height: 60px;
        // box-shadow: 1px 1px 2px #0006;
        box-shadow: 2px 4px 8px #95afc0;
      `}
    >
      {/* {showPortalLogo &&
        <img style={{ cursor: 'pointer' }} onClick={() => window.open("http://www.dhigroup.com", "_blank")} src={url + "/images/DHI_Logo_small.png"} title="www.dhigroup.com" alt="" />
      } */}

      <StyledHomeButton onClick={handleWorkspacesClick}>
        <HomeIcon />
      </StyledHomeButton>
      {/* {showWorkspaceLogo && (
        <img
          style={{ margin: '4px', width: '50px' }}
          src={'data:image/png;base64,' + workspaceImage}
          alt="apps logo"
        />
      )} */}
      { workspaceName && (
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 700,
            textTransform: 'none',
            margin: '0px 5px !important;',
          }}
        >
          {workspaceName}
        </Typography>
      )}
      {applicationName &&  (
        <SharedDropdownList
          applicationName={applicationName}
          listApps={listApps}
          closeDropdown={closeDropdown}
          setCloseDropdown={setCloseDropdown}
        />
      )}
      {showAvilableWorkspaces && login && (
        <StyledNavTop
          className={css`
            margin-left: auto;
          `}
        >
          <StyledIconButton
            color="primary"
            size="large"
            aria-label="available workspaces"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={(e) => {
              handleOpenWorkspaces?.(e);
            }}
          >
            <FactCheckIcon />
          </StyledIconButton>
        </StyledNavTop>
      )}
      {workspaceEdit && showAvilableWorkspaces && (
      <StyledNavTop>
       <StyledIconButton
            color="primary"
            size="large"
            aria-label="available workspaces"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={(e) => {
              handleEditWorkspaces?.(e);
            }}
          ><BorderColorIcon />
      </StyledIconButton>
      </StyledNavTop>
      )}
      {login && (
        <StyledNavTop
          className={css`
            margin-left: ${showAvilableWorkspaces ? '' : 'auto;'};
          `}
        >
          <StyledUserIconBtn
            // showAvilableWorkspaces={showAvilableWorkspaces}
            onClick={handleOpenUserMenu}
            // sx={{
            //   marginLeft: showAvilableWorkspaces ? '' : 'auto',
            // }}
          >
            {userImage ? (
              <Avatar
                alt="Profile Image"
                src={'data:image/png;base64,' + userImage}
                className={css`
                  padding: 10px;
                `}
              />
            ) : (
              userInitial
            )}
          </StyledUserIconBtn>
        </StyledNavTop>
      )}
      {listNav && (
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {listNav &&
            listNav.map((m) => (
              <MenuItem
                key={m.label}
                onClick={() => m.handleClickNavItem?.(m?.label)}
              >
                <Typography textAlign="center">{m.label}</Typography>
              </MenuItem>
            ))}
        </Menu>
      )}
      {!login && (
        <StyledNavTop
          className={css`
            margin-left: auto !important;
          `}
        >
          <StyledIconButton
            onClick={(e) => {
              handleOpenLoginPage?.(e);
            }}
          >
            <PowerSettingsNewIcon />
          </StyledIconButton>
        </StyledNavTop>
      )}
    </StyledRow>

    // </Toolbar>
  );
}

export default SharedHeaderApp;

const StyledHomeButton = styled(Button)`
  height: 100%;
  min-height: 60px;
  border-radius: 0px;
  &:hover {
    background-color: rgb(219, 228, 233);
  }
`;

const StyledButton = styled(Button)`
  margin: 0px;
  padding: 0px;
  text-transform: none;
  // background-color:#F2F5F7;
  &:hover {
    // background-color:#F2F5F7;
  }
`;

const StyledIconButton = styled(IconButton)`
  box-shadow: 0.7px 0.7px 2px #0006;
`;

//  ${({ showAvilableWorkspaces }) => {
//   //   return `
//   //       margin-left:${showAvilableWorkspaces ? null : 'auto'};
//   //   `;
//   // }};

const StyledNavTop = styled.div`
  min-height: 60px;
  align-items: center;
  display: flex;
  padding: 0 15px;
  &:hover {
    background-color: rgb(219, 228, 233);
  }
`;

const StyledUserIconBtn = styled(IconButton)<{
  // showAvilableWorkspaces?: boolean;
}>`
  background-color: #0b4566;
  color: #fff;
  box-shadow: 0.7px 0.7px 2px #0006;
  width: 40px;
  height: 40px;
  font-size: 18px;
  padding: 0px;
  margin: 0px 5px;
  &:hover {
    background-color: #0b4566;
  }
`;
