import ReactGA from 'react-ga';

export const updateGoogleAnalytics = (trackingId, workspaceName, applicationName, userName, ipAddress, location) => {
    ReactGA.initialize(trackingId);

    let loc = '/workspaces' + location.pathname.split('/workspaces')[1];

    let datetime = new Date();
    datetime = datetime.toUTCString()

    ReactGA.set({ page: loc });
    ReactGA.pageview(`Landing page of ${workspaceName} - ${applicationName}`);

    ReactGA.event({
        category: `Workspace application ${workspaceName} - ${applicationName} landing page`,
        action: 'Display workspace application landing page',
        label: `${workspaceName} - ${applicationName} - ${ipAddress} - ${userName} - ${datetime}`
    });
};