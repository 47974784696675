import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import RichTextEditor from '../../../Editor/RichTextEditor';
import ImageEditor from '../../../Editor/ImageEditor';
import WorkspacesApplicationsDetailConnectionModal from '../Modal/WorkspacesApplicationsDetailConnectionModal';
import { routeCodes } from '../../../../configurations/routes';
import { modalImageEditorStyle } from '../../../../utils/modal';
import { updateWorkspaceApplicationAction } from '../../../../actions/updateWorkspaceApplicationAction';
import {
  updateWorkspaceApplicationWithThunk,
  updateWorkspaceApplicationStatusWithThunk,
  updateWorkspaceApplicationConnectionWithThunk,
} from '../../../../api/workspacesApplicationsThunk';
import { updateRoleAction } from '../../../../actions/updateRoleAction';
import { updateDialogWorkspacesApplicationsAction } from '../../../../actions/updateDialogWorkspacesApplicationsAction';
import { showSwitchButton } from '../../../../configurations/app';
import { updateWorkspaceApplicationConnectionsAction } from '../../../../actions/updateWorkspaceApplicationConnectionsAction';
import { updateDialogWorkspacesApplicationsConnectionAction } from '../../../../actions/updateDialogWorkspacesApplicationsConnectionAction';
import { getCookies } from 'apps/portal/src/backend/api/getCookies';

const clone = require('clone');

class WorkspacesApplicationsDetailBody extends Component {
  constructor(props) {
    super(props);
    this.onWorkspaceApplicationTitleChange =
      this.onWorkspaceApplicationTitleChange.bind(this);
    this.onWorkspaceApplicationDescriptionChange =
      this.onWorkspaceApplicationDescriptionChange.bind(this);
    this.updateWorkspaceApplicationDetail =
      this.updateWorkspaceApplicationDetail.bind(this);
    this.updateConnectionState = this.updateConnectionState.bind(this);
    this.onWorkspaceApplicationUrlChange =
      this.onWorkspaceApplicationUrlChange.bind(this);
    this.onWorkspaceApplicationAllowPublicAccessChange =
      this.onWorkspaceApplicationAllowPublicAccessChange.bind(this);
    this.onWorkspaceApplicationUrlSettingsChange =
      this.onWorkspaceApplicationUrlSettingsChange.bind(this);
    this.onWorkspaceApplicationKeywordsChange =
      this.onWorkspaceApplicationKeywordsChange.bind(this);
    this.onSelectFile = this.onSelectFile.bind(this);
    this.onCloseImageEditor = this.onCloseImageEditor.bind(this);
    this.handleNoSnapshotChange = this.handleNoSnapshotChange.bind(this);
    this.handleDefaultSnapshotChange =
      this.handleDefaultSnapshotChange.bind(this);
    this.handleSnapshotChange = this.handleSnapshotChange.bind(this);
    this.state = {
      src: null,
      imageEditorIsOpen: false,
      isCheckedApplicationTitle:
        props.workspaceApplication.application_title_locked,
      isCheckedApplicationDesc:
        props.workspaceApplication.application_desc_locked,
      isCheckedApplicationUrl: props.workspaceApplication.url_locked,
      isCheckedApplicationSnapshot: props.workspaceApplication.snapshot_locked,
      isCheckedAllowPublicAccess:
        props.workspaceApplication.allow_public_access_locked,
      isCheckedApplicationUrlSettings:
        props.workspaceApplication.url_settings_locked,
      isCheckedApplicationKeywords: props.workspaceApplication.keywords_locked,
      isCheckedApplicationConnections:
        props.workspaceApplication.connections_locked,
      isCheckedApplicationLogo: props.workspaceApplication.logo_locked,
      stateConnections: [],
    };
    this.handleChangeApplicationTitle =
      this.handleChangeApplicationTitle.bind(this);
    this.handleChangeApplicationDesc =
      this.handleChangeApplicationDesc.bind(this);
    this.handleChangeApplicationUrl =
      this.handleChangeApplicationUrl.bind(this);
    this.handleChangeApplicationSnapshot =
      this.handleChangeApplicationSnapshot.bind(this);
    this.handleChangeAllowPublicAccess =
      this.handleChangeAllowPublicAccess.bind(this);
    this.handleChangeApplicationUrlSettings =
      this.handleChangeApplicationUrlSettings.bind(this);
    this.handleChangeApplicationKeywords =
      this.handleChangeApplicationKeywords.bind(this);
    this.handleChangeApplicationConnections =
      this.handleChangeApplicationConnections.bind(this);
    this.handleChangeApplicationLogo =
      this.handleChangeApplicationLogo.bind(this);
    this.switchUserMode = this.switchUserMode.bind(this);
    this.removeWorkspaceApplication =
      this.removeWorkspaceApplication.bind(this);
    this.onSelectSnapshot = this.onSelectSnapshot.bind(this);
    this.getSnapshotImageStyle = this.getSnapshotImageStyle.bind(this);
    this.imgSnapshot = React.createRef();
    this.imgDefaultSnapshot = React.createRef();
    this.imgNoSnapshot = React.createRef();
    this.imgSnapshotRadioButton = React.createRef();
    this.imgDefaultSnapshotRadioButton = React.createRef();
    this.imgNoSnapshotRadioButton = React.createRef();
    this.imgLogoContainer = React.createRef();
    this.imgLogo = React.createRef();
  }

  static propTypes = {
    workspaceApplication: PropTypes.object.isRequired,
    dispatchWorkspaceApplication: PropTypes.func.isRequired,
    updateWorkspaceApplication: PropTypes.func.isRequired,
    updateWorkspaceApplicationStatus: PropTypes.func.isRequired,
    role: PropTypes.object.isRequired,
    dispatchRole: PropTypes.func.isRequired,
    dispatchDialogWorkspacesApplications: PropTypes.func.isRequired,
    dispatchDialogWorkspacesApplicationsConnection: PropTypes.func.isRequired,
  };

  state = {
    src: null,
    imageEditorIsOpen: false,
  };

  switchUserMode(e) {
    const userRole =
      this.props.role.user_role === 'Administrator' ? 'Owner' : 'Administrator';
    const role = {
      ...this.props.role,
      user_role: userRole,
    };

    this.props.dispatchRole(role);
  }

  onWorkspaceApplicationTitleChange(e) {
    const workspaceApplication = {
      ...this.props.workspaceApplication,
      application_title: e.target.value,
    };

    this.props.dispatchWorkspaceApplication(workspaceApplication);
  }

  onWorkspaceApplicationDescriptionChange(e) {
    const workspaceApplication = {
      ...this.props.workspaceApplication,
      application_desc: e.target.value,
    };

    this.props.dispatchWorkspaceApplication(workspaceApplication);
  }

  onWorkspaceApplicationUrlChange(e) {
    const workspaceApplication = {
      ...this.props.workspaceApplication,
      url: e.target.value,
    };

    this.props.dispatchWorkspaceApplication(workspaceApplication);
  }

  onWorkspaceApplicationAllowPublicAccessChange(e) {
    const workspaceApplication = {
      ...this.props.workspaceApplication,
      allow_public_access: e.target.checked,
    };

    this.props.dispatchWorkspaceApplication(workspaceApplication);
  }

  onWorkspaceApplicationUrlSettingsChange(e) {
    const workspaceApplication = {
      ...this.props.workspaceApplication,
      url_settings: e.target.value,
    };

    this.props.dispatchWorkspaceApplication(workspaceApplication);
  }

  onWorkspaceApplicationKeywordsChange(e) {
    const workspaceApplication = {
      ...this.props.workspaceApplication,
      keywords: e.target.value.split(',').map(function (item) {
        return item.trim();
      }),
    };
    this.props.dispatchWorkspaceApplication(workspaceApplication);
  }

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({ src: reader.result, imageEditorIsOpen: true });
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onCloseImageEditor(image, aspectRatio) {
    this.setState({ imageEditorIsOpen: false });

    let workspaceApplication = {
      ...this.props.workspaceApplication,
      url_image: image,
      image_aspect_ratio: aspectRatio,
      is_logo_changed: true,
    };

    this.props.dispatchWorkspaceApplication(workspaceApplication);
  }

  handleNoSnapshotChange() {
    let application = {
      ...this.props.workspaceApplication,
      snapshot_type: 0,
    };
    this.props.dispatchWorkspaceApplication(application);
  }

  handleDefaultSnapshotChange() {
    let application = {
      ...this.props.workspaceApplication,
      snapshot_type: 1,
    };
    this.props.dispatchWorkspaceApplication(application);
  }

  handleSnapshotChange() {
    let application = {
      ...this.props.workspaceApplication,
      snapshot_type: 2,
    };
    this.props.dispatchWorkspaceApplication(application);
  }

  updateWorkspaceApplicationDetail() {
    const { workspaceApplication, role } = this.props;
    const { stateConnections } = this.state;

    let isConnectionUpdated = false;
    for (let i in workspaceApplication.connections) {
      let connection = workspaceApplication.connections[i];
      let stateConnection = stateConnections[i];
      if (connection.connection_string !== stateConnection.connection_string) {
        isConnectionUpdated = true;
        break;
      }
    }

    if (isConnectionUpdated) {
      this.props.updateWorkspaceApplicationConnection(
        workspaceApplication,
        stateConnections,
        role,
        this.props.history
      );
    } else {
      this.props.updateWorkspaceApplication(
        workspaceApplication,
        false,
        this.props.history
      );
    }
  }

  updateWorkspaceApplicationStatus() {
    let workspacesApplications = {
      id: this.props.workspaceApplication.application_id,
      name: this.props.workspaceApplication.application_name,
      status: this.props.workspaceApplication.status,
      dialog_title: 'Warning',
      dialog_type:
        this.props.workspaceApplication.status === 'Enabled'
          ? 'disable'
          : 'enable',
      route_back_to: '',
      show_dialog:
        this.props.workspaceApplication.status === 'Enabled' ? true : false,
    };

    this.props.dispatchDialogWorkspacesApplications(workspacesApplications);

    if (this.props.workspaceApplication.status === 'Disabled') {
      let workspaceApplication = {
        ...this.props.workspaceApplication,
        status:
          this.props.workspaceApplication.status === 'Enabled'
            ? 'Disabled'
            : 'Enabled',
      };

      this.props.updateWorkspaceApplicationStatus(workspaceApplication);
    }
  }

  handleChangeApplicationTitle() {
    let workspaceApplication = {
      ...this.props.workspaceApplication,
      application_title_locked: !this.state.isCheckedApplicationTitle,
    };
    this.props.dispatchWorkspaceApplication(workspaceApplication);

    this.setState({
      isCheckedApplicationTitle: !this.state.isCheckedApplicationTitle,
    });
  }

  handleChangeApplicationDesc() {
    let workspaceApplication = {
      ...this.props.workspaceApplication,
      application_desc_locked: !this.state.isCheckedApplicationDesc,
    };
    this.props.dispatchWorkspaceApplication(workspaceApplication);

    this.setState({
      isCheckedApplicationDesc: !this.state.isCheckedApplicationDesc,
    });
  }

  handleChangeApplicationUrl() {
    let workspaceApplication = {
      ...this.props.workspaceApplication,
      url_locked: !this.state.isCheckedApplicationUrl,
    };
    this.props.dispatchWorkspaceApplication(workspaceApplication);

    this.setState({
      isCheckedApplicationUrl: !this.state.isCheckedApplicationUrl,
    });
  }

  handleChangeApplicationSnapshot() {
    let workspaceApplication = {
      ...this.props.workspaceApplication,
      snapshot_locked: !this.state.isCheckedApplicationSnapshot,
    };
    this.props.dispatchWorkspaceApplication(workspaceApplication);

    this.setState({
      isCheckedApplicationSnapshot: !this.state.isCheckedApplicationSnapshot,
    });
  }

  handleChangeAllowPublicAccess() {
    if (this.state.isCheckedAllowPublicAccess === true) {
      let workspaceApplication = {
        ...this.props.workspaceApplication,
        allow_public_access_locked: false,
      };
      this.props.dispatchWorkspaceApplication(workspaceApplication);
    } else {
      let workspaceApplication = {
        ...this.props.workspaceApplication,
        allow_public_access_locked: true,
      };
      this.props.dispatchWorkspaceApplication(workspaceApplication);
    }
    this.setState({
      isCheckedAllowPublicAccess: !this.state.isCheckedAllowPublicAccess,
    });
  }

  handleChangeApplicationUrlSettings() {
    let workspaceApplication = {
      ...this.props.workspaceApplication,
      url_settings_locked: !this.state.isCheckedApplicationUrlSettings,
    };
    this.props.dispatchWorkspaceApplication(workspaceApplication);

    this.setState({
      isCheckedApplicationUrlSettings:
        !this.state.isCheckedApplicationUrlSettings,
    });
  }

  handleChangeApplicationKeywords() {
    let workspaceApplication = {
      ...this.props.workspaceApplication,
      keywords_locked: !this.state.isCheckedApplicationKeywords,
    };
    this.props.dispatchWorkspaceApplication(workspaceApplication);

    this.setState({
      isCheckedApplicationKeywords: !this.state.isCheckedApplicationKeywords,
    });
  }

  handleChangeApplicationConnections() {
    let workspaceApplication = {
      ...this.props.workspaceApplication,
      connections_locked: !this.state.isCheckedApplicationConnections,
    };
    this.props.dispatchWorkspaceApplication(workspaceApplication);

    const { connections } = this.props;
    for (var i in connections) {
      connections[i].locked = !this.state.isCheckedApplicationConnections;
    }
    this.props.dispatchUpdateConnections(connections);

    this.setState({
      isCheckedApplicationConnections:
        !this.state.isCheckedApplicationConnections,
    });
  }

  handleChangeApplicationLogo() {
    let workspaceApplication = {
      ...this.props.workspaceApplication,
      logo_locked: !this.state.isCheckedApplicationLogo,
    };
    this.props.dispatchWorkspaceApplication(workspaceApplication);

    this.setState({
      isCheckedApplicationLogo: !this.state.isCheckedApplicationLogo,
    });
  }

  getAspectRatioClass() {
    const { image_aspect_ratio } = this.props.workspaceApplication;

    let aspectRatioClass = 'crop-logo43';
    if (image_aspect_ratio >= 1 && image_aspect_ratio < 1.2) {
      aspectRatioClass = 'crop-logo11';
    } else if (image_aspect_ratio >= 1.2 && image_aspect_ratio < 1.4) {
      aspectRatioClass = 'crop-logo43';
    } else if (image_aspect_ratio >= 1.4 && image_aspect_ratio < 1.6) {
      aspectRatioClass = 'crop-logo32';
    } else if (image_aspect_ratio >= 1.6 && image_aspect_ratio < 2.3) {
      aspectRatioClass = 'crop-logo169';
    } else if (image_aspect_ratio >= 2.3) {
      aspectRatioClass = 'crop-logo219';
    } else {
      if (
        this.imgLogoContainer.current !== null &&
        this.imgLogo.current !== null
      ) {
        let containerWidth = this.imgLogoContainer.current.offsetWidth;
        let logoWidth = this.imgLogo.current.offsetWidth;
        let containerHeight = this.imgLogoContainer.current.offsetHeight;
        let logoHeight = this.imgLogo.current.offsetHeight;

        this.imgLogo.current.style.left =
          containerWidth / 2 - logoWidth / 2 + 'px';
        this.imgLogo.current.style.top =
          (1 / image_aspect_ratio) * containerHeight +
          (containerHeight / 2 - logoHeight / 2) +
          'px';
        this.imgLogo.current.style.position = 'absolute';
      }
    }
    return aspectRatioClass;
  }

  onConnectionStringChange(e, id) {
    const {
      workspaceApplication,
      connections,
      dispatchWorkspaceApplication,
      dispatchUpdateConnections,
    } = this.props;
    let filteredConnection = connections.filter((item) => id === item.id)[0];
    filteredConnection.connection_string = e.target.value;
    let index = connections.findIndex((item) => item.id === id);
    if (~index) {
      connections[index] = filteredConnection;
    }
    let wpApps = {
      ...workspaceApplication,
      connections: connections,
    };
    dispatchWorkspaceApplication(wpApps);
    dispatchUpdateConnections(connections);
  }

  handleChangeConnectionString(id, lockState) {
    const { workspaceApplication, connections } = this.props;
    if (!workspaceApplication.connections_locked) {
      let filteredConnection = connections.filter((item) => id === item.id)[0];
      filteredConnection.locked = !lockState;
      let index = connections.findIndex((item) => item.id === id);
      if (~index) {
        connections[index] = filteredConnection;
      }

      this.props.dispatchUpdateConnections(connections);
    }
  }

  // handleChangeEditConnectionString(id, edit) {
  //     const { workspaceApplication, connections } = this.props;
  //     if (workspaceApplication.connections_locked === false) {
  //         const updateConnections = connections.map(item => {
  //             return {
  //                 ...item,
  //                 edit: item.id === id ? !edit : item.edit
  //             }
  //         })
  //         this.props.dispatchUpdateConnections(updateConnections);
  //     }
  // }
  handleChangeEditConnectionString(id, edit) {
    const { workspaceApplication, connections } = this.props;
    if (!workspaceApplication.connections_locked) {
      const updateConnections = connections.map((item) => {
        return {
          ...item,
          edit: item.id === id ? !edit : item.edit,
        };
      });
      this.props.dispatchUpdateConnections(updateConnections);
    }
  }

  updateConnectionState() {
    const { workspaceApplication } = this.props;

    let cs = clone(workspaceApplication.connections);
    this.setState({ stateConnections: cs });
  }

  removeWorkspaceApplication() {
    const { workspaceApplication } = this.props;
    let workspacesApplications = {
      id: workspaceApplication.application_id,
      name: workspaceApplication.application_name,
      status: workspaceApplication.status,
      dialog_title: 'Warning',
      dialog_type: 'trash',
      route_back_to:
        routeCodes.WORKSPACES_LIST +
        '/' +
        workspaceApplication.workspace_name.toLowerCase().replaceAll(' ', '+') +
        '/properties',
      show_dialog: true,
    };
    this.props.dispatchDialogWorkspacesApplications(workspacesApplications);
  }

  onSelectSnapshot = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        let imgs = reader.result.split('base64,');
        if (imgs.length > 0) {
          let workspaceApplication = {
            ...this.props.workspaceApplication,
            snapshot_type: 2,
            snapshot_custom_url_image: imgs[1],
            is_snapshot_changed: true,
          };
          this.imgSnapshotRadioButton.current.checked = true;
          this.props.dispatchWorkspaceApplication(workspaceApplication);
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  getSnapshotImageStyle() {
    if (
      this.imgSnapshot.current !== null &&
      this.imgDefaultSnapshot.current !== null &&
      this.imgNoSnapshot.current !== null
    ) {
      this.imgSnapshot.current.style.height =
        this.imgSnapshot.current.offsetWidth * (175 / 381) + 'px';
      this.imgDefaultSnapshot.current.style.height =
        this.imgDefaultSnapshot.current.offsetWidth * (175 / 381) + 'px';
      this.imgNoSnapshot.current.style.height =
        this.imgNoSnapshot.current.offsetWidth * (175 / 381) + 'px';
    }
  }

  componentDidMount() {
    this.updateConnectionState();
  }

  componentWillMount() {
    Modal.setAppElement('body');
  }

  componentWillUnmount() {
    this.props.dispatchUpdateConnections([]);
  }

  render() {
    const { workspaceApplication, role, connections } = this.props;
    const { src, imageEditorIsOpen } = this.state;

    return (
      <div className="mainBody">
        <div className="row mt-5">
          <div className="col-md-7 order-md-1 col-lg-8 col-xl-7">
            <div className="wsHomeSection">
              <div className="desc pr-4">
                <div className="formWrap">
                  <div
                    className={
                      role.user_role === 'Administrator' ? 'groupForm' : null
                    }
                  >
                    {role.user_role === 'Administrator' ? (
                      <label className="btn iconBtn customLockCheckbox">
                        <input
                          type="checkbox"
                          className="lockInput"
                          onChange={this.handleChangeApplicationTitle}
                          checked={!this.state.isCheckedApplicationTitle}
                        />
                        <span className="icon-wrd-unlock"></span>
                        <span className="icon-wrd-lock"></span>
                      </label>
                    ) : null}
                    <div className="inputForm">
                      <input
                        className="form-control"
                        type="text"
                        name=""
                        required
                        defaultValue={workspaceApplication.application_title}
                        onChange={this.onWorkspaceApplicationTitleChange}
                        disabled={
                          role.user_role !== 'Administrator' &&
                          this.state.isCheckedApplicationTitle
                        }
                      />
                      <label>Title</label>
                    </div>
                  </div>
                  <div
                    className={
                      role.user_role === 'Administrator' ? 'groupForm' : null
                    }
                  >
                    {role.user_role === 'Administrator' ? (
                      <label className="btn iconBtn customLockCheckbox">
                        <input
                          type="checkbox"
                          className="lockInput"
                          onChange={this.handleChangeApplicationDesc}
                          checked={!this.state.isCheckedApplicationDesc}
                        />
                        <span className="icon-wrd-unlock"></span>
                        <span className="icon-wrd-lock"></span>
                      </label>
                    ) : null}
                    <div className="inputForm">
                      <textarea
                        className="form-control"
                        type="text"
                        name=""
                        required
                        defaultValue={workspaceApplication.application_desc}
                        onChange={this.onWorkspaceApplicationDescriptionChange}
                        disabled={
                          role.user_role !== 'Administrator' &&
                          this.state.isCheckedApplicationDesc
                        }
                      />
                      <label>Description</label>
                    </div>
                  </div>
                  <div
                    className={
                      role.user_role === 'Administrator' ? 'groupForm' : null
                    }
                  >
                    <div className="inputForm mt-5">
                      <input
                        className="form-control"
                        type="text"
                        name=""
                        required
                        defaultValue={workspaceApplication.url}
                        onChange={this.onWorkspaceApplicationUrlChange}
                        disabled={true}
                      />
                      <label>URL</label>
                    </div>
                  </div>
                  <div
                    className={
                      role.user_role === 'Administrator'
                        ? 'groupForm groupAppForm mt-5'
                        : null
                    }
                  >
                    {role.user_role === 'Administrator' ? (
                      <label className="btn iconBtn customLockCheckbox">
                        <input
                          type="checkbox"
                          className="lockInput"
                          onChange={this.handleChangeApplicationSnapshot}
                          checked={!this.state.isCheckedApplicationSnapshot}
                        />
                        <span className="icon-wrd-unlock"></span>
                        <span className="icon-wrd-lock"></span>
                      </label>
                    ) : null}
                    <label className="text">Application Snapshot</label>
                    <p>Choose snapshot</p>
                    <div className="row">
                      <div className="col-sm-4 col-md-4 col-xl-5">
                        <label className="custom-checkbox custom-pick-image reveal">
                          {workspaceApplication.snapshot_type === 0 && (
                            <input
                              ref={this.imgNoSnapshotRadioButton}
                              type="radio"
                              name="lpwb"
                              className="custom-control-input"
                              defaultChecked
                              disabled={
                                role.user_role !== 'Administrator' &&
                                this.state.isCheckedApplicationSnapshot
                              }
                            />
                          )}
                          {workspaceApplication.snapshot_type !== 0 && (
                            <input
                              ref={this.imgNoSnapshotRadioButton}
                              type="radio"
                              name="lpwb"
                              className="custom-control-input"
                              disabled={
                                role.user_role !== 'Administrator' &&
                                this.state.isCheckedApplicationSnapshot
                              }
                            />
                          )}
                          <div
                            className="imgContainer"
                            onClick={this.handleNoSnapshotChange}
                          >
                            <img
                              ref={this.imgNoSnapshot}
                              src={
                                process.env.PUBLIC_URL +
                                '/images/no-image-wide.png'
                              }
                              style={this.getSnapshotImageStyle()}
                              className="themeAppImg"
                              alt=""
                            />
                          </div>
                          <p>
                            <span className="icon-wrd-checkmark"></span> No
                            snapshot
                          </p>
                        </label>
                      </div>
                      <div className="col-sm-4 col-md-4 col-xl-5">
                        <label className="custom-checkbox custom-pick-image">
                          {workspaceApplication.snapshot_type === 1 && (
                            <input
                              ref={this.imgDefaultSnapshotRadioButton}
                              type="radio"
                              name="lpwb"
                              className="custom-control-input"
                              defaultChecked
                              disabled={
                                role.user_role !== 'Administrator' &&
                                this.state.isCheckedApplicationSnapshot
                              }
                            />
                          )}
                          {workspaceApplication.snapshot_type !== 1 && (
                            <input
                              ref={this.imgDefaultSnapshotRadioButton}
                              type="radio"
                              name="lpwb"
                              className="custom-control-input"
                              disabled={
                                role.user_role !== 'Administrator' &&
                                this.state.isCheckedApplicationSnapshot
                              }
                            />
                          )}
                          <div
                            className="imgContainer"
                            onClick={this.handleDefaultSnapshotChange}
                          >
                            {workspaceApplication.snapshot_default_url_image !==
                              undefined && (
                              <img
                                ref={this.imgDefaultSnapshot}
                                className="themeAppImg"
                                style={this.getSnapshotImageStyle()}
                                src={
                                  'data:image/png;base64,' +
                                  workspaceApplication.snapshot_default_url_image
                                }
                                alt=""
                              />
                            )}
                            {workspaceApplication.snapshot_default_url_image ===
                              undefined && (
                              <img
                                ref={this.imgDefaultSnapshot}
                                className="themeAppImg"
                                style={this.getSnapshotImageStyle()}
                                alt=""
                              />
                            )}
                          </div>
                          <p>
                            <span className="icon-wrd-checkmark"></span> Default
                            snapshot
                          </p>
                        </label>
                      </div>
                      <div className="col-sm-4 col-md-4 col-xl-5">
                        <label className="custom-checkbox custom-pick-image">
                          {workspaceApplication.snapshot_type === 2 && (
                            <input
                              ref={this.imgSnapshotRadioButton}
                              type="radio"
                              name="lpwb"
                              className="custom-control-input"
                              defaultChecked
                              disabled={
                                role.user_role !== 'Administrator' &&
                                this.state.isCheckedApplicationSnapshot
                              }
                            />
                          )}
                          {workspaceApplication.snapshot_type !== 2 && (
                            <input
                              ref={this.imgSnapshotRadioButton}
                              type="radio"
                              name="lpwb"
                              className="custom-control-input"
                              disabled={
                                role.user_role !== 'Administrator' &&
                                this.state.isCheckedApplicationSnapshot
                              }
                            />
                          )}
                          <div
                            className="imgContainer"
                            onClick={this.handleSnapshotChange}
                          >
                            {workspaceApplication.snapshot_custom_url_image !==
                              undefined && (
                              <img
                                ref={this.imgSnapshot}
                                className="themeAppImg"
                                style={this.getSnapshotImageStyle()}
                                src={
                                  'data:image/png;base64,' +
                                  workspaceApplication.snapshot_custom_url_image
                                }
                                alt=""
                              />
                            )}
                            {workspaceApplication.snapshot_custom_url_image ===
                              undefined && (
                              <img
                                ref={this.imgSnapshot}
                                className="themeAppImg"
                                style={this.getSnapshotImageStyle()}
                                alt=""
                              />
                            )}
                          </div>
                          <p>
                            {role.user_role === 'Administrator' ||
                            !this.state.isCheckedApplicationSnapshot
                              ? 'Click browse if you want to upload new snapshot'
                              : null}
                          </p>
                          <button
                            className="btn btn-wrd_default icoTextBtn browseLogo"
                            disabled={
                              role.user_role !== 'Administrator' &&
                              this.state.isCheckedApplicationSnapshot
                            }
                          >
                            <label className="custom-file-upload">
                              <input
                                type="file"
                                onChange={this.onSelectSnapshot}
                              />
                              <span className="icon-wrd-folder"></span>
                              Browse
                            </label>
                          </button>
                        </label>
                      </div>
                    </div>
                  </div>
                  {!getCookies.isMikeMine && (
                  <div
                    className={
                      role.user_role === 'Administrator' ? 'groupForm' : null
                    }
                  >
                    {role.user_role === 'Administrator' ? (
                      <label className="btn iconBtn customLockCheckbox">
                        <input
                          type="checkbox"
                          className="lockInput"
                          onChange={this.handleChangeAllowPublicAccess}
                          checked={!this.state.isCheckedAllowPublicAccess}
                        />
                        <span className="icon-wrd-unlock"></span>
                        <span className="icon-wrd-lock"></span>
                      </label>
                    ) : null}
                    <div className="custom-controls-stacked">
                      <label className="custom-control ios-switch">
                        <input
                          type="checkbox"
                          onClick={
                            this.onWorkspaceApplicationAllowPublicAccessChange
                          }
                          className="ios-switch-control-input"
                          defaultChecked={
                            workspaceApplication.allow_public_access
                          }
                          disabled={
                            role.user_role !== 'Administrator' &&
                            this.state.isCheckedAllowPublicAccess
                          }
                        />
                        <span className="ios-switch-control-indicator"></span>
                        <span className="text">Allow Public Access</span>
                      </label>
                    </div>
                  </div>
                  )}

                  <div
                    className={
                      role.user_role === 'Administrator' ? 'groupForm' : null
                    }
                  >
                    {role.user_role === 'Administrator' ? (
                      <label className="btn iconBtn customLockCheckbox">
                        <input
                          type="checkbox"
                          className="lockInput"
                          onChange={this.handleChangeApplicationKeywords}
                          checked={!this.state.isCheckedApplicationKeywords}
                        />
                        <span className="icon-wrd-unlock"></span>
                        <span className="icon-wrd-lock"></span>
                      </label>
                    ) : null}
                    <div className="inputForm">
                      <input
                        className="form-control"
                        type="text"
                        name=""
                        required
                        defaultValue={
                          workspaceApplication.keywords !== undefined
                            ? workspaceApplication.keywords.join()
                            : null
                        }
                        onChange={this.onWorkspaceApplicationKeywordsChange}
                        disabled={
                          role.user_role !== 'Administrator' &&
                          this.state.isCheckedApplicationKeywords
                        }
                      />
                      <label>Keyword(s)</label>
                      <p className="mt-2">
                        <span className="icon-wrd-alert-triangle"></span> Use
                        coma ( , ) if using more than one. eg: rainfall, price,
                        etc
                      </p>
                    </div>
                  </div>
                  <div
                    className={
                      role.user_role === 'Administrator' ? 'groupForm' : null
                    }
                  >
                    {role.user_role === 'Administrator' ? (
                      <label className="btn iconBtn customLockCheckbox">
                        <input
                          type="checkbox"
                          className="lockInput"
                          onChange={this.handleChangeApplicationConnections}
                          checked={!this.state.isCheckedApplicationConnections}
                        />
                        <span className="icon-wrd-unlock"></span>
                        <span className="icon-wrd-lock"></span>
                      </label>
                    ) : null}
                    <label className="text">Connection Strings</label>
                    <div className="csGroup pl-3 pr-5 pt-4 pb-1">
                      {connections !== undefined && connections.length > 0
                        ? connections.map((item) => (
                            <div
                              className={
                                role.user_role === 'Administrator'
                                  ? 'groupForm'
                                  : null
                              }
                              key={item.id}
                            >
                              {role.user_role === 'Administrator' ? (
                                <>
                                  <label className="btn iconBtn customLockCheckbox">
                                    <input
                                      type="checkbox"
                                      className="lockInput"
                                      onChange={(e) =>
                                        this.handleChangeConnectionString(
                                          item.id,
                                          item.locked
                                        )
                                      }
                                      checked={!item.locked}
                                    />
                                    <span className="icon-wrd-unlock"></span>
                                    <span className="icon-wrd-lock"></span>
                                  </label>

                                  {/* <label className="btn iconBtn customEditCheckbox">
                                                                    <input type="checkbox" className="lockInput" onChange={(e) => this.handleChangeEditConnectionString(item.id, item.edit)} checked={!item.edit} />
                                                                    <span className="icon-wrd-edit"></span>
                                                                    <span className="icon-wrd-check-circle"></span>
                                                                </label> */}
                                  <label className="btn iconBtn customEditCheckbox">
                                    <input
                                      type="checkbox"
                                      className="lockInput"
                                      onChange={(e) =>
                                        this.handleChangeEditConnectionString(
                                          item.id,
                                          item.edit
                                        )
                                      }
                                      checked={!item.edit}
                                    />
                                    <span className="icon-wrd-edit"></span>
                                    <span className="icon-wrd-check-circle"></span>
                                  </label>
                                </>
                              ) : null}
                              <div className="inputForm">
                                {item.edit ? (
                                  <textarea
                                    className="form-control"
                                    name=""
                                    required
                                    rows="5"
                                    cols="120"
                                    style={{ resize: 'none' }}
                                    value={item.connection_string}
                                    onChange={(e) =>
                                      this.onConnectionStringChange(e, item.id)
                                    }
                                    disabled={
                                      role.user_role !== 'Administrator' &&
                                      item.locked
                                    }
                                  />
                                ) : (
                                  <textarea
                                    className="form-control"
                                    name=""
                                    required
                                    rows="5"
                                    cols="120"
                                    style={{ resize: 'none' }}
                                    value={
                                      item.shortConnectionString === ''
                                        ? item.connection_string
                                        : item.shortConnectionString
                                    }
                                    disabled={true} //{role.user_role !== 'Admin' && item.locked}
                                  />
                                )}
                                <label>{item.focus_area_name}</label>
                              </div>
                            </div>
                          ))
                        : 'No focus area found'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 col-lg-4 col-xl-3">
            <div className="wsLogo pb-5 text-center">
              <div
                ref={this.imgLogoContainer}
                className="logoContainer wsHome text-center"
              >
                <img
                  src={process.env.PUBLIC_URL + '/images/transCircle.png'}
                  className="insideCircle"
                  alt=""
                />
                <img
                  ref={this.imgLogo}
                  src={
                    (workspaceApplication.url_image !== undefined
                      ? 'data:image/png;base64,'
                      : '') + workspaceApplication.url_image
                  }
                  className={this.getAspectRatioClass()}
                  alt=""
                />
                {role.user_role === 'Administrator' ? (
                  <div className="logoLockContainer">
                    <label className="btn iconBtn customLockCheckbox pt-4 pl-4">
                      <input
                        type="checkbox"
                        className="lockInput"
                        onChange={this.handleChangeApplicationLogo}
                        checked={!this.state.isCheckedApplicationLogo}
                      />
                      <span className="icon-wrd-unlock"></span>
                      <span className="icon-wrd-lock"></span>
                    </label>
                  </div>
                ) : null}
              </div>
              <button
                className="btn btn-wrd_default icoTextBtn browseLogo"
                disabled={
                  role.user_role !== 'Administrator' &&
                  this.state.isCheckedApplicationLogo
                }
              >
                <label
                  className={
                    role.user_role === 'Administrator' ||
                    !this.state.isCheckedApplicationLogo
                      ? 'custom-file-upload'
                      : null
                  }
                >
                  {role.user_role === 'Administrator' ||
                  !this.state.isCheckedApplicationLogo ? (
                    <input type="file" onChange={this.onSelectFile} />
                  ) : null}
                  <span className="icon-wrd-folder icon-upload-folder"></span>
                  Browse
                </label>
              </button>
              <span className="d-block mt-1 mb-5 font-italic text-grey">
                {role.user_role === 'Administrator' ||
                !this.state.isCheckedApplicationLogo
                  ? 'Browse to change logo'
                  : null}
              </span>
              <button
                className="btn mt-4 textBtn w-75 greenBtn"
                onClick={() => this.updateWorkspaceApplicationDetail()}
              >
                Update Changes
              </button>
              <button
                className={
                  workspaceApplication.status === 'Enabled'
                    ? 'btn textBtn w-75 redBtn'
                    : 'btn textBtn w-75 greenBtn'
                }
                onClick={() => this.updateWorkspaceApplicationStatus()}
              >
                {workspaceApplication.status === 'Enabled'
                  ? 'Suspend'
                  : 'Reinstate'}
              </button>
            </div>
          </div>
        </div>
        {src && (
          <Modal
            isOpen={imageEditorIsOpen}
            style={modalImageEditorStyle}
            contentLabel="Image Editor"
          >
            <ImageEditor srcImage={src} onClose={this.onCloseImageEditor} />
          </Modal>
        )}
        <ul className="topNavBtn">
          {role.user_role === 'Administrator' && (
            <li>
              <button
                className="btn redBtn"
                onClick={this.removeWorkspaceApplication}
              >
                <span className="icon-wrd-trash"></span>
                Remove Workspace Application
              </button>
            </li>
          )}
        </ul>
        {showSwitchButton && (
          <button
            className="btn customBtn"
            onClick={() => this.switchUserMode()}
          >
            {role.user_role === 'Administrator' ? 'Owner' : 'Administrator'}{' '}
            Mode
          </button>
        )}
        <WorkspacesApplicationsDetailConnectionModal
          type="detail"
          updateConnection={this.updateConnectionState}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  detail,
  role,
  workspaceApplicationConnections,
}) => ({
  workspaceApplication: detail.workspace_application,
  role,
  connections: workspaceApplicationConnections.connections,
});

const mapDispatchToProps = (dispatch) => ({
  dispatchWorkspaceApplication: (workspaceApplication) =>
    dispatch(updateWorkspaceApplicationAction(workspaceApplication)),
  updateWorkspaceApplication: (workspaceApplication, bypassUrl, historyProps) =>
    dispatch(
      updateWorkspaceApplicationWithThunk(
        workspaceApplication,
        bypassUrl,
        historyProps
      )
    ),
  updateWorkspaceApplicationStatus: (workspaceApplication) =>
    dispatch(updateWorkspaceApplicationStatusWithThunk(workspaceApplication)),
  updateWorkspaceApplicationConnection: (
    workspaceApplication,
    connections,
    role,
    historyProps
  ) =>
    dispatch(
      updateWorkspaceApplicationConnectionWithThunk(
        workspaceApplication,
        connections,
        role,
        historyProps
      )
    ),
  dispatchRole: (role) => dispatch(updateRoleAction(role)),
  dispatchDialogWorkspacesApplications: (dialog) =>
    dispatch(updateDialogWorkspacesApplicationsAction(dialog)),
  dispatchDialogWorkspacesApplicationsConnection: (dialog) =>
    dispatch(updateDialogWorkspacesApplicationsConnectionAction(dialog)),
  dispatchUpdateConnections: (connections) =>
    dispatch(updateWorkspaceApplicationConnectionsAction(connections)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsDetailBody)
);
