import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import WorkspacesApplicationsItem from './WorkspacesApplicationsItem';
import WorkspacesApplicationsCategory from './WorkspacesApplicationsCategory';
import UserAvailableWorkspacesContainer from '../../UserAvailableWorkspaces/UserAvailableWorkspacesContainer';
import { updateRoleAction } from '../../../../actions/updateRoleAction';
import { getWorkspacesApplicationsAction } from '../../../../actions/getWorkspacesApplicationsAction';
import { getWorkspacesCategoriesAction } from '../../../../actions/getWorkspacesCategoriesAction';
import { showSwitchButton } from '../../../../configurations/app';
import {
  defaultAvailableWorkspacesPageIndex,
  defaultAvailableWorkspacesPageSize,
} from '../../../../configurations/app';
import { getUserAvailableWorkspacesWithThunk } from '../../../../api/userAvailableWorkspacesThunk';
import { updateWpAppsOrderWithThunk } from '../../../../api/workspacesApplicationsThunk';
import { updateWorkspaceApplicationCategoryWithThunk } from '../../../../api/workspacesListThunk';
import { routeCodes } from '../../../../configurations/routes';
import { getUrlWorkspaceId } from '../../../../utils/url';
import { reOrderCategoryItems } from '../../../../utils/index';
import { mergeWorkspacesApplicationsCategories } from '../../../../utils/workspacesCategories';
import { updateAvailableWorkspacesOpenedStatus } from '../../../../utils/workspaces';
import { getCookies } from '../../../../backend/api/getCookies';

class WorkspacesApplicationsBody extends Component {
  constructor(props) {
    super(props);
    this.switchUserMode = this.switchUserMode.bind(this);
    this.createWorkspaceCategory = this.createWorkspaceCategory.bind(this);
    this.setDragInfoState = this.setDragInfoState.bind(this);
    this.hideList = this.hideList.bind(this);
    this.categoryBody = React.createRef();
    this.categoryDrop = React.createRef();

    this.state = {
      wsListOpened: false,
      firstOpen: true,
      applicationId: '',
      categoryTitle: '',
    };
  }

  static propTypes = {
    role: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
    updateWpAppsOrder: PropTypes.func.isRequired,
    dispatchWorkspacesApplications: PropTypes.func.isRequired,
    dispatchWorkspacesCategories: PropTypes.func.isRequired,
    dispatchRole: PropTypes.func.isRequired,
    getUserAvailableWorkspaces: PropTypes.func.isRequired,
    updateWorkspaceApplicationCategory: PropTypes.func.isRequired,
  };

  getCurrentWorkspaceId = () => {
    let id = getUrlWorkspaceId(this.props.location.pathname);
    return id;
  };

  availableWorkspacesSwitch() {
    this.setState({ wsListOpened: !this.state.wsListOpened });
    this.setState({ firstOpen: false });
    if (this.state.wsListOpened === false) {
      const { role } = this.props;
      this.props.getUserAvailableWorkspaces(
        role.user_id,
        role.user_role,
        defaultAvailableWorkspacesPageIndex,
        defaultAvailableWorkspacesPageSize
      );
    } else {
      updateAvailableWorkspacesOpenedStatus(false);
    }
  }

  createWorkspaceCategory() {
    const workspaceName = this.getCurrentWorkspaceId();
    this.props.history.push(
      routeCodes.WORKSPACES_LIST + '/' + workspaceName + '/categories/create'
    );
  }

  switchUserMode(e) {
    const userRole =
      this.props.role.user_role === 'Administrator' ? 'Owner' : 'Administrator';
    const role = {
      ...this.props.role,
      user_role: userRole,
    };

    this.props.dispatchRole(role);
  }

  onDrop = (e) => {
    const workspacesId = this.props.workspace.id;
    const apps = this.props.items.map((app, i) => {
      return {
        applicationId: app.id,
        index: i,
      };
    });

    this.props.updateWpAppsOrder(workspacesId, apps);
    this.setDragInfoState('', '');
  };

  onDragOver = async (e, index, isHaveApps) => {
    e.stopPropagation();
    e.preventDefault();

    this.categoryDrop.current.setAttribute(
      'class',
      isHaveApps
        ? 'drop-area-app drop-area-hover mb-4'
        : 'drop-area drop-area-hover mb-4'
    );

    let applicationId = this.state.applicationId;
    let apps = this.props.items.filter(
      (item) => item.id === applicationId && item.category_title === ''
    );
    if (apps.length === 0) {
      return;
    }

    let categApps = this.props.items.filter(
      (item) => item.category_title === ''
    );
    const draggedOverItem = categApps[index];
    const draggedItem = this.props.items.filter((item) => item.selected)[0];

    if (
      draggedItem === draggedOverItem ||
      draggedOverItem.is_linked === false
    ) {
      return;
    }

    const applications = reOrderCategoryItems(
      this.props.items,
      draggedOverItem.index,
      draggedItem
    );

    this.props.dispatchWorkspacesApplications(applications);

    let withWorkspaceCategories = this.props.workspacesCategories.filter(
      (item) => item.category_title !== '' && item.is_workspace_category
    );
    let withAppCategories = this.props.workspacesCategories.filter(
      (item) => item.category_title !== '' && !item.is_workspace_category
    );
    let mergeWorkspaceCategories = await mergeWorkspacesApplicationsCategories(
      withWorkspaceCategories,
      applications
    );
    mergeWorkspaceCategories =
      mergeWorkspaceCategories.concat(withAppCategories);

    this.props.dispatchWorkspacesCategories(mergeWorkspaceCategories);
  };

  onSelect = (app) => {
    const { items } = this.props;
    const applications = items.map((item, i) =>
      app.id === item.id
        ? { ...item, selected: true }
        : { ...item, selected: false }
    );
    this.props.dispatchWorkspacesApplications(applications);
  };

  onCategoryItemDragOver(e, isHaveApps) {
    e.stopPropagation();
    e.preventDefault();

    this.categoryDrop.current.setAttribute(
      'class',
      isHaveApps
        ? 'drop-area-app drop-area-hover mb-4'
        : 'drop-area drop-area-hover mb-4'
    );
  }

  onCategoryItemDragLeave(e, isHaveApps) {
    e.stopPropagation();
    e.preventDefault();

    let viewportOffset = this.categoryDrop.current.getBoundingClientRect();
    let boxTop = viewportOffset.top;
    let boxBottom = boxTop + viewportOffset.height;
    let y = e.clientY;

    if (y > boxTop && y < boxBottom) {
      return;
    }
    this.categoryDrop.current.setAttribute(
      'class',
      isHaveApps ? 'drop-area-app mb-4' : 'drop-area mb-4'
    );
  }

  async onCategoryItemDrop(e, isHaveApps) {
    e.stopPropagation();
    e.preventDefault();

    this.categoryDrop.current.setAttribute(
      'class',
      isHaveApps ? 'drop-area-app mb-4' : 'drop-area mb-4'
    );

    let appId = this.state.applicationId;
    let apps = this.props.items.filter(
      (item) => item.id === appId && item.category_title !== ''
    );
    if (apps.length === 0) {
      return;
    }

    const { workspace, role } = this.props;
    let applicationId = e.dataTransfer.getData('application_id');

    const draggedItem = this.props.items.filter((item) => item.selected)[0];
    draggedItem.category_title = '';
    const applications = reOrderCategoryItems(
      this.props.items,
      this.props.items.length - 1,
      draggedItem
    );

    this.props.dispatchWorkspacesApplications(applications);

    let withWorkspaceCategories = this.props.workspacesCategories.filter(
      (item) => item.category_title !== '' && item.is_workspace_category
    );
    let withAppCategories = this.props.workspacesCategories.filter(
      (item) => item.category_title !== '' && !item.is_workspace_category
    );
    let mergeWorkspaceCategories = await mergeWorkspacesApplicationsCategories(
      withWorkspaceCategories,
      applications
    );
    mergeWorkspaceCategories =
      mergeWorkspaceCategories.concat(withAppCategories);

    this.props.dispatchWorkspacesCategories(mergeWorkspaceCategories);

    const isAdmin =
      role.user_role.toLowerCase() === 'administrator' ? true : false;
    this.props.updateWorkspaceApplicationCategory(
      workspace.id,
      applicationId,
      '',
      true,
      isAdmin
    );
    this.setDragInfoState('', '');
  }

  setDragInfoState(applicationId, categoryTitle) {
    this.setState({ applicationId, categoryTitle });
  }

  hideList() {
    this.setState({ wsListOpened: false });
  }

  render() {
    const { role, items, workspace, workspacesCategories } = this.props;
    const { applicationId, categoryTitle } = this.state;

    let noCategoryApplications = items.filter(
      (item) => item.category_title === ''
    );
    let withWorkspaceCategories = workspacesCategories.filter(
      (item) => item.category_title !== ''
    );

    return (
      <div className="mainBody">
        <div className="row mt-2">
          <div className=" col-xl-12">
            <div className="wsAppsSection">
              {!getCookies.isMikeMine && (
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-9">
                    <button
                      className="btn icoTextBtn btn-wrd_default mr-3"
                      onClick={() => this.createWorkspaceCategory()}
                    >
                      <span className="icon-wrd-add"></span>
                      Add Category
                    </button>
                  </div>
                </div>
              )}
              <div ref={this.categoryBody}>
                {noCategoryApplications.length === 0 && (
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="category-header mb-3">
                          <span className="icon-wrd-binders"></span>
                          <h5>No application category</h5>
                        </div>
                      </div>
                    </div>
                    <div
                      ref={this.categoryDrop}
                      className="drop-area mb-4"
                      onDrop={(e) => this.onCategoryItemDrop(e, false)}
                      onDragOver={(e) => this.onCategoryItemDragOver(e, false)}
                      onDragLeave={(e) =>
                        this.onCategoryItemDragLeave(e, false)
                      }
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            No available app(s) in this category, drag and drop
                            an app here to add to the category
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {noCategoryApplications.length > 0 && (
                  <div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="category-header mb-3">
                          <span className="icon-wrd-binders"></span>
                          <h5>No application category</h5>
                        </div>
                      </div>
                    </div>
                    <div
                      ref={this.categoryDrop}
                      className="drop-area-app mb-4"
                      onDrop={(e) => this.onCategoryItemDrop(e, true)}
                      onDragOver={(e) => this.onCategoryItemDragOver(e, true)}
                      onDragLeave={(e) => this.onCategoryItemDragLeave(e, true)}
                    >
                      <div className="row">
                        {noCategoryApplications.map(
                          (application, index) =>
                            application.is_enabled_global && (
                              <div
                                className="col-3"
                                draggable={application.is_linked}
                                key={application.id}
                                onDragOver={(e) =>
                                  this.onDragOver(e, index, true)
                                }
                              >
                                <WorkspacesApplicationsItem
                                  role={role}
                                  item={application}
                                  onSelect={this.onSelect}
                                  onDrop={(e) => this.onDrop(e)}
                                  setDragInfoState={this.setDragInfoState}
                                />
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                )}
                {withWorkspaceCategories.map((category, index) => (
                  <WorkspacesApplicationsCategory
                    key={index}
                    role={role}
                    category={category}
                    workspace={workspace}
                    items={items}
                    workspacesCategories={workspacesCategories}
                    applicationId={applicationId}
                    categoryTitle={categoryTitle}
                    setDragInfoState={this.setDragInfoState}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.firstOpen
              ? 'workspaceList d-none'
              : this.state.wsListOpened
              ? 'workspaceList open animated fadeInRight'
              : 'workspaceList open animated fadeOutRight'
          }
        >
          <UserAvailableWorkspacesContainer hideList={this.hideList} />
        </div>
        <button
          className="btn iconBtn wsListBtn"
          onClick={() => this.availableWorkspacesSwitch()}
        >
          <span
            className={
              this.state.wsListOpened ? 'icon-wrd-close' : 'icon-wrd-ws-list'
            }
          ></span>
        </button>
        {showSwitchButton && (
          <button
            className="btn customBtn"
            onClick={() => this.switchUserMode()}
          >
            {role.user_role === 'Administrator' ? 'Owner' : 'Administrator'}{' '}
            Mode
          </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ role, workspacesCategories }) => ({
  role,
  workspacesCategories,
});

const mapDispatchToProps = (dispatch) => ({
  updateWpAppsOrder: (workspacesId, apps) =>
    dispatch(updateWpAppsOrderWithThunk(workspacesId, apps)),
  dispatchWorkspacesApplications: (apps) =>
    dispatch(getWorkspacesApplicationsAction(apps)),
  dispatchRole: (role) => dispatch(updateRoleAction(role)),
  getUserAvailableWorkspaces: (userId, userRole, pageIndex, pageSize) =>
    dispatch(
      getUserAvailableWorkspacesWithThunk(userId, userRole, pageIndex, pageSize)
    ),
  dispatchWorkspacesCategories: (workspaceCategories) =>
    dispatch(getWorkspacesCategoriesAction(workspaceCategories)),
  updateWorkspaceApplicationCategory: (
    workspaceId,
    applicationId,
    categoryId,
    isWorkspaceCategory,
    isAdmin
  ) =>
    dispatch(
      updateWorkspaceApplicationCategoryWithThunk(
        workspaceId,
        applicationId,
        categoryId,
        isWorkspaceCategory,
        isAdmin
      )
    ),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkspacesApplicationsBody)
);
