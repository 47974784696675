import {
    createWorkspaceCategoryApi,
    updateWorkspaceCategoryApi,
    deleteWorkspaceCategoryApi,
    getWorkspaceCategoryByIdApi,
    getWorkspaceApplicationCategoryListApi,
} from '../backend/domain/workspacesCategories';

import {
    getWorkspaceApplicationListApi
} from '../backend/domain/workspacesApplications';

import { loadingCreateWorkspaceCategoryAction } from '../actions/loadingCreateWorkspaceCategoryAction';
import { loadingWorkspaceCategoryAction } from '../actions/loadingWorkspaceCategoryAction';
import { loadingWorkspacesApplicationsAction } from '../actions/loadingWorkspacesApplicationsAction';
import { getWorkspaceCategoryAction } from '../actions/getWorkspaceCategoryAction';
import { clearWorkspacesApplicationsAction } from '../actions/clearWorkspacesApplicationsAction';
import { clearWorkspaceCategoryAction } from '../actions/clearWorkspaceCategoryAction';
import { clearWorkspacesCategoriesAction } from '../actions/clearWorkspacesCategoriesAction';
import { routeCodes } from '../configurations/routes';
import { handleWorkspaceCategoryCreationError, handleWorkspaceCategoryUpdateError } from '../utils/errorMessages';
import { overrideWorkspaceId, getWorkspaceNameById } from '../utils/workspaces';
import { getWorkspaceCategoryIdByName, mergeWorkspacesApplicationsCategories } from '../utils/workspacesCategories';

export const createWorkspaceCategoryWithThunk = (workspaceId, category) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);

        dispatch(loadingCreateWorkspaceCategoryAction(true));
        createWorkspaceCategoryApi(wId, category)
            .then(async (response) => {
                handleWorkspaceCategoryCreationError(dispatch, response.status, response.error_messages, routeCodes.WORKSPACES_LIST + "/" + workspaceId + "/applications");
            })
            .then(() => {
                dispatch(loadingCreateWorkspaceCategoryAction(false));
            });
    };

export const updateWorkspaceCategoryWithThunk = (workspaceId, category) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);

        dispatch(loadingWorkspaceCategoryAction(true));
        updateWorkspaceCategoryApi(wId, category)
            .then(async (response) => {
                handleWorkspaceCategoryUpdateError(dispatch, response.status, response.error_messages, routeCodes.WORKSPACES_LIST + "/" + workspaceId + "/applications");
            })
            .then(() => {
                dispatch(loadingWorkspaceCategoryAction(false));
            });
    };

export const deleteWorkspaceCategoryWithThunk = (workspaceId, categoryId, routeBackTo, historyProps, isAdmin) =>
    async (dispatch) => {
        if (routeBackTo !== "") {
            dispatch(loadingWorkspaceCategoryAction(true));
            deleteWorkspaceCategoryApi(workspaceId, categoryId)
                .then(() => {
                    historyProps.push(routeBackTo);
                    dispatch(loadingWorkspaceCategoryAction(false));
                });
        }
        else {
            dispatch(loadingWorkspacesApplicationsAction(true));
            deleteWorkspaceCategoryApi(workspaceId, categoryId)
                .then(() => {
                    getWorkspaceApplicationListApi(workspaceId, isAdmin)
                        .then((response) => {
                            dispatch(clearWorkspacesCategoriesAction([]));
                            return response;
                        })
                        .then(async (response) => {
                            let workspaceCategories = await getWorkspaceApplicationCategoryListApi(workspaceId)

                            let w = await getWorkspaceNameById(workspaceId);
                            let applications = getWorkspacesApplications(w.workspace.name, response.applications);
                            dispatch(clearWorkspacesApplicationsAction(applications));

                            let mergeWorkspaceCategories = await mergeWorkspacesApplicationsCategories(workspaceCategories, applications);
                            dispatch(clearWorkspacesCategoriesAction(mergeWorkspaceCategories));
                        })
                })
                .then(() => {
                    dispatch(loadingWorkspacesApplicationsAction(false));
                });
        }
    };

export const getWorkspaceCategoryByIdWithThunk = (workspaceId, categoryId) =>
    async (dispatch) => {
        let wId = await overrideWorkspaceId(workspaceId);
        let categId = await getWorkspaceCategoryIdByName(wId, categoryId);

        dispatch(clearWorkspaceCategoryAction([]));
        dispatch(loadingWorkspaceCategoryAction(true));
        getWorkspaceCategoryByIdApi(wId, categId)
            .then(response => {
                dispatch(getWorkspaceCategoryAction(response));
            })
            .then((response) => {
                dispatch(loadingWorkspaceCategoryAction(false));
                return response;
            });
    };

const getWorkspacesApplications = (workspaceName, workspacesApplications) => {
    let applications = [];

    for (let i in workspacesApplications) {
        let application = workspacesApplications[i];
        application.workspace_name = workspaceName;
        applications.push(application);
    }
    return applications;
}
