export const saveSessionStorage = async (key, value) => {
    sessionStorage.setItem(key, value);
}

export const getSessionStorage = (key) => {
    const result = sessionStorage.getItem(key);    
    return (result !== null) ? result : "";
}

export const removeSessionStorage = (key) => {
    sessionStorage.removeItem(key);
}