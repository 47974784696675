import {
    GET_WORKSPACES_PAGINATION,
    CLEAR_WORKSPACES_PAGINATION,
    GET_WORKSPACES_MEMBERS_PAGINATION,
    CLEAR_WORKSPACES_MEMBERS_PAGINATION,
    GET_WORKSPACES_FOCUS_AREAS_PAGINATION,
    CLEAR_WORKSPACES_FOCUS_AREAS_PAGINATION,
    GET_APPLICATIONS_PAGINATION,
    CLEAR_APPLICATIONS_PAGINATION,
    GET_USERS_PAGINATION,
    CLEAR_USERS_PAGINATION,
    GET_USER_AVAILABLE_WORKSPACES_PAGINATION,
    CLEAR_USER_AVAILABLE_WORKSPACES_PAGINATION,
    GET_WORKSPACES_SHARE_LIST_PAGINATION,
    CLEAR_WORKSPACES_SHARE_LIST_PAGINATION
} from '../actions/index';

const initialPaginationState = {
    workspace: {
        row_count: 1,
        current_page: 1,
        page_size: 0,
        page_count: 1,
        start_row_number: 1,
        end_row_number: 1,
        total_count: 1
    },
    member: {
        row_count: 1,
        current_page: 1,
        page_size: 0,
        page_count: 1,
        start_row_number: 1,
        end_row_number: 1,
        total_count: 1
    },
    area: {
        row_count: 1,
        current_page: 1,
        page_size: 0,
        page_count: 1,
        start_row_number: 1,
        end_row_number: 1,
        total_count: 1
    },
    application: {
        row_count: 1,
        current_page: 1,
        page_size: 0,
        page_count: 1,
        start_row_number: 1,
        end_row_number: 1,
        total_count: 1
    },
    user: {
        row_count: 1,
        current_page: 1,
        page_size: 0,
        page_count: 1,
        start_row_number: 1,
        end_row_number: 1,
        total_count: 1
    },
    available_workspaces: {
        row_count: 1,
        current_page: 1,
        page_size: 0,
        page_count: 1,
        start_row_number: 1,
        end_row_number: 1,
        total_count: 1,
        has_more: false,
        next_page: 2
    },
    workspaces_share_list: {
        row_count: 1,
        current_page: 1,
        page_size: 0,
        page_count: 1,
        start_row_number: 1,
        end_row_number: 1,
        total_count: 1
    }
}

export const pagination = (state = initialPaginationState, action) => {
    const { workspace, member, application, area, user, available_workspaces, workspaces_share_list } = action

    switch (action.type) {
        case GET_WORKSPACES_PAGINATION:
            return {
                ...state,
                workspace
            }
        case CLEAR_WORKSPACES_PAGINATION:
            return {
                ...state,
                workspace: initialPaginationState.workspace
            }
        case GET_WORKSPACES_MEMBERS_PAGINATION:
            return {
                ...state,
                member
            }
        case CLEAR_WORKSPACES_MEMBERS_PAGINATION:
            return {
                ...state,
                member: initialPaginationState.member
            }
        case GET_WORKSPACES_FOCUS_AREAS_PAGINATION:
            return {
                ...state,
                area
            }
        case CLEAR_WORKSPACES_FOCUS_AREAS_PAGINATION:
            return {
                ...state,
                member: initialPaginationState.area
            }
        case GET_APPLICATIONS_PAGINATION:
            return {
                ...state,
                application
            }
        case CLEAR_APPLICATIONS_PAGINATION:
            return {
                ...state,
                application: initialPaginationState.application
            }
        case GET_USERS_PAGINATION:
            return {
                ...state,
                user
            }
        case CLEAR_USERS_PAGINATION:
            return {
                ...state,
                user: initialPaginationState.user
            }
        case GET_USER_AVAILABLE_WORKSPACES_PAGINATION:
            return {
                ...state,
                available_workspaces
            }
        case CLEAR_USER_AVAILABLE_WORKSPACES_PAGINATION:
            return {
                ...state,
                available_workspaces: initialPaginationState.available_workspaces
            }
        case GET_WORKSPACES_SHARE_LIST_PAGINATION:
            return {
                ...state,
                workspaces_share_list
            }
        case CLEAR_WORKSPACES_SHARE_LIST_PAGINATION:
            return {
                ...state,
                workspaces_share_list: initialPaginationState.workspaces_share_list
            }
        default:
            return state;
    }
};