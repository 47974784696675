interface SortingOption {
  label: string;
  value: string;
}

interface SortingOptions {
  [key: string]: SortingOption[];
}

const tagAscending: SortingOption = {
  label: 'Tag (A-Z)',
  value: 'tag ASC',
};

const tagDescending: SortingOption = {
  label: 'Tag (Z-A)',
  value: 'tag DESC',
};

const nameAscending: SortingOption = {
  label: 'Name (A-Z)',
  value: 'name ASC',
};

const nameDescending: SortingOption = {
  label: 'Name (Z-A)',
  value: 'name DESC',
};

const productNameAscending: SortingOption = {
  label: 'Name (A-Z)',
  value: 'productName ASC',
};

const productNameDescending: SortingOption = {
  label: 'Name (Z-A)',
  value: 'productName DESC',
};

const productTagAscending: SortingOption = {
  label: 'Tag (A-Z)',
  value: 'productTag ASC',
};

const productTagDescending: SortingOption = {
  label: 'Tag (Z-A)',
  value: 'productTag DESC',
};

const usernameDescending: SortingOption = {
  label: 'Username (Z-A)',
  value: 'username DESC',
};

const usernameAscending: SortingOption = {
  label: 'Username (A-Z)',
  value: 'username ASC',
};

const creationTimeDescending: SortingOption = {
  label: 'Newest first',
  value: 'creationTime DESC',
};

const creationTimeAscending: SortingOption = {
  label: 'Oldest first',
  value: 'creationTime ASC',
};

const defaultOptions: SortingOption[] = [
  nameAscending,
  nameDescending,
  creationTimeDescending,
  creationTimeAscending,
];

const options: SortingOptions = {
  products: [tagAscending, tagDescending, ...defaultOptions],
  productSubscriber: [usernameAscending, usernameDescending],
  subscriptions: [productTagAscending, productTagDescending, productNameAscending, productNameDescending, usernameAscending, usernameDescending],
  defaultOptions,
};

const getSortingOptions = (context?: 'products' | 'subscriptions' | 'productSubscriber') => {
  return options[context || 'defaultOptions'];
};

export default getSortingOptions;
