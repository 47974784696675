import { updateDialogUsersLoginErrorAction } from '../actions/updateDialogUsersLoginErrorAction';
import { updateDialogUsersCreationErrorAction } from '../actions/updateDialogUsersCreationErrorAction';
import { updateDialogWorkspacesCreationErrorAction } from '../actions/updateDialogWorkspacesCreationErrorAction';
import { updateDialogApplicationsCreationErrorAction } from '../actions/updateDialogApplicationsCreationErrorAction';
import { updateDialogWorkspacesApplicationUpdateErrorAction } from '../actions/updateDialogWorkspacesApplicationUpdateErrorAction';
import { updateDialogResetPasswordErrorAction } from '../actions/updateDialogResetPasswordErrorAction';
import { updateDialogRegistrationReminderErrorAction } from '../actions/updateDialogRegistrationReminderErrorAction';
import { updateDialogWorkspaceSettingsErrorAction } from '../actions/updateDialogWorkspaceSettingsErrorAction';
import { updateDialogPortalSettingsErrorAction } from '../actions/updateDialogPortalSettingsErrorAction';
import { updateDialogSharingMembersErrorAction } from '../actions/updateDialogSharingMembersErrorAction';
import { updateDialogContactAdministratorsErrorAction } from '../actions/updateDialogContactAdministratorsErrorAction';
import { updateDialogFocusAreasShapeErrorAction } from '../actions/updateDialogFocusAreasShapeErrorAction';
import { updateDialogCategoriesCreationErrorAction } from '../actions/updateDialogCategoriesCreationErrorAction';
import { updateDialogWorkspacesCategoriesCreationErrorAction } from '../actions/updateDialogWorkspacesCategoriesCreationErrorAction';
import { updateToasterAction } from '../actions/updateToasterAction'

export const handleUserConfirmationError = (dispatch, userId, error) => {
    let login_error = {
        id: userId,
        name: '',
        status: error,
        dialog_title: 'Confirmation Failed',
        dialog_type: 'User Confirmation',
        show_dialog: true
    };

    dispatch(updateDialogUsersLoginErrorAction(login_error));
}

export const handleUserLoginError = (dispatch, userId, error) => {
    let errorMessage = error.error;

    if (errorMessage.UserName) {
        errorMessage = errorMessage.UserName[0];
    }
    else if(errorMessage.Password) {
        errorMessage = errorMessage.Password[0];
    }

    let login_error = {
        id: userId,
        name: '',
        status: '',
        dialog_title: errorMessage,
        dialog_type: 'Login Failed',
        show_dialog: true
    };
    dispatch(updateDialogUsersLoginErrorAction(login_error));
}

export const handleUserCreationError = (dispatch, status, message, url) => {
    let user_creation_error = {
        id: '',
        name: url,
        status: status,
        dialog_title: (status === 200) ? 'Creation Success' : 'Creation Failed',
        dialog_type: (status === 200) ? 'User created' : message,
        show_dialog: true
    };

    dispatch(updateDialogUsersCreationErrorAction(user_creation_error));
}

export const handleUserRegistrationError = (dispatch, status, message, errorMessage, url) => {
    let user_creation_error = {
        id: '',
        name: url,
        status: status,
        dialog_title: (status === 200) ? 'Registration Success' : 'Registration Failed',
        dialog_type: (status === 200) ? message : errorMessage,
        show_dialog: true
    };

    dispatch(updateDialogUsersCreationErrorAction(user_creation_error));
}

export const handleMembersShareError = (dispatch, status, message, url) => {
    let user_sharing_error = {
        id: '',
        name: url,
        status: status,
        dialog_title: (status === 200) ? 'Success' : 'Failed',
        dialog_type: (status === 200) ? 'Member(s) are shared to selected workspace(s)' : message,
        show_dialog: true
    };

    dispatch(updateDialogSharingMembersErrorAction(user_sharing_error));
}

export const handleUserUpdateError = (dispatch, status, message, fromProfile) => {
    let user_update_error = {
        id: '',
        name: '',
        status: status,
        dialog_title: (status === 200) ? 'Update Success' : 'Update Failed',
        dialog_type: (status === 200 && fromProfile) ? 'Your profile has been updated' : (status === 200 && !fromProfile) ? 'User profile has been updated' : message,
        show_dialog: true
    };

    dispatch(updateDialogUsersCreationErrorAction(user_update_error));
}

export const handleWorkspaceCreationError = (dispatch, status, message, url) => {
    let workspace_creation_error = {
        id: '',
        name: url,
        status: status,
        dialog_title: (status === 200) ? 'Creation Success' : 'Creation Failed',
        dialog_type: (status === 200) ? 'Workspace created' : message,
        show_dialog: true
    };

    dispatch(updateDialogWorkspacesCreationErrorAction(workspace_creation_error));
}

export const messageHandleUpdateError = (dispatch, status, message) => {
    let toaster = {
        status: status,
        message: message,
        error:'',
        show_dialog: true,
        redirect: false,
        dialog_title: (status === 200) ? 'Update Success' : 'Update Failed',
        dialog_type: message,
    };
    dispatch(updateToasterAction(toaster));
}

export const handleWorkspaceUpdateError = (dispatch, status, message, domainUrl, workspaceName) => {
    let workspace_update_error = {
        id: '',
        name: '',
        status: status,
        dialog_title: (status === 200) ? 'Update Success' : (status !== null) ? 'Update Failed' : 'Warning',
        dialog_type: (status === 200) ? 'Workspace updated' : message,
        show_dialog: true,
        domain_url: domainUrl,
        workspace_name: workspaceName
    };

    dispatch(updateDialogWorkspacesCreationErrorAction(workspace_update_error));
}

export const handleWorkspaceUpdateWarning = (dispatch, status, message, info) => {
    let workspace_update_error = {
        id: '',
        name: '',
        status: status,
        dialog_title: 'Warning',
        dialog_type: message,
        dialog_info: info,
        show_dialog: true
    };

    dispatch(updateDialogWorkspacesCreationErrorAction(workspace_update_error));
}

export const handleApplicationCreationError = (dispatch, status, message, url) => {
    let application_creation_error = {
        id: 'create',
        name: url,
        status: status,
        dialog_title: (status === 200) ? 'Creation Success' : 'Creation Failed',
        dialog_type: (status === 200) ? 'Application created' : message,
        show_dialog: true
    };

    dispatch(updateDialogApplicationsCreationErrorAction(application_creation_error));
}

export const handleApplicationUpdateError = (dispatch, status, message) => {
    let application_creation_error = {
        id: 'update',
        name: '',
        status: status,
        dialog_title: (status === 200) ? 'Update Success' : 'Update Failed',
        dialog_type: (status === 200) ? 'Application updated' : message,
        show_dialog: true
    };

    dispatch(updateDialogApplicationsCreationErrorAction(application_creation_error));
}

export const handleWorkspaceApplicationUpdateError = (dispatch, status, message) => {
    let workspace_application_update_error = {
        id: '',
        name: '',
        status: status,
        dialog_title: (status === 200) ? 'Update Success' : 'Update Failed',
        dialog_type: (status === 200) ? 'Application updated' : message,
        show_dialog: true
    };

    dispatch(updateDialogWorkspacesApplicationUpdateErrorAction(workspace_application_update_error));
}

export const handleResetPasswordError = (dispatch, status, message, url, type, username) => {
    let reset_password_error = {
        id: '',
        name: url,
        status: status,
        dialog_title: (status === 200) ? 'Reset Success' : 'Reset Failed',
        dialog_type: (status === 200) ? (type === 'Admin') ? 'A reset password confirmation has been sent to user ' + username + '.' : 'A reset password confirmation has been sent to your email.' : message,
        show_dialog: true
    };

    dispatch(updateDialogResetPasswordErrorAction(reset_password_error));
}

export const handleRegistrationReminderError = (dispatch, status, message, username) => {
    let registration_reminder_error = {
        id: '',
        name: '',
        status: status,
        dialog_title: (status === 200) ? 'Registration Reminder Sent' : 'Registration Reminder Failed',
        dialog_type: (status === 200) ? 'A registration reminder has been sent to user ' + username + '.' : message,
        show_dialog: true
    };

    dispatch(updateDialogRegistrationReminderErrorAction(registration_reminder_error));
}

export const handleWorkspaceSettingsUpdateError = (dispatch, status, message) => {
    let workspace_settings_update_error = {
        id: '',
        name: '',
        status: status,
        dialog_title: (status === 200) ? 'Update Success' : 'Update Failed',
        dialog_type: (status === 200) ? (message !== '') ? message : 'Settings updated' : message,
        show_dialog: true
    };

    dispatch(updateDialogWorkspaceSettingsErrorAction(workspace_settings_update_error));
}

export const handlePortalSettingsUpdateError = (dispatch, status, message) => {
    let portal_settings_error = {
        id: '',
        name: '',
        status: status,
        dialog_title: (status === 200) ? 'Update Success' : 'Update Failed',
        dialog_type: (status === 200) ? 'Settings updated' : message,
        show_dialog: true
    };

    dispatch(updateDialogPortalSettingsErrorAction(portal_settings_error));
}

export const handleContactAdministratorsError = (dispatch, status, message) => {
    let contact_administrators_error = {
        id: '',
        name: '',
        status: status,
        dialog_title: (status === 200) ? 'Success' : 'Failed',
        dialog_type: (status === 200) ? 'Email sent' : 'Email not send',
        show_dialog: true
    };

    dispatch(updateDialogContactAdministratorsErrorAction(contact_administrators_error));
}

export const handleFocusAreasShapeError = (dispatch, status, message) => {
    let focus_area_shape_error = {
        id: '',
        name: '',
        status: status,
        dialog_title: (status === 200) ? 'Success' : 'Failed',
        dialog_type: (status === 200) ? 'Focus area(s) added' : message,
        show_dialog: true
    };

    dispatch(updateDialogFocusAreasShapeErrorAction(focus_area_shape_error));
}

export const handleWorkspaceCategoryCreationError = (dispatch, status, message, url) => {
    let workspace_category_creation_error = {
        id: 'create',
        name: url,
        status: status,
        dialog_title: (status === 200) ? 'Creation Success' : 'Creation Failed',
        dialog_type: (status === 200) ? 'Workspace category created' : message,
        show_dialog: true
    };

    dispatch(updateDialogWorkspacesCategoriesCreationErrorAction(workspace_category_creation_error));
}

export const handleWorkspaceCategoryUpdateError = (dispatch, status, message, url) => {
    let workspace_category_creation_error = {
        id: 'create',
        name: url,
        status: status,
        dialog_title: (status === 200) ? 'Update Success' : 'Update Failed',
        dialog_type: (status === 200) ? 'Workspace category updated' : message,
        show_dialog: true
    };

    dispatch(updateDialogWorkspacesCategoriesCreationErrorAction(workspace_category_creation_error));
}

export const handleCategoryCreationError = (dispatch, status, message, url) => {
    let category_creation_error = {
        id: 'create',
        name: url,
        status: status,
        dialog_title: (status === 200) ? 'Creation Success' : 'Creation Failed',
        dialog_type: (status === 200) ? 'Category created' : message,
        show_dialog: true
    };

    dispatch(updateDialogCategoriesCreationErrorAction(category_creation_error));
}

export const handleCategoryUpdateError = (dispatch, status, message, url) => {
    let category_creation_error = {
        id: 'create',
        name: url,
        status: status,
        dialog_title: (status === 200) ? 'Update Success' : 'Update Failed',
        dialog_type: (status === 200) ? 'Category updated' : message,
        show_dialog: true
    };

    dispatch(updateDialogCategoriesCreationErrorAction(category_creation_error));
}