import {
    getUserAvailableWorkspacesApi,
    getUserAvailableWorkspacesSearchApi
} from '../backend/domain/users';

import { getUserAvailableWorkspacesAction } from '../actions/getUserAvailableWorkspacesAction';
import { getUserAvailableWorkspacesPaginationAction } from '../actions/getUserAvailableWorkspacesPaginationAction';
import { loadingUserAvailableWorkspacesAction } from '../actions/loadingUserAvailableWorkspacesAction';

export const getUserAvailableWorkspacesWithThunk = (userId, userRole, pageIndex, pageSize) =>
    (dispatch) => {
      const userRolee='Administrator'||userRole
        dispatch(loadingUserAvailableWorkspacesAction(true));
        getUserAvailableWorkspacesApi(userId, userRolee, pageIndex, pageSize)
            .then((response) => {
                dispatch(getUserAvailableWorkspacesPaginationAction(response.pagination));
                return response;
            })
            .then((response) => {
                response.is_opened = true;
                dispatch(getUserAvailableWorkspacesAction(response));
                return response
            })
            .then((response) => {
                dispatch(loadingUserAvailableWorkspacesAction(false));
                return response;
            });
    };

export const getUserAvailableWorkspacesSearchWithThunk = (userId, userRole, pageIndex, pageSize, keyword) =>
    (dispatch) => {
      const userRolee='Administrator'||userRole
        dispatch(loadingUserAvailableWorkspacesAction(true));
        getUserAvailableWorkspacesSearchApi(userId, userRolee, pageIndex, pageSize, keyword)
            .then((response) => {
                dispatch(getUserAvailableWorkspacesPaginationAction(response.pagination));
                return response;
            })
            .then(response => {
                response.is_opened = true;
                dispatch(getUserAvailableWorkspacesAction(response));
            })
            .then((response) => {
                dispatch(loadingUserAvailableWorkspacesAction(false));
                return response;
            });
    };
