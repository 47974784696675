import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

class PortalInfoContainer extends Component {
    static propTypes = {
        portalLogin: PropTypes.object.isRequired
    };

    loginWellcome = React.createRef();

    componentDidUpdate(prevProps) {
        if (this.props.portalLogin.welcome_text !== '' && this.loginWellcome.current !== undefined) {
            for (let i = 0; i < this.loginWellcome.current.children.length; i++) {
                let childNode = this.loginWellcome.current.children[i];
                if (childNode.innerText !== '') {
                    childNode.className = 'draw-wyswyg';
                }
            }
        }
    }

    render() {
        const { portalLogin } = this.props;

        return (
            <div className={(portalLogin.welcome_background_selected === 'NoBackground') ? "h-100 w-100 align-items-center justify-content-center" : "h-100 w-100 d-flex align-items-center justify-content-center"}>
                {
                    (portalLogin.welcome_background_selected === 'NoBackground') ?
                        <div style={{ backgroundColor: portalLogin.welcome_background_color }} className="crop-welcome1820"> </div>
                        : <img src={"data:image/png;base64," + portalLogin.welcome_background_image}
                            className="crop-welcome1820" alt="" />

                }
                <div className="p-5 welcome-logo-index">
                    {(portalLogin.welcome_text_selected === 'Custom') ?
                        <div ref={this.loginWellcome} className="welcome-text-pos" dangerouslySetInnerHTML={{ __html: portalLogin.welcome_text }} />
                        : null
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ portalLogin }) => ({
    portalLogin: portalLogin.portal_login,
});

export default withRouter(connect(mapStateToProps)(PortalInfoContainer));