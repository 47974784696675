const jwt = require('jsonwebtoken');
const { anonymousUserId, anonymousUserName, anonymousUserRole } = require('../configurations/app')

export const decodeToken = (token) => {
    let decoded = jwt.decode(token);
    return decoded;
}

export const encodeToken = (data) => {
    let encoded = jwt.sign(data, 'shhhhh');
    return encoded;
}

export const generateAnonymousToken = () => {
    let data = {
        aud: "",
        email: "",
        exp: 0,
        iat: 0,
        iss: "",
        nameid: anonymousUserId,
        nbf: 0,
        role: anonymousUserRole,
        unique_name: anonymousUserName
    }

    return encodeToken(data);
}