import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { updatePortalSettingFeatureAction } from '../../../../actions/updatePortalSettingFeatureAction';
import { updatePortalSettingFeatureWithThunk, addPortalSettingFeatureWithThunk } from '../../../../api/portalSettingsThunk';
import { updateDialogPortalsAction } from '../../../../actions/updateDialogPortalsAction';
import ImageEditor from '../../../Editor/ImageEditorWithId';
import { Link } from 'react-router-dom';
import { routeCodes } from '../../../../configurations/routes';
import { modalImageEditorStyle } from '../../../../utils/modal';
import RichTextEditor from '../../../Editor/RichTextEditor';
import Modal from 'react-modal';
import { updateDialogPortalSettingsErrorAction } from '../../../../actions/updateDialogPortalSettingsErrorAction';

class PortalSettingsFeatureItem extends Component {
    constructor(props) {
        super(props);
        this.onSettingsTitleChange = this.onSettingsTitleChange.bind(this);
        this.onSettingsDescriptionChange = this.onSettingsDescriptionChange.bind(this);
        this.onSelectFile = this.onSelectFile.bind(this);
        this.onCloseImageEditor = this.onCloseImageEditor.bind(this);
        this.updatePortalSettingFeatureChanges = this.updatePortalSettingFeatureChanges.bind(this);
        this.displayAction = this.displayAction.bind(this);
        this.hideAction = this.hideAction.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.removePortalSettingFeature = this.removePortalSettingFeature.bind(this);
        this.createPortalSettingFeature = this.createPortalSettingFeature.bind(this);
        this.cancelPortalSettingFeature = this.cancelPortalSettingFeature.bind(this);
        this.uploadImg = this.uploadImg.bind(this);

        this.state = {
            src: null,
            imageEditorIsOpen: false,
            stickyActionIsOpen: false
        }
    }

    static propTypes = {
        item: PropTypes.object.isRequired,
        settingFeature: PropTypes.object.isRequired
    };

    onSettingsTitleChange(e) {
        const { item, settingFeature } = this.props;
        let features = settingFeature.features;
        let index = features.findIndex(feature => feature.id === item.id);
        features[index].feature_title = e.target.value;

        let featureSetting = {
            ...settingFeature,
            features
        };

        this.props.dispatchUpdateSettingFeature(featureSetting);
    }

    uploadImg(data, insertImage) {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
            insertImage(reader.result, image => {
                if (image.width() <= 1000) {
                    image.css('width', image.width());
                } else {
                    image.css('width', '100%');
                }
            });

            const { item, settingFeature } = this.props;
            let features = settingFeature.features;
            let index = features.findIndex(feature => feature.id === item.id);
            features[index].feature_description =  reader.result;

            let featureSetting = {
                ...settingFeature,
                features
            };

            this.props.dispatchUpdateSettingFeature(featureSetting);
        }

    }

    onSettingsDescriptionChange(e) {
        const { item, settingFeature } = this.props;
        let features = settingFeature.features;
        let index = features.findIndex(feature => feature.id === item.id);
        features[index].feature_description = e.target.value;

        let featureSetting = {
            ...settingFeature,
            features
        };

        this.props.dispatchUpdateSettingFeature(featureSetting);
    }

    onSelectFile(e) {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                this.setState({
                    src: reader.result, imageEditorIsOpen: true
                })
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    onCloseImageEditor(image, aspectRatio, id) {
        this.setState({ imageEditorIsOpen: false });

        const { settingFeature } = this.props;
        let features = settingFeature.features;
        let index = features.findIndex(feature => feature.id === id);
        features[index].image = image;
        features[index].image_aspect_ratio = aspectRatio;

        let featureSetting = {
            ...settingFeature,
            features
        };

        this.props.dispatchUpdateSettingFeature(featureSetting);
    }

    cancelPortalSettingFeature() {
        const { item, settingFeature } = this.props;
        let features = settingFeature.features;
        let index = features.findIndex(feature => feature.id === item.id);
        features.splice(index, 1);

        let featureSetting = {
            ...settingFeature,
            features
        };

        this.props.dispatchUpdateSettingFeature(featureSetting);
    }

    createPortalSettingFeature() {
        const { item, settingFeature } = this.props;
        let index = settingFeature.features.findIndex(feature => feature.id === item.id);
        if (!settingFeature.features[index].feature_title) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Feature title is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        } else if (!settingFeature.features[index].feature_description) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Feature description is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        }
        else if (!settingFeature.features[index].image_aspect_ratio) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Feature image is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        }
        this.props.addSettingFeature(settingFeature, index);
    }

    updatePortalSettingFeatureChanges() {
        const { item, settingFeature } = this.props;
        let index = settingFeature.features.findIndex(feature => feature.id === item.id);
        if (!settingFeature.features[index].feature_title) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Feature title is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        } else if (!settingFeature.features[index].feature_description) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Feature description is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        }
        else if (!settingFeature.features[index].image_aspect_ratio) {
            let error = {
                status: 500,
                dialog_title: 'Update Failed',
                dialog_type: 'Feature image is required',
                show_dialog: true
            };
            return this.props.dispatchDialogPortalSettingsError(error);
        }
        this.props.updateSettingFeature(settingFeature, index);
    }

    displayAction() {
        this.setState({ stickyActionIsOpen: !this.state.stickyActionIsOpen });
    }

    hideAction() {
        this.setState({ stickyActionIsOpen: false });
    }

    removePortalSettingFeature() {
        const { item } = this.props;

        let portals = {
            id: item.id,
            name: item.feature_title,
            status: item.status,
            dialog_title: 'Warning',
            dialog_type: 'trash',
            dialog_context: 'feature',
            show_dialog: true
        };

        this.props.dispatchDialogPortals(portals);

        this.setState({ stickyActionIsOpen: !this.state.stickyActionIsOpen });
    }

    updateStatus() {
        const { item } = this.props;

        let portals = {
            id: item.id,
            name: item.feature_title,
            status: item.status,
            dialog_title: 'Warning',
            dialog_type: (item.status === 'Enabled') ? 'disable' : 'enable',
            dialog_context: 'feature',
            show_dialog: (item.status === 'Enabled') ? true : false
        };

        this.props.dispatchDialogPortals(portals);

        if (item.status === 'Disabled') {
            const { settingFeature } = this.props;
            let features = settingFeature.features;
            let index = features.findIndex(feature => feature.id === item.id);
            features[index].status = 'Enabled';

            let featureSetting = {
                ...settingFeature,
                features
            };

            this.props.updateSettingFeature(featureSetting, index);
        }

        this.setState({ stickyActionIsOpen: !this.state.stickyActionIsOpen });
    }

    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
        const { item } = this.props;
        const { src, imageEditorIsOpen, stickyActionIsOpen } = this.state;
        return (
            <div className="sectionBanner p-4 mb-5">
                <div className="row">
                    <div className="col-xl-5">
                        <div className="containerBanner p-4 text-center">
                            <img src={((item.image !== undefined && item.image !== "") ? "data:image/jpeg;base64," : process.env.PUBLIC_URL + "/images/no-image.png") + item.image} className="bannerDisplay" alt="" />
                            <span className={(item.status === "Disabled") ? "float-left text-red mt-4 pt-2" : "float-left text-lime mt-4 pt-2"}>
                                {(item.status === "Disabled") ? "Disabled" : "Enabled"}</span>
                            <label className="btn btn-wrd_default icoTextBtn browsePhoto m-0 mt-3">
                                <input type="file" onChange={this.onSelectFile} />
                                <span className="icon-wrd-folder"></span>
                                Browse </label>
                        </div>
                    </div>
                    <div className="col-xl-7">
                        <div className="formWrap mt-5">
                            <div className="inputForm">
                                <input className="form-control" type="text" name=""
                                    defaultValue={item.feature_title} onChange={this.onSettingsTitleChange} required />
                                <label>Title</label>
                            </div>
                            {/* <div className="inputForm">
                                <div className="summernote">
                                    <RichTextEditor value={item.feature_description} onChange={this.onSettingsDescriptionChange}
                                    disabled={false} imgType={"other"}   onImgUpload={this.uploadImg}/>
                                </div>
                            </div> */}
                            <div className="inputForm">
                                <input className="form-control" type="text" name=""
                                    defaultValue={item.feature_description} onChange={this.onSettingsDescriptionChange} />
                                <label>Description</label>
                            </div>
                            {(item.status !== undefined && item.status !== "") ?
                                <div className="d-block text-center">
                                    <button className="btn textBtn greenBtn updateBtn" onClick={this.updatePortalSettingFeatureChanges}>Update Changes</button>
                                </div>
                                :
                                <div className="row text-center">
                                    <div className="col-md-6 col-lg-6 order-md-2">
                                        <button className="btn textBtn greenBtn w-75" onClick={this.createPortalSettingFeature}>Create Feature</button>
                                    </div>
                                    <div className="col-md-6 col-lg-6 order-md-1">
                                        <button className="btn textBtn greyBtn w-75" onClick={this.cancelPortalSettingFeature}> Cancel</button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                {(item.status !== undefined && item.status !== "") ?
                    <button onClick={() => this.displayAction(item.id)} onBlur={() => this.hideAction()} className="btn moreBtn"><span className="icon-wrd-more"></span></button>
                    : null
                }

                <ul className={(stickyActionIsOpen) ? "action show-action" : "action"}>
                    <li><Link to={routeCodes.PORTAL_SETTINGS} onMouseDown={this.updateStatus} className={(item.status === "Disabled") ? "greenLink" : "redLink"}><span
                        className="icon-wrd-lock"></span>{(item.status === "Disabled") ? "Enable" : "Disable"}</Link></li>
                    <li><Link to={routeCodes.PORTAL_SETTINGS} onMouseDown={this.removePortalSettingFeature} className="redLink"><span
                        className="icon-wrd-trash"></span>Remove</Link></li>
                </ul>

                {src &&
                    <Modal
                        isOpen={imageEditorIsOpen}
                        style={modalImageEditorStyle}
                        contentLabel="Image Editor">
                    <ImageEditor srcImage={src} onClose={this.onCloseImageEditor} idEditor={item.id} imgType={"feature"}/>
                    </Modal>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ portalSettings }) => ({
    settingFeature: portalSettings.setting_feature
});

const mapDispatchToProps = (dispatch) => ({
    dispatchDialogPortals: (dialog) => dispatch(updateDialogPortalsAction(dialog)),
    dispatchUpdateSettingFeature: (settingFeature) => dispatch(updatePortalSettingFeatureAction(settingFeature)),
    updateSettingFeature: (settingFeature, index) => dispatch(updatePortalSettingFeatureWithThunk(settingFeature, index)),
    addSettingFeature: (settingFeature, index) => dispatch(addPortalSettingFeatureWithThunk(settingFeature, index)),
    dispatchDialogPortalSettingsError: (dialog) => dispatch(updateDialogPortalSettingsErrorAction(dialog)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortalSettingsFeatureItem));
