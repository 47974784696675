import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class WorkspacesCreateData extends Component {
    static propTypes = {
        workspace: PropTypes.object.isRequired
    };

    render() {
        const { workspace } = this.props;
        return (
            <div className="creationInfo container-fluid">
                <div className="row mb-3">
                    <div className="col-6 p-0">
                        <small className="d-block pt-3 pl-3">Date of Creation</small>
                        <strong className="d-block pt-1 pb-3 pl-3">{workspace.created_date}</strong>
                    </div>
                    {/* <div className="col-6 p-0">
                        <small className="d-block pt-3 pl-3">Creation Incharge</small>
                        <strong className="d-block pt-1 pb-3 pl-3">{workspace.username}</strong>
                    </div> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ setup }) => ({
    workspace: setup.workspace
});

export default withRouter(connect(mapStateToProps)(WorkspacesCreateData));
