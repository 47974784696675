import { GET_WORKSPACE_APPLICATION_LANDING, 
    GET_APPLICATIONS_LANDING,
    GET_APPLICATIONS_LANDING_THEME,
} from './index';

export const getWorkspaceApplicationLandingAction = (workspace_application_landing_data) => ({
    type: GET_WORKSPACE_APPLICATION_LANDING,
    workspace_application_landing_data
});

export const getApplicationsLandingAction = (applications) => ({
    type: GET_APPLICATIONS_LANDING,
    applications
});

export const getApplicationsLandingThemeAction = (theme) => ({
    type: GET_APPLICATIONS_LANDING_THEME,
    theme
});