exports.zoom=7;
exports.minZoom=4;
exports.maxZoom=17;
exports.defaultLat=21.42664;
exports.defaultLng=39.82563;
exports.defaultZoom=1.4;
exports.defaultMinZoom=1;
exports.defaultMaxZoom=17;
exports.defaultBounds=[[-90, -180], [90, 180]];
exports.defaultSelectedFeatureColor="#F34573";
exports.defaultSelectedFeatureDelta=3;
exports.defaultSelectedFeatureLineDelta=5;