import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import SidebarContainer from '../../Sidebar/SidebarContainer';
import CategoriesCreateBody from './CategoriesCreateBody';
import CategoriesCreationErrorModal from '../Modal/CategoriesCreationErrorModal';
import { changeNavigationAction } from '../../../actions/changeNavigationAction';
import { setupNewWorkspaceCategoryAction } from '../../../actions/setupNewWorkspaceCategoryAction';
import { clearSetupNewWorkspaceCategoryAction } from '../../../actions/clearSetupNewWorkspaceCategoryAction';

class CategoriesCreateContainer extends Component {
    static propTypes = {
        category: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        navigation: PropTypes.object.isRequired,
        dispatchNavigation: PropTypes.func.isRequired
    };

    changeNavigation(name) {
        let navigation = {
            name
        };
        this.props.dispatchNavigation(navigation);
    }

    dispatchNewCategory(id, title, description) {
        let newCategory = {
            ...this.props.category,
            category_id: id,
            category_title: title,
            category_description: description
        };
        this.props.setupNewCategory(newCategory);
    }

    setDefaultData() {
        this.dispatchNewCategory("", "", "");
    }

    componentWillMount() {
        this.setDefaultData();
    }

    componentWillUnmount() {
        this.props.clearSetupNewCategory();
    }

    componentDidMount() {
        this.changeNavigation('Workspaces');
    }

    render() {
        const { loading, loadingTheme } = this.props;

        return (
            <>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="BallTriangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <CategoriesCreateBody />
                                </div>
                            </div>
                        </main>
                        <CategoriesCreationErrorModal type="error" />
                    </div>
                }
            </>
        );
    }
}

const mapStateToProps = ({ setup, loading, navigation }) => ({
    category: setup.workspace_category,
    loading: loading.create_workspace_category.loading,
    loadingTheme: loading.theme.loading,
    navigation
});

const mapDispatchToProps = (dispatch) => ({
    setupNewCategory: (user) => dispatch(setupNewWorkspaceCategoryAction(user)),
    clearSetupNewCategory: () => dispatch(clearSetupNewWorkspaceCategoryAction([])),
    dispatchNavigation: (navigation) => dispatch(changeNavigationAction(navigation))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoriesCreateContainer));
