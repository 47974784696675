import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setupNewUserAction } from '../../../actions/setupNewUserAction'
import { registerWorkspacesMembersWithThunk } from '../../../api/workspacesMembersThunk';
import { getUrlWorkspaceId } from '../../../utils/url';
import { overrideWorkspaceId } from '../../../utils/workspaces';
import { routeCodes } from '../../../configurations/routes';

class UserRegisterAction extends Component {
    constructor(props) {
        super(props);
        this.registerNewUser = this.registerNewUser.bind(this);
        this.cancelRegister = this.cancelRegister.bind(this);
    }

    static propTypes = {
        workspace: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        routeBack: PropTypes.object.isRequired,
        setupNewUser: PropTypes.func.isRequired,
        registerWorkspaceMember: PropTypes.func.isRequired,
        scrollTop: PropTypes.func.isRequired
    };

    cancelRegister() {
        this.props.history.goBack()
    }

    async registerNewUser() {
        const { user } = this.props;

        this.props.scrollTop();
        let id = getUrlWorkspaceId(this.props.location.pathname);
        if (id !== "") {
            let wId = await overrideWorkspaceId(id);
            this.props.registerWorkspaceMember(wId, user, routeCodes.WORKSPACES_LIST + "/" + id);
        }
    }

    render() {
        return (
            <div className="col-md-12">
                <div className="inputForm buttonGroup mt-2 text-center">
                    <button className="btn greyBtn icoTextBtn mx-2" onClick={this.cancelRegister}><span className="icon-wrd-close"></span>Cancel</button>
                    <button className="btn greenBtn icoTextBtn mx-2" onClick={this.registerNewUser}><span className="icon-wrd-add"></span>Register</button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ detail, setup, routeBack }) => ({
    workspace: detail.workspace,
    user: setup.user,
    routeBack: routeBack.create_user
});

const mapDispatchToProps = (dispatch) => ({
    setupNewUser: (user) => dispatch(setupNewUserAction(user)),
    registerWorkspaceMember: (id, member, url) => dispatch(registerWorkspacesMembersWithThunk(id, member, url))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserRegisterAction));