import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clearWorkspaceCategoryAction } from '../../../../actions/clearWorkspaceCategoryAction';
import { getWorkspaceCategoryByIdWithThunk } from '../../../../api/workspacesCategoriesThunk';
import { getUrlWorkspaceId, getUrlWorkspaceCategoryId } from '../../../../utils/url';
import Loader from 'react-loader-spinner'
import SidebarContainer from '../../../Sidebar/SidebarContainer';
import WorkspacesCategoriesDetailBody from './WorkspacesCategoriesDetailBody';
import { changeNavigationAction } from '../../../../actions/changeNavigationAction';
import WorkspacesCategoriesCreationErrorModal from '../Modal/WorkspacesCategoriesCreationErrorModal';

class WorkspacesCategoriesDetailContainer extends Component {
    static propTypes = {
        category: PropTypes.object.isRequired,
        getWorkspaceCategoryById: PropTypes.func.isRequired,
        clearWorkspaceCategory: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        dispatchNavigation: PropTypes.func.isRequired
    };

    getCurrentWorkspaceId = () => {
        let id = getUrlWorkspaceId(this.props.location.pathname);
        return id;
    }

    getCurrentWorkspaceCategoryId = () => {
        let id = getUrlWorkspaceCategoryId(this.props.location.pathname);
        return id;
    }

    changeNavigation(name) {
        let navigation = {
            name
        };
        this.props.dispatchNavigation(navigation);
    }

    componentDidMount() {
        const workspaceId = this.getCurrentWorkspaceId();
        const categoryId = this.getCurrentWorkspaceCategoryId();
        this.props.getWorkspaceCategoryById(workspaceId, categoryId);

        this.changeNavigation('Worksapces');
    }

    componentWillUnmount() {
        this.props.clearWorkspaceCategory();
    }

    render() {
        const { category, loading, loadingTheme, role } = this.props;

        if (category.category_id === undefined || category.category_id === '')
            return (
                <div>
                    {(loading || loadingTheme) &&
                        <div className="loader-container">
                            <Loader
                                type="BallTriangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                </div>
            );

        return (
            <div>
                {loading &&
                    <div className="loader-container">
                        <Loader
                            type="BallTriangle"
                            color="#00BFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                }
                {!loadingTheme &&
                    <div>
                        <SidebarContainer />
                        <main>
                            <div className="mapBackground" style={{ backgroundImage: "url(" + process.env.PUBLIC_URL + "/images/map-background.png)" }}></div>
                            <div className="container-fluid h-100">
                                <div className="mcp">
                                    <WorkspacesCategoriesDetailBody role={role} />
                                </div>
                            </div>
                        </main>
                        <WorkspacesCategoriesCreationErrorModal type="error" />
                    </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ detail, loading, role, navigation }) => ({
    category: detail.workspace_category,
    loading: loading.workspace_category.loading,
    loadingTheme: loading.theme.loading,
    role,
    navigation
});

const mapDispatchToProps = (dispatch) => ({
    getWorkspaceCategoryById: (workspaceId, categoryId) => dispatch(getWorkspaceCategoryByIdWithThunk(workspaceId, categoryId)),
    clearWorkspaceCategory: () => dispatch(clearWorkspaceCategoryAction([])),
    dispatchNavigation: (navigation) => dispatch(changeNavigationAction(navigation))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkspacesCategoriesDetailContainer));