import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner'
import { userLoginConfirmationWithThunk } from '../../../api/loginThunk';
import { updateLoginAction } from '../../../actions/updateLoginAction';
import { encryptPassword, checkPassword } from '../../../utils/password'
import { handleUserConfirmationError } from '../../../utils/errorMessages';

class UsersConfirmationLoginBody extends Component {
    constructor(props) {
        super(props);
        this.login = this.login.bind(this);
        this.enterLogin = this.enterLogin.bind(this);
        this.handleChangeUserName = this.handleChangeUserName.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeNewPassword = this.handleChangeNewPassword.bind(this);
        this.handleChangeConfirmNewPassword = this.handleChangeConfirmNewPassword.bind(this);
        this.state = {
            password_validation: '',
            new_password_validation: '',
            confirm_password_validation: ''
        };
    }

    static propTypes = {
        login: PropTypes.object.isRequired,
        loading: PropTypes.bool.isRequired,
        userLoginConfirmation: PropTypes.func.isRequired,
        dispatchLogin: PropTypes.func.isRequired,
        dispatchDialogUsersLoginError: PropTypes.func.isRequired,
        portalLogin: PropTypes.object.isRequired,
        userId: PropTypes.string.isRequired,
        token: PropTypes.string.isRequired,
        actionType: PropTypes.string.isRequired
    };

    async login() {
        const { login, userId, token, actionType } = this.props;

        if (login.new_password !== login.change_new_password) {
            this.props.dispatchDialogUsersLoginError(userId, "New and confirmation password do not match.");
        }
        else {
            // await this.props.userLoginConfirmation(userId, login.user_name, token, encryptPassword(login.password), encryptPassword(login.new_password), actionType, this.props.history);
            await this.props.userLoginConfirmation(userId, login.user_name, token, login.password, login.new_password, actionType, this.props.history);

        }
    }

    async enterLogin(e) {
        if (e.key === 'Enter') {
            this.login();
        }
    }

    handleChangeUserName(e) {
        const login = {
            ...this.props.login,
            user_name: e.target.value
        }

        this.props.dispatchLogin(login);
    }

    handleChangePassword(e) {
        const login = {
            ...this.props.login,
            password: e.target.value
        }

        let passwordCheck = checkPassword(login.password);
        if (passwordCheck === 'OK') {
            this.setState({ password_validation: '' })
        } else {
            this.setState({ password_validation: passwordCheck })
        }

        this.props.dispatchLogin(login);
    }

    handleChangeNewPassword(e) {
        const login = {
            ...this.props.login,
            new_password: e.target.value
        }

        let passwordCheck = checkPassword(login.new_password);
        if (passwordCheck === 'OK') {
            this.setState({ new_password_validation: '' })
        } else {
            this.setState({ new_password_validation: passwordCheck })
        }

        this.props.dispatchLogin(login);
    }

    handleChangeConfirmNewPassword(e) {
        const login = {
            ...this.props.login,
            change_new_password: e.target.value
        }

        let passwordCheck = checkPassword(login.change_new_password);
        if (passwordCheck === 'OK') {
            this.setState({ confirm_password_validation: '' })
        } else {
            this.setState({ confirm_password_validation: passwordCheck })
        }

        this.props.dispatchLogin(login);
    }

    render() {
        const { portalLogin, loading } = this.props;

        return (
            <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                {
                    (portalLogin.login_background_selected === 'NoBackground') ?
                        <div style={{ backgroundColor: portalLogin.login_background_color }} className="crop-welcome1820"> </div>
                        : <img src={"data:image/png;base64," + portalLogin.login_background_image}
                            className="crop-welcome1820" alt="" />

                }
                <div className="w-100 p-4 text-center">
                    {loading &&
                        <div className="loader-container">
                            <Loader
                                type="BallTriangle"
                                color="#00BFFF"
                                height="100"
                                width="100"
                            />
                        </div>
                    }
                    <button className="loginBackBtn btn">
                        <span className="icon-wrd-arrow-left"></span>
                    </button>
                    <div className="row" onKeyPress={this.enterLogin}>
                        <div className="formWrap col-10 offset-1 col-md-8 offset-md-2">
                            <h2>Login</h2>
                            <p className="mb-5">Welcome, please login to your account.</p>
                            <div className="inputForm">
                                <input className="form-control" type="text" name="" onChange={this.handleChangeUserName} required />
                                <label>Username</label>
                            </div>
                            <div className="inputForm">
                                <input className="form-control text-password" type="text" name="" onChange={this.handleChangePassword} required />
                                <label>Password</label>
                                <span className="password-validation">{this.state.password_validation}</span>
                            </div>
                            <div className="inputForm">
                                <input className="form-control text-password" type="text" name="" onChange={this.handleChangeNewPassword} required />
                                <label>New Password</label>
                                <span className="password-validation">{this.state.new_password_validation}</span>
                            </div>
                            <div className="inputForm">
                                <input className="form-control text-password" type="text" name="" onChange={this.handleChangeConfirmNewPassword} required />
                                <label>Confirm New Password</label>
                                <span className="password-validation">{this.state.confirm_password_validation}</span>
                            </div>
                            <div className="row mt-5">
                                <div className="col-6 positionRemember">
                                    <div className="custom-control custom-checkbox">
                                        <input type="checkbox" className="custom-control-input" id="endDate" />
                                        <label className="custom-control-label pl-2" htmlFor="endDate">Remember me</label>
                                    </div>
                                </div>
                            </div>
                            <button className="btn textBtn updateBtn greenBtn mt-5 w-50 loginBtn" onClick={this.login}>Login</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = ({ detail, loading, portalLogin }) => ({
    login: detail.login,
    loading: loading.login.loading,
    portalLogin: portalLogin.portal_login
});

const mapDispatchToProps = (dispatch) => ({
    userLoginConfirmation: (userId, userName, token, password, newPassword, actionType, historyProps) => dispatch(userLoginConfirmationWithThunk(userId, userName, token, password, newPassword, actionType, historyProps)),
    dispatchLogin: (login) => dispatch(updateLoginAction(login)),
    dispatchDialogUsersLoginError: (userId, error) => handleUserConfirmationError(dispatch, userId, error)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersConfirmationLoginBody));
